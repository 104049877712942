/* eslint-disable react/prop-types */
// const { default: InputText } = require("../Inputs/inputText/inputText")
import React, { useEffect, useState } from 'react'
import { SelectSimpleComponent } from 'views/Admin/Shared/Forms/SelectSimple'
import { secteurActivity } from '../Inputs/InputSelect/dataSelection'
import InputText from '../Inputs/inputText/inputText'

const InputsIntroduction = ({ setIntroduction, introduction, setIsSaved }) => {
  const [inputs, setInputs] = useState(introduction)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (isLoad) {
      setIntroduction((prev) => ({ ...prev, ...inputs }))
    }
    setIsLoad(true)
  }, [inputs])

  const handleChange = (e, type) => {
    setIsSaved(false)
    setInputs((prev) => ({ ...prev, [type]: e?.target?.value }))
  }
  const handleSelect = (value) => {
    setIsSaved(false)
    setInputs((prev) => ({ ...prev, activity: value }))
  }
  return (
    <div className='container'>
      <div className='content' style={{ padding: '10px 10px 50px 120px' }}>
        <div className="input-title">
          <InputText value={inputs?.title}
            placeholder='Titre useCase' bgIsInWhite={false}
            handleChange={(e) => handleChange(e, 'title')} label='Titre' />
        </div>
        <div className="input-title">
          <SelectSimpleComponent items={secteurActivity.sort()} label="Secteur d'activité" name={'activity'}
            defaultValue={inputs?.activity} handleAction={handleSelect} />
        </div>
        <div className='paragraphsS'>
          <div className="input-p1">
            <InputText value={inputs?.subTitle}
              placeholder='Sous titre' bgIsInWhite={false}
              handleChange={(e) => handleChange(e, 'subTitle')} label='Sous titre' />
          </div>
          <div className="input-p2">
            <InputText value={inputs?.description}
              placeholder='Description' bgIsInWhite={false}
              handleChange={(e) => handleChange(e, 'description')} label='Description' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputsIntroduction
