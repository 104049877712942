/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AWS from 'aws-sdk'
import { MenuItem, Modal, Select } from '@mui/material'
import { useLocation, useSearchParams } from 'react-router-dom'
import { campaignsListFetched, getCampaignsList } from '_redux/Campaigns/reducers'
import API from 'services/axios-config'
import { getAllMissionsError, haveAllMissions } from '_redux/Missions/reducers'
import moment from 'moment'
import { RightModalProvider } from 'views/Lists/Campaigns/rightModalContext'
import HeaderList from 'views/Lists/Headers'
import BtnComponent from 'components/GlobalInputs/Btn'
import { SelectInputMultiple } from 'components/GlobalInputs/Select'
import { ReactComponent as CloseSvg } from 'assets/close.svg'
import CheckIcon from "@mui/icons-material/Check";
import { smIsGet, haveAllSM, getAllSMError } from '_redux/StreetMarketers/reducers'

const LeftComponent = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  };

  function handleKeyDown(e) {
    const params = new URLSearchParams(searchParams);
    if (e.key === "Enter") {
      if (params.has('search')) {
        params.set('search', inputValue);
      } else {
        params.append('search', inputValue);
      }
      setSearchParams(params);
    }
  }

  return (
    <div className="bookmark-list">
      <h1 className="bookmark-title">{props.title}</h1>
      <div className="bookmark-filter">
        <input value={inputValue} onKeyDown={handleKeyDown} onChange={handleInputChange} type="text" placeholder="Rechercher..." className="temporary-filter" />
      </div>
    </div>
  );
};

const AdminGalleryView = () => {
  const [imageList, setImageList] = useState([])
  const [currentScreenSize, setCurrentScreenSize] = useState(null)
  const cloudfront = process.env.REACT_APP_CLOUDFRONT_URL;
  const [photoView, setphotoView] = useState(null)
  const [photoSelect, setPhotoSelect] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()
  const newRef = useRef()
  const paramsObj = Object.fromEntries(searchParams.entries())
  const [selectMode, setSelectMode] = useState(false)

  useEffect(() => {
    AWS.config.update({
      accessKeyId: 'AKIAWZ3GZQIZTYAMH2WX',
      secretAccessKey: '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',
      region: 'eu-west-3'
    })
  }, [])

  useEffect(() => {
    if (newRef.current) { setCurrentScreenSize(newRef?.current?.getBoundingClientRect()?.width) }
  }, [newRef.current])

  async function fetchImagesFromS3Folder() {
    const getNewUrl = () => {
      const folder = paramsObj?.folder ? `${paramsObj?.folder}/` : null
      const campaign_id = paramsObj?.campaign_id ? `${paramsObj?.campaign_id}/` : null
      const mission_id = paramsObj?.mission_id ? `missions/${paramsObj?.mission_id}/` : null
      const fullUrl = (folder || '') + (campaign_id || '') + (mission_id || '')
      return fullUrl
    }
    setImageList([])
    const s3 = new AWS.S3()
    try {
      const url = getNewUrl()
      const folderPath = url
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
        Prefix: folderPath
      }

      const response = await s3.listObjectsV2(params).promise()

      for (const object of response.Contents) {
        const imageUrl = `${cloudfront}/${object.Key}`
        setImageList(prev => [...prev, imageUrl])
      }
    } catch (error) {
      console.log('Erreur lors de la récupération des images depuis S3:', error)
    }
  }

  useEffect(() => {
    Object.keys(paramsObj).length !== 0 && fetchImagesFromS3Folder()
    Object.keys(paramsObj).length === 0 && setImageList([])
  }, [searchParams])

  const handleSelectImg = (src) => {
    if (!photoSelect?.includes(src)) { setPhotoSelect(prev => [...prev, src]) } else { setPhotoSelect(prev => prev?.filter(item => item !== src)) }
  }

  const PictureView = ({ src, setphotoView, photoView }) => {
    const isSelect = photoSelect?.includes(src)
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: currentScreenSize / 5, padding: '10px', width: currentScreenSize / 5, position: 'relative' }}>
        <img onClick={() => selectMode ? handleSelectImg(src) : setphotoView(src)} src={src}
          style={{ objectPosition: 'center', objectFit: 'cover', width: '100%', height: '100%', borderRadius: 15 }} />
        {selectMode && <div
          onClick={() => selectMode ? handleSelectImg(src) : setphotoView(src)}
          style={{
            position: 'absolute',
            top: 0,
            right: 10,
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}>
          {isSelect ? <div className="select-icon" style={{ width: 30, height: 30 }}>
            <CheckIcon className="check" />
          </div> : <div className="select-icon" style={{ backgroundColor: 'white', width: 30, height: 30, border: '1px solid black' }}>
          </div>}
        </div>}
      </div>
    )
  }

  const removeFromObject = (keysToRemove) => {
    keysToRemove.forEach(key => {
      delete paramsObj[key]
    })
    return paramsObj
  }

  const CampaignList = () => {
    const { list } = useSelector(state => state?.campaignsList)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      dispatch(getCampaignsList())
      setIsLoading(list.length == 0);
      API.get('/campaign_names').then(result => {
        dispatch(campaignsListFetched(result?.data))
      }).catch(_ => _)
        .finally(()=> setIsLoading(false))
    }, [dispatch])

    return (
      <div style={{ marginTop: 20 }}>
        <SelectInputMultiple
          label="Liste des campagnes"
          value={selectedCampaigns?.e}
          items={list}
          keys={['campaign_name']}
          disabled={isLoading}
          onChange={(e) => setSelectedCampaigns((prev) => {
            return {
              ...prev,
              e
            }
          })}
        />
      </div>
    )
  }

  const SmList = () => {
    const { fetching, users } = useSelector((state) => state.streetMarketer);

    useEffect(() => {
      dispatch(smIsGet([]))
  
      API.get(`/gallery_users`)
        .then((res) => {
          dispatch(haveAllSM(res.data))
        })
        .catch(() => {
          dispatch(getAllSMError());
        });
    }, [dispatch]);
  
    return (
      <div style={{ marginTop: 20 }}>
        <SelectInputMultiple
          label="Liste des street marketers"
          value={selectedSms?.e}
          items={users}
          keys={['first_name', 'last_name']}
          disabled={fetching}
          onChange={(e) => setSelectedSms((prev) => {
            return {
              ...prev,
              e
            }
          })}
        />
      </div>
    )
  }

  const Gallery = ({ images, setphotoView, photoView, selectedCampaigns, selectedSms }) => {
    const extractCampaignIdFromUrl = (url) => {
      const parts = url.split('/');
      const campaignIndex = parts.indexOf('campaigns');
      return campaignIndex !== -1 ? Number(parts[campaignIndex + 1]) : null;
    };
  
    const extractProfileIdFromUrl = (url) => {
      const parts = url.split('/');
      const profileIndex = parts.indexOf('profile-pictures');
      return profileIndex !== -1 ? Number(parts[profileIndex + 1]) : null;
    };
  
    const filteredImages = images.filter((image) => {
      if (paramsObj?.folder === 'campaigns') {
        const campaignId = extractCampaignIdFromUrl(image);
        return selectedCampaigns?.e?.length === 0 || selectedCampaigns?.length === 0 || selectedCampaigns?.e?.some(campaign => campaign.id === campaignId);
      } else if (paramsObj?.folder === 'profile-pictures') {
        const profileId = extractProfileIdFromUrl(image);
        return selectedSms?.e?.length === 0 || selectedSms?.length === 0 || selectedSms?.e?.some(sm => sm.id === profileId);      }
      return true;
    });
    return (
      <div
        ref={newRef}
        style={{ flexDirection: 'row', flexWrap: 'wrap', display: 'flex', margin: '20px 0px', justifyContent: 'center' }}
      >
        {currentScreenSize && filteredImages.map((value, index) => {
          const parts = value?.split('/')
          const lastPart = parts.pop()
          return <PictureView key={`${lastPart}`} src={value} setphotoView={setphotoView} photoView={photoView} />
        })}
      </div>
    )
  }

async function downloadImages(urls) {
  const queryString = new URLSearchParams();
  urls.forEach(url => queryString.append('urls[]', url));

   const downloadUrl = `${process.env.REACT_APP_BACK_API}/download_gallery_images?${queryString.toString()}`;
   window.open(downloadUrl, "_blank");
}



  const { pathname } = useLocation();
  const [currentView, setCurrentView] = useState('profile-pictures')
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedSms, setSelectedSms] = useState([])

  return (
    <RightModalProvider>
      <div className="list-container">
        <div className="list-content">
          <HeaderList
            leftComponent={
              <LeftComponent
                title={pathname.split("/").slice(-1)[0].toUpperCase()}
              />
            }
          />
          <div style={{ backgroundColor: 'white', height: '100%', padding: '50px 30px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
              <div style={{ fontSize: 16, fontWeight: '700' }}>TYPE DE PHOTO</div>
              <BtnComponent
                text={!selectMode ? 'SÉLECTIONNER PLUSIEURS IMAGES' : 'ANNULER SÉLECTION'}
                onClick={() => { !selectMode ? setSelectMode(true) : setSelectMode(false); setPhotoSelect([]) }}
                variant="rounded"
                color={selectMode ? 'red' : '#ffff'}
                style={{ fontWeight: 800, fontSize: 12, width: 'fit-content', backgroundColor: '#23272A' }}
              />
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30, flexWrap: 'wrap' }}>
              <BtnComponent
                text={'PHOTO DE PROFIL'}
                onClick={() => {
                  setCurrentView('profile-pictures')
                  setSearchParams({ folder: 'profile-pictures' })
                }}
                variant="regular"
                color={currentView === "profile-pictures" ? '#60EED2' : "white"}
                style={{ borderRadius: 10, fontWeight: 700, fontSize: 17, width: 'fit-content', height: 60, width: 355, backgroundColor: '#23272A', margin: '10px 0px' }}
              />
              <BtnComponent
                text={'PHOTO DE CAMPAGNE'}
                onClick={() => {
                  setCurrentView('campaigns')
                  setSearchParams({ folder: 'campaigns' })
                }}
                variant="regular"
                color={currentView === "campaigns" ? '#60EED2' : "white"}
                style={{ borderRadius: 10, fontWeight: 700, fontSize: 17, width: 'fit-content', height: 60, width: 355, backgroundColor: '#23272A', margin: '10px 0px' }}
              />
              <BtnComponent
                text={'IMAGES VITRINE'}
                onClick={() => {
                  setCurrentView('vitrine_v3')
                  setSearchParams({ folder: 'vitrine_v3' })
                }
                }
                variant="regular"
                color={currentView === "vitrine_v3" ? '#60EED2' : "white"}
                style={{ borderRadius: 10, fontWeight: 700, fontSize: 17, width: 'fit-content', height: 60, width: 355, backgroundColor: '#23272A', margin: '10px 0px' }}
              />
            </div>

            {
              paramsObj?.folder && paramsObj?.folder === 'campaigns' &&
              <CampaignList />
            }
            {
              paramsObj?.folder && paramsObj?.folder === 'profile-pictures' &&
              <SmList />
            }
            <Modal open={!!photoView} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ backgroundColor: '#fff', borderRadius: 15 }}>
                <div style={{ marginRight: 15, marginTop: 10, display: 'flex', flexDirection: 'row-reverse', cursor: 'pointer' }} onClick={() => setphotoView(null)}><CloseSvg width={30} height={26} /></div>
                <img src={photoView} style={{ padding: 20, maxWidth: '80vw', maxHeight: '80vh' }} />
                <BtnComponent
                  text={'TÉLÉCHARGER'}
                  onClick={() => downloadImages([photoView])}
                  color={'#60EED2'}
                  style={{
                    fontWeight: 800, fontSize: 14, backgroundColor: '#23272A', fontWeight: '700', height: 50, borderBottomLeftRadius: 12, borderBottomRightRadius: 12
                  }}
                />
              </div>
            </Modal>
            <Gallery images={imageList} setphotoView={setphotoView} photoView={photoView} selectedCampaigns={selectedCampaigns} selectedSms={selectedSms} />
            {photoSelect?.length > 0 &&
              <BtnComponent
                text={'TÉLÉCHARGER LES PHOTOS'}
                onClick={() => downloadImages(photoSelect)}
                variant="rounded"
                color={'#ffff'}
                style={{
                  position: 'fixed',
                  zIndex: 1000,
                  bottom: 16, right: 0, left: '50%', fontWeight: 800, fontSize: 14, width: 450, backgroundColor: '#23272A', fontWeight: '700', height: 50, transform: 'translate(-50%, -50%)',
                }}
              />
            }
          </div>
        </div>
      </div>
    </RightModalProvider>
  )
}

export default AdminGalleryView
