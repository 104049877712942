/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import { ReactComponent as CloseSvg } from 'assets/close.svg'
import { ReactComponent as BackLogo } from 'assets/back-arrow.svg'
import './style.scss'

const DisplaySinglePhoto = (props) => {
  const { displayedImage, setDisplayedImage, photoArray, handleAddPhoto, selectedImages } = props
  
  const isSelected = useMemo(() => {
    return (selectedImages.find(image => image.id === displayedImage.id))
  }, [selectedImages, displayedImage])

  const handleDownloadPhoto = (url) => {
    const encodedUrls = [encodeURIComponent(url)]
    const downloadUrl = `${
      process.env.REACT_APP_BACK_API
    }/download_array_images?images=${JSON.stringify(encodedUrls)}`
    const downloadWindow = window.open(downloadUrl, '_blank')
    setTimeout(() => {
      downloadWindow.close()
    }, 300000)
  }

  const changePhoto = (value) => {
    const currentIndex = photoArray.findIndex((photo) => photo.picture_url === displayedImage.picture_url)
    let newIndex
    if (value === 'previous') {
      newIndex = currentIndex > 0 ? currentIndex - 1 : photoArray.length - 1
    } else if (value === 'next') {
      newIndex = currentIndex < photoArray.length - 1 ? currentIndex + 1 : 0
    }
    setDisplayedImage(photoArray[newIndex])
  }

  return (
    <div className="single-photo-container">
      <div className="single-photo-header-container">
        <div className={`single-photo-header-content-item ${isSelected ? 'unselect' : 'select'}`} onClick={() => handleAddPhoto(displayedImage)}>
        {isSelected ? 'DÉSELECTIONNER' : 'SÉLECTIONNER'}
        </div>

        <div className="single-photo-header-content-item" onClick={() => setDisplayedImage(null)}>
          <CloseSvg className="close-icon" />
        </div>
      </div>
      <div className="photo-display">
        <img
          src={process.env.REACT_APP_BACK_API + displayedImage.picture_url}
          alt="display-photo"
          className="display-image"
        />
        <div className="navigation-buttons">
          <div className="prev-button" onClick={() => changePhoto('previous')}>
            <BackLogo className="back-icon" />
          </div>
          <div className="next-button" onClick={() => changePhoto('next')}>
            <BackLogo className="back-icon" />
          </div>
        </div>
      </div>
      <div className="download-button" onClick={() => handleDownloadPhoto(displayedImage.picture_url)}>
        TÉLÉCHARGER
      </div>
    </div>
  )
}

export default DisplaySinglePhoto
