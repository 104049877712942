/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import style from './styles.scss'
import { ButtonComponent } from 'views/Admin/Shared/Buttons/Generic'

export const ModalConfirmationComponent = ({
  open,
  title,
  text,
  confirmation,
  handleConfirm,
  handleCancel
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={style.box}>
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 3 }}>
          {text}
        </Typography>
        <Typography id="modal-modal-description" variant="subtitle1" sx={{ mt: 3, mb: 5 }}>
          {confirmation}
        </Typography>
        <Box className={style.buttons}>
          <ButtonComponent
            text='Confirmer'
            handleActions={() => handleConfirm()}
            color='green'
          />
          <ButtonComponent
            text='Annuler'
            handleActions={() => handleCancel()}
            color='red'
          />
        </Box>
      </Box>
    </Modal>
  )
}
