import React from "react";
import Prestations from "./Prestations";
import PublicateButtons from "./Prestations/publicateButtons";

const LeftLayout = (props) => {
  return (
    <div
      style={leftLayoutContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <Prestations { ...props }/>
      {!props.clientView && <PublicateButtons />}
    </div>
  );
};

const leftLayoutContainer = {
  width: 250,
  zIndex: 999,
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  pointerEvents: "none",
  top: 90,
  bottom: 60,
  left: 10,
};

export default LeftLayout;
