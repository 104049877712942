import React, { memo, useMemo } from 'react'

const divideArray = (array, length) => {
  const newArray = [...array]
  const divideRes = Math.floor(newArray.length / length)
  let results = []
  for (let i = 0; i < length; i++) {
    results.push(newArray.splice(0, divideRes))
  }
  for (let i = 0; i < newArray.length; i++) {
    results[i].push(newArray[i])
  }
  results = results.filter(itm => itm.length)
  return results
}

const masonryContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  gap: 10
}

const masonryColumnStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 10
}

const Masonry = ({ dataArray, columnCount, ChildsElement, removeUsecase, setUseCaseList }) => {
  return useMemo(() => {
    const arr = divideArray(dataArray, columnCount)
    return (
      <div style={masonryContainerStyle}>
        {
          arr?.map((itm, indexRow) => <div key={`mansory-${indexRow}`} style={masonryColumnStyle}>
            {
              itm?.map((elm, indexColumn) =>
                <ChildsElement data={elm} indexColumn={indexColumn}
                  indexRow={indexRow} key={elm?.id || indexColumn}
                  setUseCaseList={setUseCaseList}
                  removeUsecase={removeUsecase}
                />)
            }
          </div>)
        }
      </div>
    )
  }, [dataArray, columnCount])
}

export default memo(Masonry)
