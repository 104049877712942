/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Checkbox } from '@mui/material'
import './style.scss'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as StatusSvg } from 'assets/status.svg'
import { ReactComponent as CalendarSvg } from 'assets/calendar.svg'
import CalendarRangePicker from 'components/CalendarRangePicker'
import { ReactComponent as CloseSvg } from 'assets/close.svg'
import moment from 'moment'

const filters = {
  status: [
    'Brouillon',
    'En recherche de SM',
    'En cours',
    'Terminé',
    'Non publié',
  ]
}

const filtersIcons = {
  status: StatusSvg
}

export const Filter = (props) => {
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    campaign_dates: undefined
  })
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const [filterSectionsState, setFilterSectionsState] = useState({})

  const toggleFilterSection = (sectionKey) => {
    setFilterSectionsState((prevState) => ({
      ...prevState,
      [sectionKey]: !prevState[sectionKey]
    }))
  }

  useEffect(() => {
    const filtersFromQueryParams = {}
    const paramKeys = Array.from(queryParams.keys())
    for (const key of paramKeys) {
      const filterValue = queryParams?.get(key) || ''
      if (key === 'status') { filtersFromQueryParams[key] = filterValue.split(',').filter(Boolean) }
    }

    if (paramKeys.includes('start_date') && paramKeys.includes('end_date')) {
      filtersFromQueryParams.campaign_dates = {
        start: queryParams.get('start_date'),
        end: queryParams.get('end_date')
      }
    }

    setSelectedFilters((prev) => ({ ...prev, ...filtersFromQueryParams }))
  }, [queryParams])

  const FiltersIcon = ({ filterName, isUsed }) => {
    const FilterIcon = filtersIcons[filterName]
    if (!FilterIcon) {
      return null
    }

    const color = isUsed ? 'white' : 'black'
    return <FilterIcon width={25} height={25} fill={color} color={color} />
  }

  function handleUpdateFilter (filterName, value, checked, type) {
    const updatedFilters = { ...selectedFilters }
    if (type === 'array') {
      if (checked) {
        if (!updatedFilters[filterName].includes(value)) {
          updatedFilters[filterName].push(value)
        }
      } else {
        updatedFilters[filterName] = updatedFilters[filterName].filter(
          (item) => item !== value
        )
      }
    } else if (filterName === 'campaign_dates') {
      if (value !== undefined) updatedFilters[filterName] = value
      else delete updatedFilters[filterName]
    }

    if (Object.keys(updatedFilters).includes('start_date')) { delete updatedFilters.start_date }
    if (Object.keys(updatedFilters).includes('end_date')) { delete updatedFilters.end_date }

    setSelectedFilters(updatedFilters)

    const updatedQueryParam = Object.keys(updatedFilters)
      .map((key) => {
        if (
          key === 'campaign_dates' &&
          updatedFilters.campaign_dates !== undefined
        ) {
          return `start_date=${moment(updatedFilters[key]?.start).format(
            'YYYY-MM-DD'
          )}&end_date=${moment(updatedFilters[key]?.end).format('YYYY-MM-DD')}`
        } else if (
          updatedFilters[key] &&
          Array.isArray(updatedFilters[key]) &&
          updatedFilters[key].length > 0
        ) { return `${key}=${updatedFilters[key].join(',')}` } else if (typeof updatedFilters[key] === 'number') { return `${key}=${updatedFilters[key]}` }
        return ''
      })
      .filter((param) => param !== '')
      .join('&')

    navigate(`?${updatedQueryParam}`)
  }

  return (
    <div id="hidden-filter" className={'filter-campaign-container'}>
        <div className="header">FILTRES</div>
        {Object.keys(filters).map((key) => (
          <div
            key={key}
            className={`filter-section ${
              filterSectionsState[key] ? 'opened' : 'closed'
            }`}
          >
            <div
              className="title-section"
              onClick={() => toggleFilterSection(key)}
              style={{
                backgroundColor:
                  selectedFilters[key] && selectedFilters[key].length > 0
                    ? 'black'
                    : 'white',
                color:
                  selectedFilters[key] && selectedFilters[key].length > 0
                    ? 'white'
                    : 'black'
              }}
            >
              {key.split('_').join(' ')}{' '}
              <FiltersIcon
                filterName={key}
                isUsed={selectedFilters[key] && selectedFilters[key].length > 0}
              />
            </div>
            {filterSectionsState[key] && (
              <div className="filter-content">
                {filters[key].map((filterValue) => (
                  <div key={filterValue} className="filter-container">
                    <div>{filterValue}</div>
                    <Checkbox
                      onChange={(_, checked) =>
                        handleUpdateFilter(key, filterValue, checked, 'array')
                      }
                      checked={
                        selectedFilters[key]?.includes(filterValue) || false
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
        {/* DATES DE CAMPAGNE */}
        <div
          key={'campaign_dates'}
          className={`filter-section ${
            filterSectionsState.campaign_dates ? 'opened' : 'closed'
          }`}
        >
          <div
            className="title-section"
            onClick={() => toggleFilterSection('campaign_dates')}
            style={{
              backgroundColor: selectedFilters.campaign_dates
                ? 'black'
                : 'white',
              color: selectedFilters.campaign_dates ? 'white' : 'black'
            }}
          >
            DATES DE CAMPAGNE
            <div style={{ display: 'flex', gap: 10 }}>
              {selectedFilters.campaign_dates !== undefined && (
                <div className="reset-filter-icon-container">
                  <CloseSvg
                    style={{
                      fill: 'red',
                      cursor: 'pointer',
                      width: 25,
                      height: 25
                    }}
                    onClick={() => {
                      handleUpdateFilter(
                        'campaign_dates',
                        undefined,
                        null,
                        'object'
                      )
                    }}
                  />
                </div>
              )}
              <CalendarSvg
                width={25}
                height={25}
                fill={selectedFilters.campaign_dates ? 'white' : 'black'}
              />
            </div>
          </div>

          {filterSectionsState.campaign_dates && (
            <div className="filter-content calendar-filter-container">
              <CalendarRangePicker
                onChange={(data) => {
                  const startDate = new Date(data?.startDate) ?? undefined
                  const endDate = new Date(data?.endDate) ?? undefined
                  if (startDate && endDate) {
                    handleUpdateFilter(
                      'campaign_dates',
                      { start: startDate, end: endDate },
                      null,
                      'object'
                    )
                  } else if (selectedFilters.campaign_dates !== undefined) {
                    handleUpdateFilter(
                      'campaign_dates',
                      undefined,
                      null,
                      'object'
                    )
                  }
                }}
                startDate={selectedFilters.campaign_dates?.start}
                endDate={selectedFilters.campaign_dates?.end}
              />
            </div>
          )}
        </div>
    </div>
  )
}
