// import React from 'react'
// import { warning } from 'utils/images-url'

export const dialogConfig = {
  error: {
    title: 'Erreur action',
    description: "L'action effectué n'est pas encore connecté ! Prevenez Alex si besoin urgemment !",
    action1: 'Compris !'
  },
  logout: {
    title: 'Déconnexion',
    description: 'Vous êtes sur le point de vous déconnecter',
    action1: 'Continuer',
    action2: 'Annuler'
  },
  submitSmProfile: {
    title: 'Est-tu sûr de vouloir continuer ?',
    description: "Tu est sur le point de modifier les informations d'un street marketeur, vérifie bien que les informations soient bonnes avant de continuer.",
    action1: 'Confirmer',
    action2: 'Retour'
  },
  submitCompanyInfo: {
    title: 'Est-tu sûr de vouloir continuer ?',
    description: "Tu es sur le point de créer/modifier les informations d'une compagnie, vérifie bien que les informations soient bonnes avant de continuer.",
    action1: 'Confirmer',
    action2: 'Retour'
  },
  archiveSm: {
    title: 'Tu es sur le point d\'archiver un SM !',
    description: "Est-tu sûr de vouloir continuer ?",
    action1: 'ARCHIVER',
    action2: 'RETOUR'
  },
  removeCampaign: {
    title: 'Êtes-vous sur de vouloir continuer ?',
    description: 'Si vous continuez, la campagne sera définitivement supprimé ainsi que les missions associées ! Soyez sûr de ce que vous faites avant de continuer !!!',
    action1: 'Confirmer',
    action2: 'Retour'
  },
  removeCompany: {
    title: "Archiver l'entreprise ?",
    description: "Es-tu sûr de vouloir archiver cette entreprise ? tu n'aura plus la possibilité de créer des campagne pour elle et tu ne pourra la consulter qu'avec le filtre <Archivé> de la liste des entreprises !",
    action1: 'Confirmer',
    action2: 'Annuler',
    subActionText: 'Je souhaite définitivement supprimer ce client !'
  },
  removeSlot: {
    title: 'Êtes-vous sur de vouloir continuer ?',
    description: 'Si vous continuez, le créneau sera définitivement supprimé ! Soyez sûr de ce que vous faites avant de continuer !!! CELA SUPPRIMERA: les missions, leurs tracés, leurs anomalies et leurs photos !',
    action1: 'Confirmer',
    action2: 'Retour'
  },
  deleteWebhook: {
    title: 'Est-tu sûr de vouloir supprimer ce webhook ?',
    description: "Si t'appuie sur 'SUPPRIMER', le webhook sélectionné va être supprimé",
    action1: 'Supprimer',
    action2: 'Retour'
  },
  updateWebhook: {
    title: 'Est-tu sûr de vouloir re-générer ce webhook ?',
    description: "Si t'appuie sur 'RE-GÉNÉRER', le webhook sélectionné va être supprimé puis recréé pour pouvoir être en status enabled, si la suppression fonctionne mais pas la création, tu retrouveras les informations pour le recréer en cliquant sur 'AJOUTER UN WEEBHOOK' en haut de la page",
    action1: 'Re-générer',
    action2: 'Retour'
  },
  addWebhook: {
    title: 'Est-tu sûr de vouloir créer ce webhook ?',
    description: "Si t'appuie sur 'CRÉER', un webhook sera créé avec les informations que tu as rentrées",
    action1: 'Créer',
    action2: 'Retour'
  },
  invalidSlot: {
    title: 'Il manque des informations importante sur le slot !',
    description: "Si t'appuie sur 'CONTINUER', le slot sera disponible pour les SM malgrès l'absence d'informations essentiels ! Cela peut engendrer des bugs et erreurs sur l'application mobile ! Est-tu sur de vouloir continuer ?",
    action1: 'Continuer',
    action2: 'Retour'
  },
  refusedSM: {
    title: 'Tu est sur le point de refuser un SM sur une mission !',
    description: 'Souhaite tu lui envoyer une explication sur son refus ?',
    action1: 'Continuer',
    action2: 'Retour'
  },
  abortPrestationModify: {
    title: 'Tu as des modification non enregistré dans ta prestation !',
    description: 'Est-tu sûr de vouloir continuer ?',
    action1: 'Annuler les modifications',
    action2: 'Retour'
  },
  removePrestation: {
    title: 'Tu es sur le point de supprimer la prestation !',
    description: 'Attention ! tu vas de ce fait perdre les accès aux crénaux et aux missions qui lui sont associé. Est-tu sûr de vouloir continuer ?',
    action1: 'Supprimer',
    action2: 'Annuler'
  },
  abortSlotModify: {
    title: 'Tu as des modification non enregistré dans ton créneau !',
    description: 'Est-tu sûr de vouloir continuer ?',
    action1: 'Annuler les modifications',
    action2: 'Retour'
  },
  abortCampaignModify: {
    title: 'Tu as des modification non enregistré dans ton créneau !',
    description: 'Est-tu sûr de vouloir continuer ?',
    action1: 'Annuler les modifications',
    action2: 'Retour'
  },
  deleteCampaignModify: {
    title: 'Tu es sur le point de supprimer cette campagne !',
    description: 'Est-tu sûr de vouloir continuer ?',
    action1: 'Supprimer',
    action2: 'Retour'
  }
}
