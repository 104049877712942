import React from 'react'
import {
  Modal
} from "@mui/material";
import BtnComponent from 'components/GlobalInputs/Btn';
import { SelectInput } from 'components/GlobalInputs/Select'
import { ReactComponent as Star2Svg } from 'assets/star2.svg';
import './styles.scss'

const SpotsModal = ({ modalOpen, handleCloseModal, spots, handleAttributeNoteToSpot }) => {
  const spotsWithTitles = spots.map(spot => spot.title ? spot : { ...spot, title: `[PAS DE NOM] (${spot?.address?.label ?? '[PAS D\'ADRESSE]'})` })
  const [selectedSpot, setSelectedSpot] = React.useState(null);
  const [currentNotation, setCurrentNotation] = React.useState(0);

  React.useEffect(() => {
    if (selectedSpot) setCurrentNotation(selectedSpot?.note ?? 0)
  }, [selectedSpot])
  return (
    <Modal open={!!modalOpen} onClose={handleCloseModal}>
      <div className="spot-modal-container">
        <div className="categorie-title" style={{ alignSelf: "center" }}>ATTRIBUTION DE NOTE PAR SPOT</div>
        <div>Le SM a plusieurs spots sur sa mission, attribue une note à chaque spot.</div>
        <div style={{ marginTop: 30, marginBottom: 20 }}>Quel spot souhaites-tu noter ?</div>
        <SelectInput label="SPOT" variant="rounded" keys={["title"]} items={spotsWithTitles} onChange={(s) => setSelectedSpot(s)} />
        {selectedSpot &&
          <div>
            <div className="spot-info-container">
              <div className="spot-info-key-value-conatiner">
                <div className="spot-info-key">Type du spot:</div>
                <div className="spot-info-value">{selectedSpot.spot_type ?? '-'}</div>
              </div>
              <div className="spot-info-key-value-conatiner">
                <div className="spot-info-key">Description:</div>
                <div className="spot-info-value">{selectedSpot.description ?? '-'}</div>
              </div>
              <div className="spot-info-key-value-conatiner">
                <div className="spot-info-key">Adresse du spot</div>
                <div className="spot-info-value">{selectedSpot.address?.label ?? '-'}</div>
              </div>
              <div className="spot-info-notation-conatiner">
                <div className="spot-info-notation-key">Quelle note souhaites-tu attribuer au spot ?</div>
                <div className="spot-info-notation-stars-container">
                  {[...Array(5)].map((_, index) => (
                    <Star2Svg
                      key={index}
                      color={currentNotation && index < currentNotation ? "#60EED2" : "white"}
                      height={30}
                      width={30}
                      onClick={() => setCurrentNotation(index + 1)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="btn-container">
              <BtnComponent text={"VALIDER"} color={"#60EED2"} variant="rounded" onClick={() => {
                handleAttributeNoteToSpot(selectedSpot.id, currentNotation)
                handleCloseModal()
              }} disabled={currentNotation === 0} />
              <BtnComponent text={"ANNULER"} color={"red"} variant="rounded" onClick={handleCloseModal} />
            </div>
          </div>}
      </div>
    </Modal>
  )
}

export default SpotsModal;
