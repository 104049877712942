// eslint-disable-next-line no-unused-vars
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addActionToStack, addItemToDrag, resetItemsDrag } from '_redux/Map/reducers'
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'
import { DrawPolygon } from '../../DrawingComponents/Polygon'
import { toWKT, wktToPath } from 'views/Admin/Views/Cartes/functions/functionsMap'
import { parse } from 'wellknown'
import { getItemType } from '../DraggableList/function'
import API from 'services/axios-config'
import ConfirmationModal from '../confirmationModal'
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ReactComponent as MyMapVision } from 'assets/map-vision.svg'
import './style.scss'
import { ItemTitleIcon } from '../components/ItemsTitleIcon'
import { InputChangeName } from '../components/ItemChangeTitle'

const ZoneView = memo(({ currentZone, clientView }) => {
  const dispatch = useDispatch()
  const { multiSelectType, handleSelectPolygon, currentPolygonSelect, handleOpenPolygonInfos, setPrestationsList, removeItems, clientVisibility, handleClientVisibility, handleZoomMap, modifyItems, mapItemsVisibility, updateSingleItemVisibilityByType } = useCampaignMap()
  const itemsDragSelected = useSelector(state => state.map.itemsDragSelected)
  const [isHover, setIsHover] = useState(false)
  const [modifyName, setModifyName] = useState(false)
  const isMoving = useSelector(state => state.map.isMoving)
  function handleAddItemDrag () {
    dispatch(addItemToDrag({ type: multiSelectType, value: { type: 'zone', ...currentZone } }))
    if (multiSelectType === 'default') {
      handleZoomMap(currentZone)
    }
  }
  const [openValidation, setOpenValidation] = useState(false)

  const isSelected = itemsDragSelected.filter(current => current.type === "zone" && current.id === currentZone?.id).length > 0
  const isMultipleSelect = itemsDragSelected.length > 1

  const polygonSelected = (currentPolygonSelect?.id === currentZone.id && getItemType(currentPolygonSelect) === getItemType(currentZone))

  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setIsVisible(mapItemsVisibility?.zones?.[currentZone.id] ?? true);
  }, [mapItemsVisibility?.zones?.[currentZone.id], isVisible])

  const updateIsVisible = (value) => {
    updateSingleItemVisibilityByType(currentZone.id, 'zones', value);
  };

  function handleClickPolygon (e) {
    setIsHover(false)
    if (multiSelectType === 'cmd') {
      handleAddItemDrag()
    } else if (!polygonSelected) {
      handleSelectPolygon(currentZone)
    } else {
      handleOpenPolygonInfos(e)
    }
  }

  /* START REFACTORED PARAMS */
  /* Polygon glitch when editing (FIXED) */
  const handlePolygonOnMouseOver = useCallback(() => {
    if (!polygonSelected && !isHover) {
      setIsHover(true);
    }

  }, [polygonSelected, isHover]);

  const handlePolygonOnMouseOut = useCallback(() => {
    if (!polygonSelected && isHover) {
      setIsHover(false);
    }
  }, [polygonSelected, isHover]);

  function usePolygonParams({
    currentZone,
    polygonSelected,
    isHover,
    isSelected,
    isVisible,
    handleClickPolygon,
    handlePolygonOnMouseOver,
    handlePolygonOnMouseOut,
  }) {
    const {
      id,
      polygon,
      fillColor,
      fillOpacity,
      strokeWeight,
    } = currentZone;
    const path = useMemo(() => wktToPath(parse(polygon)?.coordinates[0]), [polygon]);
    return useMemo(() => {
      return {
        key: id,
        path,
        draggable: polygonSelected,
        editable: polygonSelected,
        visible: isVisible,
        onClick: handleClickPolygon,
        onMouseOver: handlePolygonOnMouseOver,
        onMouseOut: handlePolygonOnMouseOut,
        options: {
          fillColor,
          strokeColor: ((!polygonSelected && isHover) || isSelected) ? '#60EED2' : fillColor,
          fillOpacity,
          strokeWeight: ((!polygonSelected && isHover) || isSelected) ? 5 : strokeWeight,
        },
      };
    }, [
      id,
      path,
      polygonSelected,
      isHover,
      isSelected,
      isVisible,
      fillColor,
      fillOpacity,
      strokeWeight,
      handleClickPolygon,
      handlePolygonOnMouseOver,
      handlePolygonOnMouseOut,
    ]);
  }

  const params = usePolygonParams({
    currentZone,
    polygonSelected,
    isHover,
    isSelected,
    isVisible,
    handleClickPolygon,
    handlePolygonOnMouseOver,
    handlePolygonOnMouseOut,
  });
  /* END REFACTORED PARAMS */

  function handleModifyPolygon (path) {
    const newPath = toWKT(path)
    if (newPath !== currentZone.polygon) {
      dispatch(addActionToStack({ type: "modify", itemType: "polygon", item: currentZone }))
      API.patch(`zones/${currentZone.id}`, { ...currentZone, polygon: newPath })
      setPrestationsList(prev => prev.map(presta => {
        if (presta.id === currentZone.prestation_id) {
          if (currentZone.slot_id) {
            return ({
              ...presta,
              slots: presta.slots.map(_slot => {
                if (_slot.id === currentZone.slot_id) {
                  return ({
                    ..._slot,
                    items: _slot.items.map(_item => {
                      if (_item.id === currentZone.id && getItemType(_item) === getItemType(currentZone)) { return ({ ..._item, polygon: newPath }) } else { return (_item) }
                    })
                  })
                } else { return (_slot) }
              })
            })
          } else {
            return ({
              ...presta,
              initials: presta.initials.map(_initial => {
                if (_initial.id === currentZone.id && getItemType(_initial) === getItemType(currentZone)) {
                  return ({ ..._initial, polygon: newPath })
                } else {
                  return (_initial)
                }
              })
            })
          }
        } else {
          return presta
        }
      }))
    }
  }

  function handleZoneIsHover (value) { setIsHover(value) }

  function handleRemoveZone (e) {
    e.stopPropagation()
    setOpenValidation(true)
  }

  function validAction (e) {
    e.stopPropagation()
    API.delete(`zones/${currentZone.id}`)
    dispatch(addActionToStack({ type: "delete", itemType: "polygon", item: currentZone }))
    if (!isMultipleSelect) { dispatch(resetItemsDrag()) }
    removeItems(currentZone)
  }

  function handleSendNewname (value) {
    API.patch(`zones/${currentZone.id}`, { title: value })
    modifyItems({ ...currentZone, title: value })
    setModifyName(false)
  }

  const isItClientVisible = clientVisibility?.items?.find((zone) => (zone?.id === currentZone.id && getItemType(zone) === getItemType(currentZone)))

  return (
    <div
      onClick={handleAddItemDrag}
      onMouseOver={() => handleZoneIsHover(true)}
      onMouseLeave={() => handleZoneIsHover(false)}
      className={`zone-view-container ${isSelected && "selected"} ${
        isSelected && isMultipleSelect && "multiple"
      } ${isSelected && isMoving && "move"} ${
        (isHover || polygonSelected) && "isHover"
      } ${!isVisible && "hidden"}`}
    >
      <div style={{ display: "flex", height: "100%" }}>
        <ItemTitleIcon type={'zone'} item={currentZone}/>
        {!modifyName
          ? <div className={`spot-name ${isHover && "isHover"}`} onClick={() => isSelected && setModifyName(true)}>
            {currentZone?.title || "Nouvelle zone"}
          </div>
          : <InputChangeName text={currentZone?.title} handleChange={handleSendNewname}/>
        }
        <div></div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {!clientView && <>
          {((isHover || !isVisible)) && (
            <div onClick={(e) => {
              e.stopPropagation()
              updateIsVisible(!isVisible)
            }} className="btn">
              {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon /> }
            </div>
          )}
          {(((isHover || isItClientVisible) && (isVisible && !isItClientVisible)) || (currentZone.client_visible || isItClientVisible)) &&
          <div onClick={(e) => handleClientVisibility(e, currentZone)} className="btn">
            <MyMapVision
              style={{ width: "18px" }}
              color={
                (isItClientVisible && "#FF7A00") ||
                (currentZone.client_visible && "#60EED2")
              }
            />
          </div>}
          {(((isHover && !isSelected) || (isSelected && !isMultipleSelect)) && isVisible) && (
            <div onClick={handleRemoveZone} className="btn">
              <DeleteRoundedIcon color="error" />
            </div>
          )}
        </>}
      </div>
        <DrawPolygon
          {...{ handleModifyPolygon, params }}
        />
      <ConfirmationModal
        {...{ openValidation, setOpenValidation, validAction }}
      />
    </div>
  );
})

export default ZoneView
