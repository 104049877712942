import React, { useState } from 'react';
import { Droppable } from "react-beautiful-dnd";
import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as DropdownSvg } from "assets/dropdown-icon.svg";
import './style.scss';

const AnomalieView = (props) => {
  const { prestation, children } = props
  const { handleSelectPrestation, updateSingleItemVisibilityByType, mapItemsVisibility } = useCampaignMap();
  const [isOpen, setIsOpen] = useState(true);
  const [isVisible, setIsVisible] = useState(true)

  React.useEffect(() => {
    if (prestation?.anomalies?.length > 0) {
      const allAnomaliesHidden = prestation.anomalies.every(anomaly => !mapItemsVisibility?.spots?.[anomaly.id]);
      setIsVisible(!allAnomaliesHidden);
    } else {
      setIsVisible(false);
    }
  }, [mapItemsVisibility?.spots, isVisible])

  const handleToggleList = () => {
    setIsOpen(prev => !prev);
  };

  const handleHideItems = (e) => {
    e.stopPropagation()
    prestation.anomalies.forEach((anomalie) => {
      updateSingleItemVisibilityByType(anomalie.id, 'spots', false);
    })
    setIsOpen(false);
  }

  const handleShowItems = (e) => {
    e.stopPropagation()
    prestation.anomalies.forEach((anomalie) => {
      updateSingleItemVisibilityByType(anomalie.id, 'spots', true);
    })
    setIsOpen(true);
  }

  return (
    <div className="anomalie-container">
      <Droppable droppableId={`initiales-${prestation.id}`}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            onClick={() => handleSelectPrestation(prestation)}
          >
            <div
              className="anomalie-title-container"
              onClick={handleToggleList}
            >
              <DropdownSvg className={`dropdown ${!isOpen && "rotate"}`} />
              <div className="anomalie-title">ANOMALIES</div>
              <div style={{ display: 'flex', padding: '0 10px' }}>
                {isVisible && <VisibilityOffIcon onClick={handleHideItems} />}
                {!isVisible && <VisibilityIcon onClick={handleShowItems} />}
              </div>
            </div>
            <div className={`children ${!isOpen && "hidden"}`}>
              {children}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default AnomalieView;
