/* eslint-disable react/prop-types */
import { InputAdornment, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useRef, useState } from 'react'

const CssTextField = styled(TextField)({
  '& .Mui-disabled': {
    cursor: 'not-allowed'
  },
  '& fieldset': {
    border: 'none'
  },
  '& .MuiInputBase-root': {
    borderRadius: '5px',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid transparent'
  },
  '&.rounded .MuiInputBase-root': {
    borderRadius: '20px'
  },
  '&.filled': {
    '& .MuiInputBase-root': {
      border: '1px solid #23272A'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      border: '1px solid transparent',
      backgroundColor: '#F2F2F2'
    }
  },
  '&.empty': {
    '& .MuiInputBase-root': {
      backgroundColor: '#F2F2F2'
    }
  },
  '& label': {
    color: '#23272A',
    fontWeight: '700'
  },
  '& label.Mui-focused': {
    backgroundColor: '#fff',
    padding: '0px 10px',
    borderRadius: 50,
    color: '#23272A'
  },
  '& label.Mui-disabled': {
    color: '#C6C6C6'
  },
  '& label.MuiFormLabel-filled': {
    backgroundColor: '#fff',
    padding: '0px 10px',
    borderRadius: 50,
    color: '#23272A'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    border: '1px solid transparent'
  }
})

const CssMultilineTextField = styled(TextField)({
  '& .Mui-disabled': {
    cursor: 'not-allowed'
  },
  '& fieldset': {
    border: 'none'
  },
  '& .MuiInputBase-root': {
    borderRadius: '5px',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid transparent'
  },
  '&.not-styled': {
    boxShadow: 0,
  },
  '&.rounded .MuiInputBase-root': {
    borderRadius: '20px'
  },

  '&.filled': {
    '& .MuiInputBase-root': {
      border: '1px solid #23272A'
    },
    '& .MuiInputBase-root.Mui-disabled': {
      border: '1px solid transparent',
      backgroundColor: '#F2F2F2'
    }
  },
  '&.empty': {
    '& .MuiInputBase-root': {
      backgroundColor: '#F2F2F2'
    }
  },
  '& label': {
    color: '#23272A',
    fontWeight: '700',
    backgroundColor: '#fff',
    padding: '0px 10px',
    borderRadius: 50
  },
  '& label.Mui-focused': {
    color: '#23272A'
  },
  '& label.Mui-disabled': {
    color: '#C6C6C6'
  },
  '& label.MuiFormLabel-filled': {
    color: '#23272A'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    border: '1px solid transparent'
  }
})

const CssTextFieldNotStyled = styled(TextField)({
  '& .Mui-disabled': {
    cursor: 'not-allowed'
  },
  '& fieldset': {
    border: 'none'
  },
  '& .MuiInputBase-root': {
    borderRadius: '5px',
    border: '1px solid transparent'
  },
  '&.rounded .MuiInputBase-root': {
    borderRadius: '20px'
  },
})

const CssMultilineTextFieldNotStyled = styled(TextField)({
  '& .Mui-disabled': {
    cursor: 'not-allowed'
  },
  '& fieldset': {
    border: 'none'
  },
  '& .MuiInputBase-root': {
    borderRadius: '5px',
    border: '1px solid transparent'
  },
  '&.rounded .MuiInputBase-root': {
    borderRadius: '20px'
  },
})

export const InputText = (props) => {
  const {
    name,
    label,
    type = 'text',
    placeholder,
    size = 'small',
    multiline = false,
    onChange,
    value,
    defaultValue,
    variant = 'regular',
    disabled,
    rows = 5,
    inputRef,
    noStyle = false,
    onFocus = function() {},
    onBlur = function() {},
    onKeyboardEnter = function() {},
  } = props
  const [isFilled, setIsFilled] = useState(!!value)

  const currentRef = useRef(null)

  function handleChangeValue (e) {
    onChange && onChange(e.target.value)
    setIsFilled(!!e.target.value)
  }

  if (multiline) {
    const Current = noStyle ? CssMultilineTextFieldNotStyled : CssMultilineTextField
    return (
    <Current
      ref={currentRef}
      inputRef={inputRef}
      value={value}
      name={name}
      label={label}
      type={type}
      onFocus={onFocus}
      onBlurCapture={onBlur}
      placeholder={disabled && !isFilled ? "Pas d'informations" : placeholder}
      fullWidth
      defaultValue={defaultValue}
      size={size}
      className={noStyle ? "not-styled" : `${isFilled ? 'filled' : 'empty'} ${variant}`}
      onChange={handleChangeValue}
      disabled={disabled}
      multiline
      rows={disabled && !isFilled ? 1 : rows}
      InputProps={{
        startAdornment: <InputAdornment position="start" style={{ display: 'none' }}/>
      }}
    />
    )
  }
  const Current = noStyle ? CssTextFieldNotStyled : CssTextField
  return (
    <Current
      ref={currentRef}
      inputRef={inputRef}
      value={value}
      name={name}
      label={label}
      type={type}
      onFocus={onFocus}
      onBlurCapture={onBlur}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onKeyboardEnter();
        }
      }}
      placeholder={placeholder}
      fullWidth
      defaultValue={defaultValue}
      size={size}
      className={noStyle ? "not-styled" : `${isFilled ? 'filled' : 'empty'} ${variant}`}
      onChange={handleChangeValue}
      disabled={disabled}
    />
  )
}
