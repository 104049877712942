import React, { useEffect, useMemo, useState } from "react";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Paper,
    Typography,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import API from 'services/axios-config.js';
import './style.scss';
import { SelectInput } from 'components/GlobalInputs/Select'
import { useCampaignMap } from "../campaignMapContext";
import { enqueueSnackbar } from "notistack";
import { ReactComponent as EmptyStarSvg } from 'assets/star2.svg'
import { SelectAddressComponent } from 'components/SelectAddressComponent'
import FilterComponent from 'components/GlobalInputs/Filters'

const ConfirmationModal = ({ isOpen, onClose, selectedSpotIds, currentPrestationsSpotsList, handleSuccess }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { setPrestationsList } = useCampaignMap();

    const handleConfirmationModalClose = () => {
        setIsLoading(true)
        API.post('duplicate_spots', {
            spot_ids: selectedSpotIds,
            slot_id: selectedSlot.id,
            prestation_id: selectedSlot.prestation_id,
        })
            .then((res) => {
                const createdSpotsList = res.data;
                const { slot_id, prestation_id } = createdSpotsList[0];
                setPrestationsList(prev => prev.map(presta => {
                    if (!prestation_id || presta.id === prestation_id) {
                        if (slot_id) {
                            return {
                                ...presta,
                                slots: presta.slots.map(_slot => {
                                    if (_slot.id === slot_id) {
                                        return {
                                            ..._slot,
                                            items: [..._slot.items, ...createdSpotsList]
                                        };
                                    } else {
                                        return _slot;
                                    }
                                })
                            };
                        } else {
                            return {
                                ...presta,
                                initials: [...presta.initials, ...createdSpotsList]
                            };
                        }
                    } else {
                        return presta;
                    }
                }));
                enqueueSnackbar(`${createdSpotsList.length} spot(s) ont été ajouté(s) avec succès dans le créneau : ${selectedSlot.title || `#${selectedSlot.id}`}`, { variant: "success" });
                handleSuccess();
            })
            .catch(() => enqueueSnackbar("Une erreur est survenue lors de l'ajout des spots. Veuillez réessayer.", { variant: "error" })
            )
            .finally(() => setIsLoading(false))
    };

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className="confirmation-modal"
        >
            <div className="confirmation-modal-content">
                <div className="confirmation-modal-title">
                    Utilisation des spots sélectionnés :
                </div>
                <div className="confirmation-modal-description">
                    À quel créneau de Flying Hand souhaites-tu attribuer ces spots ?
                </div>
                <SelectInput disabled={isLoading} label="CRÉNEAU" variant="rounded" keys={['title']} items={currentPrestationsSpotsList} onChange={(e) => setSelectedSlot(e)} />
                <div className="confirmation-modal-submit-btn" onClick={handleConfirmationModalClose} style={{ visibility: selectedSlot && !isLoading ? 'visible' : 'hidden' }}>
                    Valider
                </div>
            </div>
        </Modal>
    );
};

const ExistingSpots = ({ isOpen, handleClose }) => {
    const [selectedSpotIds, setSelectedSpotIds] = useState([]);
    const [existingSpotsList, setExistingSpotsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const { prestationsList } = useCampaignMap();
    const [spotsTypesList, setSpotsTypesList] = useState([]);

    /* FOR FILTERS */
    const [selectedFilters, setSelectedFilters] = useState({
        spotTypes: [],
        cities: [],
        noteSm: null,
    });

    const AddressAutoComplete = ({ handleUpdateFilter }) => {
        const [, setValue] = useState('');

        function handleSelectValue(value) {
            setValue(value)
            handleUpdateFilter(value)
        }

        return (
            <div style={{ margin: '0 0 20px 0' }}>
                <SelectAddressComponent address={null} onSelectAddress={(e) => handleSelectValue(e?.city)} label="RECHERCHER" onlyCities className="existing-spot-filter-popover-collapse-cities-autocomplete" />
            </div>
        );
    };

    const NoteSm = ({ handleUpdateFilter, currentFilters }) => {
        const [hoveredStar, setHoveredStar] = useState(null);

        const handleMouseEnter = (star) => {
            setHoveredStar(star);
        };

        const handleMouseLeave = () => {
            setHoveredStar(null);
        };

        const handleNoteSmStarClicked = (note) => {
            const noteSm = note === currentFilters.noteSm ? null : note;
            handleUpdateFilter(noteSm)
        }

        return (
            <div className="existing-spot-filter-popover-collapse-content">
                <div className="existing-spot-filter-popover-collapse-text">SÉLECTIONNE UNE NOTE ICI ET TU VERRAS LES SMs AVEC DES NOTES SUPÉRIEURES OU ÉGALE.</div>
                <div className="existing-spot-filter-popover-collapse-stars-container">
                    {[1, 2, 3, 4, 5].map((star) => (
                        <EmptyStarSvg
                            key={star}
                            style={{ width: 20, height: 20 }}
                            color={
                                hoveredStar >= star
                                    ? "#60EED280"
                                    : currentFilters.noteSm && currentFilters.noteSm >= star && !hoveredStar
                                        ? "#60EED2"
                                        : "white"
                            }
                            stroke="black"
                            onMouseEnter={() => handleMouseEnter(star)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleNoteSmStarClicked(star)}
                        />
                    ))}
                </div>
            </div>)
    };

    const filters = [
        { key: 'spotTypes', label: 'TYPE DE SPOT', options: spotsTypesList, multiple: true },
        { key: 'cities', label: 'VILLE', customComponent: <AddressAutoComplete />, multiple: true },
        { key: 'noteSm', label: 'NOTE', customComponent: <NoteSm />, multiple: false }
    ];

    /* END FOR FILTERS */

    const handleRowSelect = (event, id) => {
        const isChecked = event.target.checked;
        setSelectedSpotIds(prevIds => {
            if (isChecked) {
                return [...prevIds, id];
            } else {
                return prevIds.filter(selectedId => selectedId !== id);
            }
        });
    };

    const handleSelectAllRows = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allSpotIds = filteredExistingSpotsList.map(spot => spot.id);
            setSelectedSpotIds(allSpotIds);
        } else {
            setSelectedSpotIds([]);
        }
    };

    const currentPrestationsSpotsList = useMemo(() => {
        const filteredPrestations = prestationsList.filter(prestation => prestation.slots && prestation.slots.length > 0 && prestation.presta_type === 'Flying Hand');
        const allSlots = filteredPrestations.flatMap(prestation => prestation.slots.map(slot => ({ ...slot, title: slot.title ? slot.title : `Créneau #${slot.id}` })));
        return allSlots;
    }, [prestationsList]);

    useEffect(() => {
        setSelectedSpotIds([]);
        setIsLoading(true);
        API.get('/spots/get_spot_types')
            .then((res) => {
                if (res?.data?.spot_types) {
                    setSpotsTypesList(res.data.spot_types)
                }
            })
            .catch((_) => console.log('Une erreur est survenue'))
        API.get('/get_all_flying_hand_spots')
            .then((res) => {
                if (res?.data?.length) setExistingSpotsList(res.data);
            })
            .catch((_) => console.log('Une erreur est survenue'))
            .finally(() => setIsLoading(false));
    }, []);

    const handleCreateSpots = () => {
        setConfirmationModalOpen(true);
    };

    const handleConfirmationModalClose = () => {
        setConfirmationModalOpen(false);
    };

    const filteredExistingSpotsList = React.useMemo(() => {
        setSelectedSpotIds([])
        return existingSpotsList.filter(spot => {
            const { spotTypes, cities, noteSm } = selectedFilters;
            const matchesSpotTypes = !spotTypes?.length || spotTypes.includes(spot.spot_type);
            const matchesCities = !cities?.length || cities.includes(spot.address.city);
            const matchesNoteSm = noteSm == null || spot.average_note >= noteSm;
            return matchesSpotTypes && matchesCities && matchesNoteSm;
        });
    }, [existingSpotsList, selectedFilters])

    const isAllSelected = filteredExistingSpotsList.length > 0 && selectedSpotIds.length === filteredExistingSpotsList.length;

    const getFilledAndEmptyStarsCount = (avgNote) => {
        const totalStars = 5;
        const roundedNote = Math.round(avgNote || 0);
        const filledStars = Math.min(roundedNote, totalStars);
        const emptyStars = totalStars - filledStars;
        return { filledStars, emptyStars }
    }

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                className="existing-spots-modal"
            >
                <div className="existing-spots-modal-content">
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)'
                        }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div className="existing-spots-modal-header-container">
                        <div className="existing-spots-modal-header-title">LISTE DES SPOTS DÉJÀ EXISTANTS</div>
                        {selectedSpotIds.length > 0 && (
                            <div className="existing-spots-modal-header-selected-count">
                                {selectedSpotIds.length} {selectedSpotIds.length === 1 ? 'spot' : 'spots'} sélectionné{selectedSpotIds.length !== 1 && 's'}
                            </div>
                        )}
                    </div>
                    {!isLoading && (
                        <TableContainer component={Paper} sx={{ height: '100%', boxShadow: 'none' }}>
                            <div className="add-filters-btn-container">
                                <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} />
                            </div>
                            <Table sx={{
                                borderCollapse: 'separate',
                                borderSpacing: '0 8px',
                                '& .MuiTableCell-root': {
                                    borderColor: 'black',
                                },
                            }}>
                                <TableHead className="sticky-header">
                                    <TableRow>
                                        <TableCell style={{ width: 5 }}>
                                            <Checkbox
                                                checked={isAllSelected}
                                                onChange={handleSelectAllRows}
                                                indeterminate={!isAllSelected && selectedSpotIds.length > 0}
                                                color="primary"
                                                sx={{
                                                    color: "black",
                                                    '&.Mui-checked': {
                                                        color: "#60EED2",
                                                    },
                                                    '&.MuiCheckbox-indeterminate': {
                                                        color: "#60EED2",
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell style={{ width: 30, fontSize: 14, fontWeight: 800 }}>Nom</TableCell>
                                        <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Adresse</TableCell>
                                        <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Type de spot</TableCell>
                                        <TableCell style={{ fontSize: 14, fontWeight: 800 }}>Description</TableCell>
                                        <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Note Moye.</TableCell>
                                        <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Nb de missions</TableCell>
                                        <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Nb flyers</TableCell>
                                        <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Cadence</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredExistingSpotsList.map((row) => {
                                        const cadence = Math.round((row?.total_hours_without_pause > 0 ? row.total_distributed_flyers / row.total_hours_without_pause : 0));
                                        return (
                                        <TableRow key={row.id} sx={{ height: 50 }}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedSpotIds.includes(row.id)}
                                                    onChange={(event) => handleRowSelect(event, row.id)}
                                                    sx={{
                                                        color: "black",
                                                        '&.Mui-checked': {
                                                            color: "#60EED2",
                                                        },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        WebkitLineClamp: 2,
                                                        maxWidth: 250,
                                                        textOverflow: 'ellipsis',
                                                        wordWrap: 'break-word',
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {row.title || '-'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        WebkitLineClamp: 3,
                                                        maxWidth: 250,
                                                        textOverflow: 'ellipsis',
                                                        wordWrap: 'break-word',
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {row.address?.label || '-'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{row.spot_type || '-'}</TableCell>
                                            <TableCell>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        WebkitLineClamp: 3,
                                                        maxWidth: 250,
                                                        textOverflow: 'ellipsis',
                                                        wordWrap: 'break-word',
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {row.description || '-'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                            {row.average_note != null
                                                ? <div style={{ display: 'flex', gap: 3 }}>
                                                    {Array(getFilledAndEmptyStarsCount(row.average_note).filledStars).fill().map((_, index) => (
                                                        <EmptyStarSvg key={`filled-${index}`} style={{ width: 20, height: 20 }} color="#60EED2" stroke="black" />
                                                    ))}
                                                    {Array(getFilledAndEmptyStarsCount(row.average_note).emptyStars).fill().map((_, index) => (
                                                        <EmptyStarSvg key={`empty-${index}`} style={{ width: 20, height: 20 }} color="white" stroke="black" />
                                                    ))}
                                                </div>
                                                : <div>[PAS DE NOTE]</div>}
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        WebkitLineClamp: 2,
                                                        maxWidth: 250,
                                                        textOverflow: 'ellipsis',
                                                        wordWrap: 'break-word',
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {row.number_of_missions ? `${row.number_of_missions} mission${row.number_of_missions > 0 ? 's' : ''}` : '-'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        WebkitLineClamp: 2,
                                                        maxWidth: 250,
                                                        textOverflow: 'ellipsis',
                                                        wordWrap: 'break-word',
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {row.total_distributed_flyers || '-'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        display: '-webkit-box',
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        WebkitLineClamp: 2,
                                                        maxWidth: 250,
                                                        textOverflow: 'ellipsis',
                                                        wordWrap: 'break-word',
                                                        fontSize: 12,
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {cadence ? `${cadence} flyers/heure` : '-'}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    <div className="existing-spots-modal-submit-btn" style={{ visibility: selectedSpotIds?.length > 0 ? 'visible' : 'hidden' }} onClick={handleCreateSpots}>
                        UTILISER CES SPOTS
                    </div>
                </div>
            </Modal>

            {confirmationModalOpen && <ConfirmationModal
                isOpen={confirmationModalOpen}
                onClose={handleConfirmationModalClose}
                selectedSpotIds={selectedSpotIds}
                currentPrestationsSpotsList={currentPrestationsSpotsList}
                handleSuccess={() => {
                    handleConfirmationModalClose();
                    handleClose();
                }}
            />}
        </>
    );
};

export default ExistingSpots;
