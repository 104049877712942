import { enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'

export async function getAllCampaigns (currentPage, per, searchParams) {
  const apiSearchParams = new URLSearchParams()
  searchParams.forEach((value, param) => {
    if (value) {
      apiSearchParams.append(param, value.split(','))
    }
  })
  try {
    return await API.get(`/campaigns?include_company=true&page=${currentPage + 1}&per=${per}${apiSearchParams?.toString() ? '&' + apiSearchParams.toString() : ''}`)
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    )
  }
}

export async function getAllDevis (currentPage, per, searchParams) {
  const apiSearchParams = new URLSearchParams()
  searchParams.forEach((value, param) => {
    if (value) {
      apiSearchParams.append(param, value.split(','))
    }
  })
  try {
    return await API.get(`/devis?include_company=true&page=${currentPage + 1}&per=${per}${apiSearchParams?.toString() ? '&' + apiSearchParams.toString() : ''}`)
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    )
  }
}

export async function getDevisById (devisId) {
  try {
    return await API.get(`/devis/${devisId}`)
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    )
  }
}

export async function refuseDevisById (devisId, description) {
  try {
    return await API.put(`/devis/${devisId}/refuse_devis`, { refuse_description: description })
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des slots à eu lieu"
    )
  }
}
