/* eslint-disable react/prop-types */
import React from 'react'
import './style.scss'

const SlotCardView = (props) => {
  return (
    <>

    </>
  )
}

export default SlotCardView
