import React from 'react'
import './ValidationPublish.scss'
import { useRightModalMapLayout } from 'views/Lists/Campaigns/rightModalContext'
import API from 'services/axios-config'
import { getItemType } from '../../DraggableList/function'
import { enqueueSnackbar } from 'notistack'
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'

const ValidationPublish = ({ type }) => {
  const { removedLastStack } = useRightModalMapLayout();
  const { clientVisibility, prestationsList, modifyItems, setClientVisibility } = useCampaignMap();

  const groupedArrays = clientVisibility.items.reduce((acc, obj) => {
    let key;
    if (obj.anomalie_type) {
      key = obj.mission.slot.prestation_id;
    } else {
      key = obj.prestation_id;
    }
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  function validateVisibility () {
    const promises = clientVisibility.items.map((item) => {
      const modifiedItem = { client_visible: !item.client_visible };
      return API.patch(
        `${getItemType(item) + "s"}/${item.id}`,
        modifiedItem
      ).then(() => {
        modifyItems({ ...item, ...modifiedItem });
      });
    });
    Promise.all(promises)
      .then(() => {
        setClientVisibility(null);
        removedLastStack();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  }

  function getCurrentPrestation (id) {
    const currentPresta = prestationsList.find(presta => presta.id.toString() === id)?.presta_type ?? "-"
    return currentPresta
  }

  function getNumberType (type, items) {
    const numberOf = items.filter(_item => getItemType(_item) === type).length
    if (numberOf > 0) {
      return `Nombre de ${type}s : ${items.filter(_item => getItemType(_item) === type).length}`
    }
  }

  return (
    <div style={{ padding: 20 }}>
      <div className="ValidationPublish-first-title">
        {type === "publish"
          ? "TU ES SUR LE POINT D’AJOUTER UNE/DES NOUVELLE(S) ZONE(S) SUR L’ESPACE CLIENT !"
          : "TU ES SUR LE POINT DE DÉPUBLIER TOUTES LES ZONES AFFICHÉES AU CLIENT"}
      </div>
      <div className="ValidationPublish-second-title">
        Vérifie bien que les zones que tu souhaites {type !== "publish" && " ne pas "} afficher sont bien les
        bonnes avant de valider.
      </div>
      <div className="ValidationPublish-second-title">
        {type === "publish"
          ? "Le client pourra voir ces informations : "
          : "Le client ne pourra plus voir ces informations : "}
      </div>
      { Object.keys(groupedArrays).map((key) => {
        return (
          <div className="ValidationPublish-prestations" key={`visibility-prestation-${key}`}>
            <div >La prestation: { getCurrentPrestation(key) }</div>
            <div>{getNumberType("zone", groupedArrays[key])}</div>
            <div>{getNumberType("spot", groupedArrays[key])}</div>
            <div>{getNumberType("mission", groupedArrays[key])}</div>
          </div>)
      })
      }
      <div>
        <div
          style={{
            marginTop: "100px",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <div
            className="ValidationPublish-publish-map-btn"
            onClick={() => {
              validateVisibility();
            }}
          >
            VALIDER
          </div>
          <div
            className="ValidationPublish-publish-map-btn"
            style={{ color: "red" }}
            onClick={() => {
              removedLastStack();
            }}
          >
            ANNULER
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidationPublish
