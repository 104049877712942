import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const CustomDonutChart = ({ data, labels, title, height, showPercentage = false }) => {
  const total = data.reduce((acc, value) => acc + value, 0);

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: ['#49C5CC', '#FD6F1A'],
        hoverBackgroundColor: ['#49C5CC', '#FD6F1A'],
      },
    ],
  };

  const options = {
    cutout: '40%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: title,
        color: '#000000',
        padding: {
          top: 20,
          bottom: 20,
        },
      },
      tooltip: {
        callbacks: {
          label(tooltipItem) {
            const dataset = tooltipItem.dataset.data;
            const currentValue = dataset[tooltipItem.dataIndex];
            if (showPercentage) {
              const percentage = ((currentValue / total) * 100).toFixed();
              return `${percentage}%`;
            }
            return `${currentValue}`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return <Doughnut data={chartData} options={options} height={height} />;
};

export default CustomDonutChart;
