import moment from 'moment';
import './styles.scss'

const LeftBlockValidation = ({ data, dataRef, missionSelected, setMissionSelected, setNewData, title }) => {
  const defaultValue = {
    commentary: "",
    bonus_sm: ""
  }
  function handleSelectMission(value) {
    setMissionSelected(value)
    if (value?.id !== missionSelected?.id) setNewData(defaultValue)
  }
  return (<div style={{ borderRight: "1px solid black", flex: 1, maxWidth: 370, overflow: "hidden" }}>
    <div style={{ padding: "30px 0px 20px 20px", display: "flex", flexDirection: "column", height: "calc(100% - 30px)" }}>
      <div className="categorie-title" style={{ marginLeft: 10 }}>{title}</div>
      <div ref={dataRef} style={{ overflowY: "scroll" }}>
      {data.map((value, index) => {
        return <div key={`validation-mission-${value.id}-${index}`} style={{ padding: 10, borderBottom: "1px solid black", backgroundColor: missionSelected?.id === value.id ? "#f2f2f2" : "unset" }} onClick={() => handleSelectMission(value)}>
        <div style={{ marginBottom: 5, fontWeight: 800, fontFamily: "Overpass" }}>{value?.user?.first_name} {value?.user?.last_name?.toUpperCase()}</div>
        <div>{value?.campaign?.campaign_name} - {value?.prestation?.presta_type} - {moment(value?.check_in)?.format("DD/MM")}</div>
      </div>
      })
      }
      </div>
    </div>
  </div>)
}

export default LeftBlockValidation;
