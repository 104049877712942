import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as DropdownSvg } from "assets/dropdown-icon.svg";
import { ReactComponent as LogementsSvg } from "assets/logements.svg";
import { ReactComponent as PopulationSvg } from "assets/population.svg";
import { ReactComponent as HLMSvg } from "assets/hlm.svg";
import { ReactComponent as SuperficieSvg } from "assets/superficie.svg";
import { ReactComponent as AgeSvg } from "assets/age.svg";
import { ReactComponent as CSPSvg } from "assets/csp.svg";
import "./style.scss";
import CustomDonutChart from "./donutChart";
import { Backdrop, CircularProgress } from "@mui/material";
import { fromZonesToGeoJSON } from "views/Admin/Views/Cartes/functions/functionsMap";
import { useCampaignMap } from "../../campaignMapContext";
import { getEstimatedCadence } from "utils/helpers";

const StatCard = ({ title, value, unit }) => {
  const StatIcon = () => {
    if (title === "Logements") return <LogementsSvg />
    if (title === "Population") return <PopulationSvg />
    if (title === "HLM") return <HLMSvg />
    if (title === "Superficie") return <SuperficieSvg />
    if (title === "Âge moyen") return <AgeSvg />
    if (title === "CSP+") return <CSPSvg />
    return null;
  };
  return (
    <div className="stat-card-container">
      <div className="stat-card-title">{title}</div>
      <StatIcon />
      <div className="stat-card-value">{value.toLocaleString()}</div>
      <div className="stat-card-unit">{unit}</div>
    </div>
  );
};

const StatsLayout = ({ isSlidOpen, setIsSlideOpen, selectedZonesItems }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statsData, setStatsData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("stats");
  const controllerRef = React.useRef(new AbortController());
  const { prestationsList } = useCampaignMap();

  const selectedZonesGeoJsonList = useMemo(() => {
    const template = { type: 'FeatureCollection', features: [] }
    const geoJsonCollection = fromZonesToGeoJSON(selectedZonesItems, template, 'zones')
    return geoJsonCollection?.features ?? [];
  }, [selectedZonesItems]);

  const currentPrestation = useMemo(() => {
    const firstPrestationId = selectedZonesItems[0].prestation_id;
    const allItemsHaveSamePrestation = selectedZonesItems.every(item => item.prestation_id === firstPrestationId);
    if (!allItemsHaveSamePrestation) return null
    return prestationsList.find(prestation => prestation.id === firstPrestationId);
  }, [prestationsList, selectedZonesItems])

  const flatsPercentage = useMemo(() => {
    const housesCount = statsData?.housing_stats?.type_logements?.P20_MAISON ?? 0;
    const flatsCount = statsData?.housing_stats?.type_logements?.P20_APPART ?? 0;
    const total = housesCount + flatsCount;
    if (total > 0) {
      return ((flatsCount / total) * 100).toFixed();
    } else return null;
  }, [statsData])

  useEffect(() => {
    if (!isSlidOpen) setStatsData(null)
  }, [isSlidOpen])

  const fetchData = useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_SIMULATION_API_URL}/api/get_overlap_statistics`;
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    };

    try {
      setIsLoading(true);
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({ zones: selectedZonesGeoJsonList }),
        signal: controllerRef.current.signal,
      });

      if (response.ok) {
        const data = await response.json();
        setStatsData(data);
      } else {
        console.error('Error fetching data:', response.statusText);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted:', error.message);
      } else {
        console.error('Fetch error:', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [selectedZonesGeoJsonList]);

  useEffect(() => {
    if (isSlidOpen && statsData === null) {
      fetchData();
    }

    return () => {
      controllerRef.current.abort();
      controllerRef.current = new AbortController();
    };
  }, [isSlidOpen, statsData, fetchData]);

  const statsSliderTitle = useMemo(() => {
    if (selectedTab === "stats") return "Statistiques de la zone"
    if (selectedTab === "times") return "Temps nécessaire à la distribution"
    return "[PAS DE TITRE POUR CETTE ONGLET]"
  }, [selectedTab])

  const timesTabContent = useMemo(() => {
    if (!currentPrestation) {
      return <div className="times-tab-content-container">Il y a conflit car ce sont des zones issues de 2 prestations différentes</div>
    }
    if (!currentPrestation.flyers_weight) {
      return <div className="times-tab-content-container">La prestation de la zone sélectionnée n&apos;a pas de Grammage du support</div>
    }

    const estimatedCadence = getEstimatedCadence(currentPrestation.flyers_weight, flatsPercentage, currentPrestation.stop_pub)
    if (!estimatedCadence) {
      return <div className="times-tab-content-container">Impossible de calculer l&apos;estimation de Cadence</div>
    }

    const logementCount = statsData?.housing_stats?.logements;
    function formatEstimatedDistribution(flyersQuantity, estimatedCadence) {
      const totalMinutes = Math.round((flyersQuantity / estimatedCadence) * 60 * 1.5); // 1.5 is a temporary multiplication
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}h${minutes}min`;
    }
    const estimatedDistributionTime = formatEstimatedDistribution(logementCount, estimatedCadence)

    return <div className="times-tab-content-container">
      <div>Nombre total de logement: {logementCount?.toLocaleString() || '--'}</div>
      <div>Pourcentage d&apos;appartements: {flatsPercentage ? `${flatsPercentage}%` : '--'}</div>
      <div>Grammage du support: {currentPrestation.flyers_weight}</div>
      <div>Cadence estimée: {estimatedCadence} flyers par heure</div>
      <div style={{ marginTop: 35 }}>Nombre d&apos;heures nécessaires pour la zone : {estimatedDistributionTime}</div>
    </div>
  }, [statsData, flatsPercentage, currentPrestation])

  const statsTabContent = useMemo(() => {
    return (<div className="stats-tab-content-container">
      <div className="stats-cards-container">
        <StatCard title={"Logements"} value={statsData?.housing_stats?.logements?.toLocaleString() || '--'} unit={"Logements dans la zone"}/>
        <StatCard title={"Population"} value={statsData?.population_stats?.population?.toLocaleString() || '--'} unit={"Habitants dans la zone"}/>
        <StatCard title={"HLM"} value={statsData?.housing_stats?.hlm?.toLocaleString() || '--'} unit={"HLM dans la zone"}/>
      </div>
      <div className="stats-cards-container">
        <StatCard title={"Superficie"} value={typeof statsData?.surface === 'number' ? (statsData.surface / 1000000).toLocaleString() : '--'} unit={"km² de superficie dans la zone"}/>
        <StatCard title={"Âge moyen"} value={statsData?.population_stats?.avgAge?.toLocaleString() || '--'} unit={"ans de moyenne"}/>
        <StatCard title={"CSP+"} value={statsData?.population_stats?.csp?.toLocaleString() || '--'} unit={"cadres et professions intellectuelles supérieures entre 15 et 64 ans"}/>
      </div>
      <div className="stats-charts-container">
        <div className="chart-container">
          <CustomDonutChart
            title="Types de logements"
            data={[
              statsData?.housing_stats?.type_logements?.P20_MAISON ?? 0,
              statsData?.housing_stats?.type_logements?.P20_APPART ?? 0,
            ]}
            labels={['Maison', 'Appartements']}
            height={200}
            showPercentage
          />
        </div>
        <div className="chart-container">
          <CustomDonutChart
            title="Répartition Homme/Femme"
            data={[statsData?.population_stats?.gender?.homme ?? 0, statsData?.population_stats?.gender?.femme ?? 0]}
            labels={["Hommes", "Femmes"]}
            height={200}
          />
        </div>
      </div>
    </div>);
  }, [statsData]);

  const tabContent = useMemo(() => {
    if (selectedTab === "stats") return statsTabContent
    if (selectedTab === "times") return timesTabContent
    return <div>PAS DE CONTENUE POUR CETTE ONGLET</div>
  }, [statsData, selectedTab])

  return (
    <div className={`model-right-stats-layout ${isSlidOpen ? "slide-in" : ""}`}>
      <div
        onClick={() => setIsSlideOpen(!isSlidOpen)}
        className={`model-right-stats-layout-icon ${
          isSlidOpen ? "slide-in" : ""
        }`}
      >
        <DropdownSvg className={isSlidOpen ? "open-icon" : "close-icon"} />
      </div>
      {isSlidOpen && (
        <div className="model-right-stats-layout-second-el" >
          <Backdrop
            sx={(theme) => ({ color: '#000', zIndex: theme.zIndex.drawer + 1 })}
            open={isLoading || !statsData}
          >
            {isLoading && <CircularProgress color="inherit" />}
            {!isLoading && !statsData && <div style={{ fontSize: 20, fontWeight: 800, color: 'white' }}>Erreur dans l&apos;api de Simulation</div>}
          </Backdrop>
          <div style={{ filter: (isLoading || !statsData) ? 'blur(3px)' : 'none' }}>
            <div className="stats-title">{statsSliderTitle}</div>
            <div className="stats-tabs-btns-container">
              <div className={`stats-tabs-btn ${selectedTab === 'stats' ? 'selected' : ''}`} onClick={() => setSelectedTab("stats")}>STATISTIQUES</div>
              <div className={`stats-tabs-btn ${selectedTab === 'times' ? 'selected' : ''}`} onClick={() => setSelectedTab("times")}>TEMPS NÉCESSAIRE</div>
            </div>
            <div className="stats-silder-tabs-content-container">
              {tabContent}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatsLayout;
