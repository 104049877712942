import * as React from 'react'
import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import './style.scss'
import LeftNavbar from './LeftNavbar'

const AdminLayout = () => {
  return (
    <Box className="content-page">
        <LeftNavbar />
        <Outlet />
      </Box>
  )
}

export default AdminLayout
