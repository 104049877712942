/* eslint-disable react/prop-types */
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import './style.scss'
import PrestationModification from 'views/Slot/SlotCard/ComponentSlotCardView/ComponentPrestationModification'
import { ReactComponent as SettingsLogo } from 'assets/pen.svg'
import { Popover } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as Question } from 'assets/point-interogation.svg'
import { modifyPrestationInfo } from '_redux/Campaigns/reducers'
import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'

const BoxSubInfos = ({ prestation }) => {
  return (
    <div className="separate-items right left">
      <div>STOP PUB: {prestation?.stop_pub ? 'OUI' : 'NON'}</div>
      <div>HLM: {prestation?.hlm ? 'OUI' : 'NON'}</div>
    </div>
  )
}

const FormatTransport = ({ prestation }) => {
  return (
    <div className="separate-items">
      <div>FORMAT : {prestation?.format ? prestation?.format.toUpperCase() : '|Non défini|'}</div>
      <div>MODE DE TRANSPORT : {prestation?.transports.length > 0 ? prestation?.transports.join(', ').toUpperCase() : '|À définir|'}</div>
    </div>
  )
}

const Informations = ({ variant, prestation }) => {
  return (
    <div className={`separate-items ${variant}`}>
      {prestation?.presta_type === 'Flying Hand' && <div>URL DE FIN DE MISSION: {prestation.formulaire_link ? prestation.formulaire_link : '|À définir|'}</div>}
      <div>
        <div>INFORMATIONS DE FLYERS :</div>
        <div>{prestation?.informations ? prestation?.informations : '|À définir|'}</div>
      </div>
    </div>
  )
}

const BikeInfos = ({ prestation }) => {
  return (
    <div className={'separate-items right left'}>
      <div>NOMBRE D&apos;HEURES : {prestation?.nombre_heures ? prestation?.nombre_heures : '|À définir|'}{prestation?.nombre_heures ? 'h' : null}</div>
      <div>URL DE FIN DE MISSION: {prestation.formulaire_link ? prestation.formulaire_link : '|À définir|'}</div>
    </div>
  )
}

const FlyersQuantity = ({ prestation }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [isClientFlyersEdit, setIsClientFlyersEdit] = useState(null)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const handleEnter = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleLeave = () => {
    setAnchorEl(null)
  }

  const [clientFlyersNumber, setClientFlyersNumber] = useState(prestation?.displayed_client_flyers ?? prestation?.flyers_send)

  useEffect(() => {
    setClientFlyersNumber(prestation?.displayed_client_flyers ?? prestation?.flyers_send)
  }, [prestation])

  const handleEditClientFlyersNumber = () => {
    setIsClientFlyersEdit(prev => !prev)
  };

  const handleUpdatePrestationDisplayedClientFlyers = (value) => {
    const displayedClientFlyers = parseInt(value);
    if (displayedClientFlyers === prestation.flyers_send) return;
    const newPrestation = {
      ...prestation,
      displayed_client_flyers: displayedClientFlyers,
    }

    API.patch(`prestations/${prestation?.id}`, newPrestation)
      .then((_) => {
        dispatch(
          modifyPrestationInfo({
            campaignId: parseInt(searchParams?.get('campaign_id')),
            prestationId: prestation?.id,
            prestationInfo: newPrestation,
          })
        )
      })
      .catch((_) =>
        enqueueSnackbar(
          'Une erreur est survenu lors de la création/update de la préstation',
          { variant: 'error' }
        )
      )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div className="flyers-quantity">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Nb de flyers:
          <span style={{ color: '#F5B342' }}>{prestation?.flyers_in_progress ?? "0"}</span>/<span style={{ color: 'green' }}>{prestation?.flyers_send ?? "0"}</span>/<span style={{ color: 'red' }}>{(prestation?.quantity - (prestation?.flyers_send ?? 0))}</span>/<span>{prestation?.quantity}</span>
          <Question fill={'black'} width={25} height={25} onClick={handleEnter} />
        </div>
      </div>
      <div className="client-flyers-quantity">
        <div style={{ display: 'flex' }}>
          Nb de flyers client:
          {isClientFlyersEdit
            ? <div><input value={clientFlyersNumber} onChange={(e) => setClientFlyersNumber(e.target.value)} type="number" /></div>
            : <div>{clientFlyersNumber}</div>}
        </div>
        <div style={{ cursor: 'pointer' }} onClick={() => {
          if (isClientFlyersEdit) handleUpdatePrestationDisplayedClientFlyers(clientFlyersNumber)
          handleEditClientFlyersNumber()
        }}>
          <SettingsLogo style={{ width: 30, height: 30 }} fill="black" />
        </div>
      </div>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleLeave}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <div style={{ padding: 10 }}>
          <span style={{ color: '#F5B342' }}>[Nb en cours]</span> / <span style={{ color: 'green' }}>[Nb distribué]</span> / <span style={{ color: 'red' }}>[Nb restant]</span> / <span>[Nb total]</span>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export const FlyingBox = (props) => {
  const { addToStack } = useRightModal()
  const { prestation } = props
  return (<div>
    <FlyersQuantity {...props} />
    <div className="presta-info-separate">
      <div className="sub-infos">
        <FormatTransport {...props} />
        <BoxSubInfos {...props} />
        <Informations {...props} />
      </div>
      <div onClick={() => addToStack({ component: <PrestationModification {...{ prestation }} /> })}><SettingsLogo style={{ width: 30, height: 30 }} fill="black" /></div>
    </div>
  </div>)
}

export const FlyingHand = (props) => {
  const { addToStack } = useRightModal()
  const { prestation } = props
  return (<div>
    <FlyersQuantity {...props} />
    <div className="presta-info-separate">
      <div className="sub-infos">
        <FormatTransport {...props} />
        <Informations {...props} variant="left" />
      </div>
      <div onClick={() => addToStack({ component: <PrestationModification {...{ prestation }} /> })}><SettingsLogo style={{ width: 30, height: 30 }} fill="black" /></div>
    </div>
  </div>)
}

export const FlyingStore = (props) => {
  const { addToStack } = useRightModal()
  const { prestation } = props
  return (<div>
    <FlyersQuantity {...props} />
    <div className="presta-info-separate">
      <div className="sub-infos">
        <FormatTransport {...props} />
        <Informations {...props} variant="left" />
      </div>
      <div onClick={() => addToStack({ component: <PrestationModification {...{ prestation }} /> })}><SettingsLogo style={{ width: 30, height: 30 }} fill="black" /></div>
    </div>
  </div>)
}

export const FlyingWall = (props) => {
  const { addToStack } = useRightModal()
  const { prestation } = props
  return (<div>
    <FlyersQuantity {...props} />
    <div className="presta-info-separate">
      <div className="sub-infos">
        <FormatTransport {...props} />
        <Informations {...props} variant="left" />
      </div>
      <div onClick={() => addToStack({ component: <PrestationModification {...{ prestation }} /> })}><SettingsLogo style={{ width: 30, height: 30 }} fill="black" /></div>
    </div>
  </div>)
}

export const FlyingBike = (props) => {
  const { addToStack } = useRightModal()
  const { prestation } = props
  return (<div>
    <FlyersQuantity {...props} />
    <div className="presta-info-separate">
      <div className="sub-infos">
        <FormatTransport {...props} />
        <BikeInfos {...props} />
        <Informations {...props} />
      </div>
      <div onClick={() => addToStack({ component: <PrestationModification {...{ prestation }} /> })}><SettingsLogo style={{ width: 30, height: 30 }} fill="black" /></div>
    </div>
  </div>)
}
