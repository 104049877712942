import React from 'react'
import { Marker, Polygon, StandaloneSearchBox } from '@react-google-maps/api'
import { useCampaignMap } from '../../campaignMapContext'
import "./style.scss"
import { wktToPath } from 'views/Admin/Views/Cartes/functions/functionsMap'
import { ReactComponent as MyADDMAP } from 'assets/add-to-map.svg'
import { useSelector } from 'react-redux'

const InputSearchLocation = () => {
  const { searchBoxRef, searchLocation, currentLocation, handleSearchLocation, onMarkerComplete, onPolygonComplete } = useCampaignMap()
  const slotSelected = useSelector(state => state.map.slotSelected)
  const initialSelected = useSelector(state => state.map.initialSelected)

  function handleAddItem () {
    if (!slotSelected && !initialSelected) {
      window.alert("Veuillez d'abord séléctionner un slot ou des initials")
    } else if (currentLocation.type === "point") {
      onMarkerComplete({ position: currentLocation.value, setMap: () => function () {} })
    } else if (currentLocation.type === "polygon") {
      console.log(currentLocation)
      onPolygonComplete(currentLocation.value[0])
    }
    console.log(currentLocation)
  }

  return (
        <>
            {currentLocation &&
            <div
              className={`add-new-items ${(slotSelected || initialSelected) && "have-drop"}`}
              onClick={handleAddItem}
            >
              <MyADDMAP style={{ width: '35px', height: '35px', fill: 'black', stroke: (slotSelected || initialSelected) ? '#60EED2' : "grey" }}/>
            </div>}
            <StandaloneSearchBox
                onPlacesChanged={handleSearchLocation}
                onLoad={(searchBox) => (searchBoxRef.current = searchBox)}
            >
                <input
                    ref={searchLocation}
                    type="text"
                    placeholder="Rechercher une adresse..."
                    className="inputAddressSearch"
                />
            </StandaloneSearchBox>
            {currentLocation?.type === "point" && <Marker
                position={currentLocation?.value}
            />}
            {currentLocation?.type === "polygon" && <Polygon
                options={polylineOptions}
                path={wktToPath(currentLocation?.value[0])}
            />}
        </>
  )
}

const polylineOptions = {
  clickable: false,
  strokeColor: 'transparent', // Couleur de la bordure
  strokeWeight: 2, // Épaisseur de la bordure
  icons: [
    {
      icon: {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        strokeColor: 'blue', // Couleur du tiret
        scale: 2, // Longueur du trait du tiret
        strokeWeight: 3 // Épaisseur du tiret
      },
      offset: '0',
      repeat: '10px' // Espacement entre les tirets
    }
  ]
}

export default InputSearchLocation
