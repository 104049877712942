import { DrawingManager } from "@react-google-maps/api"
import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext"

const DrawingManagerView = () => {
  const { onPolygonComplete, onMarkerComplete } = useCampaignMap()
  return (<DrawingManager
    options={{
      polygonOptions: { editable: true, draggable: true, clickable: true },
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
      }
    }}
    // onPolygonComplete={(polygon) => onPolygonComplete(polygon)}
    onPolygonComplete={onPolygonComplete}
    onMarkerComplete={onMarkerComplete}
    // onPolylineComplete={(polyline) => onPolylineComplete(polyline)}
  />)
}

export default DrawingManagerView
