/* eslint-disable react/prop-types */
import React from "react";
import { BlackBtn } from "views/Admin/Shared/Forms/BlackBtn";
import { ReactComponent as CloseSvg } from "assets/close.svg";
import CheckIcon from "@mui/icons-material/Check";
import "./style.scss";
// import { enqueueSnackbar } from 'notistack'
// import API from 'services/axios-config'
import API from "services/axios-config";

const ViewPhoto = (props) => {
  const { selectedImages, setOpenPublicateModal, label, updateImagesClientVisiblityByIds } = props;
  function getIdsFromArray(arr) {
    return arr.map((item) => item.id);
  }
  function handleVisibilityClient() {
    console.log(selectedImages, "selectedImages-selectedImages");
    API.patch("/update_campaign_photos_client_visibility", {
      ids: getIdsFromArray(selectedImages),
      client_visible: label !== "DÉPUBLIER",
    })
      .then(() => {
        updateImagesClientVisiblityByIds(getIdsFromArray(selectedImages), label !== "DÉPUBLIER")
      })
      .catch((error) => {
        // Handle API call errors
        console.error("Error removing campaign picture:", error);
      });
  }

  return (
    <div className="view-photo-container">
      <div className="header">
        TU ES SUR LE POINT D’AJOUTER DES PHOTOS SUR L’ESPACE CLIENT !
        <CloseSvg
          className="close-icon"
          onClick={() => setOpenPublicateModal(false)}
        />
      </div>
      <div>
        {label === "DÉPUBLIER"
          ? "Vérifie bien que les photos que tu souhaites dépublier sont bien les bonnes avant de valider."
          : "Vérifie bien que les photos que tu souhaites afficher sont bien les bonnes avant de valider."}
      </div>
      {label === "DÉPUBLIER"
        ? "Tu vas dépublier les photos suivantes :"
        : "Tu vas afficher les photos suivantes :"}
      <div className="images-container">
        {selectedImages?.map((image, index) => (
          <div key={index} className="image-wrapper" onClick={() => {}}>
            <div className="image-inner-wrapper">
              <div className="checkmark-wrapper">
                <CheckIcon className="check-icon" />
              </div>
              <img
                src={process.env.REACT_APP_BACK_API + image.picture_url}
                alt=""
                className="image"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="buttons-container">
        <BlackBtn
          label={label === "DÉPUBLIER" ? "DÉPUBLIER" : "VALIDER"}
          styles={{ color: "#60eed2" }}
          handleAction={handleVisibilityClient}
        />
        <BlackBtn
          label="ANNULER"
          styles={{ color: "red" }}
          handleAction={() => setOpenPublicateModal(false)}
        />
      </div>
    </div>
  );
};

export default ViewPhoto;
