import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as DropdownSvg } from 'assets/dropdown-icon.svg'
import './style.scss'

const BottomLayout = ({ clientView, setClientView }) => {
  const [isSlidIn, setIsSlidIn] = useState(false)
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleQuitMap = (event) => {
    searchParams.delete('maps')
    navigate({
      pathname,
      search: searchParams.toString()
    })
  }

  return (
    <div className={`model-bottom-contentMaps ${isSlidIn ? "slide-in" : ""}`}>
      <div
        onClick={() => setIsSlidIn(!isSlidIn)}
        className={`model-bottom-contentMaps-icon ${
          isSlidIn ? "slide-in" : ""
        }`}
      >
        <DropdownSvg className={isSlidIn ? "rotate" : ""} />
      </div>
      <div className={"model-bottom-contentMaps-second-el "}>
        <div className="close-map-btn" onClick={() => setClientView(prev => !prev)}>
          {!clientView ? "VUE CLIENT" : "VUE PROD"}
        </div>
        <div className="close-map-btn" onClick={handleQuitMap}>
          FERMER
        </div>
      </div>
    </div>
  );
};

export default BottomLayout;
