/* eslint-disable react/prop-types */
import React from 'react'
import { TextField } from '@mui/material'
import './inputText.scss'

const InputText = (props) => {
  const {
    placeholder, value, handleChange = () => { }, label, bgIsInWhite = true,
    style, rows = 1, multiline = false, handleKeyDown
  } = props
  return (
    <div className='input_text_container' style={{
      boxShadow: bgIsInWhite
        ? '1px 1px 4px 0px rgba(0, 0, 0, 0.50)'
        : '1px 1px 4px 0px rgba(255, 255, 255, 0.50)',
      ...style
    }}>
      <TextField placeholder={placeholder} InputProps={{ disableUnderline: true }}
        value={value || ''} variant="standard" fullWidth color="secondary" label={label}
        onChange={handleChange} size="medium" style={{ height: '100%', ...style }}
        rows={rows} multiline={multiline} onKeyDown={handleKeyDown}
      />
    </div>
  )
}

export default InputText
