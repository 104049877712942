/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react'
import { Button } from '@mui/material'
import { IoMdAddCircle } from 'react-icons/io'
import { DivideTitle, ParentContainer } from '../components'
import { StatsContent } from './statsContent'
import { ModalComponent } from '../modalComponent/modalcomponent'
import { convertStatsToArray } from '../../../home/functions/functions'
import Statistics from '../../../home/components/Statistics/statistics'
import './statistics.scss'

const UseCaseStatistics = ({ data, openModal, setOpenModal }) => {
  const statRef = useRef()
  const [stats, setStats] = useState(null)
  const [isSaved, setIsSaved] = useState(false)

  const handleAddInputStat = () => {
    statRef?.current?.handleAddInputStat()
  }

  return (
    <ParentContainer style={{ backgroundColor: 'white', width: '90%', margin: 'auto' }}>
      <Statistics w_title={<>LA CAMPAGNE<br />EN QUELQUES CHIFFRES</>} g_title={''}
        listStats={convertStatsToArray(isSaved ? stats?.content : data?.datas?.content)}
        color={'#23272A'} w_style={{ color: '#23272A', fontSize: 40 }}
        justify={data?.datas?.content?.length < 3 ? 'center' : 'space-between'}
        w_style2={{ fontSize: 35 }} style={{ padding: '25px 20px' }} bg_color={'white'}
      />
      {openModal && <ModalComponent open={openModal} sectionName="stats"
        setOpenModal={setOpenModal} disable={isSaved || stats === null}
        data={stats} setIsSaved={setIsSaved} id={data?.id}
        component={
          <div className='statistics_container'>
            <div className='header'>
              <DivideTitle type={'h3'} w_title={<>LA CAMPAGNE <br />ENQUELQUES CHIFFRES</>}
                w_style={{ fontSize: 40, color: '#23272A' }} />
              <Button className="action" disabled={stats?.content?.length > 2}
                onClick={handleAddInputStat} variant="contained" color='success' >
                ajouter &nbsp;<IoMdAddCircle color="white" size={30} />
              </Button>
            </div>
            <StatsContent ref={statRef} setStats={setStats} setIsSaved={setIsSaved}
              content={isSaved ? stats?.content : data?.datas?.content || ['']} />
          </div>
        }
      />}

    </ParentContainer>
  )
}

export default UseCaseStatistics
