/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { problimatisationMacDo } from '../utils/dataText'
import ImageBloc, { DoubleImageBloc } from '../components/ImageBloc/imageBloc'
import { CASE_BOXY, CASE_CAMPUSCOACH, COUVERTURE_STREET_MARKETEUR } from '../utils/imageUrls'
import { IntroductionUseCase } from '../components/IntroductionFFY/introductionFFY'
import { UseCaseAction } from '../components/Buttons/button'
import { UseCaseProblimatisation } from '../components/Explain/explain'
import { CustomDivider } from '../../UsecaseCMS/components/components'
import { convertExplainToArray, convertStatsToArray } from '../functions/functions'
import Comments from '../components/Comments/comments'
import Statistics from '../components/Statistics/statistics'
import { useNavigate } from 'react-router-dom'

export const IntroductionForUseCase = ({ data, header_url, widthp1 }) => {
  return (
    <IntroductionUseCase w_title={data?.title}
      paraph_style2={{ fontSize: 32, paddingBottom: 80 }} paraph_style1={{ fontSize: 24, width: widthp1 }}
      w_style={{ fontSize: 64, fontWeight: 700 }} ffy_style={{ padding: '10px 0 50px 120px' }}
      paraph1={data?.subTitle} paraph2={data?.description} avatar={header_url} activity={data?.activity} wactivity_style={{ fontSize: 24, fontWeight: 700 }}/>
  )
}

const IndexSectionsUseCase = ({ data }) => {
  const navigate = useNavigate()
  const { video_header, header, double_image, explain, stats, sub_image, commentary } = data
  const components = [
    {
      reference: header,
      name: 'header',
      component: video_header?.visible && <IntroductionForUseCase data={header?.datas}
      widthp1='70%' header_url={data?.header_url} />
    },
    {
      reference: double_image,
      name: 'double_image',
      component: <DoubleImageBloc srcLeft={data?.file1_url || CASE_BOXY} srcRight={data?.file2_url || CASE_CAMPUSCOACH} />
    },
    {
      reference: explain,
      name: 'explain',
      component: <UseCaseProblimatisation bg_color={'#23272A'} alternate_color={false} style_bloc1={{ flex: 2 }}
        list_items={convertExplainToArray(explain?.datas)} color={'white'} style_bloc2={{ flex: 2 }} />
    },
    {
      reference: stats,
      name: 'stats',
      component: <> <Statistics w_title={<>LA CAMPAGNE<br />EN QUELQUES CHIFFRES</>} g_title={''}
        listStats={convertStatsToArray(stats?.datas?.content)} bg_color={'white'}
        color={'#23272A'} w_style={{ color: '#23272A', fontSize: 40 }}
        w_style2={{ fontSize: 35 }} justify={stats?.datas?.content?.length < 3 ? 'center' : 'space-between'}
        margin={stats?.datas?.content?.length < 3 ? '100px' : '0'} />
        <CustomDivider bg_color={'#23272A'} width="100%" color={'#23272A'} />
      </>
    },
    { reference: sub_image, name: 'sub_image', component: <ImageBloc src={data?.sub_image_url || COUVERTURE_STREET_MARKETEUR} height={615} /> },
    {
      reference: commentary,
      name: 'commentary',
      component: <Comments avatar={data?.commentary_url} comment={commentary?.datas?.description} />
    }
  ]

  const displaySortedComponents = components
    ?.sort((a, b) => +a?.reference?.position - +b?.reference?.position)
    ?.map((section, index) =>
      <Fragment key={`${section?.reference}-${index}`}>
        {section?.reference?.visible &&
          section?.component}
      </Fragment>
    )

  return (
    <>
      {displaySortedComponents}
      <UseCaseAction prevId={data?.prev_id} nextId={data?.next_id} navigate={navigate} />
      <CustomDivider bg_color={'#23272A'} width="100%" color={'#23272A'} />
    </>
  )
}

export default IndexSectionsUseCase
