/* eslint-disable react/prop-types */
import React from 'react'
import ModifyCampaign from './ModifyCampaign'
import ModifyInfoProd from './ModifyInfoProd'
import './style.scss'
import ModifyInfoSM from './ModifyInfoSM'

const ModifyContent = ({ campaignData, handleChangeCampaign }) => {
  return (
    <>
      <ModifyCampaign {...{ campaignData, handleChangeCampaign }} />
      <ModifyInfoProd {...{ campaignData, handleChangeCampaign }} />
      <ModifyInfoSM {...{ campaignData, handleChangeCampaign }} />
    </>
  )
}

export default ModifyContent
