/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import BtnComponent from 'components/GlobalInputs/Btn';
import { manuelAddInvoiceDetail, regeneratePdf, SmValidatedMissions, updateSmInvoice, ValidateSmInvoice } from './functions';
import ValidatedMissions from './ValidatedMissions';
import DeleteModal from './DeleteModal';
import { enqueueSnackbar } from 'notistack';
import { SimpleInput } from 'views/Cartes/CampaignViewMap/MapLayout/LeftLayout/components/ItemChangeTitle';

const InvoiceModal = ({ open, onClose, invoiceData }) => {
    const [formData, setFormData] = useState({
        date: '',
        due_date: '',
        sm_siret: '',
    });
    const [rows, setRows] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [isValidatedMissionsModalOpen, setIsValidatedMissionsModalOpen] = useState(false);
    const [missions, setMissions] = useState([])
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [invoiceDetailId, setInvoiceDetailId] = useState()
    const [invoiceTotals, setInvoiceTotals] = useState({
        totalHT: 0,
        totalTTC: 0
    });
    const [newLineIndex, setNewLineIndex] = useState(null)
    const updateTotals = (newRows) => {
        const totalHT = newRows.reduce((sum, row) => sum + row.qty * row.price, 0);
        const totalTTC = newRows.reduce((sum, row) => sum + row.amount, 0);
        setInvoiceTotals({ totalHT, totalTTC });
    };
    useEffect(() => {
        setNewLineIndex(null)
        if (invoiceData) {
            setFormData({
                date: invoiceData?.date,
                due_date: invoiceData?.due_date,
                sm_siret: invoiceData?.sm_siret,
            });
            const mappedRows = invoiceData.sm_invoice_details.map((detail) => ({
                description: detail.description,
                type: 'SERVICES',
                date: detail.date,
                qty: detail.quantity,
                unit: detail.unit,
                price: detail.unit_price,
                tva: detail.tva,
                amount: parseFloat((detail.quantity * detail.unit_price * (1 + detail.tva / 100.0)).toFixed(2)),
                id: detail.id,
            }));
            setRows(mappedRows);
        }
    }, [invoiceData]);

    const handleInputChange = (field, value) => {
        setFormData((prevData) => {
            const newData = { ...prevData, [field]: value };
            setIsChanged(JSON.stringify(newData) !== JSON.stringify(invoiceData));
            if (field === 'date' || field === 'due_date') {
                const daysDifference = calculatePaymentTerms(newData.date, newData.due_date);
                newData.payment_terms = `${daysDifference} ${daysDifference < 2 ? 'jour' : 'jours'}`;
            }
            return newData;
        });
    };


    const handleDeleteRow = (id) => {
        if (!['Validé', 'Réglée'].includes(invoiceData?.status)) {
            setDeleteModalOpen(true);
            setInvoiceDetailId(id)
        }
    };

    const handleInputChangeRow = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        const { qty, price, tva } = newRows[index];
        newRows[index].amount = parseFloat((qty * price * (1 + tva / 100.0)).toFixed(2));
        setRows(newRows);
        setIsChanged(
            JSON.stringify(newRows) !== JSON.stringify(invoiceData.sm_invoice_details) ||
            JSON.stringify(formData) !== JSON.stringify({
                date: invoiceData.date,
                due_date: invoiceData.due_date,
                sm_siret: invoiceData.sm_siret,
            })
        );
        updateTotals(newRows);
    };
    useEffect(() => {
        if (invoiceData) {
            const initialTotalHT = invoiceData.sm_invoice_details.reduce(
                (sum, detail) => sum + detail.quantity * detail.unit_price,
                0
            );
            const initialTotalTTC = invoiceData.sm_invoice_details.reduce(
                (sum, detail) => sum + detail.quantity * detail.unit_price * (1 + detail.tva / 100.0),
                0
            );

            setInvoiceTotals({
                totalHT: initialTotalHT,
                totalTTC: initialTotalTTC
            });
        }
    }, [invoiceData]);
    const handleValidate = () => {
        setIsLoading(true)
        ValidateSmInvoice(invoiceData?.id)
            .then(() => {
                onClose()
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
            .finally(() => setIsLoading(false))
    }

    const handleSendToSm = () => {
        setIsLoading(true)
        updateSmInvoice(invoiceData?.id, { invoice: { status: 'À venir' } })
            .then(() => {
                onClose();
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
            .finally(() => setIsLoading(false))
    }

    const handleRegeneratePDF = () => {
        setIsLoading(true)
        regeneratePdf(invoiceData?.id, { invoice: { status: 'À venir' } })
            .then(() => {
                onClose();
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
            .finally(() => setIsLoading(false))
    }

    const handleUpdate = () => {
        const updatedInvoice = {};

        if (formData.date !== invoiceData.date) updatedInvoice.date = formData.date;
        if (formData.due_date !== invoiceData.due_date) updatedInvoice.due_date = formData.due_date;
        if (formData.sm_siret !== invoiceData.sm_siret) updatedInvoice.sm_siret = formData.sm_siret;

        const updatedInvoiceDetails = rows.map((row, index) => {
            const originalRow = invoiceData.sm_invoice_details[index];
            const updatedRow = {};
            if (row.date !== originalRow?.date) updatedRow.date = row.date;
            if (row.qty !== originalRow?.quantity) updatedRow.quantity = row.qty;
            if (row.price !== originalRow?.unit_price) updatedRow.unit_price = row.price;

            return Object.keys(updatedRow).length > 0 ? { id: row.id, ...updatedRow } : null;
        }).filter(Boolean);

        const payload = {
            invoice: updatedInvoice,
            invoice_details: updatedInvoiceDetails,
        };

        setIsLoading(true)
        updateSmInvoice(invoiceData?.id, payload)
            .then(() => {
                setIsChanged(false);
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
            .finally(() => setIsLoading(false))
    };
    const smValidatedMissions = () => {
        setIsLoading(true)
        setIsValidatedMissionsModalOpen(true)
        SmValidatedMissions(invoiceData.id)
            .then((res) => {
                setMissions(res.data.missions);
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
            .finally(() => setIsLoading(false))
    }
    const calculatePaymentTerms = (date, dueDate) => {
        const startDate = new Date(date);
        const endDate = new Date(dueDate);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays > 0 ? differenceInDays : 0;
    };
    const addInvoiceDetail = (index) => {
        const row = rows[index]; 
        if (!row.description || !row.date) {
            enqueueSnackbar("Veuillez renseigner les détails de la facture, avant de valider.", { variant: "error" });
            return;
        }
    
        const payload = {
            sm_invoice_detail: {
                description: row.description,
                date: row.date,
                quantity: row.qty,
                unit_price: row.price,
                sm_invoice_id: invoiceData?.id
            }
        }

        manuelAddInvoiceDetail(payload)
            .then((res) => {
                const updatedRows = [...rows];
                updatedRows[index] = { ...updatedRows[index], ...res.data.detail };
                setRows(updatedRows);
                updateSmInvoice(invoiceData?.id, {
                    invoice_details: updatedRows,
                })
                setIsChanged(false)
                setNewLineIndex(null)
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
    }
    return (<div>
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2" style={invoiceTitle}>
                    FACTURE - {invoiceData?.title}
                </Typography>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }} style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={headerContent}>
                        <Typography style={smText}>{invoiceData?.sm_full_name}<br />{invoiceData?.sm_address}</Typography>
                        <Box style={rightBox}>
                            <div style={headerItems}> <Typography style={
                                textStyle
                            }>Date de facturation : </Typography><TextField
                                    fullWidth
                                    type="date"
                                    value={formData.date}
                                    onChange={(e) => handleInputChange('date', e.target.value)}
                                    sx={{ width: 200, mb: 2 }}
                                    disabled={['Validé', 'Réglée'].includes(invoiceData?.status) || isLoading}
                                /></div>
                            <div style={headerItems}> <Typography style={
                                textStyle
                            }>Échéance : </Typography>
                                <TextField
                                    fullWidth
                                    type="date"
                                    value={formData.due_date}
                                    onChange={(e) => handleInputChange('due_date', e.target.value)}
                                    sx={{ width: 200, mb: 2 }}
                                    disabled={['Validé', 'Réglée'].includes(invoiceData?.status) || isLoading}
                                /></div>
                            <div style={headerItems}> <Typography style={
                                textStyle
                            }>Type d’opération : Prestation de services
                            </Typography></div>

                            <div style={headerItems}> <Typography style={{ ...infoStyle, margin: '40px 0' }}>Flying For You<br />
                                CHEIKH KANE<br />
                                3 Rue Olympe de Gouges<br />
                                92600 Asnières-Sur-Seine
                            </Typography></div>
                            <div style={siret}> <Typography style={{
                                ...textStyle,
                                textWrap: 'nowrap',
                                marginRight: 6,
                                marginBottom: 29
                            }}>Numéro de siret : </Typography>
                                <SimpleInput text={formData.sm_siret} handleChange={(e) => handleInputChange('sm_siret', e)} disabled={['Validé', 'Réglée'].includes(invoiceData?.status) || isLoading} /></div>
                        </Box>
                    </div>

                    <TableContainer>
                        <Table>
                            <TableHead style={{ background: '#D9D9D9' }}>
                                <TableRow>
                                    <TableCell style={TableCellStyle}>Description</TableCell>
                                    <TableCell style={TableCellStyle}>Type de produit</TableCell>
                                    <TableCell style={TableCellStyle}>Date</TableCell>
                                    <TableCell style={TableCellStyle}>Qté</TableCell>
                                    <TableCell style={TableCellStyle}>Unité</TableCell>
                                    <TableCell style={TableCellStyle}>Prix unitaire</TableCell>
                                    <TableCell style={TableCellStyle}>TVA</TableCell>
                                    <TableCell style={TableCellStyle}>Montant</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>{newLineIndex == index ?
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}><TextField
                                                fullWidth
                                                value={row.description}
                                                onChange={(e) => handleInputChangeRow(index, 'description', e.target.value)}
                                                sx={{ width: 80 }}
                                            /></TableCell>
                                        : <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>{row.description}</TableCell>}
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>{row.type}</TableCell>
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>
                                            <TextField
                                                fullWidth
                                                type="date"
                                                value={row.date}
                                                onChange={(e) => handleInputChangeRow(index, 'date', e.target.value)}
                                                sx={{ width: 140 }}
                                                disabled={['Validé', 'Réglée'].includes(invoiceData?.status) || isLoading}
                                            />
                                        </TableCell>
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>
                                            <TextField
                                                fullWidth
                                                value={row.qty}
                                                onChange={(e) => handleInputChangeRow(index, 'qty', e.target.value)}
                                                sx={{ width: 80 }}
                                                disabled={['Validé', 'Réglée'].includes(invoiceData?.status) || isLoading}
                                            />
                                        </TableCell>
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>h</TableCell>
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>
                                            <TextField
                                                fullWidth
                                                value={row.price}
                                                onChange={(e) => handleInputChangeRow(index, 'price', e.target.value)}
                                                sx={{ width: 80 }}
                                                disabled={['Validé', 'Réglée'].includes(invoiceData?.status) || isLoading}
                                            />
                                        </TableCell>
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>{row.tva}%</TableCell>
                                        <TableCell style={{
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            fontFamily: 'Work Sans'
                                        }}>{row.amount}</TableCell>
                                        <TableCell>
                                            {newLineIndex == index ? <IconButton onClick={()=>addInvoiceDetail(index)}>
                                                <CheckIcon sx={{ color: '#60EED2' }} />
                                            </IconButton> :
                                                <IconButton onClick={() => handleDeleteRow(row.id)}>
                                                    <DeleteIcon sx={{ color: 'red' }} />
                                                </IconButton>}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0' }}>
                        <BtnComponent text={'AJOUTER UNE LIGNE'} onClick={smValidatedMissions} variant="regular" color='#ffff' style={buttonStyle} disabled={['Validé', 'Réglée'].includes(invoiceData?.status) || isLoading || newLineIndex} />
                    </div>
                    <Divider color='black' />
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '20px 0', width: 'fit-content', alignSelf: 'end' }}>
                        <div style={headerItems}>
                            <Typography style={totalStyle}>Total HT</Typography>
                            <Typography style={totalStyle}>{invoiceTotals.totalHT.toFixed(2)}€</Typography>
                        </div>
                        <Divider color='black' width={150} />
                        <div style={headerItems}>
                            <Typography style={footerStyle}>Total TTC</Typography>
                            <Typography style={footerStyle}>{invoiceTotals.totalTTC.toFixed(2)}€</Typography>
                        </div>
                    </div>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '0 20px' }}>
                    <Typography style={footerStyle}>Moyen de paiement : </Typography>
                    <div>
                        <Typography style={footerStyle}>Banque : {invoiceData?.sm_banque} </Typography>
                        <Typography style={footerStyle}>SWIFT/BIC : {invoiceData?.sm_bic}</Typography>
                        <Typography style={footerStyle}>IBAN : {invoiceData?.sm_iban}</Typography>

                    </div>
                </Box>
                <Typography style={footerStyle}>
                    Conditions de paiement : {formData.payment_terms || invoiceData?.payment_terms}
                </Typography>
                <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 50 }}>
                    <Typography style={footerStyle}>Exonéré de TVA 293-B du CGI </Typography>
                    <Typography style={footerStyle}>{invoiceData?.sm_full_name}</Typography>
                    <Typography style={footerStyle}>{invoiceData?.sm_address}</Typography>
                    <Typography style={footerStyle}>{invoiceData?.sm_siret}</Typography>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                    <BtnComponent text={'ENREGISTRER'} variant="rounded" onClick={handleUpdate} color='#ffff' style={buttonStyle} disabled={!isChanged} fetching={isLoading} />
                    {invoiceData?.status === 'À venir' && <BtnComponent text={'VALIDER FACTURE'} variant="rounded" onClick={handleValidate} style={buttonStyle} fetching={isLoading} />}
                    {invoiceData?.status === 'Contestée' && <BtnComponent text={'ENVOYER AU SM'} variant="rounded" onClick={handleSendToSm} style={buttonStyle} fetching={isLoading} />}
                    {invoiceData?.status === 'Non générée' && <BtnComponent text={'REGÉNÉRER'} variant="rounded" onClick={handleRegeneratePDF} style={buttonStyle} fetching={isLoading} />}
                    <BtnComponent text={'RETOUR'} variant="rounded" onClick={() => { onClose() }} color='red' style={buttonStyle} />
                </Box>
            </Box>
        </Modal>
        <ValidatedMissions
            open={isValidatedMissionsModalOpen}
            onClose={() => setIsValidatedMissionsModalOpen(false)}
            missions={missions}
            rows={rows}
            setRows={setRows}
            setIsChanged={setIsChanged}
            smInvoiceId={invoiceData?.id}
            updateTotals={updateTotals}
            setNewLineIndex={setNewLineIndex}
        />
        <DeleteModal
            smInvoiceId={invoiceData?.id}
            open={deleteModalOpen}
            invoiceDetailId={invoiceDetailId}
            onClose={() => setDeleteModalOpen()}
            rows={rows}
            setRows={setRows}
            updateTotals={updateTotals}
        />
    </div>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
    borderRaduis: 50
};
const textStyle = {
    textAlign: 'right',
    color: 'black',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Work Sans'
}
const invoiceTitle = {
    textAlign: 'right',
    color: 'black',
    fontWeight: '700',
    fontSize: '12px',
    fontFamily: 'Work Sans'

}
const smText = {
    textAlign: 'left',
    color: 'black',
    fontWeight: '500',
    fontSize: '12px',
    marginBottom: 150,
    fontFamily: 'Work Sans'
}
const headerContent = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
}
const rightBox = {
    flexDirection: 'column',
    display: 'flex',
    width: 330,
    alignSelf: 'flex-end',
}
const infoStyle = {
    textAlign: 'left',
    color: 'black',
    fontWeight: '500',
    fontSize: '12px',
    marginTop: 20,
    fontFamily: 'Work Sans'
}
const headerItems = {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
}
const siret = {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginTop: 20,
}
const footerStyle = {
    color: 'black',
    fontWeight: '500',
    fontSize: '12px',
    margin: 10,
    fontFamily: 'Work Sans'
}
const totalStyle = {
    color: 'black',
    fontWeight: '500',
    fontSize: '10px',
    margin: 10,
    fontFamily: 'Work Sans'
}
const buttonStyle = {
    borderRaduis: 10,
    width: 130,
    fontWeight: 800,
    fontSize: 12,
}
const TableCellStyle = {
    fontWeight: '700',
    fontSize: '10px',
    color: 'black'
}
export default InvoiceModal;
