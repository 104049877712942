import React, { Fragment } from 'react'

export const convertExplainToArray = (explain) => {
  return [
    {
      title: 'PROBLÉMATIQUE',
      content: explain?.problem && Object?.values(explain?.problem)?.map((prob) => prob?.toUpperCase())
    },
    {
      title: 'SOLUTION PROPOSÉE',
      content: explain?.solutions && Object?.values(explain?.solutions)?.map((sol, index) => {
        return (
        <Fragment key={`explai-item-${index}`}>{sol?.title?.toUpperCase()} : <br/>
        {sol?.list && Object?.values(sol?.list).map((item, indexj) => {
          return (
            <Fragment key={`solution-itel-${indexj}`}>
            {item?.toUpperCase()} <br/>
            </Fragment>
          )
        })}
        </Fragment>
        )
      })
    }
  ]
}

export const convertStatsToArray = (stats) => {
  return stats && Object?.values(stats)
}
