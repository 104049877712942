import React from 'react'
import './style.scss'

const BookMark = (props) => {
  return (props.leftComponent)
}

const RightAction = (props) => {
  return (props.rightComponent)
}

const HeaderList = (props) => {
  return (
        <div className="header-lists-container">
            <BookMark {...props}/>
            <RightAction {...props}/>
        </div>)
}

export default HeaderList
