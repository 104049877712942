/* eslint-disable react/prop-types */
import React from 'react'
import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { SelectAddressComponent } from 'components/SelectAddressComponent'
import DateAndTimePicker from '../DateAndTimePicker'
import './style.scss'

const ModifyContent = (props) => {
  const { slot, handleChangeSlot } = props
  const listTypeCost = ['heure', 'mission', 'affiche']

  return (
    <>
      <TextField fullWidth label={'Nom du créneau:'} className='input-component info' value={slot?.title ? slot.title : null} onChange={(e) => handleChangeSlot({ title: e.target.value })} />
      <div className='slotmodif-component-container'>
        <div>
          <TextField
            fullWidth
            label={'Tarif:'}
            className='input-component'
            value={slot?.cost ? slot.cost : null}
            type='number'
            onChange={(e) => handleChangeSlot({ cost: e.target.value })}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
          {!slot?.cost && <span style={{ color: 'red', fontSize: '12px', padding: '10px 0 0 10px' }}>Sans Tarif, ce créneau ne sera pas affiché sur l&apos;application.</span>}
        </div>
        <FormControl className='input-component-typeCost'>
          <InputLabel>Par:</InputLabel>
          <Select
            value={slot?.type_cost ? slot.type_cost : null}
            label="Par:"
          >
            {
              listTypeCost.map((item, index) => (
                <MenuItem value={item} key={`${index}-${item}`} onClick={() => handleChangeSlot({ type_cost: item })}>{item}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <DateAndTimePicker {...props} fieldChange={'date_start_mission'} label="début:" />
        <DateAndTimePicker {...props} fieldChange={'date_end_mission'} label="fin:" />
      </div>
      {(!slot?.date_start_mission || !slot?.date_end_mission) && <span style={{ color: 'red', fontSize: '12px', paddingLeft: '10px' }}>Sans date, ce créneau ne sera pas affiché sur l&apos;application.</span>}
      <SelectAddressComponent className='input-address' address={slot?.address?.label ? slot.address.label : null} onSelectAddress={(e) => handleChangeSlot({ address: e })} label='Adresse:' />
      {!slot?.address?.label && <span style={{ color: 'red', fontSize: '12px', paddingLeft: '10px' }}>Sans adresse, ce créneau ne sera pas affiché sur l&apos;application.</span>}
      <TextField
          fullWidth
          label={'Bonus Backup :'}
          className='input-component'
          value={slot?.backup_cost ? slot.backup_cost : null}
          type='number'
          onChange={(e) => handleChangeSlot({ backup_cost: e.target.value })}
          style={{ marginTop: 40 }}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>
          }}
        />
    </>
  )
}

export default ModifyContent
