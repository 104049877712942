/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import { Typography } from '@mui/material'
import { SimpleButton } from '../Buttons/button'
import { ParentContainer } from '../ParentContainer/parentContainer'
import './chooseffy.scss'

export const UseCaseProblimatisation = ({
  bg_color, color = '23272A', list_items,
  alternate_color = true, style_bloc1, style_bloc2, style, btnStyle
}) => {
  return (
  <ParentContainer style={{ backgroundColor: bg_color }}>
    <div className='v_chooseffy_container' style={{ ...style }}>
      <div className='content_container'>
        {list_items?.map((item, index) => {
          return (
            <div key={`content-${index}`} className='content'>
              <div className='btn' style={style_bloc1}>
                <SimpleButton text={item?.title?.toUpperCase()} style={{ fontSize: 35, cursor: 'unset', ...btnStyle }}
                  className={`${alternate_color ? index === 0 ? 'simpleBtnWhite' : 'simpleBtnBlack' : 'simpleBtnWhite'}`} />
              </div>
              <div className='content_bloc_list' style={style_bloc2}>
                <div style={{ border: `1px solid ${color}` }} className='divider'></div>
                <div className='content_list'>
                  {item?.content?.map((argument, indexj) => {
                    return (
                      <Typography key={`arg-${indexj}`} style={{ color }} className='argument'>{argument}</Typography>
                    )
                  })}
                </div>
              </div>
            </div>)
        })}
      </div>
    </div>
  </ParentContainer>
  )
}
