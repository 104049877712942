/* eslint-disable react/prop-types */
import React from 'react'
import { CircularProgress } from '@mui/material'
import style from './style.module.scss'

export const ButtonComponent = ({
  text,
  icon,
  handleActions,
  action,
  reverse,
  color = 'green',
  disabled = false,
  row,
  loading = false
}) => {
  return (
    <button
      type="button"
      onClick={() => handleActions(action, row)}
      className={`${style.button} ${reverse ? style.reverse : ''} ${color} ${disabled || loading ? 'disabled' : ''}`}
      disabled={disabled}
    >
      {loading ? <CircularProgress style={{ width: 'auto', height: 'auto' }} /> : <div>{icon}{text}</div>}
    </button>
  )
}

export const ButtonComponent2 = ({ text, handleActions, color, disabled = false }) =>
  <button
    type="button"
    onClick={handleActions}
    className={`${style.button_v2} ${disabled ? 'disabled' : ''}`}
    disabled={disabled}
    style={{ color }}
  >
    {text}
  </button>
