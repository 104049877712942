/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PrestationBlock from './PrestationBlock'
import './style.scss'
import { Skeleton } from '@mui/material'
import API from 'services/axios-config'
import PrestationModification from 'views/Slot/SlotCard/ComponentSlotCardView/ComponentPrestationModification'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import { useDispatch } from 'react-redux'
import { addPrestation, deletePrestation } from '_redux/Campaigns/reducers'
import { enqueueSnackbar } from 'notistack'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'

const PrestationsView = (props) => {
  const { campaignData, campaignLoading } = props
  const [loading, setLoading] = useState(false)
  const { addToStack, removedLastStack } = useRightModal()
  const dispatch = useDispatch()

  function handleRemovedUnusedPresta (presta_id) {
    API.delete(`prestations/${presta_id}`)
      .then(_ => {
        removedLastStack()
        dispatch(deletePrestation({ campaignId: campaignData?.campaign.id, prestationId: presta_id }))
      })
  }

  function handleCreatePrestation () {
    setLoading(true)
    API.post(`campaigns/${campaignData?.campaign.id}/prestations`)
      .then((res) => {
        addToStack({ component: <PrestationModification prestation={{ ...res?.data, documents: [] }}/>, action: () => handleRemovedUnusedPresta(res?.data?.id) })
        dispatch(addPrestation({ campaignId: campaignData?.campaign.id, prestationData: { ...res?.data, slots: [], documents: [] } }))
      })
      .catch(err => enqueueSnackbar('La création de prestation à échoué', err))
      .finally(() => setLoading(false))
  }

  if (!campaignLoading) {
    return (
            <div className="prestation-view">
                {campaignData?.prestations?.map((prestation, index) => {
                  return (<PrestationBlock {...props} key={`${prestation}-${index}`} {...{ prestation }}/>)
                })}
                <BlackBtn handleAction={handleCreatePrestation} label="Ajouter une prestation" styles={{ width: 'min-content', textWrap: 'nowrap' }} disabled={loading}/>
            </div>
    )
  } else {
    return (
            <div className="prestation-view">
                <Skeleton variant="rounded" width={'auto'} height={60} sx={{ margin: '7px' }} />
                <Skeleton variant="rounded" width={'auto'} height={60} sx={{ margin: '7px' }} />
                <Skeleton variant="rounded" width={'auto'} height={60} sx={{ margin: '7px' }} />
                <Skeleton variant="rounded" width={'auto'} height={60} sx={{ margin: '7px' }} />
                <Skeleton variant="rounded" width={'auto'} height={60} sx={{ margin: '7px' }} />
            </div>
    )
  }
}

export default PrestationsView
