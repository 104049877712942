/* eslint-disable react/prop-types */
import React from 'react'
import { IconButton, TableCell, TableHead, TableRow } from '@mui/material'
import './style.scss'

const CloseAllIcon = ({ closeAll, setCloseAll }) => {
  if (closeAll) {
    return (
      <IconButton
        className="icon-button"
        aria-label="expand row"
        size="small"
        onClick={() => setCloseAll(false)}
        style={{ position: 'absolute', top: '13%', padding: 10 }}
      >
        <div className="closeAll-btn"></div>
      </IconButton>
    )
  }
}

const HeaderTable = ({ columns, setCloseAll, closeAll }) => {
  return (
    <TableHead>
      <TableRow style={{ position: 'relative' }}>
        {columns?.map((column) => {
          return column?.isVisible
            ? <TableCell
            key={column.id}
            align={column.align}
            className="header-cell"
          >
            {column.id !== 'collapsable'
              ? (
                  column.column_name
                )
              : (
              <CloseAllIcon {...{ closeAll, setCloseAll }} />
                )}
          </TableCell>
            : null
        })}
      </TableRow>
    </TableHead>
  )
}

export default HeaderTable
