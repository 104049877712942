/* eslint-disable react/prop-types */
import { OverlayView } from '@react-google-maps/api'
import React, { useState } from 'react'
import FormatColorFillRoundedIcon from '@mui/icons-material/FormatColorFillRounded'
import { CompactPicker } from 'react-color'
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded'
import { Checkbox } from '@mui/material'

const WindowInfoMarker = (props) => {
  const { modalInfos, setModalInfos } = props
  const [pickColor, setPickColor] = useState(false)
  const [rayonDisabled, setRayonDisabled] = useState(modalInfos?.additional_ref?.current?.radius === 0)

  const handleModifLayer = (infoChange) => {
    modalInfos?.currentRef?.current?.setOptions({ ...infoChange })
  }

  const handleModifLayerAdditional = (infoChange) => {
    modalInfos?.additional_ref?.current?.setOptions({ ...infoChange })
  }

  const handleChangeRayonVisible = (value) => {
    if (value?.target.checked) {
      setRayonDisabled(false)
    } else {
      modalInfos?.additional_ref?.current?.setOptions({ radius: 0 })
      setRayonDisabled(true)
    }
    modalInfos?.handleModifyLayer()
  }

  const sendModifyToBackend = () => {
    modalInfos?.handleModifyLayer()
  }

  return (
    <div>
    <OverlayView
      position={modalInfos?.position}
      mapPaneName={'floatPane'}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'space-between', backgroundColor: '#fff', padding: 10, minWidth: 285 }}
        ref={ ref => ref && window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref)}
      >
        <div style={{ display: 'flex', backgroundColor: '#fff', padding: '0 5px', position: 'absolute', top: -20, alignSelf: 'end', alignItems: 'center', justifyContent: 'center' }} onClick={() => setModalInfos(null)}><CancelPresentationRoundedIcon/>Fermer</div>
        <div style={{ fontSize: 15, fontFamily: 'Fredoka One' }}>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 16, color: 'black' }}>
            {modalInfos?.item?.address?.label}

          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', cursor: 'pointer' }}
              onClick={() => setPickColor((prev) => !prev)}>
              <FormatColorFillRoundedIcon />

            </div>
          </div>
        </div>
      {pickColor &&
              <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                <div>
                <CompactPicker onChangeComplete={(e) => {
                  handleModifLayer({ fillColor: e?.hex })
                  sendModifyToBackend({ fillColor: e?.hex })
                }}/>
                </div>
                {modalInfos?.additional_ref?.current && <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Activer le rayon :</div>
                  <Checkbox defaultChecked={!rayonDisabled} onChange={handleChangeRayonVisible}/>
                </div>
                {!rayonDisabled && <>
                  <p>Rayon de la zone</p>
                  <input type={'range'} max={1000} min={50}
                    defaultValue={modalInfos?.additional_ref?.current?.radius}
                    onChange={(e) => handleModifLayerAdditional({ radius: parseInt(e?.target?.value) })}
                    onMouseUp={(e) => sendModifyToBackend({ radius: parseInt(e?.target?.value) })}
                  />
                </>}
                </>}
              </div>
            }
      </div>
      </OverlayView>
      </div>
  )
}

export default WindowInfoMarker
