/* eslint-disable react/prop-types */
import React from "react";
import { ReactComponent as MapSVG } from "assets/global_map.svg";
import "./style.scss";
import CampaignStatusCard from "components/CampaignStatusCard";
import { Badge } from "@mui/material";

export const VisuStatus = (props) => {
  const currentRowId = props.row.find((_item) => _item?.id === "id").value;
  const currentRow = props.campaignsList.find(
    (_row) => _row?.campaign?.id?.toString() === currentRowId
  )?.campaign;
  const currentStatus = currentRow.status;
  if (props.isDevis) {
    return <div className="black-btn center">{currentStatus}</div>;
  }
  return <CampaignStatusCard status={currentStatus} />;
};

export const HandleMap = (props) => {
  const currentRow = props.row.find((_item) => _item?.id === "id").value;
  return (
    <div
      className="map-btn-action"
      onClick={(e) => props.action(e, currentRow)}
    >
      <MapSVG />
    </div>
  );
};

export const CampaignName = (props) => {
  const currentRowId = props.row.find((_item) => _item?.id === "id").value;
  const currentRow = props.campaignsList.find(
    (_row) => _row?.campaign?.id?.toString() === currentRowId
  )?.campaign;
  const showSlotAlert = currentRow.have_slot_need_validation;
  const campaignName = currentRow.campaign_name;
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
      {showSlotAlert && (
        <Badge
          badgeContent="!"
          color="error"
          overlap="circular"
        ></Badge>
      )}
      {campaignName}
    </div>
  );
};
