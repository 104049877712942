import { enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'

export async function getAllInvoices(currentPage, per, params = "") {
  try {
    return await API.get(`/sm_invoices?page=${currentPage + 1}&per=${per}&${params}`);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des factures à eu lieu"
    )
  }
}

export async function getSmInvoices(id) {
  try {
    return await API.get(`/sm_invoices/${id}`);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention la facture d'un sm à eu lieu"
    )
  }
}
export async function updateSmInvoice(id, payload) {
  try {
    return await API.patch(`/sm_invoices/${id}`, payload);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'update la facture d'un sm à eu lieu"
    )
  }
}

export async function regeneratePdf(id) {
  try {
    return await API.get(`/sm_invoices/${id}/regenerate_invoice_pdf`);
  } catch (err) {
    if (err.response.data.error) {
      return enqueueSnackbar(
        `Une erreur lors de l'update la facture d'un sm à eu lieu: ${err.response.data.error}`
      )
    } else {
      return enqueueSnackbar(
        "Une erreur lors de l'update la facture d'un sm à eu lieu"
      )
    }
  }
}

export async function ValidateSmInvoice(id) {
  try {
    return await API.get(`/sm_invoices/${id}/validate`);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de validation de la facture d'un sm à eu lieu"
    )
  }
}

export async function SmValidatedMissions(id) {
  try {
    return await API.get(`/sm_invoices/${id}/get_unused_validated_terminated_missions`);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'obtention des missions validées"
    )
  }
}

export async function Delete(sm_invoice_details_id) {
  try {
    return await API.delete(`/sm_invoice_details/${sm_invoice_details_id}`);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de la suppression"
    )
  }
}

export async function addInvoiceDetail(payload) {
  try {
    return await API.post('/sm_invoice_details/generate_invoice_detail_from_mission', payload);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de la création de la ligne de facture à partir d'une mission à eu lieu"
    )
  }
}

export async function manuelAddInvoiceDetail(payload) {
  try {
    return await API.post('/sm_invoice_details/add_invoice_detail', payload);
  } catch (err) {
    return enqueueSnackbar(
      "Une erreur lors de l'ajout manuelle d'un détail de facture"
    )
  }
}
