import React, { useRef, useState } from "react";
import "./style.scss";
import ChildrenMaps from "./Maps/childrenMap";
import SearchMaps from "../../mapsSearch";

const CreateCampaignMap = () => {
  const mapRef = useRef(null);
  const [clickedLocation, setClickedLocation] = useState(null);

  return (
    <div className="carte-container">
      <div className="map-content">
        <SearchMaps {...{ mapRef, clickedLocation, setClickedLocation }}>
          <ChildrenMaps mapRef={mapRef} />
          {/* <MapsLayout {...{ mapRef, clickedLocation }} positionX="right" content={["location"]} /> A REMETTRE POUR FAIRE FONCTIONNER LE CLICK */}
        </SearchMaps>
      </div>
    </div>
  );
};

export default CreateCampaignMap;
