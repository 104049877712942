/* eslint-disable camelcase */
import { presta_icons } from './prestaIcon'
import moment from 'moment'

function transformValue (column, item) {
  let current_item = item
  if (column?.sub_items) {
    if (column?.sub_items?.length === 1) current_item = item[column?.sub_items[0]]
    if (column?.sub_items?.length === 2) current_item = item[column?.sub_items[0]]?.[column?.sub_items[1]]
    if (column?.sub_items?.length === 3) current_item = item[column?.sub_items[0]]?.[column?.sub_items[1]]?.[column?.sub_items[2]]
  }
  if (column?.type === 'prestation') {
    return (presta_icons[current_item])
  }
  if (column?.exist) {
    if (column?.format === 'date') {
      return (moment(item[column?.id])?.format('DD/MM'))
    } else if (typeof item[column?.id] === 'object' && item[column?.id] !== null) { return item[column?.id] } else if (typeof item[column?.id] === 'string' && item[column?.id]?.includes('/rails/active_storage')) { return (process.env.REACT_APP_BACK_API + item[column?.id]) } else { return (item[column?.id]?.toString() ? item[column?.id]?.toString() : '[empty]') }
  } else if (column?.id === 'full_name') {
    return (current_item?.first_name && current_item?.last_name ? `${current_item?.first_name} ${current_item?.last_name}` : '[empty]')
  } else if (column?.sub_items) { return (current_item) } else if (column?.type === 'picture' && column?.src) { return (column.src) } else { return ('[non défini]') }
}

export function createData (current_list, items) {
  const formatted_list = current_list?.map((_current) =>
    items?.map((_item) =>
      ({ ..._item, value: transformValue(_item, _current) })))
  return (formatted_list)
}
