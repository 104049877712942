/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { IoMdAddCircle } from 'react-icons/io'
import InputText from '../../Inputs/inputText/inputText'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

const SolutionExplain = ({ setSolutionList, explains, solutionIndex }) => {
  const [explainList, setExplainList] = useState(explains)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (isLoad) {
      setSolutionList(prev => prev?.map(
        (sol, index) => index === solutionIndex
          ? { ...sol, list: explainList }
          : sol)
      )
    }
    setIsLoad(true)
  }, [explainList])

  const handleAddSolutionContent = () => setExplainList((prev) => ([...prev, '']))

  const handleDelete = (index) => setExplainList(
    (prev) => (prev.filter((_, _index) => _index !== index))
  )

  const handleChange = (e, index) => {
    setExplainList(prev => prev
      ?.map((sol, _index) => _index === index ? e?.target?.value : sol)
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        {explainList?.map((sol, indexj) => {
          return <div key={`solution-${solutionIndex}-${indexj}`} className="list-solution-action">
            <div className="list">
              <div className="list-item">
                <InputText placeholder={`solution ${indexj + 1}`} handleChange={(e) => handleChange(e, indexj)}
                  value={sol} label="solution proposée" bgIsInWhite={false} style={{ height: 'unset' }} />
              </div>
            </div>
            <div className="action" onClick={() => handleDelete(indexj)}>
              <DeleteRoundedIcon color="error" size={30} />
            </div>
          </div>
        }
        )}
      </div>
      <div className="action" onClick={() => handleAddSolutionContent()}>
        <IoMdAddCircle color="#00FFE0" size={30} />
      </div>
    </div>
  )
}

export default SolutionExplain
