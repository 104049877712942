import React, { memo, useState, useEffect, useMemo } from "react";
import { GoogleMap, MarkerClusterer, OverlayView } from "@react-google-maps/api";
import { CampaignMapProvider, useCampaignMap } from "./campaignMapContext";
import InputSearchLocation from "./MapLayout/InputSearchLocation";
import LeftLayout from "./MapLayout/LeftLayout";
import InfosModal from "./MapLayout/InfosModal";
import BottomLayout from "./MapLayout/BottomLayout";
import StatsLayout from "./MapLayout/StatsLayout";
import { RightModalProvider } from "views/Lists/Campaigns/rightModalContext";
import LocationTracker from "components/LocationTracker/LocationTracker";
import { useSelector, useDispatch } from "react-redux";
import { setShowGraph } from "_redux/Missions/reducers";
import ExistingSpots from "./ExistingSpots";
import "./style.scss";
import { getItemType } from "views/Cartes/CampaignViewMap/MapLayout/LeftLayout/DraggableList/function";
import { AnomalieMarker } from "views/Cartes/CampaignViewMap/MapLayout/DrawingComponents/Marker";
import { AnomaliesInfos } from "views/Cartes/CampaignViewMap/MapLayout/InfosModal";

const MapLayout = memo(({ mapRef }) => {
  const [clientView, setClientView] = useState(false);
  const [isSlidOpen, setIsSlideOpen] = useState(false);
  const itemsDragSelected = useSelector((state) => state.map.itemsDragSelected);
  const {
    prestationsList,
    currentMissionsLastLocations,
    setCurrentMissionsLastLocations,
    currentPolygonSelect
  } = useCampaignMap();

  const selectedZonesItems = useMemo(() => {
    setIsSlideOpen(false);
    const zonesItems = itemsDragSelected.filter((item) => item.type === 'zone')
    if (zonesItems?.length > 0) {
      return zonesItems;
    } else if (currentPolygonSelect) {
      return [currentPolygonSelect];
    }
    return [];
  }, [itemsDragSelected, currentPolygonSelect]);

  const [existingSpotsListModalOpen, setExistingSpotsListModalOpen] =
    useState(false);
  const { currentMissionLaunch, showGraph } = useSelector(
    (state) => state.missions
  );

  const pendingMissions = useMemo(() => {
    return (
      prestationsList?.flatMap((prestation) =>
        prestation?.slots?.flatMap((slot) => slot.items ?? [])
      ) ?? []
    ).filter((item) => item !== undefined && getItemType(item) === "mission");
  }, [prestationsList]);

  const isCurrentMissionLaunchEnCours = useMemo(() => {
    if (currentMissionLaunch && currentMissionLaunch?.id) {
      const currentMissionLaunchId = currentMissionLaunch.id.toString();
      const currentMissionLaunchIncurrentMissionsLastLocations =
        currentMissionsLastLocations?.[currentMissionLaunchId] ?? null;
      return currentMissionLaunchIncurrentMissionsLastLocations !== null;
    }
    return null;
  }, [currentMissionsLastLocations, currentMissionLaunch]);

  useEffect(() => {
    setCurrentMissionsLastLocations((prev) => {
      const newLocations = {};
      pendingMissions.forEach((mission) => {
        if (mission.status === "En cours") {
          const sortedPositions = mission?.sorted_positions ?? [];
          const lastArray = sortedPositions[sortedPositions.length - 1] ?? [];
          const lastPosition = lastArray[lastArray.length - 1] ?? null;
          if (lastPosition) {
            newLocations[mission.id] = {
              ...lastPosition,
              on_break: lastPosition.pause_indicator === 1,
            };
          }
        } else {
          newLocations[mission.id] = null;
        }
      });
      return {
        ...prev,
        ...newLocations,
      };
    });
  }, [pendingMissions, setCurrentMissionsLastLocations]);

  useEffect(() => {
    const updateLocations = async (locations, missionId, onBreak) => {
      for (const location of locations) {
        await new Promise(resolve => {
            setTimeout(() => {
              setCurrentMissionsLastLocations((prev) => {
                  return {
                      ...prev,
                      [missionId]: {
                          ...location,
                          on_break: onBreak,
                      },
                  };
              });
              resolve();
            }, 1000);
        });
      }
    };
    const socket_port = `${process.env.REACT_APP_BACK_API}/cable`.replace(
      /^https?:\/\//,
      ""
    );
    let ws;
    if (process.env.REACT_APP_BACK_API.includes("localhost")) {
      ws = new WebSocket(`ws://${socket_port}`);
    } else ws = new WebSocket(`wss://${socket_port}`);
    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({ channel: "LocationsChannel" }),
        })
      );
    };
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (
        data?.type !== "ping" &&
        data?.type !== "confirm_subscription" &&
        data?.type !== "welcome"
      ) {
        if (data?.message?.locations?.length > 0 && data?.message?.mission_id) {
          const missionId = data?.message.mission_id.toString();
          updateLocations(data.message.locations, missionId, data?.message.on_break)
        }
      }
    };
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  const haveAtLeastOneFlyingHandPrestation = React.useMemo(() => {
    return prestationsList.some(
      (prestation) => prestation.presta_type === "Flying Hand"
    );
  }, [prestationsList]);

  const handleExistingSpotsListModalOpen = () => {
    setExistingSpotsListModalOpen(true);
  };

  const handleExistingSpotsListModalClose = () => {
    setExistingSpotsListModalOpen(false);
  };

  return (
    <React.Fragment>
      <InputSearchLocation />
      <LeftLayout {...{ clientView }} />
      {haveAtLeastOneFlyingHandPrestation && (
        <div
          className="flying-hand-add-existing-spot-btn"
          onClick={handleExistingSpotsListModalOpen}
        >
          AJOUTER UN POINT EXISTANT
        </div>
      )}
      {existingSpotsListModalOpen && (
        <ExistingSpots
          isOpen={existingSpotsListModalOpen}
          handleClose={handleExistingSpotsListModalClose}
        />
      )}
      {showGraph &&
        !isCurrentMissionLaunchEnCours &&
        currentMissionLaunch?.locations?.length && (
          <div className="campaign-map-graph">
            <LocationTracker mapRef={mapRef} />
          </div>
        )}
      {selectedZonesItems?.length > 0 && <StatsLayout {...{ isSlidOpen, setIsSlideOpen, selectedZonesItems }} />}
      <BottomLayout {...{ clientView, setClientView }} />
    </React.Fragment>
  );
});

const Maps = () => {
  const {
    mapRef,
    handleClickLocation,
    infosModal,
    anomaliesParamsListForMarkerCluster,
    mapItemsVisibility,
    prestationsList,
    setInfosModal,
    setAnomaliesInfosModal,
    anomaliesInfosModal,
  } = useCampaignMap();
  const [coordinates] = useState({ lat: 48.8584, lng: 2.2945 });
  const onMapLoad = (map) => {
    if (mapRef) {
      mapRef.current = map;
    }
  };
  const dispatch = useDispatch();
  const [anomaliesModalPosition, setAnomaliesModalPosition] = useState(null);

  useEffect(() => {
    dispatch(setShowGraph(false));

    return () => {
      // Perform actions before the component is unmounted
      dispatch(setShowGraph(false));
      // You can trigger an action here
      // For example, dispatching an action with Redux
      // Or calling a function to perform a cleanup
    };
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={{ flex: 1 }}
      center={coordinates}
      onZoomChanged={() => setTimeout(() => {}, 2000)}
      zoom={10}
      onLoad={onMapLoad}
      onClick={handleClickLocation}
    >
      <MapLayout mapRef={mapRef} />
      {infosModal && <InfosModal />}
      <MarkerClusterer onClick={(cluster) => {
        // when 2 anomalies are in the same location, the info modal is handled differntly that spots/single anomalies
        const cords = cluster.getMarkers().map((marker) => `${marker.getPosition().lat()}|${marker.getPosition().lng()}`)
        const uniqueCords = [...new Set(cords)];
        if (uniqueCords.length === 1) {
          const [lat, lng] = uniqueCords[0].split('|')
          const anomalieSpots = prestationsList.flatMap(prestation => prestation.anomalies).filter(anomalie => anomalie.address?.latitude?.toString() === lat && anomalie.address?.longitude?.toString() === lng)
          setAnomaliesInfosModal(anomalieSpots)
          setAnomaliesModalPosition({ lat, lng })
          setInfosModal(null) // close other modals
        }
      }} averageCenter>
        {(clusterer) => {
          return (
            <>
              {anomaliesParamsListForMarkerCluster.map(
                (markerParams, index) => {
                  if (mapItemsVisibility?.spots?.[markerParams.id] ?? true) {
                    return (
                      <AnomalieMarker
                        key={index}
                        params={{
                          ...markerParams,
                          visible:
                            mapItemsVisibility?.spots?.[markerParams.id] ?? true,
                        }}
                        clusterer={clusterer}
                      />
                    )
                  }
                  return null;
                }
              ).filter(res => res !== null)}
            </>
          );
        }}
      </MarkerClusterer>
      {anomaliesInfosModal && anomaliesModalPosition &&
      <OverlayView position={{ lat: anomaliesModalPosition.lat, lng: anomaliesModalPosition.lng }} mapPaneName={"floatPane"}>
        <AnomaliesInfos />
      </OverlayView>}
    </GoogleMap>
  );
};

const CampaignViewMap = () => {
  return (
    <CampaignMapProvider>
      <RightModalProvider>
        <Maps />
      </RightModalProvider>
    </CampaignMapProvider>
  );
};

export default CampaignViewMap;
