/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { Circle, Marker } from '@react-google-maps/api'

const MemoizedPointComponent = ({ item, handleClickMarker, currentPrestation }) => {
  const { ref, additional_ref, address, fillColor } = item

  const new_zone_ref = ref
  const new_circle_ref = additional_ref

  const onLoad = (ref) => {
    new_zone_ref.current = ref
  }

  const onLoadCircle = (ref) => {
    if (new_circle_ref) { new_circle_ref.current = ref }
  }
  return (
            <>
                {currentPrestation?.presta_type === 'Flying Hand' && <Circle
                onLoad={onLoadCircle}
                    center={{ lat: address.latitude, lng: address.longitude }}
                    radius={(!item.radius && item.radius !== 0) ? 50 : item?.radius}
                    options={{
                      fillColor, // Couleur de remplissage
                      strokeColor: fillColor // Couleur du contour
                    }}
                />}
                <Marker
                    clickable
                    onLoad={onLoad}
                    position={{ lat: address.latitude, lng: address.longitude }}
                    onClick={(e) => handleClickMarker(e, new_zone_ref, item)}
                    icon={{
                      path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                      scale: 6,
                      fillColor, // Use the state variable for the color
                      strokeColor: fillColor, // Use the state variable for the color
                      fillOpacity: 1,
                      strokeWeight: 1
                    }}
                />
            </>
  )
}

export default MemoizedPointComponent
