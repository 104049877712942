/* eslint-disable react/prop-types */
import React from 'react'
import { CircularProgress, Modal } from '@mui/material'
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'

const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //   width: 400,
  backgroundColor: 'white',
  borderRadius: 15,
  boxShadow: 24,
  padding: 30,
  zIndex: 99999
}

export const ModalWaitingSend = ({ open }) => {
  const { waitingSendDoneCount, waitingSendTotalCount } = useCampaignMap()
  const displayedProgress = React.useMemo(() => {
    if (waitingSendDoneCount === null || waitingSendDoneCount === undefined || waitingSendTotalCount === null || waitingSendTotalCount === undefined) return null
    return `${waitingSendDoneCount}/${waitingSendTotalCount}`
  }, [waitingSendDoneCount, waitingSendTotalCount])
  return (
        <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: 999999 }}
      >
      <div onClick={e => e.stopPropagation()} style={styles}>

          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            Chargement des zones en cours veuillez patienter ...
            {displayedProgress && <span>{displayedProgress}</span>}
            <CircularProgress/>
            {/* {documents?.length === 0 ? <div>Il n'y a aucun document lié à cette prestation</div> : <div>Liste des documents :</div>}
            {documents?.length > 0 &&
              <div>
                {newDocs?.map((doc, index) =>
                  <div style={{ display: "flex", justifyContent: "space-between", gap: 20 }}>
                    <div>{doc?.file_name}</div>
                    <div style={{ color: "red" }} onClick={() => removeDocuments(doc.id)}>Supprimer</div>
                  </div>)}
              </div>
            }
            {loadNewFile ? <CircularProgress/> : <input type="file" onChange={sendNewFile}/>} */}
          </div>
      </div>
      </Modal>
  )
}
