import { Droppable } from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext"
import './style.scss'

const InitialView = (props) => {
  const { handleSelectPrestation } = useCampaignMap()
  const initialSelected = useSelector(state => state.map.initialSelected)

  const isActive = initialSelected?.id === props.prestation.id

  return (
    <div style={{ backgroundColor: "white", marginTop: 10 }}>
      <Droppable droppableId={`initiales-${props.prestation.id}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="initials-container">
              <div>Zones initiales</div>
              <div onClick={() => handleSelectPrestation(props.prestation)} className={`add-initial-btn ${isActive && "active"}`}>+</div>
            </div>
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default InitialView
