/* eslint-disable react/prop-types */
import React from 'react'
import { ParentContainer } from '../ParentContainer/parentContainer'
import './imageBloc.scss'

const ImageBloc = ({ src, height = 842 }) => {
  return (
    <div style={{ backgroundColor: '#23272A', height }}>
      <img src={src} height={height} alt="bloc" className='lazyload'
        style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
    </div>
  )
}

export default ImageBloc

export const DoubleImageBloc = ({ srcLeft, srcRight, height }) => {
  return (
    <ParentContainer style={{ backgroundColor: 'white' }} >
    <div className='double-bloc' style={{ height }}>
      <div style={{ display: 'flex', gap: 20, justifyContent: 'space-between' }}>
        <div style={{ width: 800 }}>
          <img src={srcLeft} height={height} width={800} alt="bloc" className='lazyload'
            style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
        </div>
        <div style={{ width: 600 }}>
          <img src={srcRight} height={height} width={600} alt="bloc" className='lazyload'
            style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
        </div>
      </div>
    </div>

    </ParentContainer>
  )
}
