/* eslint-disable react/prop-types */
import { Modal } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import API from 'services/axios-config'
import { ButtonComponent } from 'views/Admin/Shared/Buttons/Generic'
import { SelectObjectsComponent } from 'views/Admin/Shared/Forms/SelectObjects'
import { ReactComponent as Star2Svg } from 'assets/star2.svg';
import FilterComponent from 'components/GlobalInputs/Filters'

const AssignSmModal = ({ action, open, close, loading = false }) => {
  const [smList, setSmList] = useState([])
  const [smSelected, setSmSelected] = useState(null)
  const [disabled, setDisabled] = useState(false)

  const [selectedFilters, setSelectedFilters] = useState({
    noteSm: null,
  });

  useEffect(() => {
    setDisabled(true)
    API.get(`/users?profil_type=marketer&status=["Validé"]${selectedFilters.noteSm ? `&rating_global=${selectedFilters.noteSm}` : ''}`)
      .then((response) => {
        setSmList(response?.data?.users?.map(user => ({ ...user, full_name: `${user?.first_name} ${user?.last_name}` })))
      })
      .catch((err) => enqueueSnackbar('Une erreur est survenu lors de la récupération des Street-marketeurs : ' + err, { variant: 'error' }))
      .finally(() => setDisabled(false))
  }, [selectedFilters])

  function handleAssignSm (e) {
    setSmSelected(e)
  }

  function handleclose () {
    setSmSelected(null)
    close()
  }

  const NoteSm = ({ handleUpdateFilter, currentFilters }) => {
    const [hoveredStar, setHoveredStar] = useState(null);

    const handleMouseEnter = (star) => {
        setHoveredStar(star);
    };

    const handleMouseLeave = () => {
        setHoveredStar(null);
    };

    const handleNoteSmStarClicked = (note) => {
        const noteSm = note === currentFilters.noteSm ? null : note;
        handleUpdateFilter(noteSm)
    }

    return (
        <div className="existing-spot-filter-popover-collapse-content">
            <div className="existing-spot-filter-popover-collapse-text">SÉLECTIONNE UNE NOTE ICI ET TU VERRAS LES SMs AVEC DES NOTES SUPÉRIEURES OU ÉGALE.</div>
            <div className="existing-spot-filter-popover-collapse-stars-container">
                {[1, 2, 3, 4, 5].map((star) => (
                    <Star2Svg
                        key={star}
                        style={{ width: 20, height: 20 }}
                        color={
                            hoveredStar >= star
                                ? "#60EED280"
                                : currentFilters.noteSm && currentFilters.noteSm >= star && !hoveredStar
                                    ? "#60EED2"
                                    : "white"
                        }
                        stroke="black"
                        onMouseEnter={() => handleMouseEnter(star)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleNoteSmStarClicked(star)}
                    />
                ))}
            </div>
        </div>)
  };

  const filters = [
    { key: 'noteSm', label: 'NOTE', customComponent: <NoteSm />, multiple: false }
  ];

  const renderSmItem = (props, option) => {
    const isEmpty = option.full_name === "Aucune correspondance"
    return <li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>{option.full_name}</div>
      {!isEmpty && option.rating_global !== null && <div style={{ display: 'flex', gap: 4 }}>
      {[...Array(5)].map((_, index) => (
          <Star2Svg
            key={index}
            color={option.rating_global && index < option.rating_global ? "#60EED2" : "white"}
            height={12}
            width={12}
          />
        ))}
      </div>}
    </li>
  }

  return (
    <Modal open={open} onClose={handleclose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ padding: 20, backgroundColor: '#fff', borderRadius: 15 }}>
          <div style={{ marginBottom: 15 }}>Assigner un street marketeur à la mission: </div>
          <div style={{ margin: '20px 0' }}>
            <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} />
          </div>
          <SelectObjectsComponent
            name='Street-marketeur'
            handleAction={(e) => handleAssignSm(e)}
            items={smList}
            item_name="full_name"
            label='Street-marketeur'
            disabled={disabled}
            customRenderOption={renderSmItem}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
            {smSelected && <ButtonComponent text="Confimer" handleActions={() => action(smSelected?.id)} loading={loading}/>}
          </div>
        </div>
      </Modal>
  )
}
export default AssignSmModal
