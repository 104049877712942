/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { useSelector } from 'react-redux'
import './style.scss'
import { memo, useState } from 'react'
import PrestationLayout from './prestationLayout'
import WindowInfo from './InfoWindow/windowInfo'
import WindowInfoMarker from './InfoWindow/windowInfoMarker'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import ValidationPublish from 'components/Zones/ValidationPublish/ValidationPublish'
const LeftLayout = memo((props) => {
  const { addToStack } = useRightModal()
  const { zoom, mapRef } = props
  const services = [
    'FlyingBox',
    'FlyingHand',
    'FlyingStore',
    'FlyingBike',
    'FlyingWall'
  ]

  const [slotSelected, setSlotSelected] = useState(null)
  const [prestationSelected, setPrestationSelected] = useState(null)
  const [activePolygon, setActivePolygon] = useState(null)
  const [activePolyline, setActivePolyline] = useState(null)
  const [modalInfos, setModalInfos] = useState(null)
  const [fullZoneList, setFullZoneList] = useState({ FlyingBike: [] })
  const visibilityZones = useSelector((state) => state.zone.visibilityZones)

  function handelFullZoneList (zones) {
    setFullZoneList([...fullZoneList, ...zones])
  }

  function handleSelectSlot (slot) {
    setSlotSelected(slot)
    setPrestationSelected(null)
  }

  function handleSelectPrestation (presta) {
    setPrestationSelected(presta)
    setSlotSelected(null)
  }

  // function formatZoneList(mapZones) {
  //   const defaultZones = [];

  //   services.forEach((service) => {
  //     defaultZones.push(mapZones[service]);
  //   });

  //   console.log(defaultZones, "defaultZones");
  //   return defaultZones;
  // }

  return (
    <div className="left-layout-container text-ffy" style={{ zoom }}>
      {modalInfos?.type === 'zones' && (
        <WindowInfo {...{ slotSelected, setModalInfos, modalInfos }} />
      )}
      {modalInfos?.type === 'spots' && (
        <WindowInfoMarker {...{ slotSelected, setModalInfos, modalInfos }} />
      )}
      {services.map((_current_prestation) => {
        return (
          <div key={`current-presta-${_current_prestation}`}>
            <PrestationLayout
              prestationName={_current_prestation}
              {...{
                handleSelectSlot,
                slotSelected,
                handleSelectPrestation,
                prestationSelected,
                mapRef,
                activePolygon,
                setActivePolygon,
                activePolyline,
                setActivePolyline,
                handelFullZoneList,
                setModalInfos
              }}
            />
            {/* <ContentMap
              prestationName={_current_prestation}
              {...{setPolygonRefs}}
            /> */}
          </div>
        )
      })}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div
          className="publish-map-btn"
          style={
            !visibilityZones?.publish?.length
              ? {
                  backgroundColor: '#C8C8C8',
                  color: '#FFFFFF',
                  cursor: 'not-allowed'
                }
              : {}
          }
          onClick={() => {
            visibilityZones?.publish?.length && addToStack({
              component: <ValidationPublish type="publish"/>
            })
          }
          }
        >
          PUBLIER
        </div>
        {visibilityZones.hide.length && (
          <div
            className="publish-map-btn"
            style={{ color: 'red' }}

            onClick={() => {
              visibilityZones?.hide?.length && addToStack({
                component: <ValidationPublish type="hide" />
              })
            }
            }
          >
            DÉPUBLIER
          </div>
        )}
      </div>
    </div>
  )
})

export default LeftLayout
