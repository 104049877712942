import React, { useState } from 'react'
import { InputText } from './InputText'
import { SelectInput, SelectInputMultiple } from './Select'
import BtnComponent from './Btn'
import AddressComponent from './Address'
import FilterComponent from './Filters'
import API from 'services/axios-config';
import { AutocompleteInput } from 'components/GlobalInputs/Select'

const InputTexts = () => {
  return (
        <>
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
            <InputText label="LABEL" variant="rounded" />
            <InputText label="LABEL" variant="rounded" />
            <InputText label="LABEL" variant="rounded" disabled />
            <InputText label="LABEL" variant="rounded" disabled value="TEXTE" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
            <InputText label="LABEL" />
            <InputText label="LABEL" />
            <InputText label="LABEL" disabled />
            <InputText label="LABEL" disabled value="TEXTE" />
          </div>
        </div>
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
            <InputText label="LABEL" variant="rounded" placeholder="Saisir les informations..." multiline />
            <InputText label="LABEL" variant="rounded" placeholder="Saisir les informations..." multiline />
            <InputText label="LABEL" variant="rounded" placeholder="Saisir les informations..." multiline disabled />
            <InputText label="LABEL" variant="rounded" placeholder="Saisir les informations..." multiline disabled value="TEXTE" />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
            <InputText label="LABEL" placeholder="Saisir les informations..." multiline />
            <InputText label="LABEL" placeholder="Saisir les informations..." multiline />
            <InputText label="LABEL" placeholder="Saisir les informations..." multiline disabled />
            <InputText label="LABEL" placeholder="Saisir les informations..." multiline disabled value="TEXTE" />
          </div>
        </div></>
  )
}

const InputSelects = () => {
  return (
        <>
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
          <SelectInput label="LABEL" variant="rounded" value={20} items={[20, 30, 40]}/>
            <SelectInput label="LABEL" variant="rounded" />
            <SelectInput label="LABEL" variant="rounded" nullable keys={['first_name', 'last_name']} items={[{ first_name: 'alexandre', last_name: 'somville' }, { first_name: 'alexandre1', last_name: 'somville1' }, { first_name: 'alexandre2', last_name: 'somville2' }]}/>
            <SelectInput label="LABEL" variant="rounded" value={20} nullable items={[20, 30, 40]}/>
            <SelectInput label="LABEL" variant="rounded" disabled />
            <SelectInput label="LABEL" variant="rounded" disabled value={20} items={[20, 30, 40]}/>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
          <SelectInput label="LABEL" value={20} items={[20, 30, 40]}/>
            <SelectInput label="LABEL" />
            <SelectInput label="LABEL" value={20} nullable items={[20, 30, 40]}/>
            <SelectInput label="LABEL" disabled />
            <SelectInput label="LABEL" disabled value={20} items={[20, 30, 40]}/>
          </div>
        </div>
        </>
  )
}

const InputSelectsMultiple = () => {
  return (
        <>
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
            <SelectInputMultiple label="LABEL" variant="rounded" value={['value1']} items={['value1', 'value2', 'value3']}/>
            <SelectInputMultiple label="LABEL" variant="rounded" value={[{ value: 'value1', label: 'value1' }]} items={[{ value: 'value1', label: 'value1' }, { value: 'value2', label: 'value2' }, { value: 'value3', label: 'value3' }]}/>
            <SelectInputMultiple label="LABEL" variant="rounded" keys={['first_name', 'last_name']} value={[{ first_name: 'alexandre', last_name: 'somville' }]} items={[{ first_name: 'alexandre', last_name: 'somville' }, { first_name: 'alexandre1', last_name: 'somville1' }, { first_name: 'alexandre2', last_name: 'somville2' }]}/>
            <SelectInputMultiple label="LABEL" variant="rounded" disabled />
            <SelectInputMultiple label="LABEL" variant="rounded" disabled keys={['first_name', 'last_name']} value={[{ first_name: 'alexandre', last_name: 'somville' }]} items={[{ first_name: 'alexandre', last_name: 'somville' }, { first_name: 'alexandre1', last_name: 'somville1' }, { first_name: 'alexandre2', last_name: 'somville2' }]}/>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
          <SelectInputMultiple label="LABEL" value={['value1']} items={['value1', 'value2', 'value3']}/>
          <SelectInputMultiple label="LABEL" value={'toto'}/>
            <SelectInputMultiple label="LABEL" value={[{ value: 'value1', label: 'value1' }]} items={[{ value: 'value1', label: 'value1' }, { value: 'value2', label: 'value2' }, { value: 'value3', label: 'value3' }]}/>
            <SelectInputMultiple label="LABEL" keys={['first_name', 'last_name']} value={[{ first_name: 'alexandre', last_name: 'somville' }]} items={[{ first_name: 'alexandre', last_name: 'somville' }, { first_name: 'alexandre1', last_name: 'somville1' }, { first_name: 'alexandre2', last_name: 'somville2' }]}/>
            <SelectInputMultiple label="LABEL" disabled />
            <SelectInputMultiple label="LABEL" disabled keys={['first_name', 'last_name']} value={[{ first_name: 'alexandre', last_name: 'somville' }]} items={[{ first_name: 'alexandre', last_name: 'somville' }, { first_name: 'alexandre1', last_name: 'somville1' }, { first_name: 'alexandre2', last_name: 'somville2' }]}/>
            <SelectInputMultiple variant={11} />
          </div>
        </div>
        </>
  )
}

const BtnStyled = () => {
  const [docTemp, setdocTemp] = useState([])

  function onRemove(value) {
    setdocTemp(prev => prev.filter(item => item !== value))
  }

  function onAdd() {
    setdocTemp(prev => [...prev, `toto-${prev?.length}`])
  }
  return (
        <>
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <BtnComponent/>
            <BtnComponent variant={"rounded"}/>
            <BtnComponent variant={"rounded"} disabled/>
            <BtnComponent variant={"regular"} disabled/>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <BtnComponent bgColor={'white'}/>
            <BtnComponent variant={"rounded"} color={"red"}/>
            <BtnComponent variant={"rounded"} isIcon/>
            <BtnComponent variant={"regular"} isIcon/>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <BtnComponent bgColor={'white'} fetching/>
            <BtnComponent variant={"rounded"} color={"red"} fetching/>
            <BtnComponent variant={"rounded"} isIcon fetching disabled/>
            <BtnComponent variant={"regular"} isIcon fetching/>
          </div>
        </div>
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
            <SelectInputMultiple label="LABEL" value={docTemp} items={docTemp} isBtn onRemove={onRemove} onClick={onAdd}/>

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '-webkit-fill-available' }}>
            <SelectInputMultiple label="LABEL" value={docTemp} items={docTemp} isBtn onRemove={onRemove} onClick={onAdd}/>
          </div>
        </div>
        </>
  )
}

const FilterStyled = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  React.useEffect(() => { console.log('selectedFilters in parent component:', selectedFilters) }, [selectedFilters])
  const CampaignAutoComplete = ({ handleUpdateFilter }) => {
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState([]);
    const [value, setValue] = useState('');

    React.useEffect(() => {
        async function getCampaigns(value) {
            if (value.trim().length > 0) {
                setFetching(true);
                try {
                    const res = await API.get(`campaign_autocomplete_by_name?campaign_name=${value}`);
                    setItems(res.data.campaigns.map(campaign => campaign.campaign_name));
                } finally {
                    setFetching(false);
                }
            }
        }

        if (value.trim().length > 0) {
            getCampaigns(value);
        }
    }, [value]);

    const handleChange = (value) => {
        setValue(value);
    };

    function handleSelectValue(value) {
      setValue(value)
      handleUpdateFilter(value, true)
  }

    return (
      <div style={{ padding: '40px 20px', overflow: 'auto', margin: '0 0 20px 0' }}>
        <AutocompleteInput label="Campaign" onChange={handleChange} onSelect={handleSelectValue} {...{ items, fetching, value }} />
      </div>
    );
  };
  const filters = [
    { key: 'statut', label: 'Statut', options: ['Option 1', 'Option 2', 'Option 3'], multiple: false },
    { key: 'date', label: 'Date', options: ['Date 1', 'Date 2', 'Date 3'], multiple: false },
    { key: 'responsables', label: 'Responsable Opérationnel', options: ['Responsable 1', 'Responsable 2', 'Responsable 3'], multiple: true },
    { key: 'campaigns', label: 'Campagne', customComponent: <CampaignAutoComplete />, multiple: true }
  ];

  return (
        <>
          <div style={{ display: 'flex', gap: 20 }}>
            <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} setFilterIsReady={ () => console.log('[OPTIONAL] USED SO THE API CALLS DON"T RUN TWICE AND WAIT FILTERS COMPONENT TO FULLY INILIZE (IF NEEDED)')}/>
          </div>
        </>
  )
}

const AddressStyled = () => {
  const [addrSelect, setAddrSelect] = useState(null)
  function onSelectAddress(value) {
    setAddrSelect(value)
  }
  return (
    <div>
      {addrSelect && <div style={{ marginBottom: 20 }}>Tu as séléctionné l&apos;addresse: {addrSelect.label}</div>}
      <AddressComponent onSelectAddress={onSelectAddress}/>
    </div>
  )
}

const PageTemplateInput = () => {
  const [currentView, setCurrentView] = useState('inputText')

  function currentViewDisplay () {
    switch (currentView) {
      case 'inputText':
        return <InputTexts/>
      case 'select':
        return <InputSelects/>
      case 'multiple':
        return <InputSelectsMultiple/>
      case 'btn':
        return <BtnStyled/>
      case 'filter':
        return <FilterStyled/>
      case 'address':
        return <AddressStyled/>
      default:
        return <InputTexts/>
    }
  }
  return (
    <div style={{ flex: 1, backgroundColor: 'white', padding: 50, overflow: 'scroll' }}>
      <div style={{ display: "flex", gap: 10 }}>
        <button style={{ backgroundColor: currentView === "inputText" ? '#60EED2' : "inherit" }} onClick={() => setCurrentView('inputText')}>inputText</button>
        <button style={{ backgroundColor: currentView === "select" ? '#60EED2' : "inherit" }} onClick={() => setCurrentView('select')}>select</button>
        <button style={{ backgroundColor: currentView === "multiple" ? '#60EED2' : "inherit" }} onClick={() => setCurrentView('multiple')}>multiple select</button>
        <button style={{ backgroundColor: currentView === "btn" ? '#60EED2' : "inherit" }} onClick={() => setCurrentView('btn')}>Button</button>
        <button style={{ backgroundColor: currentView === "filter" ? '#60EED2' : "inherit" }} onClick={() => setCurrentView('filter')}>filter</button>
        <button style={{ backgroundColor: currentView === "address" ? '#60EED2' : "inherit" }} onClick={() => setCurrentView('address')}>address</button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 40 }}>
        {currentViewDisplay()}
      </div>
    </div>
  )
}

export default PageTemplateInput
