/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import { useRightModalMapLayout } from "views/Lists/Campaigns/rightModalContext";
import ValidationPublish from "./ValidationPublish/ValidationPublish";
import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext";
import { useSelector } from "react-redux"
import "./styles.scss"
import API from 'services/axios-config'

const PublicateButtons = () => {
  const { addToStack } = useRightModalMapLayout();
  const { clientVisibility, prestationsList, modifyItems } = useCampaignMap();
  const multipleSelectItems = useSelector(state => state.map.itemsDragSelected)
  const [isLoading, setIsLoading] = React.useState(false);

  const allItems = React.useMemo(() => {
    const items = [];
    if (prestationsList.length > 0) {
      prestationsList.forEach(prestation => {
        prestation.anomalies?.forEach((anomalie) => {
          items.push({ ...anomalie, prestation_id: prestation.id })
        })
        prestation?.slots?.forEach(slot => {
          slot?.items?.forEach(item => {
            items.push({ ...item, prestation_id: prestation.id })
          })
        })
      });
    }
    return items
  }, [prestationsList])

  const updateClientVisibilty = useCallback(async (val) => {
    if (!isLoading) {
      const zonesItems = [];
      const spotsItems = [];
      const missionsItems = [];
      const itemsToProcess = multipleSelectItems.length > 0 ? multipleSelectItems : allItems;

      itemsToProcess.forEach((item) => {
        if (item.polygon) zonesItems.push(item);
        else if (item.address_id) spotsItems.push(item);
        else if (item.user) missionsItems.push({ ...item });
      })

      setIsLoading(true);

      const zonePromise = zonesItems.length > 0
        ? API.patch('update_zones_visiblity_by_ids', {
          zones_ids: zonesItems.map(item => item.id),
          client_visible: val
        }).then(() => {
          zonesItems.forEach(item => modifyItems({ ...item, client_visible: val }));
        })
        : Promise.resolve();

      const spotPromise = spotsItems.length > 0
        ? API.patch('update_spots_visiblity_by_ids', {
          spots_ids: spotsItems.map(item => item.id),
          client_visible: val
        }).then(() => {
          spotsItems.forEach(item => modifyItems({ ...item, client_visible: val }));
        })
        : Promise.resolve();

      const missionPromise = missionsItems.length > 0
        ? API.patch('update_missions_visiblity_by_ids', {
          missions_ids: missionsItems.map(item => item.id),
          client_visible: val
        }).then(() => {
          missionsItems.forEach(item => modifyItems({ ...item, client_visible: val }));
        })
        : Promise.resolve();

      Promise.all([zonePromise, spotPromise, missionPromise])
        .then(() => {
          setIsLoading(false);
        })
        .catch(error => {
          // Handle any errors here
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [allItems, isLoading, multipleSelectItems]);

  return (
    <div>
      {clientVisibility && <div
        className="publish-map-button"
        style={{
          color: clientVisibility.type === "publish" ? "rgba(96, 238, 210, 1)" : "#FF0000",
        }}
        onClick={() => {
          addToStack({
            component: <ValidationPublish type={clientVisibility.type} />,
          });
        }}
      >
        {clientVisibility.type === "publish" ? "PUBLIER" : "DÉPUBLIER"}
      </div>}
      {!clientVisibility && allItems.length > 0 && (<div className="publish-unpublish-btns-container">
        <div className="publish-map-button" style={{ color: isLoading ? '#60EED280' : '#60EED2', marginBottom: 20, cursor: isLoading ? 'not-allowed' : 'pointer' }} onClick={() => updateClientVisibilty(true)}>
          TOUT PUBLIER
        </div>
        <div className="publish-map-button" style={{ color: isLoading ? '#FF000080' : '#FF0000', cursor: isLoading ? 'not-allowed' : 'pointer' }} onClick={() => updateClientVisibilty(false)}>
          TOUT DÉPUBLIER
        </div>
      </div>)}
    </div>
  );
};

export default PublicateButtons;
