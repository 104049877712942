import { createSlice } from '@reduxjs/toolkit'

const fetchingData = { fetching: true }

export const adminAction = createSlice({
  name: 'admin',
  initialState: {
    connected: false,
    fetching: false,
    user: null,
    auth_token: null,
    hasNewMessage: false
  },
  reducers: {
    logIn: (state) => ({ ...state, ...fetchingData }),
    logged: (state, action) => {
      return {
        ...state,
        user: action.payload,
        fetching: false,
        connected: true,
        hasNewMessage: false
      }
    },
    updateHasNewMessage: (state, action) => {
      return {
        ...state,
        hasNewMessage: action.payload
      }
    },
    userFetched: (state) => ({ ...state, fetching: false }),
    userUpdated: (state, action) => ({ ...state, user: action.payload, fetching: false }),
    userNotUpdated: (state) => ({ ...state, fetching: false })
  }
})

export const { logIn, logged, userFetched, updateHasNewMessage, userUpdated, userNotUpdated } = adminAction.actions

export default adminAction.reducer
