/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { GoogleMap, StandaloneSearchBox } from '@react-google-maps/api'
import React, { memo, useRef, useState } from 'react'
import './style.scss'
import axios from 'axios'
import Clickedmap from './Views/CreateCampaign/ClickedMap'

const containerStyle = {
  width: '100%',
  height: '100%'
}

const SearchMaps = memo((props) => {
  const [selectedPolygon, setSelectedPolygon] = useState(null)
  const { mapRef, clickedLocation, setClickedLocation } = props
  const [coordinates] = useState(props?.centerCoordinate || { lat: 48.8584, lng: 2.2945 })

  const onMapLoad = (map) => {
    if (props?.refCallback) { props?.refCallback(map) } else if (mapRef) { mapRef.current = map }
  }

  const searchBoxRef = useRef(null)

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces()
    if (places.length > 0) {
      const bounds = new window.google.maps.LatLngBounds()
      places.forEach((place) => {
        if (place.geometry && place.geometry.viewport) {
          bounds.union(place.geometry.viewport)
        }
      })
      mapRef.current.fitBounds(bounds)
      if (places[0].types.includes('locality') || places[0].types.includes('administrative_area_level_2') || places[0].types.includes('sublocality_level_1')) {
        axios.get(`https://nominatim.openstreetmap.org/search.php?q=${places[0].name}&polygon_geojson=1&format=json`)
          .then(res => setSelectedPolygon(res.data[0].geojson.coordinates))
      } else {
        setClickedLocation(places[0])
      }
    }
  }

  const handleClickLocation = (e) => {
    const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    const geocoder = new window.google.maps.Geocoder()
    geocoder
      .geocode({ location: latLng })
      .then((response) => {
        if (response.results[0]) {
          setClickedLocation(response.results[0])
        } else {
          window.alert('No results found')
        }
      })
      .catch((e) => window.alert('Geocoder failed due to: ' + e))
  }

  return <GoogleMap
          mapContainerStyle={containerStyle}
          center={coordinates}
          onZoomChanged={() => setTimeout(() => { }, 2000)}
          zoom={10}
          onLoad={onMapLoad}
          options={props.defaultOptions}
          onClick={handleClickLocation}
        >
          <StandaloneSearchBox
          onLoad={(searchBox) => (searchBoxRef.current = searchBox)}
          onPlacesChanged={handlePlacesChanged}
        >
          <input
            type="text"
            placeholder="Rechercher une adresse..."
            className="inputAddressSearch"
          />
        </StandaloneSearchBox>
        <Clickedmap {...{ selectedPolygon, clickedLocation, setClickedLocation }}/>
        {props.children}
        </GoogleMap>
})

export default SearchMaps
