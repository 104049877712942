/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import "./styles.scss";
import "../styles.scss";
import HeaderList from "../Headers";
import ViewsListTable from "components/Table/ViewsListTable";
import { createData } from "components/Table/ViewsListTable/function";
import { enqueueSnackbar } from "notistack";
import { getAllInvoices, getSmInvoices, updateSmInvoice, ValidateSmInvoice } from "./functions";
import { RightModalProvider } from "../Campaigns/rightModalContext";
import FilterComponent from 'components/GlobalInputs/Filters';
import { HandleModify, VisuStatus } from "./InvoicesComponent";
import InvoiceModal from "./InvoiceModal";

const months = ['JANVIER', 'FÉVRIER', 'MARS', 'AVRIL', 'MAI', 'JUIN', 'JUILLET', 'AOÛT', 'SEPTEMBRE', 'OCTOBRE', 'NOVEMBRE', 'DÉCEMBRE'];
const status = ['NON GÉNÉRÉE', 'À VENIR', 'RÉGLÉE', 'VALIDÉ'];

const LeftComponent = (props) => {
  const [inputValue, setInputValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  };

  function handleKeyDown(e) {
    const params = new URLSearchParams(searchParams);
    if (e.key === "Enter") {
      if (params.has('search')) {
        params.set('search', inputValue);
      } else {
        params.append('search', inputValue);
      }
      setSearchParams(params);
    }
  }

  return (
    <div className="bookmark-list">
      <h1 className="bookmark-title">{props.title}</h1>
      <div className="bookmark-filter">
        <input value={inputValue} onKeyDown={handleKeyDown} onChange={handleInputChange} type="text" placeholder="Rechercher..." className="temporary-filter" />
      </div>
    </div>
  );
};

const AdminInvoicesView = () => {
  const { pathname } = useLocation();
  const [fetching, setFetching] = useState(true);
  const [invoicesListFormated, setInvoicesListFormated] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [per, setPer] = useState(25);
  const [maxPage, setMaxPage] = useState(1);

  const [filtersParams] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    months: [],
    status: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const filters = [
    { key: 'months', label: "MOIS DE LA FACTURE", options: months, multiple: true },
    { key: 'status', label: 'STATUT', options: status, multiple: true },
  ];

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    // months
    if (selectedFilters.months?.length > 0) {
      const monthIndexes = selectedFilters.months.map(month => {
        const index = months.indexOf(month) + 1;
        return index < 10 ? `0${index}` : `${index}`;
      });
      params.set('month', monthIndexes.join(','));
    } else {
      params.delete('month');
    }

    // status
    if (selectedFilters.status?.length > 0) {
      params.set('status', selectedFilters.status.join(','));
    } else {
      params.delete('status');
    }

    setSearchParams(params);
  }, [selectedFilters, setSearchParams, searchParams]);

  function getParams() {
    let params = {};
    if (searchParams?.get('search') || searchParams?.get('search') === "") {
      params = { ...params, search: searchParams?.get('search') };
    }

    searchParams.forEach((value, key) => {
      if (key !== 'search') {
        params[key] = value.split(',');
      }
    });

    let paramsString = "";
    if (Object.keys(params).length > 0) {
      paramsString += Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    }
    return paramsString;
  }

  useEffect(() => {
    setCurrentPage(0);
  }, [searchParams, filtersParams]);

  const handleModify = (e, id) => {
    getSmInvoices(id)
      .then((res) => {
        setSelectedInvoice(res.data.invoice);
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
    setIsModalOpen(true);
  }
  const handleUpdateStatus = (e, status, row) => {
    const invoiceId = row.find(_item => _item?.id === 'id').value;
    console.log('Selected Invoice', invoiceId);
    const payload = {
      invoice: { status: status },
    };
    if (status == 'Validé') {
      ValidateSmInvoice(invoiceId)
        .then((res) => {
          handleGetAllInvoices(getParams());
        })
        .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
    } else {
      updateSmInvoice(invoiceId, payload)
        .then((res) => {
          handleGetAllInvoices(getParams());

        })
        .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
    }
  }

  const items = [
    { id: 'id', exist: true },
    { id: 'pdf_url', exist: true },
    { id: 'sm_full_name', column_name: 'NOM', isVisible: true, maxLength: 18, exist: true, wrap: 'nowrap' },
    { id: 'date', column_name: 'MOIS', isVisible: true, maxLength: 18, exist: true, wrap: 'nowrap' },
    { id: 'total_amount', column_name: 'MONTANT FACTURE', exist: true, isVisible: true },
    { id: 'number_of_missions', column_name: 'MISSIONS', exist: true, isVisible: true },
    { id: 'generation_errors', column_name: 'generation_errors', isVisible: false, exist: true },
    { id: 'sm_contest_details', column_name: 'sm_contest_details', isVisible: false, exist: true},
    { id: 'status', column_name: 'STATUT', exist: true, isVisible: true, component: <VisuStatus action={handleUpdateStatus} /> },
    { id: 'modify', column_name: 'MODIFIER', exist: true, isVisible: true, component: <HandleModify action={handleModify} /> },
  ];
  function handleGetAllInvoices(params = "") {
    getAllInvoices(currentPage, per, params)
      .then((res) => {
        setInvoicesListFormated(createData(res.data.invoices, items));
        setMaxPage(res.data.maxPage);
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
      .finally(() => setFetching(false));
  }

  useEffect(() => {
    handleGetAllInvoices(getParams());
  }, [currentPage, per, searchParams, filtersParams]);

  async function handleActionInvoices(action) {
    switch (action?.type) {
      case 'row-action': {
        const invoice_url = action?.row?.find(_item => _item?.id === 'pdf_url').value
        if (invoice_url && invoice_url !== '[empty]') window.open(invoice_url, '_blank');
        break
      }
      default:
        enqueueSnackbar('Action non connecté')
        break
    }
  }

  return (
    <RightModalProvider>
      <div className="list-container">
        <div className="list-content">
          <HeaderList
            leftComponent={
              <LeftComponent
                title={pathname.split("/").slice(-1)[0].toUpperCase()}
              />
            }
          />
          <div className="filter-component-invoices-container">
            <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} />
          </div>
          <ViewsListTable
            {...{
              fetching,
              setCurrentPage,
              currentPage,
              setPer,
              per,
              setMaxPage,
              maxPage,
            }}
            handleActions={handleActionInvoices}
            columns={items}
            list={invoicesListFormated}
          />
        </div>
      </div>
      <InvoiceModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          handleGetAllInvoices(getParams());
        }}
        invoiceData={selectedInvoice}
      />
    </RightModalProvider>
  );
};
export default AdminInvoicesView;
