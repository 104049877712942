/* eslint-disable react/prop-types */
import { SimpleButton } from '../components'
import React, { useEffect, useState } from 'react'
import { problmatique } from '../Inputs/InputSelect/dataSelection'
import { SelectSimpleComponent } from 'views/Admin/Shared/Forms/SelectSimple'
import './problimatisation.scss'

const ProblemContent = ({ problems, setExplain }) => {
  const [problemList, setProblemList] = useState(problems)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (isLoad) { setExplain(prev => ({ ...prev, problem: problemList })) }
    setIsLoad(true)
  }, [problemList])

  const handleSelectProblem = (values) => {
    setProblemList(values)
  }

  return (
    <div className='content'>
      <div className='btn'>
        <SimpleButton text={'PROBLÉMATIQUE'} className={'simpleBtnWhite'}
          style={{ fontSize: 13, padding: '10px 20px', cursor: 'unset' }}
        />
      </div>
      <div className='content_bloc_list'>
        <div style={{ border: '1px solid white' }} className='divider'></div>
        <div className='content_list'>
          <SelectSimpleComponent multiple={true}
            label="" name={'Problem'} items={problmatique}
            defaultValue={problemList} handleAction={handleSelectProblem} />
        </div>
      </div>
    </div>
  )
}

export default ProblemContent
