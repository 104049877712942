/* eslint-disable multiline-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityControls from "components/VisibilityControls/VisibilityControls";
import {
  setCampaignExcludeList,
  setShowGraph,
  setCurrentMissionLaunch,
  setCampaignExcludeListLocation,
  setCampaignExcludeListZones,
  removeCampaignExcludeList,
  removeCampaignExcludeListZones,
  removeCampaignExcludeListLocation,
  setMissionExcludeList,
  setMissionExcludeListZones,
  setMissionExcludeListLocation,
  removeMissionExcludeList,
  removeMissionExcludeListZones,
  removeMissionExcludeListLocation,
} from "_redux/Missions/reducers";
import { parseCampaignString } from "utils/helpers";
import { useSelector, useDispatch } from "react-redux";

import "./style.scss";
// import API from 'services/axios-config'
// import { addFullLocation } from '_redux/Missions/reducers'
function groupMissionsByCampaign (missionLaunchList) {
  return missionLaunchList.reduce((acc, mission) => {
    const campaignName =
      mission.campaign?.campaign_name + "-" + mission.campaign.id ||
      "Unnamed Campaign";

    if (!acc[campaignName]) {
      acc[campaignName] = [];
    }

    acc[campaignName].push(mission);

    return acc;
  }, {});
}
const RealTimeLeftLayoutMap = (props) => {
  const { mapRef } = props;
  const { missionLaunchList } = useSelector((state) => state.missions);
  useSelector((state) => state.missions);
  const [hoveredSummary, setHoveredSummary] = useState(null);
  const handleMouseEnter = (summary) => setHoveredSummary(summary);
  const handleMouseLeave = () => setHoveredSummary(null);
  const [loading] = useState(false);

  const isEmpty = missionLaunchList?.length < 1;

  const handleClick = (currentMission) => {
    if (currentMission.locations.length) {
      dispatch(setShowGraph(true))
      dispatch(setCurrentMissionLaunch(currentMission));

      const lastPosition = currentMission.locations[currentMission.locations.length - 1]

      if (lastPosition) {
        mapRef?.current?.panTo({
          lat: lastPosition.latitude,
          lng: lastPosition.longitude,
        });
        mapRef?.current?.setZoom(18);
      }
    }
  };

  // const handleOver = (currentMission) => currentMission?.windowRef?.setOptions({ ariaLabel: 'infoWindowHover' })

  // const handleLeave = (currentMission) => currentMission?.id !== missionSelected && currentMission?.windowRef?.setOptions({ ariaLabel: 'infoWindow' })
  const dispatch = useDispatch();
  const {
    campaignExcludeList,
    campaignExcludeListZones,
    campaignExcludeListLocation,
    missionExcludeList,
    missionExcludeListLocation,
    missionExcludeListZones,
  } = useSelector((state) => state.missions);
  return (
    <div className="leftLayoutContent">
      <div style={{ color: isEmpty ? "black" : "rgb(96, 238, 210)" }}>
        {isEmpty
          ? "Aucune mission en cours actuellement..."
          : "CAMPAGNES EN COURS"}
      </div>
      {Object.entries(groupMissionsByCampaign(missionLaunchList)).map(
        ([campaignName, missions]) => (
          <Accordion
            key={campaignName}
            sx={{
              "&::before": { display: "none" },
              "box-shadow": "none",
              "&.MuiAccordion-root.MuiPaper-elevation": { margin: "0" },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-${campaignName}`}
              id={`panel1-header-${campaignName}`}
              // CSS for header background color (optional)
              style={{
                backgroundColor: "#FFFFFF",
                flexDirection: "row-reverse",
                minHeight: "20px",
                padding: "0",
              }}
              sx={{
                "& > .MuiAccordionSummary-content.Mui-expanded": {
                  // Target content section when expanded
                  margin: "0px", // Set your desired margin
                },
                "& > .MuiAccordionSummary-content": {
                  display: "flex",
                  justifyContent: "space-between",
                },
              }}
              onMouseEnter={() =>
                handleMouseEnter({
                  id: parseCampaignString(campaignName).id,
                  type: "campaign",
                })
              }
              onMouseLeave={handleMouseLeave}
            >
              <Typography
                // Adjust variant as needed
                // CSS for header text color (optional)
                style={{
                  color: "#23272A",
                  fontSize: "12px",
                  fontWeight: "700",
                  marginLeft: "10px",
                  margin: "0",
                }}
              >
                {parseCampaignString(campaignName).name}
              </Typography>
              {parseCampaignString(campaignName).id === hoveredSummary?.id &&
                hoveredSummary?.type === "campaign" && (
                  <VisibilityControls
                    id={parseCampaignString(campaignName).id}
                    dispatch={dispatch}
                    parseCampaignString={parseCampaignString}
                    excludeListZones={campaignExcludeListZones}
                    excludeListLocation={campaignExcludeListLocation}
                    excludeList={campaignExcludeList}
                    setExcludeListZones={setCampaignExcludeListZones}
                    setExcludeListLocation={setCampaignExcludeListLocation}
                    setExcludeList={setCampaignExcludeList}
                    removeExcludeListZones={removeCampaignExcludeListZones}
                    removeExcludeListLocation={
                      removeCampaignExcludeListLocation
                    }
                    removeExcludeList={removeCampaignExcludeList}
                    hoveredSummaryType={hoveredSummary?.type}
                  />
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              {missions.map((mission) => (
                <div
                  key={mission.id}
                  className="mission-name"
                  id={`current-mission-hover-${mission?.id}`}
                  onMouseEnter={() =>
                    handleMouseEnter({ id: mission.id, type: "mission" })
                  }
                  onMouseLeave={handleMouseLeave}
                  style={{
                    backgroundColor: mission.on_break ? '#F3B922B2' : 'unset'
                  }}
                >
                  {loading ? (
                    <CircularProgress color="secondary" size={24} /> // Consider size adjustment if needed
                  ) : (
                    <Typography
                      onClick={() => handleClick(mission)}
                      variant="body1"
                    >
                      {mission.user.first_name} {mission.user.last_name}
                    </Typography>
                  )}
                  {mission.id === hoveredSummary?.id &&
                    hoveredSummary?.type === "mission" && (
                      <VisibilityControls
                        id={mission.id}
                        userId={mission.user.id}
                        dispatch={dispatch}
                        parseCampaignString={parseCampaignString}
                        excludeListZones={missionExcludeListZones}
                        excludeListLocation={missionExcludeListLocation}
                        excludeList={missionExcludeList}
                        setExcludeListZones={setMissionExcludeListZones}
                        setExcludeListLocation={setMissionExcludeListLocation}
                        setExcludeList={setMissionExcludeList}
                        removeExcludeListZones={removeMissionExcludeListZones}
                        removeExcludeListLocation={
                          removeMissionExcludeListLocation
                        }
                        removeExcludeList={removeMissionExcludeList}
                        hoveredSummaryType={hoveredSummary?.type}
                      />
                  )}
                </div>
              ))}
            </AccordionDetails>
          </Accordion>
        )
      )}
    </div>
  );
};

export default RealTimeLeftLayoutMap;
