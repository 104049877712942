/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'
import { getItemType } from 'views/Cartes/CampaignViewMap/MapLayout/LeftLayout/DraggableList/function'

const initialState = {
  fetching: false,
  slotSelected: null,
  initialSelected: false,
  itemsDragSelected: [],
  isMoving: false,
  stackTrace: []
}

export const mapAction = createSlice({
  name: 'map',
  initialState: {
    fetching: false,
    slotSelected: null,
    initialSelected: false,
    itemsDragSelected: [],
    isMoving: false,
    stackTrace: []
  },
  reducers: {
    selectSlot: (state, action) => ({ ...state, initialSelected: false, slotSelected: action.payload }),
    selectInitial: (state, action) => ({ ...state, initialSelected: action.payload, slotSelected: false }),
    addItemToDrag: (state, action) => {
      if (action.payload.type === "default") {
        return ({ ...state, itemsDragSelected: [action.payload.value] })
      }
      if (action.payload.type === "cmd") {
        return ({
          ...state,
          itemsDragSelected: state.itemsDragSelected.filter(current => current.type === "zone" && current.id === action.payload.value?.id).length > 0
            ? state.itemsDragSelected.filter(current => !(current.type === "zone" && current.id === action.payload.value?.id))
            : [...state.itemsDragSelected, action.payload.value]
        })
      }
    },
    resetState: () => (initialState),
    modifyInStack: (state, action) => ({
      ...state,
      stackTrace: state.stackTrace.map((stack) => {
        if ((getItemType(stack.item) === getItemType(action.payload)) && (stack.item.id === action.payload.id)) {
          return ({ ...stack, item: { ...stack.item, slot_id: action.payload.slot_id } })
        } else {
          return stack
        }
      })
    }),
    resetItemsDrag: (state) => ({ ...state, itemsDragSelected: [] }),
    newItemDrag: (state, action) => ({ ...state, itemsDragSelected: action.payload }),
    dragStatus: (state, action) => ({ ...state, isMoving: action.payload }),
    addActionToStack: (state, action) => ({ ...state, stackTrace: [...state.stackTrace, action.payload] }),
    removeLastItemStack: (state) => ({ ...state, stackTrace: state.stackTrace.slice(0, -1) })
  }
})

export const {
  selectSlot,
  selectInitial,
  addItemToDrag,
  dragStatus,
  newItemDrag,
  addActionToStack,
  removeLastItemStack,
  resetItemsDrag,
  modifyInStack,
  resetState
} = mapAction.actions

export default mapAction.reducer
