export const adressFormat = (infos, location, formattedAdress) => {
  const findItem = (key) => {
    const value = infos?.find(item => item.types.includes(key))
    return value?.long_name
  }

  const newObject = {
    housenumber: findItem('street_number'),
    street: findItem('route'),
    postcode: findItem('postal_code'),
    city: findItem('locality'),
    longitude: location.lng(),
    latitude: location.lat(),
    label: formattedAdress
  }
  return newObject
}

export const handleGetDetails = (newValue, onSelectAddress) => {
  new window.google.maps.places.PlacesService(
    document.createElement('div')
  ).getDetails(
    {
      placeId: newValue?.place_id
    },
    (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        onSelectAddress(adressFormat(place?.address_components, place?.geometry?.location, place?.formatted_address))
      }
    }
  )
}
