/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSlot, setCampaignsList, deleteTeam, addSlot } from '_redux/Campaigns/reducers'
import { styled, alpha, Switch, Popover, Badge } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'
import './style.scss'
import moment from 'moment'
import ComponentSlotDetails from 'views/Slot/SlotCard/ComponentSlotCardView/ComponentSlotDetails'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import Compact from '@uiw/react-color-compact'
import { ReactComponent as CloseSvg } from 'assets/close.svg'
import { ReactComponent as TeamSvg } from 'assets/team.svg'
import DeleteTeamModal from './DeleteTeamModal'
import { ReactComponent as DuplicateSvg } from "assets/duplicate.svg";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#60EED2',
    '&:hover': {
      backgroundColor: alpha('#60EED2', theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#60EED2'
  }
}))

const SlotView = (props) => {
  const { slot, campaignData, prestation, updateCampaignData } = props
  const { state, color } = styleMission()
  const [switchDisabled, setSwitchDisabled] = useState(false)
  const dispatch = useDispatch()
  const { addToStack } = useRightModal()
  const campaignList = useSelector(state => state.campaignsList.list)
  const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false)
  const [currentSelectedTeamToDelete, setCurrentSelectedTeamToDelete] = useState(null)

  function styleMission () {
    let state
    let color
    if (!slot?.active && (slot?.missions?.length === 0) && slot?.missions?.find(mission => ['Refusé'].includes(mission.status))) {
      color = '#888888'
      state = 'Inactif'
    } else if (slot?.missions?.find(mission => ['Terminée'].includes(mission.status))) {
      color = '#07C025'
      state = 'Terminée'
    } else if (slot?.missions?.find(mission => ['En cours'].includes(mission.status))) {
      color = '#60EEDD'
      state = 'En cours'
    } else if (slot?.missions?.find(mission => ['Validé', 'En attente de lancement', 'En attente de confirmation'].includes(mission.status))) {
      color = '#FF0000'
      state = 'À venir'
    } else if (slot?.missions?.find(mission => ['En attente de validation'].includes(mission.status))) {
      color = '#FF0000'
      state = 'En attente'
    } else if (slot?.missions?.length === 0 || slot?.missions?.find(mission => mission.status !== 'Terminée')) {
      color = '#F3B922'
      state = 'Recherche sm'
    } else {
      color = '#FF0000'
      state = 'Non défini'
    }
    return { state, color }
  }

  const smNamesDisplay = React.useMemo(() => {
    if (slot.missions?.length > 0 && slot.missions.some((mission) => ['validé', 'en attente de lancement', 'en attente de confirmation', 'en cours', 'terminée'].includes(mission.status.toLowerCase()))) {
      let smNamesList = slot.missions.filter((mission) => ['validé', 'en attente de lancement', 'en attente de confirmation', 'en cours', 'terminée'].includes(mission.status.toLowerCase())) ?? []
      smNamesList = smNamesList?.map(_mission => _mission?.user_name)
      if (smNamesList.length === 1) return `${smNamesList[0]}`
      else if (smNamesList.length > 1) return `${smNamesList[0]} +${smNamesList.length - 1}`
    }
    return '-'
  }, [slot])

  function handleSlotStatus () {
    setSwitchDisabled(true)
    API.patch(`slots/${slot.id}`, { active: !slot.active })
      .then(res => {
        dispatch(updateSlot({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, slotId: slot.id, updatedSlot: { ...slot, active: !slot.active } }))
      })
      .catch(err => {
        enqueueSnackbar(err, { variant: 'error', preventDuplicate: true })
      })
      .finally(() => {
        setSwitchDisabled(false)
      })
  }

  const [teamsPopoverAnchorEl, setTeamsPopoverAnchorEl] = React.useState(null)
  const [showColorPicker, setShowColorPicker] = React.useState(false)
  const [selectedColor, setSelectedColor] = React.useState(null)
  const handleTeamsPopoverClick = (event) => {
    setTeamsPopoverAnchorEl(event.currentTarget)
  }

  const handleTeamsPopoverClose = () => {
    setTeamsPopoverAnchorEl(null)
  }

  const canAddTeam = React.useMemo(() => {
    if (slot?.teams?.length === 0) return true
    if (slot?.teams?.length === 1 && slot?.team_responsable) return true
    return false
  }, [slot, campaignData])

  const slotTeamsColors = React.useMemo(() => {
    let colors = {
      team1: '#dbdbdb',
      team2: '#dbdbdb'
    }
    if (slot?.teams?.length === 1) colors = { team1: slot.teams[0].color, team2: slot.teams[0].color }
    if (slot?.teams?.length > 1) colors = { team1: slot.teams[0].color, team2: slot.teams[1].color }
    return colors
  }, [slot])

  const handleUpdateTeamResponsable = (value) => {
    API.patch(`slots/${slot.id}`, { team_responsable: value })
      .then((response) => {
        if (value === false) {
          API.get(`slots/${slot.id}/remove_all_teams`)
            .then(() => {
              dispatch(updateSlot({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, slotId: slot.id, updatedSlot: { ...slot, teams: [], team_responsable: false } }))
            })
            .catch(err => {
              console.log('err:', err)
              enqueueSnackbar(err, { variant: 'error', preventDuplicate: true })
            })
        } else {
          dispatch(updateSlot({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, slotId: slot.id, updatedSlot: { ...slot, team_responsable: response.data.team_responsable } }))
        }
      })
      .catch(err => {
        enqueueSnackbar(err, { variant: 'error', preventDuplicate: true })
      })
      .finally(() => {
        setSwitchDisabled(false)
      })
  }

  const handleCreateTeam = (color) => {
    API.post('teams', { color, campaign_id: campaignData?.campaign.id, slot_id: slot.id })
      .then((res) => {
        const newCampaignData = {
          ...campaignData,
          campaign: {
            ...campaignData?.campaign,
            teams: [...campaignData?.campaign.teams, res.data.team]
          }
        }
        updateCampaignData(newCampaignData)
        setSelectedColor(null)
        setShowColorPicker(false)
        dispatch(setCampaignsList(campaignList.map((item) => item?.campaign.id === newCampaignData?.campaign.id ? { ...item, campaign: newCampaignData?.campaign } : item)))
      })
      .catch(err => {
        console.log('err:', err)
        enqueueSnackbar(err, { variant: 'error', preventDuplicate: true })
      })
  }

  const handleDeleteTeam = React.useCallback(() => {
    API.delete(`teams/${currentSelectedTeamToDelete.id}`)
      .then((res) => {
        dispatch(deleteTeam({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, teamId: res.data.team.id }))
      })
      .catch(err => {
        console.log('err:', err)
        enqueueSnackbar(err, { variant: 'error', preventDuplicate: true })
      })
      .finally(() => setIsDeleteTeamModalOpen(false))
  }, [currentSelectedTeamToDelete])

  const handleAssignSlotTeam = (teamId) => {
    API.post(`teams/${teamId}/assign_team_to_slot`, { slot_id: slot.id, remove: slot.teams?.some((_team) => _team.id === teamId) })
      .then((res) => {
        if (slot.teams?.some((_team) => _team.id === teamId)) {
          dispatch(updateSlot({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, slotId: slot.id, updatedSlot: { ...slot, teams: slot?.teams.filter((_team) => _team.id !== teamId) } }))
        } else {
          dispatch(updateSlot({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, slotId: slot.id, updatedSlot: { ...slot, teams: [res.data.team, ...(slot?.teams ?? [])] } }))
        }
      })
      .catch(err => {
        console.log('err:', err)
        enqueueSnackbar(err, { variant: 'error', preventDuplicate: true })
      })
  }

  const handleDeAssignSlotTeam = (teamId) => {
    if (slot.teams?.some((_team) => _team.id === teamId)) {
      API.post(`teams/${teamId}/assign_team_to_slot`, { slot_id: slot.id, remove: slot.teams?.some((_team) => _team.id === teamId) })
        .then((res) => {
          if (slot.teams?.some((_team) => _team.id === teamId)) {
            dispatch(updateSlot({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, slotId: slot.id, updatedSlot: { ...slot, teams: slot?.teams.filter((_team) => _team.id !== teamId) } }))
          } else {
            dispatch(updateSlot({ campaignId: campaignData?.campaign.id, prestationId: prestation.id, slotId: slot.id, updatedSlot: { ...slot, teams: [res.data.team, ...(slot?.teams ?? [])] } }))
          }
        })
        .catch(err => {
          console.log('err:', err)
          enqueueSnackbar(err, { variant: 'error', preventDuplicate: true })
        })
    }
  }
  const handleDuplicateSlot = () => {
    const duplicatedSlotData = {
      ...slot,
      id: undefined,
      active: false,
      missions: [],
      address: { ...slot.address },
      address_id: slot.address_id,
    };

    API.post("slots", duplicatedSlotData)
      .then((res) => {
        dispatch(
          addSlot({
            campaignId: slot?.campaign_id,
            prestationId: slot.prestation_id,
            addSlot: res.data,
            address: { ...slot.address }
          })
        );
      })
      .catch((err) => {
        enqueueSnackbar('Erreur lors de la duplication du slot', { variant: 'error' });
        console.error('Erreur:', err);
      });
  };
  const teamsPopoverIsOpen = Boolean(teamsPopoverAnchorEl)
  return (
    <div onClick={() => addToStack({ component: <ComponentSlotDetails {...props} /> })} className="slot-card" style={{ opacity: slot.active ? 1 : 0.3 }}>
      <DeleteTeamModal open={isDeleteTeamModalOpen} handleConfirm={handleDeleteTeam} handleCancel={() => { setIsDeleteTeamModalOpen(false); setCurrentSelectedTeamToDelete(null) }} />
      <div
        className="slot-card-teams"

        onClick={(e) => {
          if (slot.active) {
            e.stopPropagation()
            handleTeamsPopoverClick(e)
          }
        }}>
        {slot?.team_responsable && <div style={{ position: 'absolute', zIndex: 999 }}><TeamSvg width={30} height={30} /></div>}
        <div style={{ position: 'relative', width: '70px', height: '50px', overflow: 'hidden' }}>
          <div className="color-1-container" style={{ backgroundColor: slotTeamsColors.team1 }} />
          <div className="color-2-container" style={{ backgroundColor: slotTeamsColors.team2 }} />
        </div>
        {slot?.need_attention && (
          <div style={{ position: 'absolute', right: '-20px' }}>
            <Badge
              badgeContent="!"
              color="error"
              overlap="circular"
            ></Badge>
          </div>
        )}
        <Popover
          id={'slot-teams-popover'}
          open={teamsPopoverIsOpen}
          anchorEl={teamsPopoverAnchorEl}
          onClose={handleTeamsPopoverClose}
          onClick={(e) => e.stopPropagation()}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
        >
          <div className="teams-popover-container">
            <div className="team-leader-container">
              <span>Chef d&apos;equipe : </span>
              <GreenSwitch checked={slot?.team_responsable} onChange={(e, checked) => handleUpdateTeamResponsable(checked)} />
            </div>
            <div className="new-team-container">
              <div className="assigned-teams-container">
                <span>Attribuer</span>
                <div className="assigned-teams-list-container">
                  {
                    campaignData?.campaign?.teams?.map((team) =>
                      <div
                        key={team.id}
                        className="assigned-team-card"
                        onClick={(e) => {
                          e.stopPropagation()
                          if (canAddTeam) handleAssignSlotTeam(team.id)
                          else handleDeAssignSlotTeam(team.id)
                        }}
                        style={{ backgroundColor: team.color ?? '#000', border: slot?.teams?.some((t) => t.id === team.id) ? '2px solid blue' : 'none' }}>
                        <div className="delete-assigned-team-card" >
                          <CloseSvg width={15} height={15} fill="#EAEAEA" onClick={(e) => { e.stopPropagation(); setCurrentSelectedTeamToDelete(team); setIsDeleteTeamModalOpen(true) }} />
                        </div>
                      </div>)
                  }
                </div>
              </div>
              <span
                className="new-team"
                style={{ cursor:'pointer' }}
                onClick={() => { setShowColorPicker(true) }}>
                + Nouvelle equipe</span>
              {showColorPicker && <Compact
                color={selectedColor}
                style={{
                  boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px'
                }}
                onChange={(color) => {
                  setSelectedColor(color.hex)
                  handleCreateTeam(color.hex)
                }}
              />}
            </div>
          </div>
        </Popover>
      </div>
      <div style={{ maxWidth: 100 }}>{slot.title || ''}</div>
      {slot.date_start_mission
        ? (
          <>
            <div
              className='slot-card-date'
              title={`${moment(slot.date_start_mission).format('ddd Do MMM').toUpperCase()}`}
            >
              {moment(slot.date_start_mission).format('ddd Do MMM').toUpperCase()}
            </div>
            <div
              className='slot-card-time'
              title={`${moment(slot.date_start_mission).format('LT').replace(':', 'H')}/${moment(slot.date_end_mission).format('LT').replace(':', 'H')}`}
            >
              - {moment(slot.date_start_mission).format('LT').replace(':', 'H')}/
              {moment(slot.date_end_mission).format('LT').replace(':', 'H')}
            </div>
          </>
          )
        : (
          <>
            <div style={{ color: 'red' }}>DATE INVALIDE</div>
            <div></div>
          </>)
      }
      <div
        className='slot-card-sm-names'
        title={smNamesDisplay}
      >
        {smNamesDisplay}
      </div>
      <div
        className='slot-card-city'
        title={`${slot.address ? slot.address.city : ''}`}
      >
        {slot.address ? slot.address.city : ''}
      </div>
      <div style={{ color, background: color + '50' }} className='slot-card-state'>
        <span style={{ background: color }} className='slot-card-state-dot' ></span>
        {state}
      </div>
      <div onClick={e => e.stopPropagation()}>
        <GreenSwitch checked={slot.active} disabled={switchDisabled} onChange={handleSlotStatus} />
      </div>
      <div onClick={e => e.stopPropagation()} style={{ display: 'flex' }}>
        <div onClick={handleDuplicateSlot}>
          <DuplicateSvg />
        </div>
      </div>
    </div>
  )
}

export default SlotView
