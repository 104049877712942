import { Circle, Marker } from "@react-google-maps/api"
import { memo, useMemo, useRef } from "react"
import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext"

export const DrawMarker = memo(({ params, setIsHover, handleDragMarker, isHover }) => {
  const ref = useRef()
  const { infosModal } = useCampaignMap()

  const showWhiteCircle = useMemo(() => {
    if (infosModal?.address && infosModal?.id === params?.id) return true
    if (isHover) return true
    return false
  }, [infosModal, isHover])

  return (
    <>
      <Marker
        ref={ref}
        {...params}
        onMouseOver={() => [null, 0, undefined].includes(params?.radius) && setIsHover(true)}
        onMouseOut={() => [null, 0, undefined].includes(params?.radius) && setIsHover(false)}
        draggable={true}
        onDragEnd={handleDragMarker}
        zIndex={101}
      >
        <Circle
          {...params}
          options={{
            ...params.options,
            strokeColor: showWhiteCircle ? "white" : params.options.strokeColor
          }}
          zIndex={100}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
        />
      </Marker>
      {showWhiteCircle && <Circle
        {...params}
        radius={50}
        options={{
          fillColor: "white",
          strokeColor: "white",
          fillOpacity: 1,
        }}
        zIndex={999}
      />}
    </>)
})

export const AnomalieMarker = memo(({ params, clusterer }) => {
  const ref = useRef()

  return (
    <Marker
      key={params.id}
      zIndex={9999}
      ref={ref}
      {...params}
      clusterer={clusterer}
    />)
})
