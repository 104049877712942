import React from 'react'
export const problimatisationMacDo = [
  {
    title: 'PROBLÉMATIQUE',
    content: [
      'ACQUISITION CLIENT',
      'RESTAURATION'
    ]
  },
  {
    title: 'SOLUTION PROPOSÉE',
    content: [
      <>FLYING BOX :<br />
        CIBLAGE PRÉCIS ET DE GRAND ENVERGURE<br />
        ÉQUIPE DE STREET MARKETEUR PROFESSIONNELS ET EFFICACES
      </>

    ]
  }
]
