import { createSlice } from '@reduxjs/toolkit'

const fetchingData = { fetching: true }

export const companyListAction = createSlice({
  name: 'companyList',
  initialState: {
    fetching: false,
    current: null,
    companies: []
  },
  reducers: {
    getCompanyList: (state) => ({ ...state, ...fetchingData }),
    allCompanyAreGet: (state, action) => ({ ...state, companies: action?.payload, fetching: false }),
    getCompanyListError: (state) => ({ ...state, fetching: false }),
    removeCompany: (state, action) => ({ ...state, companies: state?.companies?.filter(comp => comp?.id !== action.payload), fetching: false })
  }
})

export const {
  getCompanyList,
  allCompanyAreGet,
  getCompanyListError,
  removeCompany
} = companyListAction.actions

export default companyListAction.reducer

export const LAUNCH_COMPANIES_ACTION = 'LAUNCH_COMPANIES_ACTION'
export const ERROR_COMPANIES_ACTION = 'ERROR_COMPANIES_ACTION'

export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES'
export const GET_COMPANY = 'GET_COMPANY'
export const RESET_CURRENT_COMPANY = 'RESET_CURRENT_COMPANY'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY'
