/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import InfosSlot from './InfosSlot'
import { ReactComponent as PoubelleLogo } from 'assets/poubelle.svg'
import './style.scss'
import API from 'services/axios-config'
import AlertDialog from 'components/DialogBox'
import { enqueueSnackbar } from 'notistack'
import { removeSlot, updateMission, afterMissionValidate } from '_redux/Campaigns/reducers'
import MissionView from './MissionView'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'

const ComponentSlotDetails = (props) => {
  const { slot, campaignData, prestation } = props
  const rawList = useSelector(state => state?.campaignsList?.list)
  const [currentSlot, setCurrentSlot] = useState([])
  const [updateModal, setUpdateModal] = useState(false)
  const { removedLastStack } = useRightModal()
  const dispatch = useDispatch()

  useEffect(() => {
    setCurrentSlot(rawList?.filter(campaign => campaign.campaign.id === campaignData.campaign.id)[0]?.prestations?.filter(prestation_ => prestation_.id === prestation.id)[0]?.slots.filter(slot_ => slot_.id === slot.id)[0])
  }, [rawList])

  const handleDeleteSlot = () => {
    API.delete(`slots/${slot.id}`)
      .then(() => {
        removedLastStack()
        dispatch(removeSlot({ campaignId: campaignData.campaign.id, prestationId: prestation.id, slotId: slot.id }))
        setUpdateModal(false)
      })
      .catch(err => enqueueSnackbar(err))
  }

  async function handleModifyMission (value) {
    return API.patch(`/missions/${value.id}`, value).then(res => {
      dispatch(updateMission({ campaignId: campaignData.campaign.id, prestationId: prestation.id, slotId: slot.id, missionId: value?.id, updatedSlot: { ...res?.data } }))
      if (res?.data?.status === 'Validé') {
        dispatch(afterMissionValidate({ campaignId: campaignData.campaign.id, prestationId: prestation.id, slotId: slot.id, missionId: value?.id }))
      }
    })
      .catch(err => {
        enqueueSnackbar('Patch mission : ' + err, { variant: 'error' })
      })
  }

  return (
    <div className='component-slot-details'>
      <InfosSlot {...props} slot={currentSlot} />
      {currentSlot && <MissionView {...{ currentSlot, handleModifyMission }}/>}
      <div className='delete-slot'>
        <div className='delete-slot-button' onClick={() => setUpdateModal(true)}>
          <PoubelleLogo />
          SUPPRIMER LE CRÉNEAU
        </div>
      </div>
      <AlertDialog open={updateModal} dialogType="removeSlot" setOpen={setUpdateModal} action1={() => handleDeleteSlot()} />
    </div>
  )
}

export default ComponentSlotDetails
