/* eslint-disable react/prop-types */
import { Box, Collapse, TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import avatar from 'assets/avatar.png'

import './style.scss'
import filtre from 'assets/Filtre.png'

function handleImageError (event) {
  event.target.src = avatar
}

function formattedContent (column, row, setOpen, open) {
  if (!column?.type && !column?.component) {
    return column?.value?.length > column?.maxLength
      ? column?.value?.substring(0, column?.maxLength - 3) + '...'
      : column?.value
  } else if (column?.component) {
    if (column?.id === 'collapse') { return (<div className={`rotating-icon-table ${open && 'is-open'}`} onClick={() => setOpen(prev => !prev)}>{React.cloneElement(column?.component, { row })}</div>) }
    return React.cloneElement(column?.component, { row })
  } else if ((column?.type === 'picture' || column?.type === 'prestation') && column?.isSVG && column?.value) {
    return React.cloneElement(column?.value, { row })
  } else if (column?.type === 'picture' && column?.value) { return <div style={{ width: 30, height: 30 }}><img className="imaged-row-table" src={column?.value} onError={handleImageError}/></div> } else return <div style={{ width: 30, height: 30 }}><img className="imaged-row-table" src={filtre} onError={handleImageError}/></div>
}

const Row = ({ row, closeAll, handleActions }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    !closeAll && open && setOpen(false)
  }, [closeAll])

  function hasCollapse () {
    return row.find((_item) => _item?.id === 'collapse')
  }

  const tableRowBackgroundColor = React.useMemo(() => {
    const isAlertColumn = row.find((column) => column.id === 'is_alert')
    const statusColumn = row.find((column) => column.id === 'status')
    if (isAlertColumn !== undefined && statusColumn !== undefined && statusColumn.value === 'En cours' && ["true", "false"].includes(isAlertColumn.value)) return JSON.parse(isAlertColumn.value)
    return false;
  }, [row]);

  return (
    <React.Fragment>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: tableRowBackgroundColor ? '#FF00001A' : 'unset' }} onClick={() => handleActions({ type: 'row-action', row })}>
        {row.map((column, index) => {
          return column?.isVisible
            ? (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ ...column, borderBottom: 0, whiteSpace: column?.wrap }}
              className="row-cell"
              title={
                column?.value?.length > column?.maxLength ? column?.value : ''
              }
              width={column.width || 'auto'}
            >
              {formattedContent(column, row, setOpen, open)}
            </TableCell>
              )
            : null
        })}
      </TableRow>
      {hasCollapse() && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>{hasCollapse()?.collapseComponent}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

export default Row
