/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { UseCaseProblimatisation as Problimatisation } from '../../../home/components/Explain/explain'
import { ModalComponent } from '../modalComponent/modalcomponent'
import { convertExplainToArray } from '../../../home/functions/functions'
import ProblemContent from './problemContent'
import SolutionContent from './Solutioncontent/solutionContent'
import './problimatisation.scss'

export const UseCaseProblimatisation = ({ data, openModal, setOpenModal }) => {
  const [explain, setExplain] = useState(null)
  const [isSaved, setIsSaved] = useState(false)
  return (
    <div className='choosecase_container' style={{ backgroundColor: '#23272A' }} >
      <Problimatisation bg_color={'#23272A'} alternate_color={false}
        style_bloc1={{ flex: 2, paddingRight: 0 }}
        list_items={isSaved ? convertExplainToArray({ ...data?.datas, ...explain }) : convertExplainToArray(data?.datas)}
        color={'white'} style_bloc2={{ flex: 2 }} style={{ padding: '0 20px 0 40px' }}
        btnStyle={{ fontSize: 25, padding: '10px 25px' }} />
      {<ModalComponent open={openModal} sectionName="explain"
        id={data?.id} disable={isSaved || explain === null}
        data={{ ...data?.datas, ...explain }} setIsSaved={setIsSaved} setOpenModal={setOpenModal}
        component={<div className='choosecase_container' style={{ backgroundColor: '#23272a' }}>
          <div className='content_case'>
            <ProblemContent setExplain={setExplain}
              problems={explain?.problem || data?.datas?.problem || ['']}
            />
            <SolutionContent setExplain={setExplain}
              solutions={explain?.solutions || data?.datas?.solutions || ['']}
            />
          </div>
        </div>}
      />}
    </div>
  )
}
