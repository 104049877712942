import React from 'react';
import './style.scss';

const DevisEstimationSection = ({ estimation }) => {
    return (
        <div className="devis-modal-estimation-container">
            <div className="estimation-title">ESTIMATION TARIFAIRE : </div>
            <div className="environ">Environ<span className="estimation-total">{estimation}</span></div>
            <p>* Ce tarif est une estimation et peut être soumis à des modifications.</p>
        </div>
    )
}

export default DevisEstimationSection;
