/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { GrLinkTop } from 'react-icons/gr'
import './scrollTop.scss'

const ScrollToTopButton = ({ targetDivRef }) => {
  const [showButton, setShowButton] = useState(false)
  const [scrollHeight, setScrollHeight] = useState(0)

  const handleButtonClick = () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollHeight = targetDivRef?.current?.scrollTop || 0
      setScrollHeight(currentScrollHeight)
      if (currentScrollHeight > 300) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }

    if (targetDivRef.current) {
      setScrollHeight(targetDivRef?.current?.scrollTop || 0)
      if (targetDivRef?.current?.scrollTop > 300) {
        setShowButton(true)
      }
      targetDivRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (targetDivRef.current) {
        targetDivRef?.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrollHeight, targetDivRef])

  return (
    <div className="scrolltop" style={{ display: showButton ? 'block' : 'none' }} >
      <div className='btn'>
      <GrLinkTop size={30} color='white' onClick={handleButtonClick} />
      </div>
    </div>
  )
}

export default ScrollToTopButton
