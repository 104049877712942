/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { InfoWindow } from '@react-google-maps/api'
import { CompactPicker } from 'react-color'
import API from 'services/axios-config'
import React, { useState } from 'react'
import { convertHexToRGBA } from 'views/Admin/Views/Cartes/functions/functionsMap'

const ContentInfoWindow = ({ currentRef, setModalOpen }) => {
  function sendModifyToBackend (infoChange) {
    API.patch(`/zones/${currentRef?.zone_id}`, infoChange)
  }
  function handleModifLayer (value) {
    currentRef.setOptions({ ...value })
    const get_square = document.getElementById(`colored-square-${currentRef?.zone_id}`)
    if (get_square) {
      get_square.style.fill = convertHexToRGBA(currentRef?.fillColor || '#111111')
      get_square.style.stroke = convertHexToRGBA(currentRef?.fillColor || '#111111')
    }
  }

  const InputTest = ({ defaultValue, type, max, min, onChange, onMouseUp }) => {
    const [value, setValue] = useState(defaultValue)

    function changeValue (value) {
      setValue(value)
      onChange(value)
    }

    return (
      <input
        type={type}
        max={max}
        min={min}
        value={value}
        onChange={(e) => changeValue(e.target.value)}
        onMouseUp={onMouseUp}
      />
    )
  }
  return (
    <div className="infoWindowZoneContainer">
      <div style={{ textAlign: 'end' }} onClick={() => setModalOpen(null)}>
        X Fermer
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
        <CompactPicker
          onChangeComplete={(e) => {
            handleModifLayer({ fillColor: e?.hex, strokeColor: e?.hex })
            sendModifyToBackend({ fillColor: e?.hex, strokeColor: e?.hex })
          }}
        />
        <p>Polygon transparency</p>
        <InputTest
          type="range"
          max={100}
          min={2}
          defaultValue={currentRef?.fillOpacity * 100 || 50}
          onChange={(e) => handleModifLayer({ fillOpacity: e / 100 })}
          onMouseUp={(e) =>
            sendModifyToBackend({ fillOpacity: e?.target?.value / 100 })
          }
        />
        <p>Border width</p>
        <InputTest
          type="range"
          max={30}
          min={1}
          defaultValue={currentRef?.strokeWeight || 1}
          onChange={(e) => handleModifLayer({ strokeWeight: e })}
          onMouseUp={(e) =>
            sendModifyToBackend({ strokeWeight: e?.target?.value })
          }
        />
      </div>
    </div>
  )
}

const InfosWindowView = ({ modalPosition, currentRef, setModalOpen }) => {
  return (
    <InfoWindow
      position={modalPosition}
      options={{ ariaLabel: 'infoWindowZone' }}
    >
      <ContentInfoWindow currentRef={currentRef} setModalOpen={setModalOpen} />
    </InfoWindow>
  )
}

export default InfosWindowView
