/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Button, Popover } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import './style.scss'

const TopContent = ({ slot, handleChangeSlot }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover-infos-mission' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const slotState = slot?.active !== undefined ? slot?.active : true

  return (
    <div className='slotmodif-details_title'>
      <Button aria-describedby={id} variant="contained" onClick={handleClick} style={{ color: slotState ? '#07C025' : '#FF0000', background: slotState ? '#07C02550' : '#FF000050' }} className='slotmodif-state-container'>
        <div className='container-flex'>
          <div>
            <span style={{ background: slotState ? '#07C025' : '#FF0000' }} className='slotmodif-state-dot' ></span>
            <div style={{ display: 'inline' }}>{slotState ? 'ACTIF' : 'INACTIF'}</div>
          </div>
          <ArrowDropDownIcon />
        </div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className='popover-slotmodif-container' >
          {
            ['ACTIF', 'INACTIF'].map((state, index) => {
              return <div
                key={`${index}-${state}`}
                className='popover-item'
                style={{ color: (state === 'ACTIF' && slotState === true ? '#07C025' : null) || (state === 'INACTIF' && slotState === false ? '#FF0000' : null), width: '150px' }}
                onClick={() => { handleChangeSlot({ active: state === 'ACTIF' }); handleClose() }}
              >
                {state}
              </div>
            })
          }
        </div>
      </Popover>
    </div>
  )
}

export default TopContent
