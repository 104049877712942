import React, { useRef } from 'react'
import {
  Modal
} from "@mui/material";
import BtnComponent from 'components/GlobalInputs/Btn';
import { InputText } from 'components/GlobalInputs/InputText';
import './styles.scss'

const TimeModal = ({ modalOpen, setModalOpen, setNewData, setMissionSelected }) => {
  const inputRefHour = useRef(null)
  const inputRefMinute = useRef(null)
  return (
    <Modal open={!!modalOpen} onClose={() => setModalOpen(null)}>
        <div className="time-modal-container">
          <div className="categorie-title" style={{ alignSelf: "center" }}>MODIFICATION DU TEMPS SM</div>
          <div>Tu es sur le point de modifier le nombre d’heures attribuées au SM pour sa mission, cela recalculera le montant qu’il gagnera.</div>
          <div style={{ marginTop: 30, marginBottom: 10 }}>Combien d’heures veux-tu que le SM soit payé ?</div>
          <div style={{ display: "flex", gap: 20 }}>
            <InputText label="NOMBRE D’HEURES" variant="rounded" type={"number"} inputRef={inputRefHour}/>
            <InputText label="NOMBRE DE MINUTES" variant="rounded" type={"number"} inputRef={inputRefMinute}/>
          </div>
          <div className="btn-container">
          <BtnComponent text={"VALIDER"} color={"#60EED2"} variant="rounded" onClick={() => {
            const timeHour = parseInt(inputRefHour.current.value || 0) * 60
            const timeMinute = parseInt(inputRefMinute.current.value) || 0
            setNewData(prev => prev ? { ...prev, real_time: timeHour + timeMinute } : { real_time: timeHour + timeMinute })
            setMissionSelected(prev => ({ ...prev, real_time: timeHour + timeMinute }))
            setModalOpen(null)
          }}/>
          <BtnComponent text={"ANNULER"} color={"red"} variant="rounded" onClick={() => setModalOpen(null)}/>
          </div>
        </div>
      </Modal>
  )
}

export default TimeModal;
