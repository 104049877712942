import React from 'react'
import { useSearchParams } from 'react-router-dom'
import UseCaseView from './useCaseView'

const HomeUseCaseContainer = () => {
  const [queryParams] = useSearchParams()
  const id = JSON.parse(queryParams?.get('id'))

  return (<UseCaseView id={id} />)
}

export default HomeUseCaseContainer
