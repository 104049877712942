/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { SelectSimpleComponent } from 'views/Admin/Shared/Forms/SelectSimple'
import {
  FlyingBike,
  FlyingBox,
  FlyingHand,
  FlyingStore,
  FlyingWall
} from './services'
import { ReactComponent as PoubelleLogo } from 'assets/poubelle.svg'
import API from 'services/axios-config'
import AlertDialog from 'components/DialogBox'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import './style.scss'
import { enqueueSnackbar } from 'notistack'
import { InputComponent } from 'views/Admin/Shared/Forms/Input'
import { useDispatch } from 'react-redux'
import {
  deletePrestation,
  modifyPrestationInfo
} from '_redux/Campaigns/reducers'
import { useSearchParams } from 'react-router-dom'

const BodyContent = (props) => {
  const { prestation } = props
  switch (prestation?.presta_type) {
    case 'Flying Box':
      return <FlyingBox {...props} />
    case 'Flying Hand':
      return <FlyingHand {...props} />
    case 'Flying Store':
      return <FlyingStore {...props} />
    case 'Flying Wall':
      return <FlyingWall {...props} />
    case 'Flying Bike':
      return <FlyingBike {...props} />
    default:
      return <div></div>
  }
}

const PrestationModification = (props) => {
  const { prestation } = props
  const [currentPresta, setCurrentPresta] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertRemovedOpen, setAlertRemovedOpen] = useState(false)
  const { replaceLastStackAction, removedLastStack } = useRightModal()
  const [isFetching, setIsFetching] = useState(false)
  // const [documentsOpen, setDocumentsOpen] = useState(false)
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  function handleSendModifications () {
    setIsFetching(true)
    API.patch(`prestations/${prestation?.id}`, currentPresta)
      .then((_) => {
        setCurrentPresta(null)
        removedLastStack()
        dispatch(
          modifyPrestationInfo({
            campaignId: parseInt(searchParams?.get('campaign_id')),
            prestationId: prestation?.id,
            prestationInfo: currentPresta
          })
        )
      })
      .catch((_) =>
        enqueueSnackbar(
          'Une erreur est survenu lors de la création/update de la préstation',
          { variant: 'error' }
        )
      )
      .finally((_) => setIsFetching(false))
  }

  function prestationHasChange () {
    setAlertOpen(true)
  }

  function handleChangePresta (value) {
    setCurrentPresta((prev) => ({ ...prev, ...value }))
    replaceLastStackAction(prestationHasChange)
  }

  function removedPrestaEmpty (presta_id) {
    return API.delete(`prestations/${presta_id}`)
      .then(() => {
        dispatch(
          deletePrestation({
            campaignId: parseInt(searchParams?.get('campaign_id')),
            prestationId: prestation?.id
          })
        )
      })
      .catch((err) =>
        enqueueSnackbar('La suppréssion de la prestation à échoué : ' + err, {
          variant: 'error'
        })
      )
  }

  function handleAbortPrestation () {
    if (!prestation?.presta_type) removedPrestaEmpty(prestation?.id)
    removedLastStack()
    setAlertRemovedOpen(false)
  }

  function removedPrestation () {
    removedPrestaEmpty(prestation?.id)
      .then(() => {
        removedLastStack()
        setAlertRemovedOpen(false)
      })
  }

  return (
    <div className="prestation-modal-content">
      {/* <Modal open={documentsOpen} onClose={() => setDocumentsOpen(false)}>
      <PrestationDocuments
        documents={prestation?.documents}
        {...{ prestation }}
      />
    </Modal> */}
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        dialogType="abortPrestationModify"
        action1={handleAbortPrestation}
      />
      <AlertDialog
        open={alertRemovedOpen}
        setOpen={setAlertRemovedOpen}
        dialogType="removePrestation"
        action1={removedPrestation}
      />
      <div>
        {prestation?.presta_type
          ? (
          <div>{prestation?.presta_type}</div>
            )
          : (
          <SelectSimpleComponent
            items={[
              'Flying Box',
              'Flying Hand',
              'Flying Store',
              'Flying Wall',
              'Flying Bike'
            ]}
            handleAction={(e) => handleChangePresta({ presta_type: e })}
            label="Choisissez une prestation"
            value={currentPresta?.presta_type}
          />
            )}
        <BodyContent
          prestation={{ ...prestation, ...currentPresta }}
          {...{ handleChangePresta }}
        />
        {(prestation?.presta_type || currentPresta?.presta_type) && (
          <div className="grid-prestation-container full">
            <div className="labelized-grid">Informations de flyers :</div>
            <InputComponent
              handleAction={(e) => handleChangePresta({ informations: e })}
              multiline
              defaultValue={prestation?.informations}
            />
            {/* <div className="labelized-grid">Documents :</div>
        <BlackBtn label={`DOCUMENTS`} styles={{ justifyContent: "center" }} handleAction={() => setDocumentsOpen(true)}/> */}
          </div>
        )}
      </div>
      <div className="bottom-content">
        {currentPresta && (
          <BlackBtn
            styles={{ width: 'min-content', padding: '15px 60px' }}
            label="ENREGISTRER"
            handleAction={handleSendModifications}
            disabled={isFetching}
          />
        )}
        {prestation?.presta_type && (
          <div
            className="removed-prestation"
            onClick={() => setAlertRemovedOpen(true)}
          >
            <PoubelleLogo />
            SUPPRIMER LA PRESTATION
          </div>
        )}
      </div>
    </div>
  )
}

export default PrestationModification
