/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import API from "services/axios-config";
import { SelectObjectsComponent } from "views/Admin/Shared/Forms/SelectObjects";
import { InputComponent } from "views/Admin/Shared/Forms/Input";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const ModifyInfoProd = ({ campaignData, handleChangeCampaign }) => {
  const [admins, setAdmins] = useState([]);

  const commercial = campaignData?.campaign?.responsable_com || {};
  const production = campaignData?.campaign?.responsable_prods || [];
  useEffect(() => {
    API.get("/users?fields=first_name,last_name&profil_type=admin")
      .then((result) => {
        setAdmins(
          result?.data?.users?.map((user) => ({
            ...user,
            full_name: `${user?.first_name} ${user?.last_name}`,
          }))
        );
      })
      .catch(() => {
        enqueueSnackbar(
          "Une erreur est survenu lors de la récupération des admins",
          { variant: "error" }
        );
      });
  }, []);

  const handleChangeCampaignInfoProd = (e) => {
    if (e?.campaign_name) {
      handleChangeCampaign({ campaign_name: e?.campaign_name }, "campaign");
    } else if (e?.responsable_com) {
      handleChangeCampaign({ responsable_com: e?.responsable_com }, "campaign");
    } else if (e?.responsable_prods) {
      handleChangeCampaign(
        { responsable_prods: e?.responsable_prods },
        "campaign"
      );
    } else if (e?.previsionnal_start_date) {
      handleChangeCampaign(
        { previsionnal_start_date: e?.previsionnal_start_date },
        "campaign"
      );
    } else if (e?.previsionnal_end_date) {
      handleChangeCampaign(
        { previsionnal_end_date: e?.previsionnal_end_date },
        "campaign"
      );
    }
  };

  return (
    <>
      <div
        style={{
          fontWeight: 700,
          fontSize: 20,
          color: "black",
          margin: "20px 0",
        }}
      >
        INFORMATIONS DE PROD
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <InputComponent
          name="campaign_name"
          defaultValue={campaignData?.campaign?.campaign_name}
          handleAction={(e) =>
            handleChangeCampaignInfoProd({ campaign_name: e })
          }
          label="Code de la campagne"
        />
        <SelectObjectsComponent
          name="responsable-commercial"
          value={
            commercial?.first_name && commercial?.last_name
              ? `${commercial?.first_name || ""} ${commercial?.last_name || ""}`
              : null
          }
          handleAction={(e) =>
            handleChangeCampaignInfoProd({ responsable_com: e })
          }
          items={admins}
          item_name="full_name"
          label="Responsable commercial"
        />
        <SelectObjectsComponent
          multiple
          name="responsable-production"
          value={production}
          handleAction={(e) =>
            handleChangeCampaignInfoProd({ responsable_prods: e })
          }
          items={admins}
          item_name="first_name"
          label="Responsable production"
        />
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <DatePicker
            key={"first-date-picker-previsionnel"}
            value={
              campaignData?.campaign?.previsionnal_start_date
                ? moment(campaignData?.campaign?.previsionnal_start_date)
                : null
            }
            onChange={(e) =>
              handleChangeCampaignInfoProd({
                previsionnal_start_date: new Date(e),
              })
            }
            label={"Date de début prévisionnel de campagne"}
            className="picker-component"/>
        </LocalizationProvider>
        <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
          <DatePicker
            key={"first-date-picker-previsionnel-end"}
            value={
              campaignData?.campaign?.previsionnal_end_date
                ? moment(campaignData?.campaign?.previsionnal_end_date)
                : null
            }
            onChange={(e) =>
              handleChangeCampaignInfoProd({
                previsionnal_end_date: new Date(e),
              })
            }
            label={"Date de fin prévisionnel de campagne"}
            className="picker-component"
          />
        </LocalizationProvider>
      </div>
    </>
  );
};

export default ModifyInfoProd;
