/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded'
import InputText from '../../Inputs/inputText/inputText'

const InputVideo = ({ url, setVideoUrl, setIsSaved }) => {
  const [videourl, setVideourl] = useState(url)
  const [isLoad, setIsLoad] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    if (isLoad) {
      setVideoUrl(videourl)
    }
    setIsLoad(true)
  }, [videourl])

  useEffect(() => {
    if (url !== '') {
      setIsPlaying(true)
    }
  }, [])

  const handleChange = (e) => {
    setIsSaved(false)
    setVideourl(e?.target?.value)
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div className="video_bloc">
        <div style={{ height: 500 }}>
          <div className={'videoPlayer'}>
            <div className="upload-input">
              <div className="input-title" >
                <InputText placeholder={'Url vidéo'} label="Url" value={videourl}
                  handleChange={handleChange} />
              </div>
            </div>
            {
              isPlaying
                ? (
                <div style={{ width: '100%', height: '100%' }}>
                  <iframe
                    src={videourl?.includes('watch?v=')
                      ? videourl?.replace('watch?v=', 'embed/')
                      : videourl}
                    title="YouTube video player"
                    allow="autoplay"
                    allowFullScreen
                    className="lazyload"
                  ></iframe>
                </div>
                  )
                : (
                <div className={'thumbnail'} style={{ height: '100%' }}
                >
                  <div className={'playIcon'}><PlayCircleRoundedIcon /></div>
                </div>
                  )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputVideo
