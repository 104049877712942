/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { ModalComponent } from '../modalComponent/modalcomponent'
import { IntroductionForUseCase } from '../../../home/sections/indexSections'
import IntroductionLogo from './introductionLogo'
import IntroductionContent from './introductionContent'
import './introduction.scss'

export const UseCaseIntroduction = ({ id, data, openModal, setOpenModal }) => {
  const [introduction, setIntroduction] = useState(null)
  const [isSaved, setIsSaved] = useState(false)
  const [logo, setLogo] = useState(
    { img: null }
  )
  const removeUnnecessaryPropreties = () => {
    const { header_url, name, ...savedData } = introduction || {}
    return savedData
  }

  return (
    <div style={{ backgroundColor: '#23272a' }}>
      <IntroductionForUseCase data={isSaved ? introduction : data?.datas}
        header_url={logo?.img || data?.header_url} />
      {openModal && <ModalComponent open={openModal} sectionName="header" setOpenModal={setOpenModal}
        data={removeUnnecessaryPropreties()} setIsSaved={setIsSaved}
        id={data?.id} disable={introduction === null || isSaved}
        component={<div style={{ backgroundColor: '#23272a' }}>
          <IntroductionLogo setLogo={setLogo} logo={logo} id={id} data={data} />
          <IntroductionContent setIsSaved={setIsSaved} setIntroduction={setIntroduction}
            introduction={introduction || data?.datas} />
        </div>
        }
      />}
    </div>
  )
}
