/* eslint-disable react/prop-types */
import React from 'react'
import './style.scss'
import CampaignStatusCard from 'components/CampaignStatusCard'

const TopContent = (props) => {
  const { campaignData, toggleShowDevis, isDevisOpen } = props

  return (
    <div className='campaignmodif-details_title'>
      <CampaignStatusCard status={campaignData.campaign.status}/>
      {campaignData?.campaign?.devis && <div className="voir-devis-btn" onClick={toggleShowDevis}>
        {isDevisOpen ? 'FERMER DEVIS' : 'VOIR DEVIS'}
      </div>}
    </div>
  )
}

export default TopContent
