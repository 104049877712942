/* eslint-disable react/prop-types */
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import React from 'react'
import './style.scss'

const DateAndTimePicker = ({ slot, slotsList, fieldChange, label, handleChangeSlot }) => {
  return (
    <>
      <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
        <DatePicker
          key={`first-date-picker-${slotsList?.id}`}
          value={slot?.[fieldChange] ? moment(slot[fieldChange]) : null}
          onChange={(e) => handleChangeSlot({ [fieldChange]: new Date(e) })}
          label={`Jour de ${label}`}
          className="picker-component"
        />
      </LocalizationProvider>
      <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
        <TimePicker
          key={`first-time-picker-${slotsList?.id}`}
          value={slot?.[fieldChange] ? moment(slot[fieldChange]) : null}
          onChange={(e) => handleChangeSlot({ [fieldChange]: new Date(e) })}
          label={`Heure de ${label}`}
          className="picker-component"
        />
      </LocalizationProvider>
    </>
  )
}

export default DateAndTimePicker
