/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { SelectSimpleComponent } from 'views/Admin/Shared/Forms/SelectSimple'
import './services.style.scss'
import { flyerFormatBike, flyerFormatBox, flyerFormatHand, flyerFormatStore, flyerFormatWall, numberOfPagesList } from 'views/Admin/Shared/PrestationsShared/flyersTypeConfig'
import { InputComponent } from 'views/Admin/Shared/Forms/Input'

export const FlyingBox = (props) => {
  const { handleChangePresta, prestation } = props
  return (
    <div>
      <div className="grid-prestation-container">
        <div className="labelized-grid">Secteur / Adressé :</div>
        <SelectSimpleComponent
          items={['Secteur', 'Adressé']}
          color="white"
          handleAction={(e) => handleChangePresta({ bal_type: e })}
          defaultValue={prestation?.bal_type}
        />
        <div className="labelized-grid">Type de flyers :</div>
        <SelectSimpleComponent
          items={flyerFormatBox}
          color="white"
          handleAction={(e) => handleChangePresta({ format: e })}
          defaultValue={prestation?.format}
        />
        {prestation?.format === 'Brochures' && <div className="labelized-grid">Nombre de pages :</div>}
        {prestation?.format === 'Brochures' && <SelectSimpleComponent
          items={numberOfPagesList}
          color="white"
          handleAction={(e) => handleChangePresta({ page_nb: e })}
          defaultValue={prestation?.page_nb}
        />}
        <div className="labelized-grid">Quantité de flyers :</div>
        <InputComponent type="numeric" handleAction={e => handleChangePresta({ quantity: e })} defaultValue={prestation?.quantity}/>
        <div className="labelized-grid">Grammage flyers :</div>
        <SelectSimpleComponent
          items={["0 à 100gr", "101gr à 150gr"]}
          color="white"
          handleAction={(e) => handleChangePresta({ flyers_weight: e })}
          defaultValue={prestation?.flyers_weight}
        />
        <div className="labelized-grid">Mode de transports :</div>
        <SelectSimpleComponent
          items={["Permis B", "Voiture", "Scooter"]}
          multiple
          color="white"
          handleAction={(e) => handleChangePresta({ transports: e })}
          defaultValue={prestation?.transports}
        />
        <div className="labelized-grid">Stop pub :</div>
        <SelectSimpleComponent
          items={[{ name: 'Oui', value: true }, { name: 'Non', value: false }]}
          color="white"
          handleAction={(e) => handleChangePresta({ stop_pub: e })}
          defaultValue={prestation?.stop_pub}
        />
        <div className="labelized-grid">HLM :</div>
        <SelectSimpleComponent
          items={[{ name: 'Oui', value: true }, { name: 'Non', value: false }]}
          color="white"
          handleAction={(e) => handleChangePresta({ hlm: e })}
          defaultValue={prestation?.hlm}
        />
        </div>

    </div>
  )
}

export const FlyingHand = (props) => {
  const { handleChangePresta, prestation } = props

  useEffect(() => {
    handleChangePresta({ formulaire_link: "https://flyingforyou.typeform.com/to/efSjHeaa" })
  }, [])

  return (
      <div>
        <div className="grid-prestation-container">
          <div className="labelized-grid">Type de flyers :</div>
          <SelectSimpleComponent
            items={flyerFormatHand}
            color="white"
            handleAction={(e) => handleChangePresta({ format: e })}
            defaultValue={prestation?.format}

          />
          <div className="labelized-grid">Quantité de flyers :</div>
          <InputComponent type="numeric" handleAction={e => handleChangePresta({ quantity: e })} defaultValue={prestation?.quantity}/>
          <div className="labelized-grid">Mode de transports :</div>
          <SelectSimpleComponent
            items={["Permis B", "Voiture", "Scooter"]}
            multiple
            color="white"
            handleAction={(e) => handleChangePresta({ transports: e })}
            defaultValue={prestation?.transports}
          />
          <div className="labelized-grid">URL de fin de mission :</div>
            <InputComponent handleAction={e => handleChangePresta({ formulaire_link: e })} defaultValue={prestation?.formulaire_link}/>
          </div>

      </div>
  )
}

export const FlyingStore = (props) => {
  const { handleChangePresta, prestation } = props
  return (
      <div>
        <div className="grid-prestation-container">
          <div className="labelized-grid">Type de media :</div>
          <SelectSimpleComponent
            items={flyerFormatStore}
            color="white"
            handleAction={(e) => handleChangePresta({ format: e })}
            defaultValue={prestation?.format}

          />
          <div className="labelized-grid">Quantité de flyers :</div>
          <InputComponent type="numeric" handleAction={e => handleChangePresta({ quantity: e })} defaultValue={prestation?.quantity}/>
          <div className="labelized-grid">Mode de transports :</div>
          <SelectSimpleComponent
            items={["Permis B", "Voiture", "Scooter"]}
            multiple
            color="white"
            handleAction={(e) => handleChangePresta({ transports: e })}
            defaultValue={prestation?.transports}
          />
          </div>

      </div>
  )
}

export const FlyingWall = (props) => {
  const { handleChangePresta, prestation } = props
  return (
      <div>
        <div className="grid-prestation-container">
          <div className="labelized-grid">Type d&apos;affiche :</div>
          <SelectSimpleComponent
            items={flyerFormatWall}
            color="white"
            handleAction={(e) => handleChangePresta({ format: e })}
            defaultValue={prestation?.format}

          />
          <div className="labelized-grid">Quantité d&apos;affiches :</div>
          <InputComponent type="numeric" handleAction={e => handleChangePresta({ quantity: e })} defaultValue={prestation?.quantity}/>
          <div className="labelized-grid">Mode de transports :</div>
          <SelectSimpleComponent
            items={["Permis B", "Voiture", "Scooter"]}
            multiple
            color="white"
            handleAction={(e) => handleChangePresta({ transports: e })}
            defaultValue={prestation?.transports}
          />
          </div>
      </div>
  )
}

export const FlyingBike = (props) => {
  const { handleChangePresta, prestation } = props
  return (
      <div>
        <div className="grid-prestation-container">
          <div className="labelized-grid">Type de flyers :</div>
          <SelectSimpleComponent
            items={flyerFormatBike}
            color="white"
            handleAction={(e) => handleChangePresta({ format: e })}
            defaultValue={prestation?.format}

          />
          <div className="labelized-grid">Quantité de flyers :</div>
          <InputComponent type="numeric" handleAction={e => handleChangePresta({ quantity: e })} defaultValue={prestation?.quantity}/>
          <div className="labelized-grid">Mode de transports :</div>
          <SelectSimpleComponent
            items={["Permis B", "Voiture", "Scooter"]}
            multiple
            color="white"
            handleAction={(e) => handleChangePresta({ transports: e })}
            defaultValue={prestation?.transports}
          />
         <div className="labelized-grid">Nombre d&apos;heures souhaités :</div>
          <InputComponent type="numeric" handleAction={e => handleChangePresta({ nombre_heures: e })} defaultValue={prestation?.nombre_heures}/>
          <div className="labelized-grid">URL de fin de mission :</div>
          <InputComponent handleAction={e => handleChangePresta({ formulaire_link: e })} defaultValue={prestation?.formulaire_link}/>
          </div>
      </div>
  )
}
