import React from "react";
import { ReactComponent as VisibilityOffIcon } from "assets/VisibilityOffIcon.svg";
import { ReactComponent as VisibilityZoneOffIcon } from "assets/zoneOff.svg";
import { ReactComponent as CampaignIcon } from "assets/campagne.svg";
import { ReactComponent as VisibilityLocationPathOffIcon } from "assets/location_path_off.svg";
import { ReactComponent as StreetMarketerProfileIcon } from "assets/street-marketeurs.svg";
import { useNavigate } from 'react-router'

const VisibilityControls = ({
  id,
  userId,
  dispatch,
  excludeList,
  excludeListLocation,
  excludeListZones,
  setExcludeList,
  removeExcludeList,
  setExcludeListZones,
  removeExcludeListZones,
  setExcludeListLocation,
  removeExcludeListLocation,
  hoveredSummaryType
}) => {
  const navigate = useNavigate()

  const handleVisibilityZoneToggle = (e) => {
    e.stopPropagation();
    if (excludeListZones.includes(id)) {
      dispatch(removeExcludeListZones(id));
    } else {
      dispatch(setExcludeListZones(id));
    }
  };

  const handleVisibilityLocationToggle = (e) => {
    e.stopPropagation();
    if (excludeListLocation.includes(id)) {
      dispatch(removeExcludeListLocation(id));
    } else {
      dispatch(setExcludeListLocation(id));
    }
  };

  const handleVisibilityToggle = (e) => {
    e.stopPropagation();
    if (excludeList.includes(id)) {
      dispatch(removeExcludeList(id));
    } else {
      dispatch(setExcludeList(id));
    }
  };

  const handleRedirectProfileSM = () => {
    navigate(`/admin/street-marketeurs?marketer_id=${userId}`)
  }

  const handleRedirectCampaign = () => {
    navigate(`/admin/campagnes?campaign_id=${id}`)
  }

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {userId && <StreetMarketerProfileIcon
        style={{ width: 15, height: 15 }}
        color={
          excludeListZones.includes(id) ? "#60EED2" : "black"
        }
        onClick={handleRedirectProfileSM}
      />}
      {hoveredSummaryType === "campaign" && <CampaignIcon
        style={{ width: 15, height: 15 }}
        fill={"black"}
        onClick={handleRedirectCampaign}
      />}
      <VisibilityZoneOffIcon
        style={{ width: 15, height: 15 }}
        color={
          excludeListZones.includes(id) ? "#60EED2" : "black"
        }
        onClick={handleVisibilityZoneToggle}
      />
      <VisibilityLocationPathOffIcon
        style={{ width: 15, height: 15 }}
        color={
          excludeListLocation.includes(id) ? "#60EED2" : "black"
        }
        onClick={handleVisibilityLocationToggle}
      />
      <VisibilityOffIcon
        style={{ width: 15, height: 15 }}
        color={excludeList.includes(id) ? "#60EED2" : "black"}
        onClick={handleVisibilityToggle}
      />
    </div>
  );
};

export default VisibilityControls;
