import React from 'react'
import { Box } from '@mui/material'
import { facebook, instagram, linkedin, youtube } from '../../../utils/imageUrls'

const SocialMedia = () => {
  return (
    <Box justifyContent={'center'} spacing={1} alignItems="center">
      <a href='https://www.facebook.com/Flying4U' target={'_blank'} rel="noreferrer">
        <img width="50" height="50" alt="facebook" style={{ padding: 12 }} className={' lazyload'} src={facebook} data-src={facebook} />
      </a>
      <a href='https://www.linkedin.com/company/33240629/' target={'_blank'} rel="noreferrer" >
        <img width="50" height="50" alt="linkedin" style={{ padding: 12 }} className={' lazyload'} src={linkedin} data-src={linkedin} />
      </a>
      <a href='https://www.youtube.com/channel/UCFQ68j-j9yyegzyrZ493Y0A?view_as=subscriber' target={'_blank'} rel="noreferrer" >
        <img width="50" height="50" alt="youtube" style={{ padding: 12 }} className={' lazyload'} src={youtube} data-src={youtube} />
      </a>
      <a href='https://www.instagram.com/flyingforyou_/?hl=fr' target={'_blank'} rel="noreferrer">
        <img width="50" height="50" alt="instagram" style={{ padding: 12 }} className={' lazyload'} src={instagram} data-src={instagram} />
      </a>
    </Box>
  )
}

export default SocialMedia
