/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { Card } from '@mui/material'
import { BiHide, BiShow } from 'react-icons/bi'
import { FiArrowUpRight } from 'react-icons/fi'
import { IoMdSave } from 'react-icons/io'
import { RxDragHandleDots2 } from 'react-icons/rx'
import './buttons.scss'
import '../CMS/sortable.scss'

export const ParentContainer = (props) => {
  return <div style={{ width: '90%', margin: 'auto', ...props?.style }}>
    <div style={{ maxWidth: props?.maxWidth, margin: 'auto' }}>
      {props.children}
    </div>
  </div>
}

export const SimpleButton = ({ text, style, className, icon, event }) => {
  return (
    <div className={className} style={{ ...style }} onClick={event} >
      {text}
      {icon && <FiArrowUpRight className='icon' size={30} />}
    </div>
  )
}

export const DivideTitle = (props) => {
  const { type, w_title, w_style } = props
  const Type = type
  return (
    <div className='content_title'>
      <Type className='main_title' style={w_style} >{w_title}</Type>
    </div>
  )
}

export const CustomDivider = ({ bg_color, color, position, width }) => {
  return <div style={{ backgroundColor: bg_color, display: 'flex', justifyContent: position }}>
  <div style={{ border: `1px solid ${color}`, width }} />
</div>
}

export const Save = ({ handleSave, color = 'white' }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IoMdSave size={50} color={color} onClick={handleSave} style={{ cursor: 'pointer' }} />
    </div>
  )
}

export const Cardcomponent = ({ name, hide, handleHide, attributes, listeners }) => {
  return (
    <Card className={'hidden-section'} style={{ display: hide ? 'none' : 'flex' }}>
      <div className="handle"><RxDragHandleDots2 size={50} {...attributes} {...listeners} /></div>
      <p>{name}</p>
      <div className="hide" style={{ position: 'absolute', right: 0, top: 0, bottom: 0, display: 'flex', alignItems: 'center' }} onClick={handleHide}>
        {hide ? <BiHide className="_hide" size={50} /> : <BiShow className="_hide" size={50} />}
      </div>
    </Card>
  )
}
