import React from 'react'
import LeftContent from './LeftContent'
import RightContent from './RightContent'
import './style.scss'

const TopPageContent = (props) => {
  return (
    <div className="top-page-container">
      <LeftContent {...props} />
      <RightContent {...props} />
    </div>
  )
}

export default TopPageContent
