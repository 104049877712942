/* eslint-disable react/prop-types */
import { IconButton } from "@mui/material";
import router from "./routes";
import { useLoadScript } from "@react-google-maps/api";
import { SnackbarProvider, useSnackbar } from "notistack";
import { RouterProvider } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import React, { createContext, useContext, useEffect, useState } from "react";
const GOOGLE_MAPS_API_KEY = "AIzaSyDPWoHUS3CqtykouTBGnZgh6jj1J5VyYrU";
const libraries = ["places", "drawing"];

const ZoomContext = createContext();

export const ZoomProvider = ({ children }) => {
  const [zoom, setZoom] = useState(1);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setZoom(calculateZoom());
  }, [windowSize]);

  const calculateZoom = () => {
    if (windowSize?.width < 500) {
      return windowSize?.width / 500;
    }
    if (windowSize?.width < 900) {
      return windowSize?.width / 900;
    }
    if (windowSize?.width < 1440) {
      return windowSize?.width / 1440;
    } else {
      return 1;
    }
  };

  const setZoomValue = (newZoom) => {
    setZoom(newZoom);
  };

  return (
    <ZoomContext.Provider value={{ zoom, setZoom: setZoomValue }}>
      {children}
    </ZoomContext.Provider>
  );
};

export const useZoom = () => {
  return useContext(ZoomContext);
};

const App = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });

  function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <CloseIcon style={{ color: "white" }} />
      </IconButton>
    );
  }

  return (
    <>
      <SnackbarProvider
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        action={(snackbarKey) => (
          <SnackbarCloseButton snackbarKey={snackbarKey} />
        )}
      />
      <ZoomProvider>
        {isLoaded && <RouterProvider router={router} />}
      </ZoomProvider>
    </>
  );
};

export default App;
