/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import InputImage from '../Inputs/InputFileImage/inputImage'
import './imagebloc.scss'

export const DoubleImageBlock = ({ height = 450, id, data }) => {
  const [images, setImages] = useState({
    file1: { img: null },
    file2: { img: null }
  })

  const listTemplate = [
    { width: 800, file_type: 'file1' },
    { width: 600, file_type: 'file2' }
  ]

  return (
    <div style={{ backgroundColor: 'white', height, width: '90%', margin: 'auto' }} className="image-container">
      <div className="bloc-container">
        {listTemplate?.map((template, index) =>
          <div key={`template-${template?.file_type}-${index}`}
            style={{ width: template?.width, height }} className='double-image'
          >
            <InputImage useCaseSection={template?.file_type} id={id} img={images[template?.file_type]?.img || data[`${template?.file_type}_url`]} inputID={template?.file_type} className="upload-left"
              setState={(param) => {
                setImages((prev) => ({ ...prev, [template?.file_type]: { ...prev[template?.file_type], img: param } }))
              }} uploadclass={'upload-image upload-logo'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const UseCaseImageBlock = ({ height = 640, id, data }) => {
  const [image, setImage] = useState({ img: null })

  return (
    <>
      <div style={{ backgroundColor: 'white', height, width: '90%', margin: 'auto' }}>
        <InputImage useCaseSection={'sub_image'} id={id} img={data?.sub_image_url || image?.img} inputID="sub-img" className="upload-left"
          setState={(param) => {
            setImage((prev) => ({ ...prev, img: param }))
          }} uploadclass={'upload-image upload-logo'}
        />
      </div>
    </>
  )
}
