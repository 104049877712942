import React from 'react';
import './style.scss';
import { ReactComponent as BackLogo } from 'assets/back-arrow.svg'
import { getDevisById, refuseDevisById } from '../functions'
import { enqueueSnackbar } from 'notistack'
import { ConfirmRefuseModal, ConfirmCreateModal } from '../devisModalConfirmationModals';
import { formatDate } from "utils/helpers";
import DevisEstimationSection from "components/DevisEstimationSection";
import DevisStatusBadge from "components/DevisStatusBadge";

export const SlidingModal = ({ isOpen, onClose, devisId, onCreateCampaign }) => {
    const [currentDevis, setCurrentDevis] = React.useState(null);
    const [isRefuseConfirmModalOpen, setIsRefuseConfirmModalOpen] = React.useState(false);
    const [isCreateConfirmModalOpen, setIsCreateConfirmModalOpen] = React.useState(false);

    React.useEffect(() => {
        getDevisById(devisId).then(res => {
            setCurrentDevis(res?.data?.devis)
        })
            .catch(err => enqueueSnackbar(err, { variant: 'error' }))
    }, [devisId])

    const isCreateCampaignButtonDisabled = React.useMemo(() => {
        if (currentDevis?.status === 'Validé') return true
        if (currentDevis?.status === 'Archivé') return true
        if (currentDevis?.status === 'Refusé') return true
        return false
    }, [currentDevis])

    const isRefuseCampaignButtonDisabled = React.useMemo(() => {
        if (currentDevis?.status === 'Archivé') return true
        if (currentDevis?.status === 'Refusé') return true
        return false
    }, [currentDevis])

    const handleCreateClick = React.useCallback(() => {
        if (!isCreateCampaignButtonDisabled) setIsCreateConfirmModalOpen(true);
    }, [isCreateCampaignButtonDisabled])

    const handleRefuseClick = React.useCallback(() => {
        if (!isRefuseCampaignButtonDisabled) setIsRefuseConfirmModalOpen(true);
    }, [isRefuseCampaignButtonDisabled]);

    const handleConfirmRefuse = async (description) => {
        await refuseDevisById(currentDevis.id, description)
        setIsRefuseConfirmModalOpen(false);
        onClose();
    };

    const handleConfirmCreate = async () => {
        onCreateCampaign(currentDevis.id)
    }

    const supportFormat = React.useMemo(() => {
        if (currentDevis && Object.keys(currentDevis).length > 0) {
            if (currentDevis.support === 'flyers' && currentDevis.format) return currentDevis.format;
            if (currentDevis.page_nb && (currentDevis.page_nb).indexOf('+') !== -1) { return `Brochure de ${currentDevis.page_nb.replace(/\+$/, ' pages ou plus')}`; }
            if (currentDevis.page_nb) return `Brochure de ${currentDevis.page_nb} pages`;
        }
        return null;
      }, [currentDevis]);

    return (
        <div className={`devis-modal-overlay ${isOpen ? 'open' : ''}`}>
            <div className="devis-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="devis-modal-header-content">
                    <BackLogo width={25} height={25} style={{ cursor: 'pointer' }} onClick={onClose} />
                    <div className="devis-modal-title">RÉCAPITULATIF DEVIS - {currentDevis?.company?.company_name} </div>
                </div>
                {currentDevis?.status && <DevisStatusBadge status={currentDevis.status} />}
                <table className="key-value-table">
                    <tbody>
                        <tr>
                            <td className="key">Nom pour la campagne :</td>
                            <td>{currentDevis?.campaign_name}</td>
                        </tr>
                        <tr>
                            <td className="key">Dates souhaitées :</td>
                            <td>{formatDate(currentDevis?.date_start)} - {formatDate(currentDevis?.date_end)} </td>
                        </tr>
                        <tr>
                            <td className="key">Prestation :</td>
                            <td>{currentDevis?.prestation_type} </td>
                        </tr>
                        {currentDevis?.addresses_selected?.length > 0 && <tr>
                            <td className="key">Villes :</td>
                            <td>{currentDevis?.addresses_selected?.map((adr) => adr?.city).join(', ')} </td>
                        </tr>}
                        {currentDevis?.simulation && <tr>
                            <td className="key">Simulation :</td>
                            <td>{currentDevis?.simulation?.name} </td>
                        </tr>}
                        <tr>
                            <td className="key">Support :</td>
                            <td>{currentDevis?.support === 'flyers' ? 'Flyers' : 'Brochures'} </td>
                        </tr>
                        <tr>
                            <td className="key">Nombre de support :</td>
                            <td>{currentDevis?.support_nb} </td>
                        </tr>
                        {(currentDevis?.support === 'brochures' || currentDevis?.support === 'flyers') && supportFormat && <tr>
                            <td className="key">{currentDevis?.support === 'brochures' ? 'Nombre de pages' : 'Fromat du support'}</td>
                            <td>{supportFormat} </td>
                        </tr>}
                        <tr>
                            <td className="key">Création de flyers :</td>
                            <td>{currentDevis?.want_create ? 'Oui' : 'Non'} </td>
                        </tr>
                        <tr>
                            <td className="key">Impression de flyers :</td>
                            <td>{currentDevis?.want_impression ? 'Oui' : 'Non'} </td>
                        </tr>
                        <tr>
                            <td className="key">Urgence : </td>
                            <td>{currentDevis?.urgent ? 'Oui' : 'Non'} </td>
                        </tr>
                        <tr>
                            <td className="key">Détails pour la campagne :</td>
                            <td>{currentDevis?.details} </td>
                        </tr>
                    </tbody>
                </table>

                <hr></hr>

                {currentDevis?.prestation_type === 'Flying Box' && currentDevis?.estimation && <DevisEstimationSection estimation={currentDevis?.estimation} />}

                <div className="devis-modal-btns-container">
                    <div className="devis-modal-btn" style={{ color: isCreateCampaignButtonDisabled ? '#60EED280' : '#60EED2', cursor: isCreateCampaignButtonDisabled ? 'not-allowed' : 'pointer' }} onClick={handleCreateClick}>CRÉER LA CAMPAGNE</div>
                    <div className="devis-modal-btn" style={{ color: isRefuseCampaignButtonDisabled ? '#FF000080' : '#FF0000', cursor: isRefuseCampaignButtonDisabled ? 'not-allowed' : 'pointer' }} onClick={handleRefuseClick}>REFUSER LE DEVIS</div>
                </div>
            </div>
            <ConfirmRefuseModal
                isOpen={isRefuseConfirmModalOpen}
                onClose={() => setIsRefuseConfirmModalOpen(false)}
                onConfirm={handleConfirmRefuse}
            />
            <ConfirmCreateModal
                isOpen={isCreateConfirmModalOpen}
                onClose={() => setIsCreateConfirmModalOpen(false)}
                onConfirm={handleConfirmCreate}
            />
        </div>
    );
};
