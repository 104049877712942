/* eslint-disable react/prop-types */
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { handleSaveUsecaseCover } from '../functions/apiFunctions'
import InputImage from '../components/Inputs/InputFileImage/inputImage'
import InputText from '../components/Inputs/inputText/inputText'
import './header.scss'

const HeaderCMS = (props) => {
  return <HeaderContent
    data={props?.presentation || ''} setIsModified={props?.setHasModified}
  />
}

export default HeaderCMS

const HeaderContent = ({ data, setIsModified }) => {
  const [queryParams] = useSearchParams()
  const id = queryParams?.get('id')
  const [presentation, setPresentation] = useState(data)
  const [cover, setCover] = useState(presentation?.cover_url || '')
  const [hasModified, setHasModified] = useState(false)

  const handleChange = (e, type) => {
    setPresentation((prev) => ({ ...prev, [type]: type === 'name' ? e?.target?.value?.toUpperCase() : e?.target?.value }))
    setHasModified(true)
    setIsModified(true)
  }

  const sendModification = () => {
    handleSaveUsecaseCover(id, presentation)
    setHasModified(false)
    setIsModified(false)
  }

  return (
    <div className='header-container' >
      <div className='inputs'>
        <div className='input-div'>
          <InputText value={presentation?.name} label='Nom du usecase'
            handleChange={(e) => handleChange(e, 'name')}
          />
        </div>
        <Button className='save' variant='contained' color='success'
          disabled={!hasModified}
          onClick={sendModification}>Enregistrer</Button>
      </div>
      <div style={{ flex: 1 }}>
        <InputImage useCaseSection={'cover'} img={cover} inputID={'cover'}
          className="upload-left" setState={(param) => {
            setCover(param)
          }} uploadclass={'upload-image upload-logo'}
        />
      </div>
    </div>
  )
}
