/* eslint-disable react/prop-types */
import React from 'react'
import { CgMenuRightAlt } from 'react-icons/cg'
import CustomDrawer from './drawer'
import './drawer.scss'

export const FFYDrawer = (props) => {
  const { height, open, handleClose, handleOpen } = props

  return (
    <div style={{ backgroundColor: 'green' }}>
      <div className='vertical_close_drawer' style={{ height, zIndex: 100 }}>
        <div className='vertical_content'>
          <div className='menu_icon' onClick={handleOpen}>
            <div style={{ display: 'flex' }} >
              <CgMenuRightAlt color='black' size={25} />
            </div>
          </div>
        </div>
        <CustomDrawer open={open} handleClose={handleClose} />
      </div>

    </div>

  )
}

export default FFYDrawer
