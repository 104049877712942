import React from 'react'
import { ReactComponent as CampaignLogo } from 'assets/campagne.svg'
import { ReactComponent as MissionsLogo } from 'assets/icon_missions.svg'
import { ReactComponent as MarketersLogo } from 'assets/icon_marketers.svg'
import { ReactComponent as CalendlyLogo } from 'assets/icon_calendly.svg'
import { ReactComponent as EntrepriseLogo } from 'assets/icon_entreprise.svg'
import { ReactComponent as GalerieLogo } from 'assets/icon_galerie.svg'
import { ReactComponent as DevtoolsLogo } from 'assets/icon_devtool.svg'
import { ReactComponent as CarteLogo } from 'assets/icon_carte.svg'
import { ReactComponent as ValidationMission } from 'assets/validation_mission.svg'
// import { ReactComponent as ChatLogo } from 'assets/chat.svg'
import { ReactComponent as SendLogo } from 'assets/send.svg'
import { ReactComponent as InvoicesLogo } from 'assets/invoices.svg'
import { ReactComponent as CadenceLogo } from 'assets/cadence.svg'

export const config = [
  {
    name: 'Carte',
    path: '/admin/realtime',
    icon: <CarteLogo style={{ width: 30, height: 30 }}/>
  },
  {
    name: 'Campagnes',
    path: '/admin/campagnes',
    icon: <CampaignLogo style={{ width: 30, height: 30 }}/>,
    subItem: [
      {
        name: 'À venir',
        path: '/admin/campagnes?future=true'

      },
      {
        name: 'En cours',
        path: '/admin/campagnes?status=En+cours'
      },
      {
        name: 'Passé',
        path: '/admin/campagnes?past=true'

      },
      {
        name: 'Devis',
        path: '/admin/campagnes?devis=true'

      }
    ]
  },
  {
    name: 'Street-Marketeurs',
    path: '/admin/street-marketeurs',
    icon: <MarketersLogo style={{ width: 30, height: 30 }}/>
  },
  {
    name: 'Missions à valider',
    path: '/admin/waiting_validation',
    icon: <ValidationMission style={{ width: 30, height: 30 }}/>
  },
  // {
  //   name: 'Chat',
  //   path: '/admin/chat',
  //   icon: <ChatLogo style={{ width: 30, height: 30 }}/>
  // },
  {
    name: 'Facturation SM',
    path: '/admin/invoices',
    icon: <InvoicesLogo style={{ width: 30, height: 30 }}/>
  },
  {
    name: 'Calendly',
    path: '/admin/calendly',
    icon: <CalendlyLogo style={{ width: 30, height: 30 }}/>
  },
  {
    name: 'Entreprises',
    path: '/admin/entreprises',
    icon: <EntrepriseLogo style={{ width: 30, height: 30 }}/>
  },
  {
    name: 'Use Case CMS',
    path: '/admin/usecase',
    icon: <MissionsLogo style={{ width: 30, height: 30 }}/>
  },
  {
    name: 'Galerie photo',
    path: '/admin/gallery?folder=profile-pictures',
    icon: <GalerieLogo style={{ width: 30, height: 30 }}/>
  },
  {
    name: 'Cadence',
    path: '/admin/cadence-map',
    icon: <CadenceLogo style={{ width: 30, height: 30 }}/>
  }

]

export const settingsConfig = [
  {
    name: 'CENTRE DE NOTIFICATION',
    path: '/admin/notifications',
    icon: <SendLogo />
  },
  {
    name: 'Devtools',
    path: '/admin/devtools',
    icon: <DevtoolsLogo />
  }
]
