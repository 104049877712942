/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import HeaderList from '../Headers'
import API from 'services/axios-config';
import useInfiniteScroll from 'utils/useInfinityScroll';
import RightBlockValidation from './rightComponent';
import LeftBlockValidation from './leftComponent';
import TimeModal from './timeModal';
import './styles.scss'
import '../styles.scss'
import FilterComponent from 'components/GlobalInputs/Filters'
import { AutocompleteInput } from 'components/GlobalInputs/Select'
import { enqueueSnackbar } from 'notistack'

const LeftComponent = (props) => {
  const [inputValue, setInputValue] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  const handleInputChange = (event) => {
    const value = event.target.value
    setInputValue(value)
  }

  function handleKeyDown(e) {
    const params = new URLSearchParams(searchParams)
    if (e.key === "Enter") {
      if (params.has('search')) {
        params.set('search', inputValue)
      } else {
        params.append('search', inputValue)
      }
      setSearchParams(params)
    }
  }

  return (
    <div className="bookmark-list">
      <h1 className="bookmark-title">{props.title}</h1>
      <div className="bookmark-filter">
        <input value={inputValue} onKeyDown={handleKeyDown} onChange={handleInputChange} type="text" placeholder="Rechercher..." className="temporary-filter" />
      </div>
    </div>
  )
}

const ValidationMission = () => {
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const validationMissionRef = useRef(null)
  const [data, setData] = useState([])
  const [missionSelected, setMissionSelected] = useState(null)
  const [modalOpen, setModalOpen] = useState(null)
  const [newData, setNewData] = useState({
    commentary: "",
    bonus_sm: "",
    backup_cost: ""
  })
  const [searchParams, setSearchParams] = useSearchParams()

  /* FOR FILTERS */
  const [selectedFilters, setSelectedFilters] = useState({});
  const CampaignAutoComplete = ({ handleUpdateFilter }) => {
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState([]);
    const [value, setValue] = useState('');

    React.useEffect(() => {
      async function getCampaigns(value) {
        if (value.trim().length > 0) {
          setFetching(true);
          try {
            const res = await API.get(`campaign_autocomplete_by_name?campaign_name=${value}`);
            setItems(res.data.campaigns.map(campaign => campaign.campaign_name));
          } finally {
            setFetching(false);
          }
        }
      }

      if (value.trim().length > 0) {
        getCampaigns(value);
      }
    }, [value]);

    const handleChange = (value) => {
      setValue(value);
    };

    function handleSelectValue(value) {
      setValue(value)
      handleUpdateFilter(value, true)
    }

    return (
      <div style={{ padding: '20px 20px 90px 20px', overflow: 'auto' }}>
        <AutocompleteInput label="Campaign" onChange={handleChange} onSelect={handleSelectValue} {...{ items, fetching, value }} />
      </div>
    );
  };

  const filters = [
    { key: 'statut', label: 'STATUT', options: ["MISSION VALIDÉE"], multiple: false },
    { key: 'date', label: 'DATE', options: ["7 DERNIERS JOURS", "14 DERNIERS JOURS", "28 DERNIERS JOURS", "3 DERNIERS MOIS"], multiple: false },
    { key: 'responsable', label: 'RESPONSABLE OPÉRATIONNEL', options: ["THIAGO", "SYFAX"], multiple: false },
    { key: 'prestation', label: 'PRESTATION', options: ["FLYING BOX", "FLYING HAND"], multiple: false },
    { key: 'campaigns', label: 'CAMPAGNE', customComponent: <CampaignAutoComplete />, multiple: true }
  ];

  /* END FOR FILTERS */

  // useEffect(() => {
  //   const params = new URLSearchParams(searchParams)
  //   for (const [key, value] of Object.entries(selectedFilters)) {
  //     if ((value !== null && !Array.isArray(value)) || (Array.isArray(value) && value.length > 0)) params.set(key, value)
  //     else params.delete(key)
  //   }
  //   setSearchParams(params)
  // }, [selectedFilters])
  function getDateBasedOnSelection(selection) {
    const today = new Date();
    const resultDate = new Date();

    switch (selection) {
      case "7 DERNIERS JOURS":
        resultDate.setDate(today.getDate() - 7);
        break;
      case "14 DERNIERS JOURS":
        resultDate.setDate(today.getDate() - 14);
        break;
      case "28 DERNIERS JOURS":
        resultDate.setDate(today.getDate() - 28);
        break;
      case "3 DERNIERS MOIS":
        resultDate.setMonth(today.getMonth() - 3);
        break;
      default:
        return null
    }

    return resultDate.toISOString();
  }

  const getFiltersParams = React.useCallback(() => {
    let filtersParams = ""
    for (const [key, value] of Object.entries(selectedFilters)) {
      if ((value !== null && !Array.isArray(value)) || (Array.isArray(value) && value.length > 0)) {
        let valueData = value
        if (key === 'date') {
          valueData = getDateBasedOnSelection(value)
        }
        filtersParams += `&${key}=${valueData}`
      }
    }
    return filtersParams;
  }, [selectedFilters]);

  function getValidationMissions(params = "") {
    const filtersParams = getFiltersParams()
    const fullParams = (filtersParams + params).trim()
    setIsLoading(true)
    API.get(`waiting_validation_missions?take=30&skip=${page === 1 ? 0 : data?.length}${fullParams}`).then(res => {
      if (res.data.length === 0) setHasMore(false)
      if (page === 1) setData(res.data)
      else setData(prev => [...prev, ...res.data])
    })
      .catch(e => {
        setData([])
        if (e?.response?.data?.error) {
          enqueueSnackbar(
            e.response.data.error,
            { variant: 'error' }
          )
        }
      })
      .finally(() => setIsLoading(false))
  }

  function getParams() {
    let paramsList = ""
    if (searchParams?.get('search') || searchParams?.get('search') === "") {
      paramsList = paramsList + `&search=${searchParams?.get('search')}`
      searchParams.delete('mission_id')
      setSearchParams(searchParams)
    } else {
      if (searchParams?.get('mission_id')) {
        paramsList = paramsList + `&mission_id=${searchParams?.get('mission_id')}`
      }
    }
    return (paramsList)
  }

  useEffect(() => {
    setPage(1)
  }, [searchParams])

  // useEffect(() => {
  //   const newSelectedFilters = {}
  //   searchParams.forEach((value, key) => {
  //     const filter = filters.find(filter => filter.key === key)
  //     if (filter) {
  //       const data = filter.multiple ? value.split(',') : value
  //       newSelectedFilters[key] = data
  //     }
  //   });
  //   console.log('newSelectedFilters:', newSelectedFilters)
  //   setSelectedFilters(newSelectedFilters)
  // }, [searchParams])

  const updateSelectedFilters = React.useCallback((newFilterData) => {
    setPage(1)
    setHasMore(true)
    if (validationMissionRef.current) validationMissionRef.current.scrollTop = 0;
    if (searchParams?.get('mission_id')) {
      searchParams.delete('mission_id')
      setSearchParams(searchParams)
    }
    setSelectedFilters(newFilterData)
  }, [searchParams])

  useEffect(() => {
    getValidationMissions(getParams())
  }, [page, searchParams, selectedFilters])

  useEffect(() => {
    setPage(1)
    setHasMore(true)
  }, [])

  useEffect(() => {
    if (data.length && !missionSelected) {
      setMissionSelected(data[0])
    }
  }, [data])

  const fetchMoreData = useCallback(() => {
    if (hasMore) setPage((prevData) => prevData + 1)
  }, [page, hasMore])

  useInfiniteScroll(fetchMoreData, validationMissionRef, isLoading, hasMore)

  function handleRemoveDocument() { }
  function handleAddDocument() { }

  function handleSubmitData() {
    const dataFiltered = data.filter(value => value.id !== missionSelected?.id)
    API.patch(`missions/${missionSelected?.id}`, newData)
      .then(_ => setData(dataFiltered))
      .then(_ => setMissionSelected(dataFiltered[0]))
      .then(_ => setNewData({
        commentary: "",
        bonus_sm: "",
        backup_cost: ""
      }))
  }

  return (
    <div className="list-container">
      <div className="list-content" style={{ cursor: isLoading ? 'wait' : 'default' }}>
        <TimeModal {...{ modalOpen, setModalOpen, setNewData, setMissionSelected }} />
        <HeaderList leftComponent={<LeftComponent title={"MISSIONS À VALIDER"} />} />
        <div style={{ backgroundColor: "white", paddingLeft: 20 }}>
          <FilterComponent filters={filters} updateSelectedFilters={updateSelectedFilters} />
        </div>
        <div style={{ backgroundColor: "white", flex: 1, display: "flex", overflow: "hidden", paddingTop: 10 }}>
          {data.length === 0 && <div>{"IL N'Y A AUCUNE MISSION EN ATTENTE DE VALIDATION."}</div>}
          {data.length > 0 && <div style={{ display: "flex", flex: 1 }}>
            <LeftBlockValidation title={`MISSION TERMINÉES ET ${selectedFilters.statut === 'MISSION VALIDÉE' ? '' : 'À'} VALIDER`} dataRef={validationMissionRef} {...{ data, missionSelected, setMissionSelected, setNewData }} />
            {missionSelected && <RightBlockValidation {...{ missionSelected, handleRemoveDocument, handleAddDocument, setData, setMissionSelected, setModalOpen, newData, setNewData, handleSubmitData }} />}
          </div>}
        </div>
      </div>
    </div>
  )
}
export default ValidationMission
