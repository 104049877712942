/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { deleteUsecase, getAllUsecases } from '../../UsecaseCMS/functions/apiFunctions'
import CaseElement from '../caseElement/caseElement'
import Masonry from '../masonry/masonry'
import FetchingScreen from 'components/Loader/fetching-screen'
import { ModalConfirmationComponent } from './modalConfirmation'

const UseCaseList = ({ searchedCase, filtered }) => {
  const [useCaseList, setUseCaseList] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [removeId, setRemoveId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoaded(false)
    getAllUsecases(setUseCaseList, setIsLoaded)
  }, [])

  const list = useMemo(() => {
    return searchedCase?.length > 0 || filtered?.length > 0
      ? useCaseList?.filter((usecase, index) => {
        return usecase?.name?.toLowerCase()?.includes(searchedCase?.toLowerCase()) ||
          filtered?.includes(usecase?.disable)
      })
      : useCaseList
  }, [searchedCase, useCaseList, filtered])

  const settingColumns = useCallback(() => {
    if (window.innerWidth >= 1400) return 3
    if (window.innerWidth >= 800) return 3
    if (window.innerWidth >= 500) return 2
    return 1
  }, [])

  const [column, setColumn] = useState(() => settingColumns())

  useEffect(() => {
    window.addEventListener('resize', () => setColumn(() => settingColumns()))
    return window.removeEventListener('resize', () => setColumn(() => settingColumns()))
  }, [setColumn, settingColumns])

  const removeUsecase = () => {
    deleteUsecase(removeId, setUseCaseList, setOpenConfirm, setIsLoading)
  }

  return (
    <>
      {isLoaded && list?.length > 0
        ? <Masonry
          dataArray={list}
          columnCount={column}
          ChildsElement={CaseElement}
          setUseCaseList={setUseCaseList}
          removeUsecase={(id) => {
            setOpenConfirm(true)
            setRemoveId(id)
          }}
        />
        : <div className='no-usecase'>
          <p>Aucun Use Case trouvé </p>
        </div>
      }
      {
        openConfirm && <ModalConfirmationComponent
          open={openConfirm} title="Supprimer un useCase" handleConfirm={removeUsecase}
          text="Attention !!, vous êtes bien sûr de vouloir supprimer ce usecase !!"
          handleCancel={() => setOpenConfirm(false)} />
      }
      {isLoading && <div style={{ zIndex: 999999 }}>
        <FetchingScreen isFetching={isLoading} />
      </div> }
    </>
  )
}

export default UseCaseList
