/* eslint-disable no-unused-vars */
import { useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import LeftLayout from '../../LeftLayout/index.js'
import InfosWindowView from '../infoWindow.js/index.js'
import './style.scss'
import { ReactComponent as DropdownSvg } from 'assets/dropdown-icon.svg'
import { setClientView } from '_redux/Zones/reducers'
import { useDispatch, useSelector } from 'react-redux'
const ContentMapView = (props) => {
  const { mapRef } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [modalPosition, setModalPosition] = useState({ top: 0, right: 0 })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isSlidIn, setIsSlidIn] = useState(false)
  const { pathname } = useLocation()
  const clientView = useSelector((state) => state.zone.clientView)
  const dispatch = useDispatch()
  function handelVueClient () {
    dispatch(setClientView(!clientView))
  }
  const handleQuitMap = (event) => {
    searchParams.delete('maps')
    navigate({
      pathname,
      search: searchParams.toString()
    })
  }

  return (
    <div>
      <LeftLayout mapRef={mapRef} />

      {modalOpen && (
        <InfosWindowView
          modalPosition={modalPosition}
          currentRef={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
      <div className={`model-bottom-contentMaps ${isSlidIn ? 'slide-in' : ''}`}>
        <div
          onClick={() => setIsSlidIn(!isSlidIn)}
          className={`model-bottom-contentMaps-icon ${
            isSlidIn ? 'slide-in' : ''
          }`}
        >
          <DropdownSvg className={isSlidIn ? 'rotate' : ''} />
        </div>
        <div className={'model-bottom-contentMaps-second-el '}>
          <div className="close-map-btn" onClick={handelVueClient}>
            {!clientView ? 'VUE CLIENT' : 'VUE PROD'}
          </div>
          <div className="close-map-btn" onClick={handleQuitMap}>
            FERMER
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContentMapView
