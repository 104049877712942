/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import './style.scss'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { fr } from 'date-fns/locale'

const today = new Date()
const last7Days = new Date()
last7Days.setDate(today.getDate() - 6)
const last14Days = new Date()
last14Days.setDate(today.getDate() - 13)
const last28Days = new Date()
last28Days.setDate(today.getDate() - 27)
const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1)
const thisMonthEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
const lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0)

const SHORTCUTS = [
  {
    id: 'last7days',
    text: 'LES 7 DERNIERS JOURS',
    startDate: last7Days,
    endDate: today
  },
  {
    id: 'last14days',
    text: 'LES 14 DERNIERS JOURS',
    startDate: last14Days,
    endDate: today
  },
  {
    id: 'last28days',
    text: 'LES 28 DERNIERS JOURS',
    startDate: last28Days,
    endDate: today
  },
  {
    id: 'thisMonth',
    text: 'CE MOIS-CI',
    startDate: thisMonthStartDate,
    endDate: thisMonthEndDate
  },
  {
    id: 'lastMonth',
    text: 'LE MOIS DERNIER',
    startDate: lastMonthStartDate,
    endDate: lastMonthEndDate
  },
  {
    id: 'allPeriode',
    text: 'TOUTE LA PÉRIODE',
    startDate: undefined,
    endDate: undefined
  }
]

const CalendarRangePicker = ({
  className,
  startDate,
  endDate,
  onChange,
  disabledDates,
  disabledDayFn,
  selectedShortcutId,
  showShortcutMenu
}) => {
  const [selectedDateRangeId, setSelectedDateRangeId] = useState(selectedShortcutId || 'allPeriode')
  const [ranges, setRanges] = useState([
    {
      startDate,
      endDate,
      key: 'selection'
    }
  ])

  const onChangeFinal = (data) => {
    setSelectedDateRangeId('custom')
    if (onChange) onChange(data[0], 'custom', 'PERSONNALISÉ')
    setRanges(data)
  }

  const handleRangeChange = (shortcut) => {
    setSelectedDateRangeId(shortcut.id)
    if (onChange) {
      onChange(
        {
          startDate: shortcut.startDate,
          endDate: shortcut.endDate,
          key: 'selection'
        },
        shortcut.id,
        shortcut.text
      )
    }
    setRanges([
      {
        startDate: shortcut.startDate,
        endDate: shortcut.endDate,
        key: 'selection'
      }
    ])
  }

  return (
    <div className="calendar-range-picker-container">
      {showShortcutMenu && (
        <div className="calendar-range-picker-shortcuts-container">
          <div className="calendar-range-picker-shortcuts-title-container">
            <span className="calendar-range-picker-shortcuts-title">PLAGE DE DATES</span>
          </div>
          <div className="calendar-range-picker-shortcuts-items-container">
            <div
              style={{ cursor: 'default' }}
              className={`calendar-range-picker-shortcuts-item-container ${selectedDateRangeId === 'custom' ? 'isSelected' : ''}`}
            >
              <span className="calendar-range-picker-shortcuts-item">PERSONNALISÉ</span>
            </div>
            {SHORTCUTS.map((shortcut) => (
              <div
                key={shortcut.id}
                className={`calendar-range-picker-shortcuts-item-container ${selectedDateRangeId === shortcut.id ? 'isSelected' : ''}`}
                onClick={() => handleRangeChange(shortcut)}
              >
                <span className="calendar-range-picker-shortcuts-item">{shortcut.text}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <DateRange
        className={`calendar-range-picker ${className || ''}`}
        locale={fr}
        editableDateInputs
        onChange={(item) => onChangeFinal([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={ranges}
        disabledDates={disabledDates}
        disabledDay={disabledDayFn || undefined}
      />
    </div>
  )
}

export default CalendarRangePicker
