import { Button } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH_USECASE_CMS_CREATION } from 'routes/routesAdmin'
import Search from './inputs/inputSearch'
import UseCaseList from './caseList/useCaseList'
import './home.scss'
import ScrollToTopButton from './scrollToTop/scrollToTop'

const HomeCaseCMS = () => {
  const targetDivRef = useRef(null)
  const navigate = useNavigate()
  const [searched, setSearched] = useState('')
  const [filtered, setFiltered] = useState([])
  const handleCreateUseCase = () => {
    navigate(`../${PATH_USECASE_CMS_CREATION}`)
  }

  return (
    <div id='homeCMS' ref={targetDivRef} className='home-container'>
      <div className='add-search'>
        <Button variant='contained' onClick={handleCreateUseCase} >Ajouter un usecase</Button>
        <Search placeholder="Rechercher un usecase" setSearched={setSearched} setFilter={setFiltered} />
      </div>
      <div className='masonry'>
        <UseCaseList searchedCase={searched} filtered={filtered} />
      </div>
      <ScrollToTopButton targetDivRef={targetDivRef} />
    </div>
  )
}

export default HomeCaseCMS
