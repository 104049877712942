import "./style.scss";

function CampaignStatusCard({ status }) {
    const colors = {
        Terminée: '#00AF26',
        'En cours': '#2934CF',
        'À venir': '#9520A8',
        'En recherche de SM': '#F5B342',
        Brouillon: '#8A8783'
    }
    return (
        <>
            {colors[status]
                ? <div
                    className="state-campaign"
                    style={{
                        background: colors[status] + '50',
                        color: colors[status]
                    }}
                >
                    {status.toUpperCase()}
                </div>
                : <></>}
        </>
    )
}

export default CampaignStatusCard;
