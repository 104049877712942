/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { SortableComponent } from './sortable'
import { ParentContainer } from '../components/components'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { useSearchParams } from 'react-router-dom'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { getCurrentComponent, handlDragEnd, handleVisualisation } from '../functions/functions'
import { updateIndexSectionsOfUseCase } from '../functions/apiFunctions'

// eslint-disable-next-line react/display-name
const CMS = forwardRef((props, ref) => {
  const { sectionsList } = props
  const [queryParams] = useSearchParams()
  const id = queryParams?.get('id')
  const [orderedList, setOrderedList] = useState([])

  useEffect(() => {
    setOrderedList(sectionsList.map((section) => ({
      id: section?.name,
      idSection: section?.id,
      name: section?.name,
      position: section?.position,
      visible: section?.visible || false
    })))
  }, [])

  useEffect(() => {
    orderedList?.forEach((item, index) => {
      item?.position !== index && updateIndexSectionsOfUseCase(item?.idSection, index)
    })
  }, [orderedList])

  const currentDataSection = (name) => {
    const existValue = sectionsList?.find(value => value?.name === name) || {}
    return ({ ...existValue })
  }

  useImperativeHandle(ref, () => ({
    visualisation () {
      handleVisualisation(id)
    }
  }))

  return (
    <ParentContainer style={{ width: '100%' }}>
      <DndContext collisionDetection={closestCenter} onDragEnd={(e) => handlDragEnd(e, setOrderedList, orderedList)}>
        <SortableContext items={orderedList} strategy={verticalListSortingStrategy} >
          {orderedList?.map((item) => {
            return <SortableComponent
              hide={item?.visible}
              key={item?.name}
              section={item?.name}
              id={item?.name} data={currentDataSection(item?.name)}
            >
              {getCurrentComponent[item?.name] || <div></div>}
            </SortableComponent>
          })}
          <div style={{ height: 100 }}></div>
        </SortableContext>
      </DndContext>
    </ParentContainer>
  )
})

export default CMS
