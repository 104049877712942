/* eslint-disable react/prop-types */
import { Divider, IconButton, Menu, MenuItem, Modal, Popper } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DateModal from "./Modals/dateModal";
import SettingsIcon from "@mui/icons-material/Settings";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { ReactComponent as AssignerSMLogo } from 'assets/assignerSM.svg'
import ConfirmationModal from "../../confirmationModal";
import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext";
import { ModalWaitingSend } from "views/Admin/Views/Cartes/Views/CreateCampaign/LeftLayout/modal-waiting-send";
import AssignSmModal from "views/Slot/SlotCard/ComponentSlotCardView/ComponentSlotDetails/InfosSlot/assignationModal";
import API from "services/axios-config";
import { enqueueSnackbar } from "notistack";
import { ReactComponent as VisibilityLocationPathOffIcon } from "assets/location_path_off.svg";
import { SelectObjectsComponent } from "views/Admin/Shared/Forms/SelectObjects";
import { ReactComponent as Star2Svg } from 'assets/star2.svg';
import BtnComponent from "components/GlobalInputs/Btn";
import moment from "moment";
import { ButtonComponent } from "views/Admin/Shared/Buttons/Generic";
import { extractLocationsFromGPX } from 'views/Admin/Views/Cartes/functions/functionsMap'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { getItemType } from 'views/Cartes/CampaignViewMap/MapLayout/LeftLayout/DraggableList/function'

const AssignSmToGPXPathModal = ({ isOpen, slot, handleCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [smList, setSmList] = useState([]);
  const [selectedSm, setSelectedSm] = useState(null)
  const [gpxLocations, setGpxLocations] = useState([])
  const [doesLocationsHaveTimestamps, setDoesLocationsHaveTimestamps] = useState(false);
  const [customCheckInCheckOut, setCustomCheckInCheckOut] = useState({
    checkIn: null,
    checkOut: null,
  })
  const { setPrestationsList } = useCampaignMap()

  useEffect(() => {
    setIsLoading(true)
    API.get(`/users?profil_type=marketer&status=["Validé"]`)
    .then((response) => {
      setSmList(response?.data?.users?.map(user => ({ ...user, full_name: `${user?.first_name} ${user?.last_name}` })))
    })
    .catch((err) => enqueueSnackbar('Une erreur est survenu lors de la récupération des Street-marketeurs : ' + err, { variant: 'error' }))
    .finally(() => setIsLoading(false))
  }, [])

  const handleImportGPX = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.gpx';
    input.onchange = async (e) => {
      const file = e.target.files[0]
      const fileName = file.name
      const fileType = fileName.split('.').pop()

      if (fileType === 'gpx') {
        extractLocationsFromGPX(file).then(({ locations, haveTimestamps }) => {
          if (locations.length < 2) {
            enqueueSnackbar(`Impossible de faire un tracé: importé moins de deux points (${locations.length})`, { variant: 'error', persist: false })
            return
          }
          setGpxLocations(locations)
          setDoesLocationsHaveTimestamps(haveTimestamps)
        })
      } else {
        enqueueSnackbar("Cette fonctionnalité n'accepte que les fichiers GPX.", { variant: 'error', persist: false })
      }
    }
    input.click()
  }

  function getTimeDifference(timestamp1, timestamp2) {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    const diffMilliseconds = Math.abs(date2 - date1);

    const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diffMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

    const formattedHours = diffHours.toString().padStart(2, '0');
    const formattedMinutes = diffMinutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  const formatCheckInCheckOutTimestamp = (timestamp) => {
    return moment(timestamp).locale('fr').format('dddd D MMMM YYYY HH:mm')
  }

  const gpxTimesData = useMemo(() => {
    if (gpxLocations.length > 2 && doesLocationsHaveTimestamps) {
      const firstLocation = gpxLocations[0];
      const lastLocation = gpxLocations.at(-1);

      if (firstLocation?.timestamp && lastLocation?.timestamp) {
        return {
          checkIn: firstLocation.timestamp,
          checkOut: lastLocation.timestamp,
          totalTime: getTimeDifference(firstLocation.timestamp, lastLocation.timestamp)
        }
      }
    }
    return null;
  }, [gpxLocations, doesLocationsHaveTimestamps])

  const renderSmItem = (props, option) => {
    const isEmpty = option.full_name === "Aucune correspondance"
    return <li {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>{option.full_name}</div>
      {!isEmpty && option.rating_global !== null && <div style={{ display: 'flex', gap: 4 }}>
      {[...Array(5)].map((_, index) => (
          <Star2Svg
            key={index}
            color={option.rating_global && index < option.rating_global ? "#60EED2" : "white"}
            height={12}
            width={12}
          />
        ))}
      </div>}
    </li>
  }

  const isValideShown = useMemo(() => {
    if (!isLoading && gpxTimesData?.checkIn && gpxTimesData?.checkIn && gpxTimesData.totalTime && selectedSm) return true
    return false
  }, [gpxTimesData, selectedSm, isLoading])

  const handleValide = () => {
    setIsLoading(true)
    const locationDataForApi = gpxLocations.map((location) => ({
      ...location,
      pause_indicator: 0,
      location_type: "default"
    }))
    const apiData = {
      locations: locationDataForApi,
      mission: {
        user_id: selectedSm.id,
        slot_id: slot.id,
        status: 'Terminée',
        check_in: gpxTimesData.checkIn,
        check_out: gpxTimesData.checkOut
      }
    }
    API.post(`add_locations_and_mission_from_gpx`, apiData)
    .then((res) => {
        setPrestationsList(current => current?.map((presta) => {
        if (presta?.id === slot?.prestation_id) {
          return ({ ...presta, slots: presta.slots?.map((_slot) => _slot.id === slot.id ? { ..._slot, items: _slot.items ? [..._slot.items, ...res.data] : res.data } : _slot) })
        } else {
          return (presta)
        }
      }
      ))
      enqueueSnackbar("Mission et Tracé SM sont ajouté avec success", { variant: 'success' });
      setIsLoading(false)
      handleCloseModal()
    })
    .catch(err => {
      setIsLoading(false)
      enqueueSnackbar("Une erreur est survenu lors de le creation du mission et l'ajout des locations" + err, { variant: 'error', persist: true });
    })
  }

  const closeModal = () => {
    if (!isLoading) handleCloseModal()
    else enqueueSnackbar("En cours de creation de tracé a partir de GPX", { variant: 'warning' });
  }

  const customCheckInCheckOutDifference = useMemo(() => {
    if (!doesLocationsHaveTimestamps && customCheckInCheckOut.checkIn && customCheckInCheckOut.checkOut) {
      return getTimeDifference(customCheckInCheckOut.checkIn, customCheckInCheckOut.checkOut)
    }
    return null;
  }, [doesLocationsHaveTimestamps, customCheckInCheckOut])

  const handleADdTimestampsToLocations = () => {
    const checkInDate = new Date(customCheckInCheckOut.checkIn);
    const checkOutDate = new Date(customCheckInCheckOut.checkOut);

    // Calculate the total difference in milliseconds
    const totalDifference = checkOutDate - checkInDate;

    // Determine the time difference per location
    const locationsLength = gpxLocations.length;
    const differencePerLocation = totalDifference / (locationsLength - 1); // Use (locationsLength - 1) for equal spacing

    // Loop through each gpxLocation and add the timestamp
    const newGpxLocations = gpxLocations.map((location, index) => {
      const timestamp = new Date(checkInDate.getTime() + (differencePerLocation * index));
      return {
        ...location,
        timestamp: timestamp.toISOString(),
      }
    });
    setGpxLocations(newGpxLocations)
    setDoesLocationsHaveTimestamps(true)
  };

  return <Modal open={isOpen} onClose={closeModal}>
    <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          boxShadow: "24px",
          width: "100%",
          maxWidth: "600px",
          borderRadius: 20,
          padding: '40px 20px',
      }}>
        <div style={{ fontSize: 16, fontWeight: 800, textAlign: 'center', color: 'black' }}>
          ASSIGNER UN SM POUR LE TRACÉ
        </div>
        <div style={{ padding: "40px 30px 0 30px" }}>
          <SelectObjectsComponent
            name='Street-marketeur'
            handleAction={(e) => setSelectedSm(e)}
            items={smList}
            item_name="full_name"
            label='Street-marketeur'
            disabled={isLoading}
            customRenderOption={renderSmItem}
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
            {selectedSm && <ButtonComponent text="IMPORTER GPX" handleActions={handleImportGPX} loading={isLoading}/>}
          </div>
          {doesLocationsHaveTimestamps && <div style={{ display: 'flex', flexDirection: 'column', gap: 4, color: 'black', paddingTop: 20, paddingLeft: 4, fontSize: 16, fontWeight: 600 }}>
              <div>
                Check-in: {formatCheckInCheckOutTimestamp(gpxTimesData.checkIn)}
              </div>
              <div>
                Check-out {formatCheckInCheckOutTimestamp(gpxTimesData.checkOut)}
              </div>
              <div>
                Temps sur le terrain: {gpxTimesData.totalTime}
              </div>
            </div>}
          {!doesLocationsHaveTimestamps && gpxLocations.length > 2 && (<div style={{ display: 'flex', flexDirection: 'column', gap: 4, color: 'black', paddingTop: 20, paddingLeft: 4, fontSize: 16, fontWeight: 600 }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  Pas de temps dans les positions du fichier GPX
                </span>
                <span style={{ fontWeight: 400, fontSize: 14 }}>
                  Merci de saisir les dates début et fin du mission
                </span>
              </div>
              <div>
                <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={customCheckInCheckOut.checkIn ? moment(customCheckInCheckOut?.checkIn) : null}
                    onChange={(e) => setCustomCheckInCheckOut(prev => ({ ...prev, checkIn: new Date(e) }))}
                    label="Jour de début"
                    className="DateInfos_DateTimePicker"
                  />
                </LocalizationProvider>
                <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
                  <TimePicker
                    value={customCheckInCheckOut?.checkIn ? moment(customCheckInCheckOut?.checkIn) : null}
                    onChange={(e) => setCustomCheckInCheckOut(prev => ({ ...prev, checkIn: new Date(e) }))}
                    label="Heure de début"
                    className="DateInfos_DateTimePicker"
                    popper={<Popper disablePortal/>}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={customCheckInCheckOut?.checkOut ? moment(customCheckInCheckOut?.checkOut) : null}
                    onChange={(e) => setCustomCheckInCheckOut(prev => ({ ...prev, checkOut: new Date(e) }))}
                    label="Jour de fin"
                    className="DateInfos_DateTimePicker"
                  />
                </LocalizationProvider>
                <LocalizationProvider adapterLocale="fr" dateAdapter={AdapterMoment}>
                  <TimePicker
                    value={customCheckInCheckOut?.checkOut ? moment(customCheckInCheckOut?.checkOut) : null}
                    onChange={(e) => setCustomCheckInCheckOut(prev => ({ ...prev, checkOut: new Date(e) }))}
                    label="Heure de fin"
                    className="DateInfos_DateTimePicker"
                  />
                </LocalizationProvider>
              </div>
              {customCheckInCheckOutDifference && <div>
                Temps sur le terrain: {customCheckInCheckOutDifference}
              </div>}
              {customCheckInCheckOutDifference && <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
                <ButtonComponent text="VALIDER LES DATES" handleActions={handleADdTimestampsToLocations} loading={isLoading}/>
              </div>}
            </div>)}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25 }}>
            {isValideShown && <BtnComponent color="white" text="VALIDER" onClick={handleValide} loading={isLoading} />}
          </div>
        </div>
    </div>
  </Modal>
};

// eslint-disable-next-line react/display-name
const MenuDialog = memo(
  ({
    setSlotIsVisible,
    slotIsVisible,
    handleRemoveSlot,
    slot,
    handleSendModify,
    campaign,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [ModalOpen, setModalOpen] = useState(null);
    const [openValidation, setOpenValidation] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modalAssignOpen, setModalAssignOpen] = useState(false)
    const [gpxModalIsOpen, setGpxModalIsOpen] = useState(false)
    const { handleAddKml, waitingSend, createItems, updateItemsVisibility } = useCampaignMap()
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
      e.stopPropagation()
      setAnchorEl(null);
    };

    const removeSlot = (e) => {
      e.stopPropagation()
      setOpenValidation(true)
    };

    const changeVisibility = (e) => {
      e.stopPropagation()
      if (slot.items?.length > 0) {
        updateItemsVisibility(slot.items, !slotIsVisible);
      }
      setSlotIsVisible(!slotIsVisible)
      setAnchorEl(null);
    };

    function validAction (e) {
      e.stopPropagation()
      handleRemoveSlot(slot);
      setAnchorEl(null);
    }

    function handleAssignSm (user_id) {
      setLoading(true)
      API.post(`users/${user_id}/slots/${modalAssignOpen}/missions?include_user=true`, { status: 'Validé' })
        .then(res => {
          createItems({ ...res.data, prestation_id: slot.prestation_id })
          setModalAssignOpen(false)
        })
        .catch(err => enqueueSnackbar("Une erreur est survenu lors de l'assignation du SM" + err, { variant: 'error' }))
        .finally(_ => setLoading(false))
    }

    const isGpxImportEnabled = useMemo(() => {
      if (slot?.items) {
        return !slot.items.some((item) => {
          const type = getItemType(item);
          return (
            type === "mission" &&
            !["En attente de validation", "Réfusé"].includes(type?.status)
          );
        });
      }
      return true;
    }, [slot.items])

    return (
      <div onClick={e => e.stopPropagation()}>
        {gpxModalIsOpen && <AssignSmToGPXPathModal isOpen={gpxModalIsOpen} slot={slot} handleCloseModal={() => setGpxModalIsOpen(false)}/>}
        <DateModal
          open={ModalOpen === "date"}
          {...{ setModalOpen, slot, handleSendModify, campaign }}
        />
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
        >
          <MoreVertIcon style={{ width: "15px", color: "black" }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transitionDuration={0}
        >
          <MenuItem onClick={(e) => {
            e.stopPropagation()
            setModalOpen("date")
          }}>
            <SettingsIcon style={{ marginRight: 10 }} />
            settings slot
          </MenuItem>
          <MenuItem onClick={(e) => {
            e.stopPropagation()
            setModalAssignOpen(slot.id)
          }}>
            <AssignerSMLogo fill="black" stroke="black" style={{ marginRight: 10, width: 24, height: 24 }} />
            Ajouter un SM
          </MenuItem>
          <Divider />
          <MenuItem onClick={(e) => {
            e.stopPropagation()
            handleAddKml(slot)
          }}>
            <NoteAddIcon style={{ marginRight: 10 }} />
            Importer KML/CSV
          </MenuItem>
          <MenuItem disabled={!isGpxImportEnabled} onClick={(e) => {
            e.stopPropagation()
            setGpxModalIsOpen(true)
          }}>
            <VisibilityLocationPathOffIcon style={{ marginRight: 10 }} />
            Importer un tracé (GPX)
          </MenuItem>
          <MenuItem onClick={removeSlot}>
            <DeleteRoundedIcon color="error" style={{ marginRight: 10 }} />
            Supprimer le Slot
          </MenuItem>
          <MenuItem onClick={changeVisibility}>
            {slotIsVisible
              ? (
              <>
                <VisibilityIcon style={{ marginRight: 10 }} />
                Cacher le contenu
              </>
                )
              : (
              <>
                <VisibilityOffIcon />
                Afficher le contenu
              </>
                )}
          </MenuItem>
        </Menu>
        <ConfirmationModal {...{ openValidation, setOpenValidation, validAction }}/>
        <ModalWaitingSend open={waitingSend} />
        <AssignSmModal action={handleAssignSm} open={!!modalAssignOpen} close={() => setModalAssignOpen(false)} loading={loading} />
      </div>
    );
  }
);

export default MenuDialog;
