/* eslint-disable react/prop-types */
import { Box, Modal } from '@mui/material'
import React, { createContext, useState, useContext } from 'react'
import './style.scss'

const RightModalContext = createContext()

export const RightModalProvider = ({ children }) => {
  const [modalStack, setModalStack] = useState([])

  const closeModal = () => {
    if (modalStack?.length > 0 && modalStack[modalStack?.length - 1]?.action) { modalStack[modalStack?.length - 1]?.action() } else {
      setModalStack((prev) => {
        const updatedStack = [...prev]
        updatedStack.pop()
        return updatedStack
      })
    }
  }

  const addToStack = (item) => setModalStack((prev) => [...prev, item])

  const replaceLastStackAction = (action) => setModalStack((prev) => {
    const updatedStack = [...prev]
    updatedStack[updatedStack?.length - 1].action = action
    return updatedStack
  })

  const removedLastStack = () => setModalStack((prev) => {
    const updatedStack = [...prev]
    updatedStack.pop()
    return updatedStack
  })

  return (
    <RightModalContext.Provider value={{ addToStack, replaceLastStackAction, removedLastStack }}>
      {modalStack.length > 0 && (
        <Modal
          open={true}
          onClose={closeModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className="slot-card-container"
        >
          <Box className="slot-card-content">
            {modalStack[modalStack?.length - 1]?.component}
          </Box>
        </Modal>
      )}
      {children}
    </RightModalContext.Provider>
  )
}

export const useRightModal = () => useContext(RightModalContext)
export const useRightModalMapLayout = () => useContext(RightModalContext)
