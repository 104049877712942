/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { IMG_SHOW_REEL } from '../../utils/imageUrls'
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded'
import './videoPlayer.scss'

const VideoPlayer = ({ video, url, height, fullWidth = false, width }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const handleIsPlaying = () => setIsPlaying(true)

  useEffect(() => {
    setIsPlaying(false)
  }, [])

  const videoSrc = {
    iframe: <iframe src={url?.replace('watch?v=', 'embed/')} className="lazyload"
      title="YouTube video player" allow="autoplay" allowFullScreen>
    </iframe>,
    thumbnail: IMG_SHOW_REEL
  }

  return (
    <div style={{ backgroundColor: 'white', width: '100%' }}>
      <div className="video_bloc" style={{ maxWidth: !fullWidth ? 1440 : 'unset', width, margin: 'auto' }}>
        <div style={{ height }}>
          <div className={'v_videoPlayer'}>
            {
              !isPlaying
                ? <div className={'thumbnail'} style={{ height: '100%' }} onClick={handleIsPlaying}>
                  <img src={video ? video?.thumbnail : videoSrc?.thumbnail} className={'thumbnailImg'} alt="thumbnail video" />
                  <div className={'playIcon'}><PlayCircleRoundedIcon /></div>
                </div>
                : <div style={{ width: '100%', height: '100%' }}>
                  {video ? video?.iframe : videoSrc?.iframe}
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoPlayer
