/* eslint-disable react/prop-types */
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { BiHide } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { activateUseCase } from '../../UsecaseCMS/functions/apiFunctions'
import './caseElement.scss'
import './animation.scss'

const CaseElement = ({ data, indexColumn, indexRow, removeUsecase, setUseCaseList }) => {
  const navigation = useNavigate()
  const [isVisible, setIsVisible] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const caseRef = useRef(null)

  const cardOver = (visibility) => {
    isVisible && (caseRef.current.style.visibility = visibility)
  }

  const handleModify = (e) => {
    navigation(`creation?id=${data?.id}`)
  }

  const handleDelete = (e) => {
    e?.stopPropagation()
    removeUsecase(data?.id)
  }

  const handleDisableCase = (e) => {
    e?.stopPropagation()
    setIsVisible(false)
    activateUseCase(data?.id, false)
  }

  useEffect(() => {
    setIsLoaded(false)
    if (isLoaded) {
      setIsVisible(data?.visible)
      !data?.visible && (caseRef.current.style.visibility = 'visible')
    }
    setIsLoaded(true)
  }, [isLoaded])

  const handleEnable = (e) => {
    e?.stopPropagation()
    activateUseCase(data?.id, true)
    setIsVisible(true)
  }

  return (
    <div className='card-container draw meet'>
      <Card className='card' onMouseEnter={() => cardOver('visible')} onMouseLeave={() => cardOver('hidden')}>
        <div onClick={(e) => handleModify(e)} ref={caseRef} id={`card-${indexColumn}-${indexRow}`} className="card_over">
          <Button className='remove' variant='contained' color='error' onClick={handleDelete}>Supprimer</Button>
          <Button className='disable' variant='contained' color='warning' onClick={handleDisableCase}
            disabled={!isVisible} >Désactiver</Button>
        </div>
        {!isVisible && <div className='disable-icon' onClick={handleEnable}>
          <section>
            <div className="section-animation spin circle">
              <BiHide size={50} color={'white'} />
            </div>
          </section>
        </div>}
        <CardMedia className='media' sx={{ height: '80%' }} image={data?.cover_url} title="useCase title" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {data?.name}
          </Typography>
          <Typography variant="body2" color="red">
            {data?.service}
          </Typography>
        </CardContent>

      </Card>
    </div>
  )
}

export default CaseElement
// height: (200 + (Math.cos(indexColumn / 15 * Math.PI * 2) + .66 * 50 * Math.PI + indexColumn * 10)),
