import { Draggable } from "react-beautiful-dnd";

const DraggableItem = (props) => {
  return (
    <Draggable draggableId={props.draggableId} index={props.index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {/* <h4>My draggable</h4> */}
            {props.children}
          </div>
        )}
      </Draggable>
  )
}

export default DraggableItem
