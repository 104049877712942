/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { TextField } from '@mui/material'
import React from 'react'
// import API from 'services/axios-config'
// import { FileUploadComponent } from 'views/Admin/Shared/Forms/FileUpload'

// const headers = { 'Content-Type': 'multipart/form-data' }

const ModifyInfoSM = ({ campaignData, handleChangeCampaign }) => {
  const [description, setDescription] = React.useState("")

  // const updateImageCampaign = (e) => {
  //   const formData = new FormData()
  //   formData.append('picture', e)
  //   setIsLoad(false)
  //   API.post(
  //     `/campaigns/${campaignData?.campaign?.id}/change_campaign_image`,
  //     formData,
  //     { headers }
  //   )
  //     .then((res) => {
  //       const imageUrl = URL.createObjectURL(e)
  //       handleChangeCampaign({ picture_url: imageUrl }, 'campaign')
  //     })
  //     .catch(() => {})
  //     .finally(() => setIsLoad(true))
  // }

  // const url_formatted = (url) => process.env.REACT_APP_BACK_API + url

  const handleChangeCampaignInfoSM = (e) => {
    if (e?.description) { handleChangeCampaign({ description: e?.description }, 'campaign') }
  }

  React.useEffect(() => {
    if (campaignData?.campaign?.description) setDescription(campaignData?.campaign?.description)
  }, [campaignData])
  return (
    <>
      <div
        style={{
          fontWeight: 700,
          fontSize: 20,
          color: 'black',
          margin: '20px 0'
        }}
      >
        POUR LES SM
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 35 }}>
        <TextField
          multiline
          fullWidth
          rows={7}
          name="description"
          value={description}
          onChange={(e) => {
            handleChangeCampaignInfoSM({ description: e.target.value })
            setDescription(e.target.value)
          }}
          label="Description pour les SM"
          />
        {/* <FileUploadComponent
          name="image_url"
          value={
            !campaignData?.campaign?.picture_url &&
            !campaignData?.campaign?.picture_url?.includes('http')
              ? null
              : campaignData?.campaign?.picture_url?.includes('http')
                ? campaignData?.campaign?.picture_url
                : url_formatted(campaignData?.campaign?.picture_url)
          }
          handleChangeFile={(e) => updateImageCampaign(e)}
          formats="image/*"
          label="Photo de campagne"
          isLoad={isLoad}
        /> */}
      </div>
    </>
  )
}

export default ModifyInfoSM
