/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import { HeaderBloc } from '../components/HeaderImage/headerImage'
import Footer from '../components/Footer/footer'
import Navbar from '../components/NavBar/navbar'
import VideoPlayer from '../components/VideoPlayer/videoPlayer'
import './vitrineContainer.scss'

const VitrineContainer = ({ hidden = false, videoUrl, Sections, Introduction }) => {
  const [windowScroll, setWindowScroll] = useState(0)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    const handleScroll = () => {
      setWindowScroll(window.pageYOffset)
    }
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const zoom = windowSize?.width < 1200 ? windowSize?.width / 1200 : 1
  const memoizedBody = useMemo(() => <Sections />, [])
  return (
    <div id='vitrineFFY' style={{ zoom, transformOrigin: 'top left', width: '100%' }}>
      <div style={{ position: '', maxWidth: hidden ? '1440px' : 'unset', margin: 'auto' }}>
        {hidden
          ? <HeaderBloc Introduction={Introduction} height={windowSize?.height} />
          : <VideoPlayer height={windowSize?.height} url={videoUrl} fullWidth={true} />
        }
        <Navbar windowHeight={windowSize?.height} scroll={windowScroll} windowWidth={windowSize?.width} />
        <div className='sections' style={{
          zIndex: 11,
          position: 'absolute',
          top: `${windowSize?.height - (windowScroll)}px`,
          left: 0,
          right: 0
        }}>
          {memoizedBody}
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default VitrineContainer
