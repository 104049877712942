/* eslint-disable react/prop-types */
import { Marker, Polyline } from '@react-google-maps/api'
import React from 'react'
import { wktToPath } from '../../../functions/functionsMap'
import './style.scss'

const Clickedmap = ({ selectedPolygon, clickedLocation }) => {
  return (
        <>
        {selectedPolygon && ( // Affiche le curseur si une position est sélectionnée
            <Polyline
              path={wktToPath(selectedPolygon[0])}
              options={polylineOptions}
            />
        )}
          {clickedLocation && ( // Affiche le curseur si une position est sélectionnée
            <Marker position={{ lat: clickedLocation?.geometry?.location.lat(), lng: clickedLocation?.geometry?.location?.lng() }} />
          )}
        </>
  )
}

const polylineOptions = {
  strokeColor: 'transparent', // Couleur de la bordure
  strokeWeight: 2, // Épaisseur de la bordure
  icons: [
    {
      icon: {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        strokeColor: 'blue', // Couleur du tiret
        scale: 2, // Longueur du trait du tiret
        strokeWeight: 3 // Épaisseur du tiret
      },
      offset: '0',
      repeat: '10px' // Espacement entre les tirets
    }
  ]
}

export default Clickedmap
