import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { memo, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'
import { ModalWaitingSend } from 'views/Admin/Views/Cartes/Views/CreateCampaign/LeftLayout/modal-waiting-send'

const MenuDialog = memo((props) => {
  const { setPrestaIsActive, prestaIsActive, currentPresta } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const { handleAddKml, handleGenerateKml, waitingSend, updateItemsVisibility } = useCampaignMap()
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleVisuPresta () {
    const allItems = [
      ...(currentPresta.slots || []).flatMap(slot => slot.items || []),
      ...(currentPresta.initials || [])
    ];
    updateItemsVisibility(allItems, !prestaIsActive);
    setPrestaIsActive(!prestaIsActive)
    setAnchorEl(null)
  }

  return (
      <div>
        <IconButton
         style={{ width:"4px", color:'black' }}
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          style={{ zIndex: 99999 }}
          transitionDuration={0}
        >
          <MenuItem onClick={handleVisuPresta}>
            {prestaIsActive ? <VisibilityIcon style={{ marginRight: 10 }}/> : <VisibilityOffIcon style={{ marginRight: 10 }}/>}
            {prestaIsActive ? "Cacher les items" : "Afficher les items"}
          </MenuItem>
          <MenuItem onClick={() => handleAddKml(currentPresta)}><NoteAddIcon style={{ marginRight: 10 }}/>Importer KML/CSV</MenuItem>
          <MenuItem onClick={() => handleGenerateKml(currentPresta)}><FileDownloadIcon style={{ marginRight: 10 }}/>Télécharger au format KML</MenuItem>
        </Menu>
        <ModalWaitingSend open={waitingSend} />
      </div>
  )
})

export default MenuDialog
