/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { memo, useEffect, useState } from 'react'
import ContentMapView from './contentMaps/index.js'
import API from 'services/axios-config.js'
// import { storePrestations } from '_redux/Map/reducers'
import { useSearchParams } from 'react-router-dom'
// import { useDispatch } from 'react-redux'

// eslint-disable-next-line react/display-name
const ChildrenMaps = memo((props) => {
  const { zoom, mapRef } = props

  const [searchParams] = useSearchParams()
  const campaign_id = searchParams.get('campaign_id')
  // const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    API.get(`/campaigns/${campaign_id}/zoned_prestations`)
      .then((res) => {
        // dispatch(storePrestations(res?.data))
      })
      .catch((_) => console.log('Une erreur est survenu'))
      .finally(_ => setIsLoading(false))
  }, [campaign_id])

  return (
    <div style={{ zoom }}>
      {!isLoading && <ContentMapView {...{ mapRef }}/>}
    </div>
  )
})

export default ChildrenMaps
