/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Popover, Skeleton, Modal } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// import { ReactComponent as ChatLogo } from 'assets/chat.svg'
import { ReactComponent as AnomaliesLogo } from 'assets/anomalies.svg'
import { ReactComponent as MapLogo } from 'assets/global_map.svg'
import { ReactComponent as FileSvg } from 'assets/file.svg'
import { ReactComponent as PhotosLogo } from 'assets/photos.svg'
import { ReactComponent as SettingsLogo } from 'assets/settings.svg'
import './style.scss'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import API from 'services/axios-config'

import { useDispatch } from 'react-redux'
import { enqueueSnackbar } from 'notistack'
import { deleteCampaignAction } from '_redux/Campaigns/reducers'
import AlertDialog from 'components/DialogBox'
import { saveAs } from 'file-saver'
import ComponentGaleriePhoto from 'views/Slot/SlotCard/ComponentSlotCardView/ComponentGaleriePhoto'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import { PrestationDocuments } from 'views/Campaign/CampaignCard/PrestationsView/PrestationBlock'
import JSZip from 'jszip'

const RightContent = (props) => {
  const { campaignData, loading } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const { addToStack } = useRightModal()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const open = Boolean(anchorEl)
  const [documentsOpen, setDocumentsOpen] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openMaps = () => {
    const existingQueryParams = new URLSearchParams(window.location.search)
    existingQueryParams.set('maps', 'true')
    navigate(`?${existingQueryParams.toString()}`)
  }

  const handleDeleteCampaign = () => {
    API.delete(`campaigns/${campaignData?.campaign.id}`)
      .then((res) => {
        dispatch(deleteCampaignAction({ id: campaignData?.campaign.id }))
        navigate('')
      })
      .catch((err) => {
        enqueueSnackbar('La suppression de la campagne à échoué : ' + err)
      })
  }

  const download_anomalies = () => {
    API.post(`/campaigns/${campaignData?.campaign.id}/spots/generate_anomalies_csv`)
      .then((response) => {
        const csvDataByPrestation = response.data;
        const zip = new JSZip();
        Object.keys(csvDataByPrestation).forEach((prestationId) => {
          const blob = new Blob([csvDataByPrestation[prestationId]], {
            type: 'application/csv'
          });
          zip.file(
            `${campaignData?.campaign.campaign_name || 'campagnes'}_anomalies_prestation_${prestationId}.csv`,
            blob
          );
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${campaignData?.campaign.campaign_name || 'campagnes'}_anomalies.zip`);
        });
      })
      .catch((error) => {
        enqueueSnackbar('Erreur lors du téléchargement du fichier :', error);
      });
  };

  const download_remarques = () => {
    API.post(`/campaigns/${campaignData?.campaign.id}/spots/generate_remarques_csv`)
      .then((response) => {
        const csvDataByPrestation = response.data;
        const zip = new JSZip();
        Object.keys(csvDataByPrestation).forEach((prestationId) => {
          const blob = new Blob([csvDataByPrestation[prestationId]], {
            type: 'application/csv'
          });
          zip.file(
            `${campaignData?.campaign.campaign_name || 'campagnes'}_remarques_prestation_${prestationId}.csv`,
            blob
          );
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `${campaignData?.campaign.campaign_name || 'campagnes'}_remarques.zip`);
        });
      })
      .catch((error) => {
        enqueueSnackbar('Erreur lors du téléchargement du fichier :', error);
      });
  };

  function handleDocuments (e) {
    e.stopPropagation()
    setDocumentsOpen(true)
  }

  const haveAnomlies = React.useMemo(() => {
    return campaignData?.prestations?.some(prestation => prestation.anomalies_count > 0) ?? false;
  }, [campaignData])

  const haveRemarques = React.useMemo(() => {
    return campaignData?.prestations?.some(prestation => prestation.remarques_count > 0) ?? false;
  }, [campaignData])

  return (
    <div className="right-content">
      {campaignData?.campaign && <Modal open={documentsOpen} onClose={() => setDocumentsOpen(false)}>
        <PrestationDocuments
          type="campaign"
          campaign={campaignData?.campaign}
          documents={campaignData?.documents}
        />
      </Modal>}
      <AlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        dialogType="deleteCampaignModify"
        action1={handleDeleteCampaign}
      />
      {!loading
        ? (
        <div className="settings-btn">
          <SettingsLogo
            style={{ width: 20, height: 20 }}
            fill="black"
            onClick={handleClick}
          />
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <div className="popover-content">
              {campaignData?.campaign?.devis?.id && <div
                className="popover-item-red"
                onClick={() => navigate(`/admin/campagnes?devis_id=${campaignData?.campaign?.devis?.id}`)}>
                  VOIR LE DEVIS INITIAL
              </div>}
              <div
                className="popover-item-red"
                onClick={() => setAlertOpen(true)}
              >
                SUPPRIMER
              </div>
              {/* <div className="popover-item-red">VOIR LES FACTURES</div> */}
            </div>
          </Popover>
        </div>
          )
        : (
        <div className="settings-btn">
          <Skeleton variant="circular" width={30} height={30} />
        </div>
          )}
      <div className="table-btn">
        {/* {!loading
          ? (
          <BlackBtn
            label="CHAT DE CAMPAGNE"
            icon={<ChatLogo style={{ width: 20, height: 20 }} fill="white" />}
            styles={{ width: 'auto' }}
          />
            )
          : (
          <Skeleton
            variant="rounded"
            width={230}
            height={52}
            sx={{ borderRadius: 50 }}
          />
            )} */}
        {!loading
          ? (
          <BlackBtn
            label="REMARQUES"
            icon={
              <AnomaliesLogo style={{ width: 20, height: 20 }} fill="white" />
            }
            styles={{
              width: 'auto',
              display: !haveRemarques ? 'none' : null
            }}
            handleAction={() => download_remarques()}
          />
            )
          : (
          <Skeleton
            variant="rounded"
            width={230}
            height={52}
            sx={{ borderRadius: 50 }}
          />
            )}
        {!loading
          ? (
          <BlackBtn
            label="ANOMALIES"
            icon={
              <AnomaliesLogo style={{ width: 20, height: 20 }} fill="white" />
            }
            styles={{
              width: 'auto',
              display: !haveAnomlies ? 'none' : null
            }}
            handleAction={() => download_anomalies()}
          />
            )
          : (
          <Skeleton
            variant="rounded"
            width={230}
            height={52}
            sx={{ borderRadius: 50 }}
          />
            )}
            {!loading
          ? (
          <BlackBtn
            label="RAPPORT CLIENT"
            icon={<FileSvg style={{ width: 20, height: 20 }} fill="white" />}
            styles={{ width: 'auto' }}
            handleAction={handleDocuments}
          />
            )
          : (
          <Skeleton
            variant="rounded"
            width={230}
            height={52}
            sx={{ borderRadius: 50 }}
          />
            )}
        {!loading
          ? (
          <BlackBtn
            label="CARTE GLOBALE"
            icon={<MapLogo style={{ width: 20, height: 20 }} fill="white" />}
            styles={{ width: 'auto' }}
            handleAction={openMaps}
          />
            )
          : (
          <Skeleton
            variant="rounded"
            width={230}
            height={52}
            sx={{ borderRadius: 50 }}
          />
            )}
        {!loading
          ? (
          <BlackBtn
            label="PHOTOS"
            icon={<PhotosLogo style={{ width: 20, height: 20 }} fill="white" />}
            styles={{
              width: 'auto',
              display: campaignData?.have_picture === 0 ? 'none' : null
            }}
            handleAction={() => addToStack({ component: <ComponentGaleriePhoto campaignId={campaignData?.campaign.id} /> })}
          />
            )
          : (
          <Skeleton
            variant="rounded"
            width={230}
            height={52}
            sx={{ borderRadius: 50 }}
          />
            )}
      </div>
    </div>
  )
}

export default RightContent
