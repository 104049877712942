/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import {
  FormControl,
  Autocomplete,
  TextField,
  createFilterOptions
} from '@mui/material'

import form from '../form.module.scss';
import React, { useState, useEffect } from 'react';

const filter = createFilterOptions();

export const SelectObjectsComponent = ({
  value = null,
  handleAction,
  label,
  items = [],
  loading = false,
  disabled = false,
  multiple = false,
  item_name = 'title',
  canAdd = false,
  getOptionLabel = () => {},
  onInputChange = () => {},
  color = 'black',
  limitTags = 1,
  disableCloseOnSelect = false,
  customRenderOption
}) => {
  const [selected, setSelected] = useState(value);
  const [options, setOptions] = useState(items);

  useEffect(() => {
    setOptions(items);
  }, [items]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <FormControl
      fullWidth
      className={`${form.formControl} ${form.selectBlockComponent} ${
        disabled ? form.disabled : ''
      }`}
    >
      <Autocomplete
        value={selected}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setSelected({
              [item_name]: newValue
            })
          } else if (newValue && newValue?.inputValue) {
            setSelected({
              [item_name]: newValue?.inputValue
            })
          } else {
            setSelected(newValue)
          }
          handleAction(newValue, event)
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          const { inputValue } = params
          const isExisting = options.some(
            (option) => inputValue === option[item_name]
          )
          if (canAdd && inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              [item_name]: `Créer :  '${inputValue}'`
            })
          } else if (
            !canAdd &&
            !options?.find((opt) =>
              opt[item_name]?.toLowerCase()?.includes(inputValue?.toLowerCase())
            )
          ) {
            filtered.push({
              [item_name]: 'Aucune correspondance'
            })
          }

          return filtered
        }}
        getOptionDisabled={(option) =>
          option[item_name] === 'Aucune correspondance'
        }
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        multiple={multiple}
        options={options}
        // onInputChange={onInputChange}
        getOptionLabel={(option) => {
          if (!option) return
          if (typeof option === 'string') {
            return option
          }
          if (option?.inputValue) {
            return option?.inputValue
          }
          return option[item_name]
        }}
        renderOption={(props, option) => (
          customRenderOption
            ? customRenderOption(props, option)
            : <li {...props}>{option[item_name]}</li>
        )}
        freeSolo
        renderInput={(params) => (
          <TextField
            className={`${form.textfield} ${
              !selected || selected?.length < 1 ? form.empty : form.full
            }`}
            {...params}
            label={label}
            InputProps={{ style: { color }, ...params.InputProps }}
          />
        )}
        disabled={disabled}
      />
    </FormControl>
  )
}
