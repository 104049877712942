import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext"
import DraggableList from "../DraggableList"
import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import API from "services/axios-config"
import './styles.scss'
import DrawingManagerView from "../../DrawingComponents/DrawingManager"
import { useSelector } from "react-redux"
import { handelIcon } from "utils/helpers"
import MenuDialog from "./MenuDialog"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const BookMark = (props) => {
  const { currentPresta, setPrestaIsActive, prestaIsActive } = props
  const { updateItemsVisibility } = useCampaignMap()

  return (
    <div className='bookmark-prestation'>
      <div className="left-content">
        {
            handelIcon({
              height: '25px',
              width: '25px',
              color: 'black',
              fill: 'black',
              stroke: 'black'
            })[currentPresta?.presta_type]
        }
        <div className="prestation-name">{currentPresta?.presta_type?.toUpperCase()}</div>
      </div>
      <div className="icons-right">
      {!prestaIsActive && <VisibilityOffIcon onClick={() => {
        const allItems = [
          ...(currentPresta.slots || []).flatMap(slot => slot.items || []),
          ...(currentPresta.initials || [])
        ];
        updateItemsVisibility(allItems, !prestaIsActive);
        setPrestaIsActive(!prestaIsActive)
      }} style={{ marginRight: 10 }}/>}
      <MenuDialog {...props}/>
      </div>
    </div>
  )
}

const PrestationLayout = (props) => {
  const [contentIsLoad, setContentIsLoad] = useState(false)
  const { setPrestationsList, prestationsList } = useCampaignMap()
  const [prestaIsActive, setPrestaIsActive] = useState(true)
  useEffect(() => {
    API.get(`prestations/${props.currentPresta.id}/slots?include_address=true`)
      .then(res => {
        const slots = res?.data
        setContentIsLoad(true)

        let finalOrderedSlots = [...slots];
        const savedSlotOrder = localStorage.getItem(`prestation-${props.currentPresta.id}-slots`);

        if (savedSlotOrder) {
          const slotOrder = JSON.parse(savedSlotOrder);
          const orderedSlots = slots.filter(slot => slotOrder.includes(slot.id));
          orderedSlots.sort((a, b) => slotOrder.indexOf(a.id) - slotOrder.indexOf(b.id));
          const remainingSlots = slots.filter(slot => !slotOrder.includes(slot.id));
          finalOrderedSlots = [...orderedSlots, ...remainingSlots];
        }

        setPrestationsList(current => current.map(presta =>
          presta?.id === props.currentPresta.id ? ({ ...presta, slots: finalOrderedSlots }) : presta
        ))
      })
    API.get(`prestations/${props.currentPresta.id}/initials`)
      .then(res => {
        setPrestationsList(current => current.map(presta =>
          presta?.id === props.currentPresta.id ? ({ ...presta, initials: res?.data }) : presta
        ))
        setContentIsLoad(true)
      })
    API.get(`prestations/${props.currentPresta.id}/spots_by_prestation`)
      .then(res => {
        setPrestationsList(current => current.map(presta =>
          presta?.id === props.currentPresta.id ? ({ ...presta, anomalies: res?.data }) : presta
        ))
        setContentIsLoad(true)
      })

  }, [])

  return (
        <div style={prestationLayoutStyle}>
          <BookMark {...props} {...{ setPrestaIsActive, prestaIsActive }}/>
          {!contentIsLoad && <CircularProgress/>}
          {contentIsLoad && <DraggableList {...{ prestaIsActive, ...props }} currentInitials={prestationsList.find(presta => presta?.id === props.currentPresta.id)?.initials} currentSlots={prestationsList.find(presta => presta?.id === props.currentPresta.id)?.slots} currentPresta={props.currentPresta}/>}
        </div>
  )
}

const Prestations = (props) => {
  const { prestationsList } = useCampaignMap()
  const slotSelected = useSelector(state => state.map.slotSelected)
  const initialSelected = useSelector(state => state.map.initialSelected)

  return (
    <div className="prestation-container">
      {(slotSelected || initialSelected) && !props.clientView && <DrawingManagerView/>}
      {prestationsList.map((value, index) => <PrestationLayout key={`current_presta_${index}`} currentPresta={value} { ...props } />)}
    </div>
  )
}

const prestationLayoutStyle = {
  pointerEvents: "all",
  marginBottom: 20
}

export default Prestations
