import { Droppable } from 'react-beautiful-dnd'
import './styles.scss'
import API from 'services/axios-config'
import { memo, useEffect, useState } from 'react'
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectSlot } from '_redux/Map/reducers'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MenuDialog from './MenuDialog'
import { ReactComponent as DropdownSvg } from "assets/dropdown-icon.svg";
import { InputChangeName } from '../components/ItemChangeTitle'
import { ReactComponent as MyPEN } from 'assets/pen.svg'

const TitleContent = memo(({ title, slot, slotIsVisible, setSlotIsVisible, setSlotOpen, slotOpen }) => {
  const slotSelected = useSelector(state => state.map.slotSelected)
  const { handleRemoveSlot, modifySlot, updateItemsVisibility } = useCampaignMap()
  const [modifyName, setModifyName] = useState(false)

  const isSelected = slotSelected?.id === slot.id

  function handleHideItems () {
    if (slot.items?.length > 0) {
      updateItemsVisibility(slot.items, !slotIsVisible);
    }
    setSlotIsVisible(!slotIsVisible)
  }

  function handleSendNewname (value) {
    API.patch(`slots/${slot.id}`, { title: value })
    modifySlot({ ...slot, title: value })
    setModifyName(false)
  }

  function handleStartModify (e) {
    e.stopPropagation()
    setModifyName(true)
  }

  return (
    <div className={`slot-title-container ${isSelected && "isSelected"} ${!slotIsVisible && 'hidden'}`} onClick={() => { (isSelected || !slotOpen) && setSlotOpen(!slotOpen) }}>
      <DropdownSvg
        className={`dropdown ${!slotOpen && "rotate"}`}
      />
      {!modifyName
        ? <div className="slot-title">{title?.toUpperCase()}</div>
        : <InputChangeName text={title} handleChange={handleSendNewname}/>
        }
      {!slotIsVisible && <VisibilityOffIcon onClick={handleHideItems} />}
      {!modifyName && <MyPEN onClick={handleStartModify}/>}
            <MenuDialog {...{ setSlotIsVisible, slotIsVisible, handleRemoveSlot, slot }}/>
    </div>
  )
})

const SlotView = (props) => {
  const { children, currentSlot, slotIsVisible, setSlotIsVisible, slotOpen, setSlotOpen, clientView } = props
  const { setPrestationsList } = useCampaignMap()

  const dispatch = useDispatch()
  useEffect(() => {
    const mergeAndOrderItems = (items, savedSlotOrder) => {
      const itemsMap = items?.reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, {});

      const orderedItems = savedSlotOrder
        .map(slot => itemsMap[slot.id])
        .filter(Boolean);

      const remainingItems = items.filter(slot => !savedSlotOrder.find(saved => saved.id === slot.id));
      return [...orderedItems, ...remainingItems];
    };

    const savedSlotOrder = JSON.parse(localStorage.getItem(`slot-${currentSlot?.id}-items`));

    Promise.all([
      API.get(`slots/${currentSlot?.id}/drawing_polygon`),
      API.get(`slots/${currentSlot?.id}/drawing_spot`),
      API.get(`slots/${currentSlot?.id}/drawing_polyline`)
    ]).then(([polygonRes, spotRes, polylineRes]) => {
      const allItems = [...polygonRes.data, ...spotRes.data, ...polylineRes.data];
      let finalOrderedItems
      if (savedSlotOrder) {
        finalOrderedItems = mergeAndOrderItems(allItems, savedSlotOrder);
      } else {
        finalOrderedItems = allItems
      }
      setPrestationsList(current => current?.map(presta => {
        if (presta?.id === currentSlot?.prestation_id) {
          return {
            ...presta,
            slots: presta.slots?.map(slot =>
              slot.id === currentSlot.id
                ? { ...slot, items: slot.items ? [...slot.items, ...finalOrderedItems] : finalOrderedItems }
                : slot
            )
          };
        } else {
          return presta;
        }
      }));
    })
  }, [])

  useEffect(() => {
    setSlotOpen(slotIsVisible)
  }, [slotIsVisible])

  return (<div className="slot-container">
    <Droppable droppableId={`droppable-${currentSlot.id}`}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          onClick={() => dispatch(selectSlot(currentSlot))}
        >
          {!clientView && <TitleContent {...props} title={currentSlot?.title || "Nouveau slot"} slot={currentSlot} {...{ slotIsVisible, setSlotIsVisible, setSlotOpen, slotOpen }} />}
          <div className={`children ${!slotOpen && "hidden"}`}>{children}</div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </div>)
}

export default SlotView
