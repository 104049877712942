/* eslint-disable react/prop-types */
import React from 'react'
import { TableBody } from '@mui/material'
import Row from './row'

const BodyTable = (props) => {
  const { list, columns, handleActions, closeAll, setCloseAll } = props

  return (
      <TableBody style={{ overflow: 'scroll' }}>
        {list
          .map((row, index) => {
            return <Row key={`current-row-${index}`} {...{ columns, row, handleActions, closeAll, setCloseAll }} onClick={() => console.log('CLICK')}/>
          })}
      </TableBody>
  )
}

export default BodyTable
