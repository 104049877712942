import { Polyline, Circle } from "@react-google-maps/api";
import { memo, useEffect, useRef, useState } from "react";
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'

export const DrawPolyline = memo(
  ({ params, setIsHover, currentMission, handleClickItem }) => {
    const ref = useRef();
    const { mapRef } = useCampaignMap()
    const [zoom, setZoom] = useState(10);
    const handleZoomChanged = () => {
      if (mapRef.current) {
        setZoom(mapRef.current.getZoom());
      }
    };

    useEffect(() => {
      if (mapRef.current) {
        mapRef.current.addListener('zoom_changed', handleZoomChanged);
      }
    }, [mapRef]);

    return (
      <>
        <Polyline
          ref={ref}
          {...params}
          onMouseUp={() => handleClickItem(currentMission)}
          onMouseOver={() => setIsHover(true)}
          onMouseOut={() => setIsHover(false)}
          zIndex={100}
        />
        {zoom > 16 && params?.circles?.start && <Circle {...params.circles.start} key={`${params.circles.start.key}-${zoom}`} zIndex={999} />}
        {zoom > 16 && params?.circles?.end && <Circle {...params.circles.end} key={`${params.circles.end.key}-${zoom}`} zIndex={999} />}
      </>
    );
  }
);
