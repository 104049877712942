import { kmlWithFolders } from '@tmcw/togeojson'
import tokml from 'geojson-to-kml'
import toGeoJSON from '@mapbox/togeojson'
import * as turf from '@turf/turf';

export const toWKT2 = (polyline) => {
  const coordinates = polyline?.getPath()?.getArray()?.map((path) => `${path?.lng()} ${path?.lat()}`)
  const wkt = `LINESTRING(${coordinates?.join(', ')})`
  return wkt
}

export const toWKT = (polygon) => {
  const coordinates = polygon?.getPath()?.getArray()?.map((path, i) => [`${path?.lng()} ${path?.lat()}`])
  const wkt = `POLYGON ((${coordinates?.join(', ')}, ${coordinates[0]}))`
  return wkt
}

export const toWKTbis = (polygon) => {
  const coordinates = polygon?.map((path, i) => {
    console.log(`${path[0]} ${path[1]}`)
    return [`${path[0]} ${path[1]}`]
  })
  const wkt = `POLYGON ((${coordinates?.join(', ')}, ${coordinates[0]}))`
  return wkt
}

export const fromGeoJSONtoWKT = (geometry) => {
  const coordinates = geometry?.coordinates[0].map((coords, i) => [`${coords[0]} ${coords[1]}`])
  return `${geometry?.type?.toUpperCase()}((${coordinates?.join(', ')}, ${coordinates[0]}))`
}

export const wktToPath = (coordinates, type = 'polygon') => {
  if (type === 'polygon') {
    return coordinates?.slice(0, -1)?.reduce((result, nextcoords) => {
      const path = { lng: nextcoords[0], lat: nextcoords[1] }
      result?.push(path)
      return result
    }, [])
  } else if (type === 'line') {
    return coordinates?.reduce((result, nextcoords) => {
      const path = { lng: nextcoords[0], lat: nextcoords[1] }
      result?.push(path)
      return result
    }, [])
  }
}

export const wktToCoordinates = (coordinates) => {
  return coordinates?.reduce((result, nextcoords) => {
    const path = { lng: nextcoords[0], lat: nextcoords[1] }
    result?.push(path)
    return result
  }, [])
}

export const coordinatesToLineString = (coordinates) => {
  return coordinates?.reduce((result, nextcoords) => {
    const path = [nextcoords?.coords?.longitude, nextcoords?.coords?.latitude]
    result?.push(path)
    return result
  }, [])
}

// "POLYGON Z ((2.4174095458984413 49.00680821013106 0, 2.2855736083984413 49.06532817834787 0, 2.3762108154296913 49.105801677620796 0, 2.5451256103515663 49.04912954122409 0, 2.4174095458984413 49.00680821013106 0))"

export const wktToReadableCoordinates = (coordinates) => {
  const regex = /([0-9.-]+) ([0-9.-]+)/g
  const positions = []
  let match

  while ((match = regex.exec(coordinates))) {
    positions.push([parseFloat(match[1]), parseFloat(match[2])])
  }

  return positions
  // return coordinates?.reduce((result, nextcoords) => {
  //   const path = { lng: nextcoords[0], lat: nextcoords[1] }
  //   result?.push(path)
  //   return result
  // }, [])
}

export const fromKMLtoGeoJSON = async (file) => {
  const response = await fetch(URL.createObjectURL(file))
  const xmlString = await response.text()
  const parser = new DOMParser()
  const xml = parser.parseFromString(xmlString, 'text/xml')
  const geojson = kmlWithFolders(xml)
  return geojson
}

export const fromGPXtoGeoJSON = async (file) => {
  const response = await fetch(URL.createObjectURL(file))
  const xmlString = await response.text()
  const parser = new DOMParser()
  const xml = parser.parseFromString(xmlString, 'text/xml')
  const testing = toGeoJSON.gpx(xml)
  return testing
}

export const fromGeoJSONToZones = (res) => {
  return res?.features?.reduce((result, nextFeature) => {
    const zone = { type: '', paths: [], propreties: {} }
    zone.type = nextFeature?.geometry?.type
    zone.propreties = nextFeature?.properties
    nextFeature?.geometry?.coordinates[0].forEach((coords, index) => {
      zone?.paths?.push({ lat: coords[1], lng: coords[0] })
    })
    result?.push(zone)
    return result
  }, [])
}

export const fromZonesToGeoJSON = (items, template, type) => {
  const features = items?.map((item) => {
    const geometry = { type: '', coordinates: [[]] }
    if (item?.type === 'zones' || type === 'zones') {
      geometry.type = 'Polygon'
      geometry.coordinates = [wktToReadableCoordinates(item?.polygon)]
    } else if (item?.type === 'spots' || type === 'spots') {
      geometry.type = 'Point'
      geometry.coordinates = [[item?.address.longitude, item?.address.latitude]]
    } else if (type === 'direction') {
      geometry.type = 'LineString'
      geometry.coordinates = coordinatesToLineString(item?.location?.positions)
    }
    return ({
      type: 'Feature',
      geometry,
      properties: {
        name: item?.title || 'new_item',
        stroke: '#555555',
        'stroke-opacity': 1.0,
        'stroke-width': 2,
        fill: '#555555',
        'fill-opacity': 0.5
      }
    })
  }, [])
  template.features = [...template.features, ...features]
  return template
}

export const fromZoneToGeoJSON = (item, template, type) => {
  const geometry = { type: '', coordinates: [[]] }
  if (item?.type === 'zones' || type === 'zones') {
    geometry.type = 'Polygon'
    geometry.coordinates = [wktToReadableCoordinates(item?.polygon)]
  } else if (item?.type === 'spots' || type === 'spots') {
    geometry.type = 'Point'
    geometry.coordinates = [[item?.address.longitude, item?.address.latitude]]
  } else if (type === 'direction') {
    geometry.type = 'LineString'
    geometry.coordinates = coordinatesToLineString(item?.location?.positions)
  }
  const features = ({
    type: 'Feature',
    geometry,
    properties: {
      name: item?.title || 'new_item',
      stroke: '#555555',
      'stroke-opacity': 1.0,
      'stroke-width': 2,
      fill: '#555555',
      'fill-opacity': 0.5
    }
  })
  template.features.push(features);
  return template
}

export const fromMissionsToGeoJSON = (fullMissionList = []) => {
  const template = []
  fullMissionList?.forEach((mission) => {
    template?.push({
      id: mission?.id,
      name: mission?.name,
      zones: fromZonesToGeoJSON(mission)
    })
  })
  return template
}

export const fromGeoJSONToKML = (geojson) => {
  const kmlDocumentName = tokml(geojson, {
    documentName: 'Zones',
    documentDescription: 'Zones as kml file',
    simplestyle: true
  })
  return (kmlDocumentName)
}

export const normalizeZIndex = (currentValue) => {
  const minZIndex = 1;
  const maxZIndex = 2147483647;
  const value = Math.round(currentValue)

  // If currentValue is negative or zero, return maxZIndex
  if (value <= 0) return maxZIndex;

  // Normalize value to a zIndex value within the defined range
  const maxExpectedValue = 90000000000;

  // Calculate the normalized zIndex
  const normalizedZIndex = ((maxExpectedValue - Math.min(value, maxExpectedValue)) / maxExpectedValue) * (maxZIndex - minZIndex) + minZIndex;

  return Math.round(normalizedZIndex);
};

export const calculatePolygonAreaWithTurf = (paths) => {
  // Ensure the polygon is closed by adding the first point at the end if it's not already there
  if (paths[0].lng !== paths[paths.length - 1].lng || paths[0].lat !== paths[paths.length - 1].lat) {
    paths.push(paths[0]); // Close the polygon by adding the first point again
  }

  const polygon = turf.polygon([paths.map(coord => [coord.lng, coord.lat])]);
  const area = turf.area(polygon);
  return area;
};

export const calculateArea = (paths) => {
  return (window?.google.maps.geometry.spherical.computeArea(paths) / 1000000).toFixed(2)
}

export const calculateLength = (paths) => {
  return (window?.google.maps.geometry.spherical.computeLength(paths) / 1000).toFixed(2)
}

export const calculateCenter = (polygon) => {
  const bounds = new window.google.maps.LatLngBounds()
  polygon?.getPath()?.getArray()?.forEach((coord) => bounds.extend(coord))
  return { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() }
}

export const deleteZone = (index, list, current) => {
  list?.splice(index, 1)
  current = null
}

export const smoothZoom = (map, level, cnt, mode) => {
  if (mode === true) {
    if (cnt < level) {
      const z = window.google.maps.event.addListener(map, 'zoom_changed', (event) => {
        window.google.maps.event.removeListener(z)
        smoothZoom(map, level, cnt + 1, true)
      })
      setTimeout(() => { map.setZoom(cnt) }, 150)
    }
  }
}

export const getDurationPath = (direction) => {
  return direction?.reduce((current, next) => {
    current.time += Math.floor(next?.duration?.value / 60)
    current.distance += (next?.distance?.value / 1000)
    return current
  }, { time: 0, distance: 0 })
}

export const groupByPrestationId = (fullMissionList, key) => {
  return fullMissionList?.reduce((result, current, currentIndex) => {
    const property = current[key]
    if (!result[property]) result[property] = []
    const newElement = { ...current, originalIndex: currentIndex }
    result[property]?.push(newElement)
    return result
  }, {})
}

export function convertHexToRGBA (hex, opacity) {
  hex = hex || '#666666'
  opacity = opacity || 1
  hex = hex?.replace('#', '')
  const r = parseInt(hex?.substring(0, 2), 16)
  const g = parseInt(hex?.substring(2, 4), 16)
  const b = parseInt(hex?.substring(4, 6), 16)
  const rgba = `rgba(${r}, ${g}, ${b}, ${opacity})`
  return rgba
}

export const extractLocationsFromGPX = async (gpxFile) => {
  const geoJson = await fromGPXtoGeoJSON(gpxFile)
  if (
    !geoJson ||
    !geoJson.features ||
    !Array.isArray(geoJson.features) ||
    geoJson.features.length === 0
  ) {
    return [];
  }

  const locations = [];
  let haveTimestamps;

  geoJson.features.forEach((feature) => {
    if (!feature.properties?.coordTimes) haveTimestamps = false
    else haveTimestamps = true
    if (
      feature.geometry &&
      feature.geometry.type === 'LineString' &&
      Array.isArray(feature.geometry.coordinates)
    ) {
      const coordinates = feature.geometry.coordinates;
      const coordTimes = feature.properties?.coordTimes;
      coordinates.forEach((coord, index) => {
        if (Array.isArray(coord) && coord.length >= 2) {
          const [longitude, latitude] = coord;
          const timestamp =
            coordTimes && coordTimes[index] ? coordTimes[index] : null;

          locations.push({
            latitude,
            longitude,
            timestamp,
          });
        }
      });
    }
  });

  return { locations, haveTimestamps };
}
