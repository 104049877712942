import React, { useEffect, useState } from "react";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from "@mui/material";
import { ReactComponent as EmptyStarSvg } from 'assets/star2.svg'
import FilterComponent from 'components/GlobalInputs/Filters'
import './style.scss'
import { SelectAddressComponent } from 'components/SelectAddressComponent'
import API from "services/axios-config";

const SpotsListModal = ({ isOpen, handleClose, spots }) => {
    const [spotsTypesList, setSpotsTypesList] = useState([]);

    useEffect(() => {
        API.get('/spots/get_spot_types')
        .then((res) => {
            if (res?.data?.spot_types) {
                setSpotsTypesList(res.data.spot_types)
            }
        })
    }, [])

    /* FOR FILTERS */
    const [selectedFilters, setSelectedFilters] = useState({
        spotTypes: [],
        cities: [],
        noteSm: null,
    });

    const AddressAutoComplete = ({ handleUpdateFilter }) => {
        const [, setValue] = useState('');

        function handleSelectValue(value) {
            setValue(value)
            handleUpdateFilter(value)
        }

        return (
            <div style={{ margin: '0 0 20px 0' }}>
                <SelectAddressComponent address={null} onSelectAddress={(e) => handleSelectValue(e?.city)} label="RECHERCHER" onlyCities className="existing-spot-filter-popover-collapse-cities-autocomplete" />
            </div>
        );
    };

    const NoteSm = ({ handleUpdateFilter, currentFilters }) => {
        const [hoveredStar, setHoveredStar] = useState(null);

        const handleMouseEnter = (star) => {
            setHoveredStar(star);
        };

        const handleMouseLeave = () => {
            setHoveredStar(null);
        };

        const handleNoteSmStarClicked = (note) => {
            const noteSm = note === currentFilters.noteSm ? null : note;
            handleUpdateFilter(noteSm)
        }

        return (
            <div className="existing-spot-filter-popover-collapse-content">
                <div className="existing-spot-filter-popover-collapse-text">SÉLECTIONNE UNE NOTE ICI ET TU VERRAS LES SMs AVEC DES NOTES SUPÉRIEURES OU ÉGALE.</div>
                <div className="existing-spot-filter-popover-collapse-stars-container">
                    {[1, 2, 3, 4, 5].map((star) => (
                        <EmptyStarSvg
                            key={star}
                            style={{ width: 20, height: 20 }}
                            color={
                                hoveredStar >= star
                                    ? "#60EED280"
                                    : currentFilters.noteSm && currentFilters.noteSm >= star && !hoveredStar
                                        ? "#60EED2"
                                        : "white"
                            }
                            stroke="black"
                            onMouseEnter={() => handleMouseEnter(star)}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => handleNoteSmStarClicked(star)}
                        />
                    ))}
                </div>
            </div>)
    };

    const filters = [
        { key: 'spotTypes', label: 'TYPE DE SPOT', options: spotsTypesList, multiple: true },
        { key: 'cities', label: 'VILLE', customComponent: <AddressAutoComplete />, multiple: true },
        { key: 'noteSm', label: 'NOTE', customComponent: <NoteSm />, multiple: false }
    ];

    /* END FOR FILTERS */

    const filteredExistingSpotsList = React.useMemo(() => {
        return spots.filter(spot => {
            const { spotTypes, cities, noteSm } = selectedFilters;
            const matchesSpotTypes = !spotTypes?.length || spotTypes.includes(spot.spot_type);
            const matchesCities = !cities?.length || cities.includes(spot.address.city);
            const matchesNoteSm = noteSm == null || spot.average_note >= noteSm;

            return matchesSpotTypes && matchesCities && matchesNoteSm;
        });
    }, [spots, selectedFilters])

    const getFilledAndEmptyStarsCount = (avgNote) => {
        const totalStars = 5;
        const roundedNote = Math.round(avgNote || 0);
        const filledStars = Math.min(roundedNote, totalStars);
        const emptyStars = totalStars - filledStars;
        return { filledStars, emptyStars }
    }

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                className="spots-list-modal"
            >
                <div className="spots-list-modal-content">
                    <div className="spots-list-modal-header-container">
                        <div className="spots-list-modal-header-title">LISTE DES SPOTS ACTUELLEMENT VISIBLES</div>
                    </div>
                    <TableContainer component={Paper} sx={{ height: '100%', boxShadow: 'none' }}>
                        <div className="add-filters-btn-container">
                            <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} />
                        </div>
                        <Table sx={{
                            borderCollapse: 'separate',
                            borderSpacing: '0 8px',
                            '& .MuiTableCell-root': {
                                borderColor: 'black',
                            },
                        }}>
                            <TableHead className="sticky-header">
                                <TableRow>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Nom</TableCell>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Adresse</TableCell>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Type de spot</TableCell>
                                    <TableCell style={{ fontSize: 14, fontWeight: 800 }}>Description</TableCell>
                                    <TableCell style={{ width: 100, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Note Moye.</TableCell>
                                    <TableCell style={{ width: 100, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Nb de missions</TableCell>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Nb flyers</TableCell>
                                    <TableCell style={{ width: 100, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Cadence</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredExistingSpotsList.map((row) => {
                                    const cadence = Math.round((row?.total_hours_without_pause > 0 ? row.total_distributed_flyers / row.total_hours_without_pause : 0));
                                    return (
                                    <TableRow key={row.id} sx={{ height: 50 }}>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {row.title || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 3,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {row.address?.label || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>{row.spot_type || '-'}</TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 3,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {row.description || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center', fontWeight: 500, fontSize: 12 }}>
                                            {row.average_note != null
                                                ? <div style={{ display: 'flex', gap: 3 }}>
                                                    {Array(getFilledAndEmptyStarsCount(row.average_note).filledStars).fill().map((_, index) => (
                                                        <EmptyStarSvg key={`filled-${index}`} style={{ width: 20, height: 20 }} color="#60EED2" stroke="black" />
                                                    ))}
                                                    {Array(getFilledAndEmptyStarsCount(row.average_note).emptyStars).fill().map((_, index) => (
                                                        <EmptyStarSvg key={`empty-${index}`} style={{ width: 20, height: 20 }} color="white" stroke="black" />
                                                    ))}
                                                </div>
                                                : <div>[PAS DE NOTE]</div>}
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {row.number_of_missions ? `${row.number_of_missions} mission${row.number_of_missions > 0 ? 's' : ''}` : '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {row.total_distributed_flyers ? `${row.total_distributed_flyers} flyers distribués` : '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {cadence ? `${cadence} flyers/heure` : '-'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Modal>
        </>
    );
};

export default SpotsListModal;
