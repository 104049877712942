/* eslint-disable react/prop-types */
import React from 'react'
import { Avatar } from '@mui/material'
import { ParentContainer } from '../ParentContainer/parentContainer'
import { DivideTitle } from '../../../UsecaseCMS/components/components'
import './comments.scss'

const Comments = ({ avatar, comment, style }) => {
  return (
    <ParentContainer style={{ backgroundColor: 'white', display: 'flex' }}>
      <div className='v_comments_container' style={style}>
        <DivideTitle type={'h3'} w_title={'ILS EN PARLENT MIEUX QUE NOUS !'}
          w_style={{ fontSize: 40, color: '#23272A', textAlign: 'center' }} style={{ width: '100%' }} />
        <div className='comments-content'>
          <Avatar className='avatar' sx={{ bgcolor: '#576574' }}>
            <div className='usecase-logo'>
              <img src={avatar} alt="logo-comment"
                className='lazyload upload-logo' />
            </div>
          </Avatar>
          <div className="comments">
            <p>{comment || ''} </p>
          </div>
        </div>

      </div>
    </ParentContainer>
  )
}

export default Comments
