import { useEffect, useState } from 'react'
import { AutocompleteInput } from '../Select'
// import axios from 'axios'
import { handleGetDetails } from 'components/SelectAddressComponent/functions'
import API from 'services/axios-config'

const AddressComponent = (props) => {
  const { onSelectAddress, current, disabled } = props
  const [fetching, setFetching] = useState(false)
  const [items, setItems] = useState([])
  const [addressList, setAddressList] = useState([])
  const [value, setValue] = useState(current || '')

  useEffect(() => {
    addressList?.length > 0 && setItems(addressList.map(res => res.description))
  }, [addressList])

  useEffect(() => {
    current && setValue(current)
  }, [current])

  async function searchLocations(value) {
    API.post('call_api_address_autocomplete', { text: value })
    .then(res => setAddressList(res.data.predictions))
    .finally(_ => setFetching(false))
  }

  function handleChangeValue(value) {
    setFetching(true)
    setValue(value)
    searchLocations(value)
  }

  function handleSelectValue(value) {
    const itemSelect = addressList.find(item => item.description === value)
    setValue(value)
    handleGetDetails(itemSelect, onSelectAddress)
  }

  return (<AutocompleteInput label="Adresse" variant={"rounded"} onChange={handleChangeValue} onSelect={handleSelectValue} { ...{ items, fetching, value, disabled } }/>)
}

export default AddressComponent;
