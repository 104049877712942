/* eslint-disable react/prop-types */
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { BiHide, BiShow } from 'react-icons/bi'
import { FaEdit } from 'react-icons/fa'
import React, { cloneElement, useState } from 'react'
import { Card } from '@mui/material'
import { Cardcomponent } from '../components/components'
import { handleHideUsecase } from '../functions/apiFunctions'
import './sortable.scss'

export const SortableComponent = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [hide, setHide] = useState(props?.hide)
  const {
    attributes, listeners, setNodeRef, transform, transition
  } = useSortable({ id: props?.id })

  const style = {
    transform: CSS.Transform.toString(transform), transition
  }

  const handleHide = () => {
    setHide((prev) => !prev)
    handleHideUsecase(props?.data?.id, { visible: !hide })
  }

  const isAnImageSection = () => {
    return props?.section?.includes('image')
  }

  return (
    <div ref={setNodeRef} style={style} >
      <div className={'draggable-container'} >
        {
          hide && <div className="handle" {...attributes} {...listeners}>
            <RxDragHandleDots2 size={50} />
          </div>
        }
        <div style={{ display: hide ? 'block' : 'none' }}
          className={hide ? 'show-section fade-in' : 'fade-out'}
        >
          <Card>
            {cloneElement(props?.children, {
              id: props?.id,
              data: props?.data,
              hidden: props?.hide,
              openModal,
              setOpenModal
            })}
          </Card>
        </div>
        <Cardcomponent name={props?.id} hide={hide} attributes={attributes}
          listeners={listeners} handleHide={handleHide} />
        <div className="section-btns" >
          {
            hide &&
            <div className="hide" onClick={handleHide}>
              {hide ? <BiHide size={50} /> : <BiShow size={50} />}
            </div>
          }
          {hide && !isAnImageSection() && <div className="hide" onClick={!hide ? null : () => setOpenModal(true)}>
            {<FaEdit size={40} />}
          </div>}
        </div>
      </div>
    </div>
  )
}
