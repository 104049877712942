/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Avatar } from '@mui/material'
import { DivideTitle, ParentContainer } from '../components'
import { ModalComponent } from '../modalComponent/modalcomponent'
import InputImage from '../Inputs/InputFileImage/inputImage'
import InputText from '../Inputs/inputText/inputText'
import Comments from '../../../home/components/Comments/comments'
import './comments.scss'

const UseCaseComments = ({ id, data, openModal, setOpenModal }) => {
  const [comment, setComment] = useState(null)
  const [isSaved, setIsSaved] = useState(false)
  const [logo, setLogo] = useState({ img: null })

  const handleChange = (e) => {
    setIsSaved(false)
    setComment({ description: e?.target?.value })
  }

  return (
    <ParentContainer style={{ backgroundColor: 'white' }}>
      <Comments avatar={ data?.commentary_url || logo?.img}
        style={{ padding: '10px 70px 45px 60px' }}
        comment={isSaved ? comment?.description : data?.datas?.description}
      />
      {openModal && <ModalComponent open={openModal} sectionName="commentary"
        setOpenModal={setOpenModal} id={data?.id}
        data={comment} setIsSaved={setIsSaved} disable={isSaved || comment === null}
        component={<div className='comments_container'>
          <DivideTitle type={'h3'} w_title={'ILS EN PARLENT MIEUX QUE NOUS !'}
            w_style={{ fontSize: 40, color: '#23272A' }} />
          <div className='comments-content'>
            <Avatar className='avatar' sx={{ bgcolor: '#576574' }}>
              <InputImage useCaseSection={'commentary'} id={id} img={logo?.img || data?.commentary_url}
                inputID="logo-comment" className={'usecase-logo'} uploadclass={'upload-logo'}
                setState={(param) => {
                  setLogo({ img: param })
                }}
              />
            </Avatar>
            <div className="input-comments">
              <InputText placeholder="Information" handleChange={handleChange}
                rows={5} multiline={true} style={{ height: 'unset' }}
                value={comment?.description || data?.datas?.description} label="Information" />
            </div>
          </div>
        </div>}
      />}
    </ParentContainer>
  )
}

export default UseCaseComments
