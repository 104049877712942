export const PATH_ADMIN = '/admin'

export const PATH_ADMIN_DASHBOARD = 'dashboard'

export const PATH_ADMIN_CARTES = 'realtime'
export const PATH_USECASE_CMS = 'usecase'
export const PATH_USECASE_CMS_CREATION = 'usecase/creation'
export const PATH_USECASE_VISUALISATION = 'usecase/visualisation'

export const PATH_ADMIN_CAMPAGNES = 'campagnes'
export const PATH_ADMIN_VALIDATION_MISSIONS = 'waiting_validation'
export const PATH_ADMIN_CAMPAGNES_CREATION = 'creation'
export const PATH_ADMIN_CAMPAGNES_UPDATE = 'modification'

export const PATH_ADMIN_STREET_MARKETEURS = 'street-marketeurs'
// export const PATH_ADMIN_CHAT = 'chat'
export const PATH_ADMIN_NOTIFICATION_CENTER = 'notifications'
export const PATH_ADMIN_SM_CALENDLY = 'calendly'

export const PATH_ADMIN_COMPANY = 'entreprises'
export const PATH_ADMIN_COMPANY_CREATION = 'profil'

export const PATH_ADMIN_BLOG = 'blog'

export const PATH_ADMIN_DEVTOOLS = 'devtools'
export const PATH_ADMIN_GALLERY = 'gallery'

export const PATH_PROFIL_ADMIN = 'profiladmin'

export const INTERCOM_CONTACT = '/ext/intercomusers'

export const PATH_ADMIN_CADENCE = 'cadence-map'

export const PATH_ADMIN_INVOICES = 'invoices'
