/* eslint-disable camelcase */
import React from 'react'
import { arrayMove } from '@dnd-kit/sortable'
import { PATH_USECASE_VISUALISATION } from 'routes/routesAdmin'
import { DoubleImageBlock, UseCaseImageBlock } from '../components/ImageBloc/imageBloc'
import { UseCaseIntroduction } from '../components/Introductions/introductions'
import { UseCaseProblimatisation } from '../components/Problimatisation/problimatisation'
import { createUseCase, getUseCaseById, updateIndexSectionsOfUseCase } from './apiFunctions'
import API from 'services/axios-config'
import UseCaseComments from '../components/Comments/comments'
import UseCaseStatistics from '../components/Statistics/statistics'
import UseCaseVideo from '../components/VideoPlayer/videoPlayer'

export const getCurrentComponent = {
  video_header: <UseCaseVideo />,
  header: <UseCaseIntroduction />,
  double_image: <DoubleImageBlock height={450} />,
  explain: <UseCaseProblimatisation />,
  stats: <UseCaseStatistics />,
  sub_image: <UseCaseImageBlock />,
  commentary: <UseCaseComments />
}

export const handlDragEnd = (event, setOrderedList) => {
  const { active, over } = event
  let activeIndex
  let overIndex
  if (active && over && active?.id !== over?.id) {
    setOrderedList((sections) => {
      activeIndex = sections.indexOf(sections?.find((item) => item?.id === active?.id))
      overIndex = sections.indexOf(sections?.find((item) => item?.id === over?.id))
      return arrayMove(sections, activeIndex, overIndex)
    })
  }
}

export const visualisation = (updateInfos) => {
  const queryData = Object?.keys(updateInfos)
    ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(updateInfos[key]))}`)
    ?.join('&')
  const url = `../../${PATH_USECASE_VISUALISATION}?${queryData}`
  window?.open(url, '_blank')
}

export const handleVisualisation = (id) => {
  const queryData =
    `${encodeURIComponent('id')}=${encodeURIComponent(JSON.stringify(id))}`
  const url = `../../${PATH_USECASE_VISUALISATION}?${queryData}`
  window?.open(url, '_blank')
}

export const didMount = (id, setCurrentUseCase, setisLoaded, navigate) => {
  if (id) {
    setisLoaded(false)
    getUseCaseById(id, setCurrentUseCase, setisLoaded)
  } else {
    setisLoaded(false)
    createUseCase(navigate, setCurrentUseCase, setisLoaded)
  }
}

export const sortSectionsByIndex = (sectionList) => {
  return sectionList?.sort((a, b) => a?.position - b?.position)
}

export const hideSection = (setUpdateInfos, name, orderedList) => {
  setUpdateInfos(prev => {
    const index = prev.findIndex(section => section?.name === name)
    if (index < 0) { return ([...prev, { section: name, name, hidden: !orderedList?.find(section => section?.name === name)?.hidden }]) } else { return (prev.map((val, _index) => index === _index ? { ...val, hidden: !val?.hidden } : val)) }
  })
}

export const makeListofSections = (currentUseCase) => {
  const sections = ['header', 'explain', 'stats', 'sub_image', 'double_image', 'commentary', 'video_header']
  if (currentUseCase) {
    const key_list = Object.keys(currentUseCase)?.filter((key, _) => sections?.includes(key))
    const results = key_list?.map((key, _) => ({ ...currentUseCase[key], name: key }))
      ?.map((section, _) => {
        switch (section?.name) {
          case 'header':
            return ({ ...section, header_url: currentUseCase.header_url })
          case 'commentary':
            return ({ ...section, commentary_url: currentUseCase.commentary_url })
          case 'sub_image':
            return { ...section, sub_image_url: currentUseCase.sub_image_url }
          case 'double_image':
            return { ...section, file1_url: currentUseCase.file1_url, file2_url: currentUseCase.file2_url }
          default: return section
        }
      })
    return results
  } else {
    return sections?.map((section, index) => ({ name: section }))
  }
}

export const sendSectionsOrder = (list, id, setUpdateInfos) => {
  // make a list of {section: string, index: number} to update positions in backend
  const data = list?.reduce((acc, current, index) => {
    acc?.push({ section: current?.section, index })
    return acc
  }, [])
  updateIndexSectionsOfUseCase(id, data)
  // update each section index in object updateInfo via response or data sended to backend
  setUpdateInfos((prev) => {
    return data.reduce((updatedPrev, sectionData) => {
      const { section, index } = sectionData
      return {
        ...updatedPrev,
        [section]: { ...(updatedPrev[section] || {}), index }
      }
    }, prev)
  })
}
export const sendTwoSectionsOrder = (id, activeSection, overSection, activeIndex, overIndex) => {
  const data = {
    [activeSection]: { index: overIndex },
    [overSection]: { index: activeIndex }
  }
  API.patch(`/usecases/${id}`, data)
    .then(_ => _)
    .catch((err) => console.log(err))
}
