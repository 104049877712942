import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { onDragEnd } from './function'
import InitialListView from './Initials'
import SlotListView from './slots'
import API from 'services/axios-config'
import { useSelector, useDispatch } from 'react-redux'
import { setActiveZones } from '_redux/Zones/reducers'
const DragNList = (props) => {
  const {
    // fullListOfSlots,
    // fullListOfInitialsItems,
    currentPrestation,
    handleSelectSlot,
    handleSelectPrestation,
    prestationSelected,
    slotSelected,
    setSlotListFormatted,
    setInitialsListFormatted,
    slotListFormatted,
    initialsListFormatted,
    mapRef,
    activePolygon,
    setActivePolygon,
    activePolyline,
    setActivePolyline,
    setModalInfos
  } = props

  const activeZones = useSelector((state) => state.zone.activeZones)
  function handleClickMarker (event, new_zone_ref, item) {
    function handleModifyLayer () {
      const fillColor = new_zone_ref?.current?.fillColor || item?.fillColor
      const radius = item?.additional_ref?.current?.radius

      if (item?.slot_id) {
        setSlotListFormatted((prev) =>
          prev.map((_slot) => {
            if (_slot?.id === item?.slot_id) {
              return {
                ..._slot,
                fullList: _slot?.fullList?.map((_item) => {
                  if (_item?.id === item?.id && _item?.type === item?.type) {
                    API.patch(`${item?.type}/${item?.id}`, {
                      ...{ radius, fillColor }
                    })
                    return { ...item, ...{ radius, fillColor } }
                  }
                  return _item
                })
              }
            } else return _slot
          })
        )
      } else {
        setInitialsListFormatted((prev) =>
          prev.map((_item) => {
            if (_item?.id === item?.id && _item?.type === item?.type) {
              API.patch(`${item?.type}/${item?.id}`, {
                ...{ radius, fillColor }
              })
              return { ...item, ...{ radius, fillColor } }
            }
            return _item
          })
        )
      }
    }

    const clickPosition = {
      lat: new_zone_ref.current.position.lat(),
      lng: new_zone_ref.current.position.lng()
    }

    if (clickPosition && new_zone_ref.current) {
      setModalInfos({
        item,
        position: clickPosition,
        additional_ref: item?.additional_ref,
        currentRef: new_zone_ref,
        handleModifyLayer: () => handleModifyLayer(),
        type: 'spots'
      })
    } else if (clickPosition) setModalInfos(null)
  }
  const calculatePolygonCenter = (paths) => {
    let latSum = 0
    let lngSum = 0

    paths.forEach((latLng) => {
      (latSum += latLng.lat()), (lngSum += latLng.lng())
    })

    const latCenter = latSum / paths.length
    const lngCenter = lngSum / paths.length

    return { lat: latCenter, lng: lngCenter }
  }
  function handleActivePolygon (event, ref_selected, item) {
    function handleModifyLayer () {
      const { fillColor, strokeColor, strokeWeight, fillOpacity } =
        ref_selected?.current
      if (item?.slot_id) {
        setSlotListFormatted((prev) =>
          prev.map((_slot) => {
            if (_slot?.id === item?.slot_id) {
              return {
                ..._slot,
                fullList: _slot?.fullList?.map((_item) => {
                  if (_item?.id === item?.id && _item?.type === item?.type) {
                    API.patch(`${item?.type}/${item?.id}`, {
                      ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
                    })
                    return {
                      ...item,
                      ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
                    }
                  }
                  return _item
                })
              }
            } else return _slot
          })
        )
      } else {
        setInitialsListFormatted((prev) =>
          prev.map((_item) => {
            if (_item?.id === item?.id && _item?.type === item?.type) {
              API.patch(`${item?.type}/${item?.id}`, {
                ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
              })
              return {
                ...item,
                ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
              }
            }
            return _item
          })
        )
      }
    }
    const clickPosition = !event
      ? calculatePolygonCenter(ref_selected.current.getPath().getArray())
      : {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        }

    if (clickPosition && ref_selected.current.editable) {
      setModalInfos({
        position: clickPosition,
        currentRef: ref_selected,
        handleModifyLayer: () => handleModifyLayer(),
        type: 'zones'
      })
    } else {
      setModalInfos(null)
    }

    if (activePolygon) {
      // const current_doc = document.getElementById(
      //   `item-zones-${activePolygon.current.itemId}`
      // );
      // if (current_doc) {
      //   current_doc.style.backgroundColor = "#fff";
      //   current_doc.style.fontWeight = "unset";
      // }
      activePolygon.current.setOptions({ editable: false, draggable: false })
    }
    if (activePolyline) {
      activePolyline.current.setOptions({
        editable: false,
        draggable: false,
        ...activePolyline.current.options
      })
      setActivePolyline(null)
    }
    setActivePolygon(ref_selected)
    ref_selected.current.setOptions({
      editable: true,
      draggable: true,
      itemId: item?.id
    })
  }

  function handleActivePolyline (event, ref_selected, item) {
    function handleModifyLayer () {
      const { fillColor, strokeColor, strokeWeight, fillOpacity } =
        ref_selected?.current
      if (item?.slot_id) {
        setSlotListFormatted((prev) =>
          prev.map((_slot) => {
            if (_slot?.id === item?.slot_id) {
              return {
                ..._slot,
                fullList: _slot?.fullList?.map((_item) => {
                  if (_item?.id === item?.id && _item?.type === item?.type) {
                    API.patch(`${item?.type}/${item?.id}`, {
                      ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
                    })
                    return {
                      ...item,
                      ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
                    }
                  }
                  return _item
                })
              }
            } else return _slot
          })
        )
      } else {
        setInitialsListFormatted((prev) =>
          prev.map((_item) => {
            if (_item?.id === item?.id && _item?.type === item?.type) {
              API.patch(`${item?.type}/${item?.id}`, {
                ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
              })
              return {
                ...item,
                ...{ fillColor, strokeColor, strokeWeight, fillOpacity }
              }
            }
            return _item
          })
        )
      }
    }

    const clickPosition = !event
      ? calculatePolygonCenter(ref_selected.current.getPath().getArray())
      : {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        }

    if (clickPosition && ref_selected.current.editable) {
      setModalInfos({
        position: clickPosition,
        currentRef: ref_selected,
        handleModifyLayer: () => handleModifyLayer(),
        type: 'zones'
      })
    } else {
      setModalInfos(null)
    }

    if (activePolyline) {
      // const current_doc = document.getElementById(
      //   `item-zones-${activePolyline.current.itemId}`
      // );
      // if (current_doc) {
      //   current_doc.style.backgroundColor = "#fff";
      //   current_doc.style.fontWeight = "unset";
      // }
      activePolyline.current.setOptions({ editable: false, draggable: false })
    }
    if (activePolygon) {
      activePolygon.current.setOptions({
        editable: false,
        draggable: false,
        ...activePolygon.current.options
      })
      setActivePolygon(null)
    }
    setActivePolyline(ref_selected)
    ref_selected.current.setOptions({
      editable: true,
      draggable: true,
      itemId: item?.id
    })
  }

  function handleRemoveItem (item, e) {
    e.stopPropagation()
    setSlotListFormatted((prev) =>
      prev.map((_slot) => {
        if (_slot.id === item.slot_id) {
          API.delete(`${item?.type}/${item?.id}`)
          return {
            ..._slot,
            fullList: _slot.fullList.filter(
              (_item) => !(_item.id === item.id && _item.type === item.type)
            )
          }
        } else return _slot
      })
    )
  }
  const dispatch = useDispatch()
  return (
    <DragDropContext
      onDragEnd={(result) => {
        onDragEnd(
          (zones) => dispatch(setActiveZones(zones)),
          activeZones,
          slotListFormatted,
          initialsListFormatted,
          result,
          setSlotListFormatted,
          setInitialsListFormatted,
          currentPrestation
        )
      }}
    >
      <div>
        <SlotListView
          {...{
            slotListFormatted,
            handleSelectSlot,
            slotSelected,
            handleRemoveItem,
            handleActivePolyline,
            handleActivePolygon,
            mapRef,
            setSlotListFormatted,
            currentPrestation,
            handleClickMarker,
            activePolygon,
            activePolyline
          }}
        />
        <Droppable
          droppableId={`prestation-${currentPrestation?.presta_type}-initials`}
        >
          {(provided) => (
            <InitialListView
              {...{
                initialsListFormatted,
                handleSelectSlot,
                slotSelected,
                handleRemoveItem,
                handleActivePolyline,
                handleActivePolygon,
                mapRef,
                provided,
                setInitialsListFormatted,
                currentPrestation,
                handleSelectPrestation,
                prestationSelected,
                handleClickMarker,
                activePolygon,
                activePolyline
              }}
            />
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}

export default DragNList
