/* eslint-disable react/prop-types */
import './style.scss'
import React, { useEffect, useState } from 'react'

// Use this Select to display and return basic STRING value
// For multiple values, use SelectSimpleMultiComponent

export const InputComponent = ({
  name,
  defaultValue = null,
  handleAction = function () { },
  label,
  type = 'text',
  disabled = false,
  textColor,
  min,
  max,
  multiline
}) => {
  const [focused, setFocused] = useState(false)
  const [valueSelect, setValueSelect] = useState('')
  const random = Math.random().toString(36).substr(2, 6)

  useEffect(() => {
    const targetDiv = document.getElementById(`customSelect-${random}`)
    const handleClickOustside = (event) => {
      const isClickedOutside = !targetDiv.contains(event.target)
      if (isClickedOutside) {
        setFocused(false)
      }
    }
    document.addEventListener('click', handleClickOustside)
    return () => document.removeEventListener('click', handleClickOustside)
  }, [])

  useEffect(() => {
    setValueSelect(defaultValue)
  }, [defaultValue])

  const handleInput = () => {
    const input = document.querySelector(`.input-id-${random}`)
    input.focus()
    setFocused(true)
  }

  if (multiline) {
    return (
      <div id={`customSelect-${random}`} className="customSelect multiline" onClick={(e) => e.stopPropagation()}>
      {label && <label onClick={(e) => handleInput()} className={`labelSelect ${valueSelect || focused ? 'focused' : ''}`}>{label}</label>}
      <textarea name="Text1" defaultValue={valueSelect} rows={6} onChange={(e) => handleAction(e.target.value)}></textarea>
      {/* <input
        name={name}
        type={type}
        onChange={(e) => handleAction(e.target.value)}
        defaultValue={valueSelect}

        className={`inputSelect input-id-${random} ${typeof valueSelect !== "boolean" && !valueSelect ? "empty" : ''}`}
        disabled={disabled}
        onClick={() => setFocused(true)} style={{ color: textColor }}
        min={min}
        max={max}
      /> */}
    </div>
    )
  }

  return (
    <div id={`customSelect-${random}`} className="customSelect" onClick={(e) => e.stopPropagation()}>
      {label && <label onClick={(e) => handleInput()} className={`labelSelect ${valueSelect || focused ? 'focused' : ''}`}>{label}</label>}
      <input
        name={name}
        type={type}
        onChange={(e) => handleAction(e.target.value)}
        defaultValue={valueSelect}
        className={`input-text input-id-${random} ${typeof valueSelect !== 'boolean' && !valueSelect ? 'empty' : ''}`}
        disabled={disabled}
        onClick={() => setFocused(true)} style={{ color: textColor }}
        min={min}
        max={max}
      />
    </div>
  )
}
