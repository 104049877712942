/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import API from "services/axios-config";
import { InfoWindow, Marker, Polygon, Polyline, Circle } from "@react-google-maps/api";
import "./style.scss";
import SearchMaps from "../../mapsSearch";
import MapsLayout from "components/Maps/MapsLayout";
import { enqueueSnackbar } from "notistack";

import {
  addLocationsToMission,
  addMissionsLaunchToList,
  addNewLocation,
  addZonesToMission,
  addSpotsToMission,
  setShowGraph,
} from "_redux/Missions/reducers";
import { wktToPath } from "../../functions/functionsMap";
import { parse } from "wellknown";
import { extractProperty } from "utils/helpers";
const polylineOptions = {
  strokeOpacity: 1,
  strokeWeight: 5,
};

const ViewPolygon = ({ currentMission }) => {
  if (currentMission?.zones?.length > 0) {
    return currentMission?.zones?.map(
      (poly, index) =>
        poly.polygon && (
          <Polygon
            key={`polygon-${index}`}
            path={wktToPath(
              poly?.polygon ? parse(poly.polygon)?.coordinates[0] : []
            )}
            options={{
              strokeColor: poly?.fillColor,
              fillColor: poly?.fillColor,
              strokeWeight: 2,
              fillOpacity: 0.2,
            }}
          />
        )
    );
  }
  return null;
};

const ViewPolyline = ({ currentMission }) => {
  const [isHover, setIsHover] = useState(false);
  const refPoly = useRef();

  function handleMouseOver(id) {
    document.getElementById(
      `current-mission-hover-${id}`
    ).style.backgroundColor = "rgba(255, 0, 0, 0.3)";
  }

  function handleMouseOut(id, isOnBreak) {
    document.getElementById(
      `current-mission-hover-${id}`
    ).style.backgroundColor = isOnBreak ? "#F3B922B2" : "white";
  }
  return <Polyline
      ref={refPoly}
      path={currentMission?.locations?.map(
        (direction) => ({
          lat: direction?.latitude,
          lng: direction?.longitude,
        })
      )}
      options={{
        ...polylineOptions,
        strokeColor: isHover ? "red" : "rgb(0, 38, 255)",
      }}
      onMouseOver={(e) => {
        setIsHover({ strokeColor: "rgba(255, 0, 0, 0.3)" });
        handleMouseOver(currentMission?.id);
      }}
      onMouseOut={(e) => {
        setIsHover(false);
        handleMouseOut(currentMission?.id, currentMission?.on_break);
      }}
    />;
};

const MissionLaunchView = () => {
  const {
    missionLaunchList,
    campaignExcludeList,
    campaignExcludeListZones,
    campaignExcludeListLocation,
    missionExcludeList,
    missionExcludeListLocation,
    missionExcludeListZones,
  } = useSelector((state) => state.missions);
  const [activeSpotIdMarker, setActiveSpotIdMarker] = useState(null);
  const getPinSvg = (color) => {
    return `<?xml version="1.0"?><svg width="24" height="32" viewBox="0 0 24 32" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.5 12.3751C0.5 5.81141 5.67259 0.5 12.0002 0.5C18.3278 0.5 23.5003 5.81142 23.5 12.3747C23.5 13.9252 22.8453 15.859 21.8004 17.9373C20.762 20.0027 19.3671 22.1554 17.9491 24.1279C15.1648 28.0012 12.3216 31.1384 11.9998 31.4912C11.6765 31.1366 8.83428 28.0002 6.05086 24.1282C4.63288 22.1557 3.23802 20.0031 2.19961 17.9376C1.15472 15.8593 0.5 13.9256 0.5 12.3751ZM6.60805 12.3751C6.60805 15.4165 9.00869 17.9197 12.0002 17.9197C14.9917 17.9197 17.3923 15.416 17.3923 12.3748C17.3923 9.33346 14.9917 6.82978 12.0002 6.82978C9.00859 6.82978 6.60805 9.33386 6.60805 12.3751Z" fill="${color}" stroke="black"/>
          </svg>
          `;
  }

  console.log(missionLaunchList)

  const handleSpotMarkerClick = (spotId) => {
    setActiveSpotIdMarker(prev => prev === spotId ? null : spotId)
  };

  return (
    <>
      {missionLaunchList?.map((currentMission, indexParent) => {
        const last_position_know = currentMission?.locations?.flat()[currentMission?.locations?.flat().length - 1]
        return (
          <>
            {!campaignExcludeList.includes(
              currentMission.campaign.id.toString()
            ) &&
              !missionExcludeList.includes(currentMission.id) && (
                <>
                  {!campaignExcludeListLocation.includes(
                    currentMission.campaign.id.toString()
                  ) &&
                    !missionExcludeListLocation.includes(currentMission.id) &&
                    last_position_know?.timestamp &&
                      <div
                        key={`launched_mission_${currentMission.id}`}
                        className="marker-content"
                      >
                       <Marker
                          visible={false}
                          position={{
                            lat: last_position_know?.latitude,
                            lng: last_position_know?.longitude,
                          }}
                        >
                          <InfoWindow
                            defaultOptions={{ disableAutoPan: true }}
                            onCloseClick={() => { }}
                            position={{
                              lat: last_position_know?.latitude,
                              lng: last_position_know?.longitude,
                            }}
                            options={{
                              ariaLabel: "infoWindowRealTimeSM",
                              disableAutoPan: true,
                            }}
                          >
                            <div className="infoWindowContainer" style={{ backgroundColor: currentMission?.on_break ? '#F3B922B2' : 'white' }}>
                              <img
                                alt="visuel profile"
                                src={
                                  process.env.REACT_APP_BACK_API +
                                  currentMission?.avatar_url
                                }
                              />
                              <div>
                                {currentMission?.user?.first_name +
                                  " " +
                                  currentMission?.user?.last_name}
                              </div>
                            </div>
                          </InfoWindow>
                        </Marker>
                      </div>
                    }
                  {!campaignExcludeListLocation.includes(
                    currentMission.campaign.id.toString()
                  ) &&
                    !missionExcludeListLocation.includes(currentMission.id) &&
                    extractProperty(currentMission?.locations, "coords") && (
                      <ViewPolyline currentMission={currentMission} />
                    )}
                  {!campaignExcludeListZones.includes(
                    currentMission.campaign.id.toString()
                  ) &&
                    !missionExcludeListZones.includes(currentMission.id) &&
                    currentMission?.zones && (
                      <ViewPolygon currentMission={currentMission} />
                    )}
                  {currentMission?.spots && currentMission.spots.map(spot => {
                    return (
                      <>
                        {currentMission?.presta_type === 'Flying Hand' && <Circle
                          center={{ lat: spot?.address?.latitude, lng: spot?.address?.longitude }}
                          radius={(!spot.geofence_radius && spot.geofence_radius !== 0) ? 50 : spot?.geofence_radius}
                          options={{
                            fillColor: spot?.fillColor,
                            strokeColor: spot?.fillColor,
                          }}
                        />}
                        <Marker
                          key={spot.id}
                          visible
                          position={{
                            lat: spot?.address?.latitude,
                            lng: spot?.address?.longitude,
                          }}
                          options={{
                            icon: {
                              url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getPinSvg(spot?.fillColor ?? 'black')),
                              scaledSize: new window.google.maps.Size(40, 40),
                            }
                          }}
                          onClick={() => handleSpotMarkerClick(spot.id)}
                        >
                          {activeSpotIdMarker === spot?.id && <InfoWindow
                            defaultOptions={{ disableAutoPan: true }}
                            options={{
                              disableAutoPan: true,
                            }}
                            onCloseClick={() => setActiveSpotIdMarker(null)}
                            position={{
                              lat: spot?.address?.latitude,
                              lng: spot?.address?.longitude,
                            }}
                          >
                            <div className="realtime-spot-infowindow-container" >
                              <div className="realtime-spot-close-infoWindow" onClick={() => setActiveSpotIdMarker(null)}>
                                X Fermer
                              </div>
                              <div className="realtime-spot-key-value-conatiner">
                                <div className="spot-key">Nom :</div>
                                <div className="spot-value" style={{ cursor: 'auto' }}>
                                  {spot?.title ?? '-'}
                                </div>
                              </div>
                              <div className="realtime-spot-key-value-conatiner">
                                <div className="spot-key">Adresse :</div>
                                <div className="spot-value" style={{ cursor: 'auto' }}>
                                  {spot?.address?.label ?? '-'}
                                </div>
                              </div>
                              <div className="realtime-spot-key-value-conatiner">
                                <div className="spot-key">Description :</div>
                                <div className="spot-value" style={{ cursor: 'auto' }}>
                                  {spot?.description ?? '-'}
                                </div>
                              </div>
                              <div className="realtime-spot-key-value-conatiner">
                                <div className="spot-key">Type de spot :</div>
                                <div className="spot-value" style={{ cursor: 'auto' }}>
                                  {spot?.spot_type ?? '-'}
                                </div>
                              </div>
                              <div className="realtime-spot-key-value-conatiner">
                                <div className="spot-key">Nombre de flyers distribués :</div>
                                <div className="spot-value" style={{ cursor: 'auto' }}>
                                  {spot?.total_distributed_flyers ?? '-'}
                                </div>
                              </div>
                            </div>
                          </InfoWindow>}
                        </Marker>
                      </>)
                  })}
                </>
              )}
          </>
        );
      })}
    </>
  );
};

const RealTimeMaps = () => {
  const [clickedLocation, setClickedLocation] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowGraph(false));

    return () => {
      // Perform actions before the component is unmounted
      dispatch(setShowGraph(false));
      // You can trigger an action here
      // For example, dispatching an action with Redux
      // Or calling a function to perform a cleanup
    };
  }, []);
  const mapRef = useRef(null);

  useEffect(() => {
    const addLocation = async (locations, missionId, onBreak) => {
      for (const location of locations) {
        await new Promise(resolve => {
          setTimeout(() => {
            dispatch(
              addNewLocation({
                mission_id: missionId,
                new_location: location,
                on_break: onBreak,
              })
            );
              resolve();
          }, 1000);
        });
      }
    };

    const socket_port = `${process.env.REACT_APP_BACK_API}/cable`.replace(/^https?:\/\//, "");
    let ws;
    if (process.env.REACT_APP_BACK_API.includes('localhost')) ws = new WebSocket(`ws://${socket_port}`);
    else ws = new WebSocket(`wss://${socket_port}`);

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          command: "subscribe",
          identifier: JSON.stringify({ channel: "LocationsChannel" }),
        })
      );
    };

    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (
        data?.type !== "ping" &&
        data?.type !== "confirm_subscription" &&
        data?.type !== "welcome"
      ) {
        if (data?.message?.locations?.length > 0 && data?.message?.mission_id) {
          addLocation(data.message.locations, data?.message?.mission_id, data?.message?.on_break ?? false)
        }
      }
    };

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, []);

  useEffect(() => {
    const getLaunchedMissionsPositions = () => {
      API.get("missions?status=En cours&include_user=true")
        .then((res) => {
          dispatch(addMissionsLaunchToList(res.data));
          res?.data?.forEach((mission) => {
            API.get(`missions/${mission?.id}/full_location?all_infos=true`).then(
              (_locations) => {
                dispatch(
                  addLocationsToMission({
                    mission_id: mission?.id,
                    locations: _locations.data,
                  })
                )
                });
            if (mission?.slot_id) {
              API.get(`slots/${mission?.slot_id}/zones`).then((_zones) =>
                dispatch(
                  addZonesToMission({
                    mission_id: mission?.id,
                    zones: _zones.data,
                  })
                )
              );
              API.get(`slots/${mission?.slot_id}/spots`).then((_spots) =>
                dispatch(
                  addSpotsToMission({
                    mission_id: mission?.id,
                    spots: _spots.data,
                  })
                )
              );
            }
          });
        })
        .catch((error) => enqueueSnackbar(error, { variant: "error" }));
    };
    getLaunchedMissionsPositions();
  }, []);

  return (
    <div className="carte-container-real-time">
      <div className="map" style={{ position: "relative" }}>
        <SearchMaps {...{ mapRef, clickedLocation, setClickedLocation }}>
          <MissionLaunchView />
          <MapsLayout {...{ mapRef, clickedLocation }} />
        </SearchMaps>
      </div>
    </div>
  );
};

export default RealTimeMaps;
