import React from 'react';
import './style.scss';
import { formatDate } from "utils/helpers";
import DevisEstimationSection from "components/DevisEstimationSection";
import DevisStatusBadge from "components/DevisStatusBadge";

export const DevisSideModal = ({ devis, company }) => {
    const supportFormat = React.useMemo(() => {
        if (devis && Object.keys(devis).length > 0) {
            if (devis.support === 'flyers' && devis.format) return devis.format;
            if (devis.page_nb && (devis.page_nb).indexOf('+') !== -1) { return `Brochure de ${devis.page_nb.replace(/\+$/, ' pages ou plus')}`; }
            if (devis.page_nb) return `Brochure de ${devis.page_nb} pages`;
        }
        return null;
    }, [devis]);
    return (
        <div className="devis-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="devis-modal-header-content">
                <div className="devis-modal-title">RÉCAPITULATIF DEVIS - {company?.company_name} </div>
            </div>
            {devis?.status && <DevisStatusBadge status={devis.status} />}
            <table className="key-value-table">
                <tbody>
                    <tr>
                        <td className="key">Nom pour la campagne :</td>
                        <td>{devis?.campaign_name}</td>
                    </tr>
                    <tr>
                        <td className="key">Dates souhaitées :</td>
                        <td>{formatDate(devis?.date_start)} - {formatDate(devis?.date_end)} </td>
                    </tr>
                    <tr>
                        <td className="key">Prestation :</td>
                        <td>{devis?.prestation_type} </td>
                    </tr>
                    {devis?.addresses_selected?.length > 0 && <tr>
                        <td className="key">Villes :</td>
                        <td>{devis?.addresses_selected?.map((adr) => adr?.city).join(', ')} </td>
                    </tr>}
                    {devis?.simulation && <tr>
                        <td className="key">Simulation :</td>
                        <td>{devis?.simulation?.name} </td>
                    </tr>}
                    <tr>
                        <td className="key">Support :</td>
                        <td>{devis?.support === 'flyers' ? 'Flyers' : 'Brochures'} </td>
                    </tr>
                    <tr>
                        <td className="key">Nombre de support :</td>
                        <td>{devis?.support_nb} </td>
                    </tr>
                    {(devis?.support === 'brochures' || devis?.support === 'flyers') && supportFormat && <tr>
                        <td className="key">{devis?.support === 'brochures' ? 'Nombre de pages' : 'Fromat du support'}</td>
                        <td>{supportFormat} </td>
                    </tr>}
                    <tr>
                        <td className="key">Création de flyers :</td>
                        <td>{devis?.want_create ? 'Oui' : 'Non'} </td>
                    </tr>
                    <tr>
                        <td className="key">Impression de flyers :</td>
                        <td>{devis?.want_impression ? 'Oui' : 'Non'} </td>
                    </tr>
                    <tr>
                        <td className="key">Urgence : </td>
                        <td>{devis?.urgent ? 'Oui' : 'Non'} </td>
                    </tr>
                    <tr>
                        <td className="key">Détails pour la campagne :</td>
                        <td>{devis?.details} </td>
                    </tr>
                </tbody>
            </table>

            <hr></hr>

            {devis?.prestation_type === 'Flying Box' && devis?.estimation && <DevisEstimationSection estimation={devis?.estimation} />}
        </div>
    );
};
