/* eslint-disable react/prop-types */
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useState, useMemo } from 'react'
import API from 'services/axios-config'
import { InputComponent } from 'views/Admin/Shared/Forms/Input'
import { SelectObjectsComponent } from 'views/Admin/Shared/Forms/SelectObjects'
import { SelectInputMultiple } from 'components/GlobalInputs/Select';

const ModifyCampaign = ({ campaignData, handleChangeCampaign }) => {
  const [allCompany, setAllCompany] = useState([])
  const [isUrgent, setIsUrgent] = useState(false);
  const [companyColabsList, setCompanyColabsList] = useState([])
  const [isLoadingColabList, setIsLoadingColabList] = useState(true)

  const getCompanyColabsList = async (companyId, campaignId) => {
    setIsLoadingColabList(true)
    API.get(`companies/${companyId}/get_company_colabs?campaign_id=${campaignId}`)
        .then(async (res) => {
          if (res.data?.campaign_colabs) {
            await handleChangeInfo({ colabs: res.data.campaign_colabs })
          }
          if (res.data?.company_colabs) {
            setCompanyColabsList(res.data.company_colabs)
          }
        })
        .catch((err) => console.log('Err:', err))
        .finally(() => setIsLoadingColabList(false))
  }

  useEffect(() => {
    if (campaignData?.company?.id) {
      getCompanyColabsList(campaignData.company.id, campaignData?.campaign?.id)
    }
  }, [campaignData?.company?.id])

  const selectedColabsList = useMemo(() => {
    return campaignData?.campaign?.colabs ?? [];
  }, [campaignData?.campaign?.colabs])

  useEffect(() => {
    API.get('/companies?fields=company_name,id')
      .then((result) => {
        setAllCompany(result?.data?.companies)
      })
      .catch(() => {
        enqueueSnackbar(
          'Une erreur est survenu lors de la récupération des clients',
          { variant: 'error' }
        )
      })
  }, [])

  useEffect(() => {
    if (campaignData) {
      setIsUrgent(JSON.parse(campaignData?.campaign?.urgent ?? 'false'));
    }
  }, [campaignData])

  const radioColor = {
    color: 'black',
    '&.Mui-checked': {
      color: 'black'
    }
  }

  function handleSendNewClient(e) {
    API.post('companies', { company_name: e?.inputValue })
      .then((res) => {
        handleChangeCampaign({ company_id: res?.data?.id }, 'campaign')
        setAllCompany((prev) => [...prev, res?.data])
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: 'error' })
      })
  }

  const handleChangeInfo = async (e) => {
    if (e?.colabs) {
      handleChangeCampaign({ ...campaignData?.campaign, colabs: e.colabs }, 'campaign')
    } else if (e?.company) {
      handleChangeCampaign({ company_id: e?.company?.id }, 'campaign')
      handleChangeCampaign(e?.company, 'company')
    } else if (e?.campaign?.campaign_name_client) { handleChangeCampaign({ campaign_name_client: e?.campaign?.campaign_name_client }, 'campaign') } else if (e?.type) {
      handleChangeCampaign({ urgent: e?.value }, 'campaign')
    } else if (e?.campaign?.montant_ht) {
      handleChangeCampaign({ montant_ht: e?.campaign?.montant_ht }, 'campaign')
    }
  }

  return (
    <>
      <div
        style={{
          fontWeight: 700,
          fontSize: 20,
          color: 'black',
          margin: '20px 0'
        }}
      >
        QUI FAIT LA CAMPAGNE ?
      </div>
      <div style={{ display: 'flex', gap: 35 }}>
        <div
          style={{ flex: 1, gap: 20, display: 'flex', flexDirection: 'column' }}
        >
          <SelectObjectsComponent
            label="Client"
            name="company"
            items={allCompany}
            item_name="company_name"
            itemAttributes={['company_name']}
            canAdd
            value={campaignData?.company?.company_name}
            handleAction={async (e) => {
              if (e === null) {
                setIsLoadingColabList(true)
                await handleChangeCampaign({ ...campaignData?.campaign, colabs: [] }, 'campaign')
                setCompanyColabsList([])
                setIsLoadingColabList(false)
              }
              if (!e?.id && e?.inputValue !== undefined) {
                handleSendNewClient(e)
              } else {
                handleChangeInfo({ company: e })
              }
            }}
            getOptionLabel={(option) =>
              option?.currentLabel ? option?.currentLabel : option?.company_name
            }
            placeholder="ex : Keemia"
            // color={campaignData?.campaign && campaignData?.company?.id === campaignData?.campaign?.devis?.company_id ? 'red' : null}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'fit-content',
            flexDirection: 'column',
            gap: 20,
            fontWeight: 700
            // color: mainCampaign && mainCampaign?.urgent === campaignData?.urgent ? "red" : "black"
          }}
        >
          Urgent ?
          <RadioGroup
            row
            value={isUrgent}
            onChange={(e) => handleChangeInfo(e.target)}
          >
            <FormControlLabel
              value={true}
              control={<Radio sx={radioColor} />}
              label="Oui"
            />
            <FormControlLabel
              value={false}
              control={<Radio sx={radioColor} />}
              label="Non"
            />
          </RadioGroup>
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        {!isLoadingColabList && <SelectInputMultiple
          onChange={(newSelected) => {
            handleChangeInfo({ colabs: newSelected })
          }}
          variant="rounded"
          label="Collaborateurs Client"
          value={selectedColabsList}
          items={companyColabsList}
          keys={['first_name', 'last_name']}
          disabled={isLoadingColabList}
        />}

      </div>
      <div style={{ marginTop: 20 }}>
        <InputComponent
          name="campaign_name_client"
          defaultValue={campaignData?.campaign?.campaign_name_client}
          handleAction={(e) =>
            handleChangeInfo({ campaign: { campaign_name_client: e } })
          }
          label="Nom de la campagne (Dashboard Client)"
        // textColor={mainCampaign?.campaign_name_client === campaignData?.campaign_name_client ? 'red' : undefined}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <InputComponent
          name="campaign_name_client"
          defaultValue={campaignData?.campaign?.montant_ht}
          handleAction={(e) =>
            handleChangeInfo({ campaign: { montant_ht: parseFloat(e) } })
          }
          type="number"
          label="Montant HT de la campagne"
        // textColor={mainCampaign?.campaign_name_client === campaignData?.campaign_name_client ? 'red' : undefined}
        />
      </div>
    </>
  )
}

export default ModifyCampaign
