import { configureStore } from '@reduxjs/toolkit'
import adminReducer from './Admins/reducers'
import campaignListReducer from './Campaigns/reducers'
import companyListReducer from './Companies/reducers'
import zoneReducer from './Zones/reducers'
import streetMarketeurReducer from './StreetMarketers/reducers'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import missionReducer from './Missions/reducers'
import mapReducer from './Map/reducers'

const reducers = combineReducers({
  admin: adminReducer,
  missions: missionReducer,
  campaignsList: campaignListReducer,
  companyList: companyListReducer,
  streetMarketer: streetMarketeurReducer,
  map: mapReducer,
  zone: zoneReducer
})

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['admin'],
  blacklist: ['campaignsList', 'missions', 'companyList', 'streetMarketer', 'map', 'zone'],
  cookie: {
    domain: '.flyingforyou.fr'
  }
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})
export const persistor = persistStore(store)
export default store
