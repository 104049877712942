import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import DragNList from './DragList/dragNList'
import { createRef, useState, useEffect } from 'react'
import MenuDialog from './MenuDialog'
// import ogr2ogr from 'ogr2ogr'
import { handelIcon } from 'utils/helpers'
import { removeVisibilityZones } from '_redux/Zones/reducers'

import {
  fromGPXtoGeoJSON,
  fromKMLtoGeoJSON,
  fromGeoJSONToKML,
  fromZonesToGeoJSON,
  toWKTbis
} from '../../../functions/functionsMap'
import API from 'services/axios-config'
import { saveAs } from 'file-saver'
import { ModalWaitingSend } from './modal-waiting-send'
import { enqueueSnackbar } from 'notistack'

function formattedSlotList (fullList) {
  if (fullList) {
    return fullList.map((_slot) => ({
      ..._slot,
      fullList: [
        ..._slot?.zones?.map((_content) => ({
          ..._content,
          type: 'zones',
          ref: createRef()
        })),
        ..._slot?.spots?.map((_content) => ({
          ..._content,
          type: 'spots',
          ref: createRef(),
          additional_ref: createRef()
        })),
        ..._slot?.missions?.map((_content) => ({
          ..._content,
          type: 'missions',
          ref: createRef()
          // additional_ref: createRef(),
        }))
      ]
    }))
  } else return []
}

function getFullInitialList (initials) {
  return [
    ...initials?.zones?.map((_zone) => ({ ..._zone, ref: createRef() })),
    ...initials?.spots?.map((_spot) => ({
      ..._spot,
      ref: createRef(),
      additional_ref: createRef()
    }))
  ]
}
function updateClientVisibleInitial (originalArray, updatedArray) {
  // Create a new array to store updated items
  const newArray = originalArray.map((originalItem) => {
    // Find matching item in the updated array based on id
    const updatedItem = updatedArray.find(
      (item) => item.id === originalItem.id && item.type === originalItem.type
    )
    if (updatedItem) {
      // If found, update client_visible property
      return { ...originalItem, client_visible: updatedItem.client_visible }
    } else {
      // If not found, return original item as is
      return originalItem
    }
  })
  return newArray
}

function updateClientVisibleSlot (originalArray, updatedArray) {
  const newArray = originalArray.map((obj) => ({
    ...obj,
    fullList: obj.fullList.map((item) => ({
      ...item,
      client_visible:
        updatedArray.find(
          (updatedItem) =>
            updatedItem.id === item.id && updatedItem.type === item.type
        )?.client_visible ?? item.client_visible
    }))
  }))
  return newArray
}

const PrestationLayout = ({
  prestationName,
  handleSelectSlot,
  slotSelected,
  activePolygon,
  setActivePolygon,
  activePolyline,
  setActivePolyline,
  mapRef,
  setModalInfos,
  handleSelectPrestation,
  prestationSelected
}) => {
  const currentPrestation = useSelector(
    (state) => state.map[prestationName],
    shallowEqual
  )
  const status = useSelector((state) => state.zone.status)
  const visibilityZones = useSelector((state) => state.zone.visibilityZones)
  const dispatch = useDispatch()

  useEffect(() => {
    if (status === 'fulfilled') {
      if ([...visibilityZones.hide, ...visibilityZones.publish].length > 0) {
        if (fullListOfInitialsItems.length > 0) {
          setFullListOfInitialsItems(
            updateClientVisibleInitial(fullListOfInitialsItems, [
              ...visibilityZones.hide,
              ...visibilityZones.publish
            ])
          )
        }
        if (fullListOfSlots.length > 0) {
          setFullListOfSlots(
            updateClientVisibleSlot(fullListOfSlots, [
              ...visibilityZones.hide,
              ...visibilityZones.publish
            ])
          )
        }
        dispatch(removeVisibilityZones())
      }
    }
  }, [status])

  const [fullListOfSlots, setFullListOfSlots] = useState(
    formattedSlotList(currentPrestation?.slots)
  )
  const [fullListOfInitialsItems, setFullListOfInitialsItems] = useState(
    getFullInitialList(currentPrestation?.initials || { spots: [], zones: [] })
  )

  const [waitingSend, setWaitingSend] = useState(false)

  const adressFormat = (infos, location, formattedAdress) => {
    const findItem = (key) => {
      const value = infos?.find((item) => item.types.includes(key))
      return value?.long_name
    }

    const newObject = {
      housenumber: findItem('street_number'),
      street: findItem('route'),
      postcode: findItem('postal_code'),
      city: findItem('locality'),
      longitude: location.lng(),
      latitude: location.lat(),
      label: formattedAdress
    }
    return newObject
  }

  async function formattedParams (type, params) {
    if (type === 'Point') {
      let currentAddress = null
      const latLng = {
        lng: params?.geometry?.coordinates[0],
        lat: params?.geometry?.coordinates[1]
      }
      const geocoder = new window.google.maps.Geocoder()
      await geocoder
        .geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            const newAddress = adressFormat(
              response.results[0]?.address_components,
              response.results[0]?.geometry?.location,
              response.results[0]?.formatted_address
            )
            currentAddress = newAddress
          } else {
            window.alert('No results found')
          }
        })
        .catch((e) => window.alert('Geocoder failed due to: ' + e))
      return {
        address_attributes: currentAddress,
        fillColor: params?.properties['icon-color'],
        prestation_id: currentPrestation?.id,
        type: 'spots'
      }
    }
    if (type === 'Polygon') {
      return {
        polygon: toWKTbis(params?.geometry.coordinates[0]),
        fillColor: params?.properties.fill,
        type: 'zones',
        title: params?.properties.name,
        prestation_id: currentPrestation?.id
      }
    } else if (type === 'LineString') return null
  }

  async function handleAddKml () {
    const promises = []
    const newItemsList = []
    let newInitialList = []
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = async (e) => {
      setWaitingSend(true)
      const file = e.target.files[0]
      const fileName = file.name
      const fileType = fileName.split('.').pop()
      if (fileType === 'kml') {
        await fromKMLtoGeoJSON(file).then(async (res) => {
          res?.children?.map(async (_child) => {
            if (_child?.children) {
              await _child?.children?.map(async (_calque) => {
                if (_calque?.geometry?.type === 'GeometryCollection') {
                  await _calque?.geometry?.geometries?.map(async (_collect) => {
                    const newPromise = new Promise((resolve) => {
                      formattedParams(_collect?.type, {
                        ..._calque,
                        geometry: _collect
                      })
                        .then((res) => {
                          if (res) newItemsList.push(res)
                        })
                        .then((_) => resolve())
                    })
                    promises.push(newPromise)
                  })
                }
                const newPromise = new Promise((resolve) => {
                  formattedParams(_calque?.geometry?.type, _calque)
                    .then((res) => {
                      if (res) newItemsList.push(res)
                    })
                    .then((_) => resolve())
                })
                promises.push(newPromise)
              })
            } else {
              if (_child?.geometry?.type === 'GeometryCollection') {
                await _child?.geometry?.geometries?.map(async (_collect) => {
                  const newPromise = new Promise((resolve) => {
                    formattedParams(_collect?.type, {
                      ..._child,
                      geometry: _collect
                    })
                      .then((res) => {
                        if (res) newItemsList.push(res)
                      })
                      .then((_) => resolve())
                  })
                  promises.push(newPromise)
                })
              }
              const newPromise = new Promise((resolve) => {
                formattedParams(_child?.geometry?.type, _child)
                  .then((res) => {
                    if (res) newItemsList.push(res)
                  })
                  .then((_) => resolve())
              })
              promises.push(newPromise)
            }
          })
        })
      } else if (fileType === 'gpx') {
        alert("L'import de gpx n'est pas encore disponible")
        await fromGPXtoGeoJSON(file).then(async (res) => {
          if (res?.features) {
            res.features.map(async (_child) => {
              const newPromise = new Promise((resolve) => {
                formattedParams(_child?.geometry?.type, _child)
                  .then((res) => {
                    if (res) newItemsList.push(res)
                  })
                  .then((_) => resolve())
              })
              promises.push(newPromise)
            })
          }
        })
      }
      await Promise.all(promises)
      const zonesList = newItemsList.filter((item) => item.type === 'zones')
      const spotsList = newItemsList.filter((item) => item.type === 'spots')
      const spotsPromise = new Promise((resolve) => {
        API.post('spots', spotsList)
          .then((res) => {
            if (res.data) {
              newInitialList = [
                ...newInitialList,
                ...res.data.map((item) => ({
                  ...item,
                  type: 'spots',
                  ref: createRef(),
                  additional_ref: createRef()
                }))
              ]
            }
          })
          .then((_) => resolve())
          .catch((err) => enqueueSnackbar(err))
      })
      const zonesPromise = new Promise((resolve) => {
        API.post('zones', zonesList)
          .then((res) => {
            if (res.data) {
              newInitialList = [
                ...newInitialList,
                ...res.data.map((item) => ({
                  ...item,
                  type: 'zones',
                  ref: createRef()
                }))
              ]
            }
          })
          .then((_) => resolve())
          .catch((err) => enqueueSnackbar(err))
      })
      await Promise.all([spotsPromise, zonesPromise])
      setWaitingSend(false)
      setFullListOfInitialsItems((prev) => [...prev, ...newInitialList])
    }
    input.click()
  }

  function handleGenerateKml () {
    const template = { type: 'FeatureCollection', features: [] }
    // fromZonesToGeoJSON(fullListOfInitialsItems, template);
    fullListOfSlots?.forEach((_slot) => {
      fromZonesToGeoJSON(_slot?.zones, template, 'zones')
      fromZonesToGeoJSON(_slot?.spots, template, 'spots')
      fromZonesToGeoJSON(_slot?.missions || [], template, 'direction')
    })
    const blob = new Blob([fromGeoJSONToKML(template)], {
      type: 'application/vnd.google-earth.kml+xml'
    })
    saveAs(blob, 'zones.kml')
  }

  const BookMark = ({ prestationName }) => {
    return (
      <div className="bookmark-container">
        <div className="bookmark-content">
          {
            handelIcon({
              height: '25px',
              width: '25px',
              color: 'black',
              fill: 'black',
              stroke: 'black'
            })[prestationName]
          }
          <span
            style={{
              color: 'black',
              fontSize: 14,
              fontWeight: 700
            }}
          >
            {prestationName}
          </span>
          <MenuDialog {...{ handleAddKml, handleGenerateKml }}/>
          <ModalWaitingSend open={waitingSend} />
          {/* <Modal> */}

          {/* </Modal> */}
        </div>
      </div>
    )
  }
  console.log(fullListOfSlots, 'fullListOfSlots')
  console.log(fullListOfInitialsItems, 'fullListOfInitialsItems')
  if (currentPrestation) {
    return (
      <div className="prestation-container">
        <BookMark prestationName={currentPrestation.presta_type} />
        <div style={{ backgroundColor: 'transparent', flex: 1 }}>
          <DragNList
            slotListFormatted={fullListOfSlots}
            initialsListFormatted={fullListOfInitialsItems}
            setSlotListFormatted={setFullListOfSlots}
            setInitialsListFormatted={setFullListOfInitialsItems}
            {...{
              currentPrestation,
              handleSelectSlot,
              handleSelectPrestation,
              prestationSelected,
              slotSelected,
              mapRef,
              activePolygon,
              setActivePolygon,
              activePolyline,
              setActivePolyline,
              setModalInfos
            }}
          />
        </div>
      </div>
    )
  }
}

export default PrestationLayout
