/* eslint-disable react/prop-types */
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Chip, CircularProgress } from '@mui/material'
import { InputText } from '../InputText'
import './style.scss'

const CssForm = styled(FormControl)({
  '& label': {
    color: '#23272A',
    fontWeight: '700'
  },
  '& label.Mui-focused': {
    backgroundColor: '#fff',
    padding: '0px 10px',
    borderRadius: 50,
    color: '#23272A'
  },
  '&.disabled': {
    '& label': {
      color: '#C6C6C6'
    }
  },
  '& label.Mui-disabled': {
    color: '#C6C6C6'
  },
  '& label.MuiFormLabel-filled': {
    backgroundColor: '#fff',
    padding: '0px 10px',
    borderRadius: 50,
    color: '#23272A'
  }
})

const CssSelect = styled(Select)({
  '& .MuiSelect-select.Mui-disabled': {
    cursor: 'not-allowed'

  },
  '& fieldset': {
    border: 'none'
  },
  '&.MuiInputBase-root': {
    borderRadius: '5px',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
    border: '1px solid transparent'
  },
  '&.rounded.MuiInputBase-root': {
    borderRadius: '20px'
  },
  '&.filled': {
    '&.MuiInputBase-root': {
      border: '1px solid #23272A'
    },
    '&.MuiInputBase-root.Mui-disabled': {
      border: '1px solid transparent',
      backgroundColor: '#F2F2F2',
      '& svg': {
        display: 'none'
      }
    }
  },
  '&.isBtn': {
    '.MuiSelect-icon': {
      display: 'none'
    }
  },
  '&.empty': {
    '&.MuiInputBase-root': {
      backgroundColor: '#F2F2F2'
    }
  },
  '&.MuiInputBase-root.Mui-disabled': {
    border: '1px solid transparent'
  }
})

export const SelectInput = (props) => {
  const {
    label,
    size = 'small',
    onChange,
    value = '',
    items = [],
    keys = ['value'],
    variant = 'regular',
    disabled,
    nullable = false,
    addLineClick,
    withAddLine = false
  } = props
  const [valueSelect, setValueSelect] = useState(value)
  const [isFilled, setIsFilled] = useState(!!value)

  function handleChangeValue(e) {
    onChange && onChange(e.target.value === 'vide' ? null : e.target.value)
    setValueSelect(e.target.value === 'vide' ? '' : e.target.value)
    setIsFilled(e.target.value === 'vide' ? false : !!e.target.value)
  }
  if ((typeof items[0] === 'object' && items[0] !== null) || withAddLine) {
    return (
      <CssForm size={size} fullWidth className={`${disabled && 'disabled'}`}>
        <InputLabel id="select-multiple-label">{label}</InputLabel>
        <CssSelect
          labelId="select-multiple-label"
          disabled={disabled}
          value={items?.find(item => {
            return JSON.stringify(item) === JSON.stringify(valueSelect)
          }) || ''}
          onChange={handleChangeValue}
          className={`${isFilled ? 'filled' : 'empty'} ${variant}`}
        >
          {items.map((item) => (

            <MenuItem
              key={keys.map(key => item[key]).join(' ')}
              value={item}
            >
              {keys.map(key => item[key]).join(' ')}
            </MenuItem>
          ))}
          {nullable && isFilled && <MenuItem style={{ color: '#C6C6C6' }} value={'vide'}>[VIDE]</MenuItem>}
          {withAddLine && <div style={{ borderTopColor: 'black', borderTopWidth: 1, borderTopStyle: typeof items[0] === 'object' ? 'solid' : 'none' }}>
            <MenuItem onClick={addLineClick} style={{ color: 'black', fontSize: 12, margin: '5px 0px', fontWeight: '700' }} value={'vide'}>+ AJOUTER UNE LIGNE</MenuItem>
          </div>}
        </CssSelect>
      </CssForm>
    )
  }

  return (
    <CssForm size={size} fullWidth className={`${disabled && 'disabled'}`}>
      <InputLabel id="select-label">{label}</InputLabel>
      <CssSelect
        labelId="select-label"
        id="simple-select"
        value={valueSelect}
        label={label}
        disabled={disabled}
        className={`${isFilled ? 'filled' : 'empty'} ${variant}`}
        onChange={handleChangeValue}
      >
        {items.map((item) => (
          <MenuItem key={JSON.stringify(item)} value={item}>
            {item}
          </MenuItem>
        ))}
        {nullable && isFilled && <MenuItem style={{ color: '#C6C6C6' }} value={'vide'}>[VIDE]</MenuItem>}
      </CssSelect>
    </CssForm>
  )
}

function truncateWithEllipsis(string, maxLength = 40) {
  if (string.length <= maxLength) {
    return string; // If the string is shorter than or equal to maxLength, return it as is.
  }
  return string.slice(0, maxLength) + '...';
}

export const SelectInputMultiple = (props) => {
  const {
    label,
    size = 'small',
    isBtn = false,
    icon = null,
    onChange = function () { },
    onClick = function () { },
    onRemove = function () { },
    onClose = function () { },
    value = [],
    keys = ['value'], // si besoin de 2 key (ex: "prenom nom") faire un array avec les keys (ex: ["first_name", "last_name"])
    items = [],
    variant = 'regular',
    disabled
  } = props
  const [valuesSelect, setValuesSelect] = useState(value)
  const [isFilled, setIsFilled] = useState(!!value?.length > 0)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setValuesSelect(value)
    setIsFilled(!!value?.length > 0)
  }, [value])

  function handleChangeValue(e) {
    onChange(e.target.value)
    setValuesSelect(e.target.value)
    setIsFilled(!!e.target.value?.length > 0)
  }

  function handleDeleteChoice(e) {
    const newValues = valuesSelect.filter((item) => item !== e)
    onChange(newValues)
    setValuesSelect(newValues)
    setIsFilled(newValues?.length > 0)
  }

  function handleDeleteChoiceObject(e) {
    const newValues = valuesSelect.filter((item) => JSON.stringify(item) !== JSON.stringify(e))
    onChange(newValues)
    setValuesSelect(newValues)
    setIsFilled(newValues?.length > 0)
  }
  if (!Array.isArray(value)) { return <div>Value dois être une tableau. Utiliser {'<SelectInput/>'} si valeur seule. </div> }

  if (typeof items[0] === 'object' && items[0] !== null) {
    return (
      <CssForm size={size} fullWidth className={`${disabled && 'disabled'}`}>
        <InputLabel id="select-multiple-label">{label}</InputLabel>
        <CssSelect
          labelId="select-multiple-label"
          multiple
          onClick={() => {
            if (!open && !disabled) setOpen(true)
          }}
          onClose={() => {
            setOpen(false);
            if (onClose) onClose();
          }}
          open={open}
          disabled={disabled}
          value={valuesSelect?.map(value => items?.find(item => JSON.stringify(item) === JSON.stringify(value)))}
          onChange={handleChangeValue}
          className={`${isFilled ? 'filled' : 'empty'} ${variant}`}
          renderValue={(valuesSelect) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {valuesSelect.map((item) => (
                <Chip size={size} key={keys.map(key => item[key]).join(' ')} label={keys.map(key => item[key]).join(' ')} onDelete={() => handleDeleteChoiceObject(item)} />
              ))}
            </Box>
          )}
        >
          {items.map((item) => (

            <MenuItem
              key={keys.map(key => item[key]).join(' ')}
              value={item}
            >
              {keys.map(key => item[key]).join(' ')}
            </MenuItem>
          ))}
        </CssSelect>
      </CssForm>
    )
  }

  return (
    <CssForm size={size} fullWidth className={`${disabled && 'disabled'}`} style={{ position: "relative" }}>
      <InputLabel id="select-multiple-label">{label}</InputLabel>
      <CssSelect
        IconComponent={() => icon ? <div style={{ marginRight: 15 }}>{icon}</div> : null}
        labelId="select-multiple-label"
        multiple
        onClick={() => {
          if (!open && !disabled && isBtn) onClick()
          else if (!disabled && !open) setOpen(true)
        }}
        onClose={() => {
          setOpen(false);
          if (onClose) onClose();
        }}
        open={open}
        disabled={disabled}
        value={valuesSelect}
        onChange={handleChangeValue}
        className={`${isFilled ? 'filled' : 'empty'} ${variant} ${isBtn && "isBtn"}`}
        renderValue={(valuesSelect) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {valuesSelect?.map((value) => (
              <Chip size={size} key={value} label={truncateWithEllipsis(value)} onDelete={() => {
                if (isBtn) {
                  onRemove(value)
                } else {
                  handleDeleteChoice(value)
                  if (onRemove) onRemove(value)
                }
              }} />
            ))}
          </Box>
        )}
      >
        {items.map((name) => (
          <MenuItem
            key={name}
            value={name}
          >
            {name}
          </MenuItem>
        ))}
      </CssSelect>
    </CssForm>
  )
}

export const AutocompleteInput = (props) => {
  const {
    label,
    fetching = false,
    onChange = function () { },
    onSelect = function () { },
    value = '',
    items = [],
    variant = 'regular',
    disabled,
  } = props
  const [textValue, setTextValue] = useState(value)
  const [selectOpen, setSelectOpen] = useState(false)

  const ValueDisplay = () => {
    return items.map((item, i) => <div className={`item-fields ${value === item && 'isSelect'}`} onMouseDown={() => onSelect(item)} key={`adresse-${i}`}>{item}</div>)
  }

  useEffect(() => {
    if (!textValue) {
      setSelectOpen(false)
    }
    (textValue !== value) && !selectOpen && setSelectOpen(true)
    onChange(textValue)
  }, [textValue])

  function getState() {
    if (fetching) {
      return (<div><CircularProgress size={25} /></div>)
    } else if (!items.length) {
      return (<div>Aucun résultat</div>)
    } else {
      return <ValueDisplay />
    }
  }

  function handleQuitSelect(e) {
    setSelectOpen(false)
  }

  function handleFocusText() {
    items.length > 0 && setSelectOpen(true)
  }

  return (
    <div className="autocomplete-select">
      <InputText {...{ label, variant, disabled, value }} onChange={setTextValue} onFocus={handleFocusText} onBlur={handleQuitSelect} />
      {selectOpen && <div style={{ position: "relative" }}>
        <div style={{ zIndex: 99, backgroundColor: "white", display: "flex", flexDirection: "column", padding: "10px 10px", position: "absolute", top: 0, boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.25)", left: 0, right: 0, borderRadius: 5 }}>
          {getState()}
        </div>
      </div>}
    </div>
  )
}
