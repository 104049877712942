// import {
//   BLOG, HELP, HOME_DIGITAL_CRATION_GRAPHIC, HOME_DIGITAL_MARKETING, HOME_DIGITAL_SEA_SMO,
//   HOME_DIGITAL_SEO, HOME_DIGITAL_WEB_SITE, HOME_FLYING_BIKE, HOME_FLYING_BOX, HOME_FLYING_HAND,
//   HOME_FLYING_STORE, HOME_FLYING_WALL, HOME_STREET_MARKETING, HOME_PAGE_FFY
// } from "routes/vitrineRoutes";

export const navbarLinks = [
  {
    name: 'ACCUEIL',
    link: ' HOME_PAGE_FFY'
  },
  {
    name: 'STREET MARKETING',
    link: ' HOME_STREET_MARKETING',
    subLinks: [
      { name: 'DISTRIBUTION EN BOÎTE AUX LETTRES', link: ' HOME_FLYING_BOX' },
      { name: 'ANIMATION MAIN À MAIN', link: ' HOME_FLYING_HAND' },
      { name: 'DÉPÔT EN COMMERCE', link: ' HOME_FLYING_STORE' },
      { name: 'VÉLO PUBLICITAIRE', link: ' HOME_FLYING_BIKE' },
      { name: 'AFFICHAGE SAUVAGE', link: ' HOME_FLYING_WALL' }
    ]
  },
  {
    name: 'MARKETING DIGITAL',
    link: ' HOME_DIGITAL_MARKETING',
    subLinks: [
      { name: 'SITE INTERNET', link: ' HOME_DIGITAL_WEB_SITE' },
      { name: 'RÉFÉRENCEMENT NATUREL', link: ' HOME_DIGITAL_SEO' },
      { name: 'RÉGIE PUBLICITAIRE WEB', link: ' HOME_DIGITAL_SEA_SMO' },
      { name: 'CRÉATION GRAPHIQUE', link: ' HOME_DIGITAL_CRATION_GRAPHIC' }
    ]
  },
  {
    name: 'BLOG',
    link: ' BLOG'
  },
  {
    name: 'AIDE',
    link: ' HELP'
  }
  // { name: "RÉFÉRENCES", link:" """ },
  // { name: "BLOG", link:" BLOG" },
  // { name: "AIDE", link:" HELP" },
]
