import { ReactComponent as MyPOLYGON } from 'assets/polygon-svgrepo-com-ivan.svg'
import { ReactComponent as MyPIN } from 'assets/pin.svg'
import { ReactComponent as MyPOLYLINE } from 'assets/polyline.svg'
import { ReactComponent as MyMapVision } from 'assets/map-vision.svg'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { cloneElement, memo, useEffect, useState } from 'react'
import InputChangeActive from 'components/Inputs/InputChangeActive'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useSelector, useDispatch } from 'react-redux'
import { setVisibilityZone } from '_redux/Zones/reducers'
import { getClientVisibility } from 'utils/helpers'

const ItemTitleIcon = memo(({ type, item }) => {
  if (type === 'zones') {
    return cloneElement(
      <MyPOLYGON
      style={{ height: '16px', width:'16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
      />,
      { fill: item?.fillColor, stroke: item?.fillColor }
    )
  } else if (type === 'spots') {
    return cloneElement(
      <MyPIN style={{ height: '16px', width:'16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
      />,
      { fill: item?.fillColor || "red", stroke: item?.fillColor || "red" }
    )
  } else if (type === 'missions') {
    return cloneElement(
      <MyPOLYLINE
        style={{ height: '16px', width:'16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
      />,
      { fill: 'red', stroke: 'blue' }
    )
  }
})

const ContentName = memo(
  ({
    polyIsLoad,
    item,
    handleRemoveItem,
    isSelected,
    mapRef,
    itemId,
    currentPrestation,
    _slot,
    handleChangeTitle,
    itemsHide
  }) => {
    const [isHidden, setIsHidden] = useState(false)
    const visibilityZones = useSelector((state) => state.zone.visibilityZones)
    useEffect(() => {
      setIsHidden(itemsHide)
    }, [itemsHide])

    function OverItem (item) {
      item?.ref?.current?.setOptions({ fillColor: 'red' })
    }
    function handleVisibilityZone (item, visibilityZones) {
      const visibilityInfo = getClientVisibility(item, visibilityZones)
      return visibilityInfo.exist
        ? visibilityInfo.visibility
        : item.client_visible
    }

    function handleClickZoneName (currentRef) {
      if (mapRef.current && currentRef.current) {
        if (currentRef.current.getPosition) {
          const markerPosition = currentRef.current.getPosition()
          mapRef.current.panTo(markerPosition)
          const desiredZoomLevel = 18
          mapRef.current.setZoom(desiredZoomLevel)
        } else {
          const bounds = new window.google.maps.LatLngBounds()
          currentRef.current.getPath().forEach((latLng) => {
            bounds.extend(latLng)
          })
          mapRef.current.fitBounds(bounds)
        }
      }
    }

    const dispatch = useDispatch()

    function handleHideItem (e) {
      e.stopPropagation()
      setIsHidden((prev) => !prev)
      item?.ref?.current?.setOptions({ visible: isHidden })
      item?.additional_ref?.current?.setOptions({ visible: isHidden })
    }
    const activeZones = useSelector((state) => state.zone.activeZones)
    useEffect(() => {
      console.log(activeZones, 'activeZones-activeZones')
    }, [activeZones])
    return (
      <div
        id={`${itemId}`}
        className="title-item-container"
        style={
          activeZones.some((el) => el.id === item.id && el.type === item.type)
            ? {
                backgroundColor: '#f1f1f1',
                fontWeight: '800'
              }
            : {}
        }
        onMouseEnter={() => OverItem(item)}
        onMouseLeave={() =>
          item?.ref?.current?.setOptions({ fillColor: item?.fillColor })
        }
        onClick={() => handleClickZoneName(item?.ref)}
      >
                {polyIsLoad && <ItemTitleIcon type={item?.type} {...{ item }} />}
        <div className="title-part-2-container">
          {item?.type === 'missions'
            ? (
            `${item?.user?.first_name} ${item?.user?.last_name}`
              )
            : (
            <InputChangeActive
              title={item.title || 'nouvelle zone'}
              isSelected={isSelected}
              onChange={(e) => handleChangeTitle(e, item)}
            />
              )}

          <div className="icons-container">
            {isHidden
              ? (
              <VisibilityOffIcon onClick={handleHideItem} style={{ width:"18px", marginTop:"3px" }} />
                )
              : (
              <VisibilityIcon className="eye-open" onClick={handleHideItem} style={{ width:"18px", marginTop:"3px" }}/>
                )}
            <MyMapVision
              className={item.client_visible ? 'map-vision-true' : 'map-vision'}
              style={{ width:"18px" }}
                 onClick={() =>
                   dispatch(
                     setVisibilityZone({
                       ...item,
                       prestationTitle: currentPrestation.presta_type,
                       prestationId: currentPrestation.id,
                       slotTitle: _slot?.title || 'nouvelle zone'
                     })
                   )
              }
              color={
                item.client_visible &&
                handleVisibilityZone(item, [
                  ...visibilityZones.hide,
                  ...visibilityZones.publish
                ])
                  ? '#60EED2'
                  : handleVisibilityZone(item, [
                    ...visibilityZones.hide,
                    ...visibilityZones.publish
                  ]) !== item.client_visible
                    ? '#FF7A00'
                    : 'black'
              }
            />
            {item?.type !== 'missions' && (
              <div
                className="remove-item"
                onClick={(e) => handleRemoveItem(item, e)}
              >
                <DeleteRoundedIcon color="error" style={{ width:"18px" }}/>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
)

export default ContentName
