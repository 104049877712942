/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import bigLogo from 'assets/FFY-logo.png'
import './headerImage.scss'
import { ICON_FLECHE_BLANC_CERCLE } from '../../utils/imageUrls'

export const HeaderBloc = ({ height, Introduction, with_logo = false }) => {
  return (
    <div style={{ height, backgroundColor: '#23272A', maxWidth: 1440 }} className="image_container">
      {with_logo && <div className='biglogo'>
        <img src={bigLogo} data-src={bigLogo} alt="bigLogo" width={329} height={274} className="lazyload" />
      </div>
      }
      <div style={{ position: 'absolute', bottom: 20, left: '50%', right: '50%', zIndex: 10 }}>
       <img src={ICON_FLECHE_BLANC_CERCLE} data-src={ICON_FLECHE_BLANC_CERCLE} alt="fleche vers le bas"
        width={50} height={50} className="lazyload" />
      </div>
      <div className='bloctext'>
        <Introduction />
      </div>
    </div>
  )
}
