/* eslint-disable no-unused-vars */
import { memo, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addItemToDrag } from '_redux/Map/reducers'
import { useCampaignMap } from 'views/Cartes/CampaignViewMap/campaignMapContext'
import './style.scss'
import { ItemTitleIcon } from '../components/ItemsTitleIcon'
import { DrawPolyline } from '../../DrawingComponents/Polyline'
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ReactComponent as MyMapVision } from 'assets/map-vision.svg'
import { getItemType } from '../DraggableList/function'
import {
  setShowGraph,
  setCurrentMissionLaunch,
} from "_redux/Missions/reducers";
import { InfoWindow, Marker } from "@react-google-maps/api";

const MissionView = memo(({ currentMission, clientView }) => {
  const dispatch = useDispatch()
  const { multiSelectType, handleZoomMap, clientVisibility, handleClientVisibility, mapItemsVisibility, updateSingleItemVisibilityByType, currentMissionsLastLocations } = useCampaignMap()
  const itemsDragSelected = useSelector(state => state.map.itemsDragSelected)
  const [isHover, setIsHover] = useState(false)
  const isMoving = useSelector(state => state.map.isMoving)
  function handleAddItemDrag() {
    dispatch(addItemToDrag({ type: multiSelectType, value: { type: 'mission', ...currentMission } }))
    if (multiSelectType === 'default') {
      if (missionEnCoursPath?.length > 0 && currentMission?.sorted_positions?.length === 0) {
        handleZoomMap({ ...currentMission, sorted_positions: missionEnCoursPath })
      } else {
        handleZoomMap(currentMission)
      }
    }
  }
  const isSelected = itemsDragSelected.filter(current => current.type === "mission" && current.id === currentMission?.id).length > 0
  const isMultipleSelect = itemsDragSelected.length > 1

  const [isVisible, setIsVisible] = useState(true)
  const [missionEnCoursPath, setMissionEnCoursPath] = useState(null)

  useEffect(() => {
    if (currentMission?.status === 'En cours') {
      setMissionEnCoursPath(currentMission?.sorted_positions)
    }
  }, [currentMission]);

  useEffect(() => {
    setIsVisible(mapItemsVisibility?.missions?.[currentMission.id] ?? true);
  }, [mapItemsVisibility?.missions?.[currentMission.id], isVisible])

  const updateIsVisible = (value) => {
    updateSingleItemVisibilityByType(currentMission.id, 'missions', value);
  };

  function handleClickItem(e) {
    setIsHover(false)
    if (multiSelectType === 'cmd') {
      handleAddItemDrag()
    } else {
      handleZoomMap(currentMission)
    }
  }

  function handleMissionIsHover(value) { setIsHover(value) }

  const isItClientVisible = clientVisibility?.items?.find((zone) => (zone?.id === currentMission.id && getItemType(zone) === getItemType(currentMission)))

  const currentMissionLastLoation = useMemo(() => {
    const currentMissionLastLoation = currentMissionsLastLocations?.[currentMission.id.toString()] ?? null
    return currentMissionLastLoation
  }, [currentMissionsLastLocations, currentMission])

  useEffect(() => {
    if (currentMissionLastLoation) {
      setMissionEnCoursPath(prev => {
        const newData = structuredClone(prev) ?? [];
        if (currentMissionLastLoation?.pause_indicator === -1 || newData?.length === 0) {
          newData.push([currentMissionLastLoation])
        } else {
          newData[newData.length - 1].push(currentMissionLastLoation);
        }
        return newData;
      });
    }
  }, [currentMissionLastLoation, currentMission]);

  return (
    <div
      onClick={handleAddItemDrag}
      onMouseDown={() => {
        dispatch(setShowGraph(true));
        dispatch(
          setCurrentMissionLaunch({
            ...currentMission,
            locations: currentMission?.sorted_positions?.length
              ? [].concat(
                ...currentMission?.sorted_positions
              )
              : [],
          })
        );
      }}
      onMouseOver={() => handleMissionIsHover(true)}
      onMouseLeave={() => handleMissionIsHover(false)}
      className={`mission-view-container ${(isSelected) && 'selected'} ${isSelected && isMultipleSelect && 'multiple'} ${isSelected && isMoving && 'move'} ${(isHover) && 'isHover'} ${!isVisible && 'hidden'} ${currentMissionLastLoation?.on_break && 'mission-on-break-slot-container'}`}

    >
      <div style={{ display: "flex", height: "100%", overflow: "hidden" }}>
        <ItemTitleIcon type={'mission'} item={currentMission} pendingItem={currentMissionLastLoation} />
        <div className={`mission-name ${isHover && "isHover"}`}>
          {currentMission?.user.first_name}
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {!clientView && <>
          {((isHover || !isVisible)) && (
            <div onClick={(e) => {
              e.stopPropagation()
              updateIsVisible(!isVisible)
            }} className="btn">
              {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          )}
          {(((isHover || isItClientVisible) && (isVisible && !isItClientVisible)) || (currentMission.client_visible || isItClientVisible)) &&
            <div onClick={(e) => handleClientVisibility(e, currentMission)} className="btn">
              <MyMapVision
                style={{ width: "18px" }}
                color={
                  (isItClientVisible && "#FF7A00") ||
                  (currentMission.client_visible && "#60EED2")
                }
              />
            </div>}
          {/* {(((isHover && !isSelected) || (isSelected && !isMultipleSelect)) && isVisible) && (
            <div onClick={handleRemoveZone} className="btn">
              <DeleteRoundedIcon color="error" />
            </div>
          )} */}
        </>}
      </div>
      {isVisible && !currentMissionLastLoation && currentMission?.sorted_positions?.map((pos, index) => {
          const firstLocation = pos?.[0];
          const lastLocation = pos?.at(-1);
          const params = {
            onClick: handleClickItem,
            path: pos?.map(_res => ({ lat: _res?.latitude, lng: _res?.longitude })),
            options: {
              strokeColor: (isHover || isSelected) ? "red" : "blue", // Use the state variable for the color
            },
            circles: {
              start: {
                key: `${currentMission.id}-start`,
                center: { lat: firstLocation?.latitude, lng: firstLocation?.longitude },
                options: {
                  strokeColor: firstLocation?.pause_indicator === 0 ? 'green' : 'yellow',
                  fillColor: firstLocation?.pause_indicator === 0 ? 'green' : 'yellow',
                  fillOpacity: 1,

                },
                radius: 0.2,
              },
              end: {
                key: `${currentMission.id}-end`,
                center: { lat: lastLocation?.latitude, lng: lastLocation?.longitude },
                options: {
                  strokeColor: lastLocation?.pause_indicator === 0 ? 'red' : 'yellow',
                  fillColor: lastLocation?.pause_indicator === 0 ? 'red' : 'yellow',
                  fillOpacity: 1,
                },
                radius: 0.2,
              }
            }
          }
          return <DrawPolyline key={`mission-${currentMission.id}-location-${index}`} {...{ setIsHover, params, currentMission, location, handleClickItem }}/>
        })
      }
      {isVisible && missionEnCoursPath?.map((pos, index) => {
          const firstLocation = pos?.[0];
          const lastLocation = pos?.at(-1);
          const isLastPoint = index + 1 === missionEnCoursPath.length

          const params = {
            onClick: handleClickItem,
            path: pos?.map(_res => ({ lat: _res?.latitude, lng: _res?.longitude })),
            options: {
              strokeColor: (isHover || isSelected) ? "red" : "blue", // Use the state variable for the color
            },
            circles: {
              start: {
                key: `${currentMission.id}-start`,
                center: { lat: firstLocation?.latitude, lng: firstLocation?.longitude },
                options: {
                  strokeColor: firstLocation?.pause_indicator === 0 ? 'green' : 'yellow',
                  fillColor: firstLocation?.pause_indicator === 0 ? 'green' : 'yellow',
                  fillOpacity: 1,

                },
                radius: 0.2,
              },
              end: {
                key: `${currentMission.id}-end`,
                center: { lat: lastLocation?.latitude, lng: lastLocation?.longitude },
                options: {
                  strokeColor: isLastPoint ? 'transparent' : 'yellow',
                  fillColor: isLastPoint ? 'transparent' : 'yellow',
                  fillOpacity: 1,
                },
                radius: 0.2,
              }
            }
          }
          return <DrawPolyline key={`mission-${currentMission.id}-location-${index}`} {...{ setIsHover, params, currentMission, location, handleClickItem }}/>
        })
      }
      {isVisible && currentMissionLastLoation &&
        (<Marker
          visible={false}
          position={{
            lat: currentMissionLastLoation?.latitude,
            lng: currentMissionLastLoation?.longitude,
          }}
        >
          <InfoWindow
            defaultOptions={{ disableAutoPan: true }}
            onCloseClick={() => { }}
            position={{
              lat: currentMissionLastLoation?.latitude,
              lng: currentMissionLastLoation?.longitude,
            }}
            options={{
              ariaLabel: "infoWindowRealTimeSM",
              disableAutoPan: true,
            }}
          >
            <div className="infoWindowContainer" style={{ backgroundColor: currentMissionLastLoation.on_break ? '#F3B922B2' : 'white' }}>
              <img
                alt="visuel profile"
                src={
                  process.env.REACT_APP_BACK_API +
                  currentMission?.avatar_url
                }
              />
              <div>
                {currentMission?.user?.first_name +
                  " " +
                  currentMission?.user?.last_name}
              </div>
            </div>
          </InfoWindow>
        </Marker>)
      }
    </div>
  )
})

export default MissionView
