import React, { useCallback, useRef, useState } from "react";
import {
  MarkerClusterer,
  Marker,
  InfoWindow,
  Circle,
} from "@react-google-maps/api";
import "./style.scss";
import { ReactComponent as Star2Svg } from "assets/star2.svg";
import { formatHoursToHHMM } from "utils/helpers";
import { ReactComponent as CloseSvg } from "assets/close.svg";
import { ReactComponent as PoubelleSvg } from "assets/poubelle.svg";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import { Checkbox } from "@mui/material";

const getPinSvg = (color) => {
  return `<?xml version="1.0"?><svg width="24" height="32" viewBox="0 0 24 32" fill="${color}" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.5 12.3751C0.5 5.81141 5.67259 0.5 12.0002 0.5C18.3278 0.5 23.5003 5.81142 23.5 12.3747C23.5 13.9252 22.8453 15.859 21.8004 17.9373C20.762 20.0027 19.3671 22.1554 17.9491 24.1279C15.1648 28.0012 12.3216 31.1384 11.9998 31.4912C11.6765 31.1366 8.83428 28.0002 6.05086 24.1282C4.63288 22.1557 3.23802 20.0031 2.19961 17.9376C1.15472 15.8593 0.5 13.9256 0.5 12.3751ZM6.60805 12.3751C6.60805 15.4165 9.00869 17.9197 12.0002 17.9197C14.9917 17.9197 17.3923 15.416 17.3923 12.3748C17.3923 9.33346 14.9917 6.82978 12.0002 6.82978C9.00859 6.82978 6.60805 9.33386 6.60805 12.3751Z" fill="${color}" stroke="black"/>
          </svg>`;
};

const FlyingHandCadenceMapContent = ({
  spotsList,
  activeInfoWindow,
  handleMarkerClick,
  handleInfoWindowClose,
  handleDeleteSpotFromCadenceView,
}) => {
  const [selectedSpotToDelete, setSelectedSpotToDelete] = useState(null);
  const [visibleCircles, setVisibleCircles] = useState({});
  const visibleCirclesRef = useRef({});

  const closeDeleteConfirmationModal = () => {
    setSelectedSpotToDelete(null);
  };

  const handleConfirmDelete = () => {
    handleDeleteSpotFromCadenceView(selectedSpotToDelete);
    setSelectedSpotToDelete(null);
  };

  const [hiddenSpotsRayons, setHiddenSpotsRayons] = useState([]);

  const handleChangeRayonVisible = (newValue, spotId) => {
    if (newValue) {
      setHiddenSpotsRayons((prev) => prev.filter((spot) => spot.id === spotId));
    } else {
      if (!hiddenSpotsRayons.includes(spotId)) {
        setHiddenSpotsRayons((prev) => [...prev, spotId]);
      }
    }
  };

  const updatePolygonVisibility = useCallback((clusterer) => {
    const newVisible = {};
    clusterer.getClusters().forEach((cluster) => {
      const markers = cluster.getMarkers() || [];
      markers.forEach((marker) => {
        const polygonId = marker.getTitle();
        newVisible[polygonId] = cluster.getSize() <= 1;
      });
    });

    // Only update state if visible polygons have changed
    if (JSON.stringify(visibleCirclesRef.current) !== JSON.stringify(newVisible)) {
      visibleCirclesRef.current = newVisible;
      setVisibleCircles(newVisible);
    }
  }, [visibleCirclesRef, visibleCircles]);

  return (
    <MarkerClusterer
      onLoad={updatePolygonVisibility}
      onClusteringEnd={updatePolygonVisibility}
      averageCenter
    >
      {(clusterer) => (
        <>
          {spotsList?.map((spot) => {
            const spotAvgNote = spot.average_note != null ? parseInt(spot.average_note, 10) : null;
            const smsNames = spot.sms_names?.length ? spot.sms_names.join(" / ") : "-";
            const formattedTotalHoursWithoutPause = spot?.total_hours_without_pause ? formatHoursToHHMM(spot?.total_hours_without_pause) : "-";
            const flyersPerHour = Math.round(spot?.total_hours_without_pause > 0 ? spot.total_distributed_flyers / spot.total_hours_without_pause : 0);

            return (
              <Marker
                key={spot.id}
                clusterer={clusterer}
                position={{
                  lat: spot.address.latitude,
                  lng: spot.address.longitude,
                }}
                title={spot.id.toString()}
                icon={{
                  url:
                    "data:image/svg+xml;charset=UTF-8," +
                    encodeURIComponent(getPinSvg(spot?.fillColor ?? "black")),
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                onClick={() => handleMarkerClick(spot.id)}
              >
                {!hiddenSpotsRayons.includes(spot.id) && (
                  <Circle
                    center={{
                      lat: spot.address.latitude,
                      lng: spot.address.longitude,
                    }}
                    radius={!spot.radius ? spot.radius : spot?.radius}
                    options={{
                      fillColor: spot?.fillColor ?? "#000",
                      strokeColor: spot?.fillColor ?? "#000",
                    }}
                    onClick={() => handleMarkerClick(spot.id)}
                    visible={visibleCircles[spot.id] ?? true}
                  />
                )}
                {selectedSpotToDelete && selectedSpotToDelete === spot.id && (
                  <DeleteConfirmationModal
                    type={"spot"}
                    handleCancel={closeDeleteConfirmationModal}
                    open
                    handleConfirm={handleConfirmDelete}
                  />
                )}
                {activeInfoWindow === spot.id && (
                  <InfoWindow
                    onCloseClick={handleInfoWindowClose}
                    options={{ headerDisabled: true }}
                  >
                    <div className="cadence-spot-infowindow-container">
                      <div className="cadence-spot-infowindow-header">
                        <PoubelleSvg
                          width={22}
                          height={22}
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedSpotToDelete(spot.id)}
                        />
                        <CloseSvg
                          width={22}
                          height={22}
                          style={{ cursor: "pointer" }}
                          onClick={handleInfoWindowClose}
                        />
                      </div>
                      <div className="cadence-spot-title-conatiner">
                        Informations du spot
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">Nom :</div>
                        <div className="spot-value">{spot?.title ?? "-"}</div>
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">Adresse :</div>
                        <div className="spot-value">
                          {spot?.address?.label ?? "-"}
                        </div>
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">Description :</div>
                        <div className="spot-value">
                          {spot?.description?.length ? spot?.description : "-"}
                        </div>
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">Type de spot :</div>
                        <div className="spot-value">
                          {spot?.spot_type ?? "-"}
                        </div>
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">
                          Nombre moyen de flyers distribués :
                        </div>
                        <div className="spot-value">
                          {spot?.average_distributed_flyers ?? "-"}
                        </div>
                      </div>
                      <div
                        className="cadence-spot-key-value-conatiner"
                        style={{ alignItems: "center" }}
                      >
                        <div className="spot-key">Note moyenne :</div>
                        <div className="spot-value" style={{ gap: 4 }}>
                          {typeof spotAvgNote === "number"
                          ? (
                            Array.from({ length: 5 }).map((_, index) => (
                              <Star2Svg
                                key={index}
                                color={
                                  index < spotAvgNote ? "#60EED2" : "white"
                                }
                                height={20}
                                width={20}
                              />
                            ))
                          )
                          : (
                            <span>[PAS DE NOTE]</span>
                          )}
                        </div>
                      </div>
                      <div className="cadence-spot-title-conatiner">
                        Informations cadence de distrib
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">Nom du SM :</div>
                        <div className="spot-value">{smsNames}</div>
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">
                          Total heures sans pause :
                        </div>
                        <div className="spot-value">
                          {formattedTotalHoursWithoutPause}
                        </div>
                      </div>
                      <div className="cadence-spot-key-value-conatiner">
                        <div className="spot-key">
                          Nombre total de flyers distribués :
                        </div>
                        <div className="spot-value">
                          {spot?.total_distributed_flyers ?? "-"}
                        </div>
                      </div>
                      <div
                        className="cadence-spot-key-value-conatiner"
                        style={{ fontWeight: 700 }}
                      >
                        <div className="spot-key">
                          Nombre de flyers par heure :
                        </div>
                        <div className="spot-value">{flyersPerHour}</div>
                      </div>
                      {spot?.radius > 0 && (
                        <div
                          className="cadence-spot-key-value-conatiner"
                          style={{ gap: 0, alignItems: "center" }}
                        >
                          <div className="spot-key">
                            Voir le rayon geofence:
                          </div>
                          <div className="spot-value">
                            <Checkbox
                              checked={!hiddenSpotsRayons?.includes(spot.id)}
                              onChange={(e) =>
                                handleChangeRayonVisible(
                                  e?.target.checked,
                                  spot.id
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        </>
      )}
    </MarkerClusterer>
  );
};

export default FlyingHandCadenceMapContent;
