/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { Polygon } from '@react-google-maps/api'
import React, { useCallback, useState, useEffect } from 'react'
import { wktToPath } from 'views/Admin/Views/Cartes/functions/functionsMap'
import { parse } from 'wellknown'

const MemoizedPolygonComponent = ({ item, handleActivePolygon, onPolygonChanged, isSelected }) => {
  const [selectedVertexIndex, setSelectedVertexIndex] = useState()
  const { ref, polygon, fillColor, strokeWeight, fillOpacity } = item
  const new_zone_ref = ref

  const handleKeyDown = useCallback(
    (event) => {
      const path = ref?.current?.getPath() ?? 0
      if (event.shiftKey && event.key === 'Delete' && selectedVertexIndex !== undefined && selectedVertexIndex !== null && isSelected && path.length > 3) {
        ref.current.setPath(ref.current.getPath().removeAt(selectedVertexIndex))
        setSelectedVertexIndex(null)
        onPolygonChanged(null, 'delete')
      }
    },
    [selectedVertexIndex, isSelected]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    if (!isSelected) window.removeEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedVertexIndex, isSelected])

  const onLoad = (ref) => {
    new_zone_ref.current = ref
  }

  function handleOverZone () {
    const current_doc = document.getElementById(
      `item-zones-${item?.id}`
    )
    if (current_doc) {
      current_doc.style.backgroundColor = '#f1f1f1'
      current_doc.style.fontWeight = '800'
    }
  }

  function handleQuitZone () {
    if (!new_zone_ref.current.editable) {
      const current_doc = document.getElementById(
        `item-zones-${item?.id}`
      )
      if (current_doc) {
        current_doc.style.backgroundColor = '#fff'
        current_doc.style.fontWeight = 'unset'
      }
    }
  }
  return (
    <Polygon
      path={wktToPath(
        polygon ? parse(polygon)?.coordinates[0] : []
      )}
      options={{ strokeColor: fillColor, fillColor, strokeWeight, fillOpacity }}
      clickable
      onLoad={(e) => onLoad(e)}
      onDragEnd={(e) => onPolygonChanged(e, 'drag')}
      onMouseUp={(e) => {
        console.log("UP")
        setSelectedVertexIndex(e.vertex)
        onPolygonChanged(e, 'modify')
      }}
      onDblClick={(e) => {
        setSelectedVertexIndex(null)
        handleActivePolygon(e, new_zone_ref, item)
      }}
      onMouseOver={() => handleOverZone()}
      onMouseOut={() => handleQuitZone()}
    />
  )
}

export default MemoizedPolygonComponent
