/* eslint-disable react/prop-types */
import ReportIcon from "@mui/icons-material/Report";
import { IconButton, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import PolicyIcon from "@mui/icons-material/Policy";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const IconedStatus = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const errors = [];
  let status = "";

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function getCurrentIcon () {
    if (!item?.date_start_mission) errors.push("il manque la date de debut");
    if (!item?.date_end_mission) errors.push("il manque la date de fin");
    if (!item?.address) errors.push("il manque l'addresse de début de mission");
    if (!item?.cost) errors.push("il manque le tarif horaire");
    if (errors.length > 0) {
      return (
        <ReportIcon style={{ width: "16px", height: "16px" }} color="error" />
      );
    } else if (!item.missions?.length > 0 && item.active) {
      status = "En recherche de street marketeur";
      return (
        <PolicyIcon style={{ width: "16px", height: "16px" }} color="warning" />
      );
    } else if (!item.missions?.length > 0) {
      status =
        "Le slot n'est pas disponible pour les street marketeur (inactif)";
      return (
        <WarningIcon style={{ width: "16px", height: "16px" }} color="warning" />
      );
    } else if (item.missions?.length > 0) {
      status = "Le slot est valide";
      return (
        <CheckCircleIcon
          style={{ width: "16px", height: "16px" }}
          color="success"
        />
      );
    }
  }

  const open = Boolean(anchorEl);
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: "4px",
        transform: "translate(-50%, -10%)",
        display: "flex",
      }}
    >
      <IconButton
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {getCurrentIcon()}
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 99999, backgroundColor: "transparent" }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{ padding: 15, backgroundColor: "#ffecaa" }}>
          {errors.map((error, index) => (
            <Typography key={`current-error-${index}`}>{error}</Typography>
          ))}
          <Typography sx={{ p: 1 }}>{status}</Typography>
        </div>
      </Popover>
    </div>
  );
};

export default IconedStatus;
