/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { toWKT, toWKT2 } from 'views/Admin/Views/Cartes/functions/functionsMap'
import MemoizedPolygonComponent from '../../slots/polygons'
import MemoizedNewPolylineComponent from '../../slots/newPolyline'
import API from 'services/axios-config'
import MemoizedPointComponent from '../../slots/point'
import React, { useEffect, useState, useCallback } from 'react'
import MemoizedPolylineComponent from '../polyline'

const MapsDraw = ({ type, item, handleActivePolyline, handleActivePolygon, setSlotListFormatted, handleClickMarker, currentPrestation, activePolygon, activePolyline }) => {
  const [polylineHistory, setPolylineHistory] = useState([])
  const [polygonHistory, setPolygonHistory] = useState([])

  const onPolygonChanged = (e, currentMovement) => {
    if ((currentMovement === 'delete') || (currentMovement === 'modify' && e.path >= 0) || (currentMovement === 'drag')) {
      API.patch(`${type}/${item?.id}`, { polygon: toWKT(item?.ref?.current) })
      if (polygonHistory.length === 0 || JSON.stringify(toWKT(item?.ref?.current)) !== JSON.stringify(polygonHistory[polygonHistory.length - 1])) {
        setPolygonHistory(prev => [...prev, toWKT(item?.ref?.current)])
      }
      setSlotListFormatted(prev => prev.map(_slot => {
        if (_slot?.id === item?.slot_id) {
          return {
            ..._slot,
            fullList: _slot?.fullList?.map(_item => {
              if ((_item?.id === item?.id && _item?.type === item?.type)) {
              // API.patch(`${item?.type}/${item?.id}`, { ...{ fillColor, strokeColor, strokeWeight, fillOpacity } })
                return { ...item, polygon: toWKT(item?.ref?.current) }
              }
              return _item
            })
          }
        } else { return _slot }
      }))
    }
  }

  const onPolylineChanged = useCallback((e, currentMovement) => {
    if ((currentMovement === 'delete') || (currentMovement === 'modify')) {
      API.patch(`${type}/${item?.id}`, { line: toWKT2(item?.ref?.current) })
      if (polylineHistory.length === 0 || JSON.stringify(toWKT2(item?.ref?.current)) !== JSON.stringify(polylineHistory[polylineHistory.length - 1])) {
        setPolylineHistory(prev => [...prev, toWKT2(item?.ref?.current)])
      }
      setSlotListFormatted(prev => prev.map(_slot => {
        if (_slot?.id === item?.slot_id) {
          return {
            ..._slot,
            fullList: _slot?.fullList?.map(_item => {
              if ((_item?.id === item?.id && _item?.type === item?.type)) {
              // API.patch(`${item?.type}/${item?.id}`, { ...{ fillColor, strokeColor, strokeWeight, fillOpacity } })
                return { ...item, line: toWKT2(item?.ref?.current) }
              }
              return _item
            })
          }
        } else { return _slot }
      }))
    }
  }, [polylineHistory, item])

  const handleActivePolygonParams = (event, item_ref, itemId) => {
    handleActivePolygon(event, item_ref, itemId, 'slots')
  }

  const handleActivePolylineParams = (event, item_ref, itemId) => {
    handleActivePolyline(event, item_ref, itemId, 'slots')
  }

  const handleUndo = useCallback(() => {
    if (activePolyline && polylineHistory.length > 1) {
      const previousItem = polylineHistory[polylineHistory.length - 2]

      API.patch(`${type}/${item?.id}`, { line: previousItem })
        .then(() => {
          setSlotListFormatted(prev => prev.map(_slot => {
            if (_slot?.id === item?.slot_id) {
              return {
                ..._slot,
                fullList: _slot?.fullList?.map(_item => {
                  if ((_item?.id === item?.id && _item?.type === item?.type)) {
                  // API.patch(`${item?.type}/${item?.id}`, { ...{ fillColor, strokeColor, strokeWeight, fillOpacity } })
                    return { ...item, line: previousItem }
                  }
                  return _item
                })
              }
            } else { return _slot }
          }))
          if (polylineHistory.length > 1) { setPolylineHistory((prev) => prev.slice(0, -1)) }
        })
        .catch((error) => {
          console.error('Undo API error:', error)
        })
    } else if (activePolygon && polygonHistory.length > 1) {
      const previousItem = polygonHistory[polygonHistory.length - 2]
      API.patch(`${type}/${item?.id}`, { polygon: previousItem })
        .then(() => {
          setSlotListFormatted(prev => prev.map(_slot => {
            if (_slot?.id === item?.slot_id) {
              return {
                ..._slot,
                fullList: _slot?.fullList?.map(_item => {
                  if ((_item?.id === item?.id && _item?.type === item?.type)) {
                  // API.patch(`${item?.type}/${item?.id}`, { ...{ fillColor, strokeColor, strokeWeight, fillOpacity } })
                    return { ...item, polygon: previousItem }
                  }
                  return _item
                })
              }
            } else { return _slot }
          }))
          if (polygonHistory.length > 1) { setPolygonHistory((prev) => prev.slice(0, -1)) }
        })
        .catch((error) => {
          console.error('Undo API error:', error)
        })
    }
  }, [polylineHistory, item, activePolyline, activePolygon, polygonHistory])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        handleUndo()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleUndo])

  if (type === 'zones') {
    if (item.polygon) {
      const isSelected = activePolygon && JSON.stringify(activePolygon?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      }))) === JSON.stringify(item.ref?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      })))

      return <MemoizedPolygonComponent handleActivePolygon={handleActivePolygonParams} {...{ onPolygonChanged, item }} isSelected={isSelected ?? false} />
    } else if (item.line) {
      const isSelected = activePolyline && JSON.stringify(activePolyline?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      }))) === JSON.stringify(item.ref?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      })))
      return <MemoizedNewPolylineComponent handleActivePolyline={handleActivePolylineParams} {...{ onPolylineChanged, item }} isSelected={isSelected ?? false} />
    }
  }
  if (type === 'missions') {
    return <MemoizedPolylineComponent item={item} />
  }
  if (type === 'spots') {
    return <MemoizedPointComponent {...{ item, currentPrestation, handleClickMarker }} />
  }
}

export default MapsDraw
