import React, { useState } from 'react';
import './style.scss';
import { ReactComponent as CloseSvg } from 'assets/close.svg'
import { TextField } from '@mui/material'

export const ConfirmRefuseModal = ({ isOpen, onClose, onConfirm }) => {
    const [description, setDescription] = useState('');

    const handleConfirm = () => {
        if (description.trim() !== '') {
            onConfirm(description);
        }
    };

    return (
        <div className={`confirm-modal-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="confirm-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="confirm-modal-header">
                    <div className="confirm-modal-title">TU ES SUR LE POINT DE REFUSER UN DEVIS CLIENT</div>
                    <div className="close-button" onClick={onClose}>
                        <CloseSvg width={25} height={25} fill="black"/>
                    </div>
                </div>
                <div className="confirm-modal-description">
                    Ajoute une description qui sera visible par le client et qui explique pourquoi le devis à été refusé
                </div>
                <div>
                    <TextField
                        multiline
                        fullWidth
                        rows={3}
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Ce champs sert a décrire brièvement la campagne et le client pour que le SM ait des informations. “Ex: Lettre d’information concernant un projet éolien à Sainte-Thorette pour l'entreprise RWE.”"
                        sx={{
                            marginBottom: '20px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                 borderColor: 'black',
                                 borderRadius: '10px',
                                 '&:hover fieldset': {
                                    borderColor: 'black',
                                  },
                                  '&.Mui-focused fieldset': {
                                     borderColor: 'black',
                                  },
                                },
                            }
                        }}
                    />
                </div>
                <div className="confirm-modal-btns">
                    <div className="devis-modal-btn" onClick={handleConfirm} style={{ color: description.trim() !== '' ? '#60EED2' : '#A9A9A9', cursor: description.trim() !== '' ? 'pointer' : 'not-allowed' }}>CONFIRMER</div>
                    <div className="devis-modal-btn" onClick={onClose} style={{ color: '#FF0000' }}>ANNULER</div>
                </div>
            </div>
        </div>
    );
};

export const ConfirmCreateModal = ({ isOpen, onClose, onConfirm }) => {

    return (
        <div className={`confirm-modal-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="confirm-modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="confirm-modal-header">
                    <div className="confirm-modal-title">TU ES SUR LE POINT DE CRÉER UNE CAMPAGNE QUI ÉTAIT LIÉE À UN DEVIS !</div>
                    <div className="close-button" onClick={onClose}>
                        <CloseSvg width={25} height={25} fill="black"/>
                    </div>
                </div>
                <div className="confirm-modal-description">
                    Cela va passer le statut du devis en à “validé” pour le client.
                </div>
                <div className="confirm-modal-description">
                    La campagne sera visible comme d&apos;habitude quand elle sera en “en cours”.
                </div>
                <div className="confirm-modal-btns" style={{ marginTop: 70 }}>
                    <div className="devis-modal-btn" onClick={onConfirm} style={{ color: '#60EED2' }}>CONFIRMER</div>
                    <div className="devis-modal-btn" onClick={onClose} style={{ color: '#FF0000' }}>ANNULER</div>
                </div>
            </div>
        </div>
    );
};
