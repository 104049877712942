/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { ModalComponent } from '../modalComponent/modalcomponent'
import VideoPlayer from '../../../home/components/VideoPlayer/videoPlayer'
import InputVideo from './VideoContent/inputVideo'
import './videoPlayer.scss'

const UseCaseVideo = ({ data, openModal, setOpenModal }) => {
  const [videoUrl, setVideoUrl] = useState(null)
  const [isSaved, setIsSaved] = useState(false)

  return (
    <>
      <VideoPlayer height={500} url={isSaved ? videoUrl : data?.datas?.url} fullWidth={true} />
      {openModal && <ModalComponent open={openModal} sectionName="video_header" setOpenModal={setOpenModal}
        data={{ url: videoUrl }} setIsSaved={setIsSaved} id={data?.id} disable={videoUrl === null || isSaved}
        component={<InputVideo setIsSaved={setIsSaved} url={data?.datas?.url || videoUrl} setVideoUrl={setVideoUrl} />}
      />}
    </>
  )
}

export default UseCaseVideo
