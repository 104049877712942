import { memo, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import MapsDraw from "./MapsDraw";
import DrawingView from "../drawingView";
import API from "services/axios-config";
import InputChangeActive from "components/Inputs/InputChangeActive";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./style.scss";
import "../style.scss";
import IconedStatus from "./IconedStatus";
import MenuDialog from "./MenuDialog";
import ContentName from "./ZoneName";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setActiveZones, setLastSelectedZone } from "_redux/Zones/reducers";
import { handleAddItemShape } from "utils/helpers";
import { ReactComponent as DropdownSvg } from "assets/dropdown-icon.svg";

const SlotListItems = memo(
  ({
    _itemsList,
    provided,
    handleRemoveItem,
    slot_id,
    isSelected,
    handleActivePolyline,
    handleActivePolygon,
    handleClickMarker,
    mapRef,
    _slot,
    showRow,
    setSlotListFormatted,
    itemsHide,
    currentPrestation,
    activePolygon,
    activePolyline,
  }) => {
    const [polyIsLoad, setPolyIsLoad] = useState(false);
    useEffect(() => {
      !polyIsLoad && setPolyIsLoad(true);
    }, []);
    const dispatch = useDispatch();

    function handleAddItem (zoneInfos) {
      setSlotListFormatted((prev) =>
        prev.map((_slot) => {
          if (_slot?.id === zoneInfos?.slot_id) {
            return {
              ..._slot,
              fullList: [..._slot.fullList, zoneInfos],
            };
          }
          return _slot;
        })
      );
    }
    function handleClickAddZone (itemClickable, indexItem) {
      if (isShiftPressed) {
        let index = -1; // Initialize index to -1 zonesto indicate not found
        for (const item of activeZones) {
          const foundIndex = _itemsList.findIndex(
            (elem) => elem.id === item.id && elem.type === item.type
          );
          if (foundIndex !== -1) {
            index = foundIndex;
            break;
          }
        }

        if (index === -1) {
          handleAddItemShape(
            true,
            itemClickable,
            setActiveZones,
            activeZones,
            dispatch
          );
        } else {
          const startIndex = Math.min(index + 1, indexItem);
          const endIndex = Math.max(index, indexItem);

          const filteredItems = _itemsList
            .slice(startIndex, endIndex + 1)
            .filter((item) => {
              // Define your filtering conditions here
              return !activeZones.some(
                (el) => el.id === item.id && el.type === item.type
              );
            });
          dispatch(
            setActiveZones([
              ...activeZones,
              ...filteredItems.map((item) => ({
                type: item.type,
                id: item.id,
                slot_id: item.slot_id,
              })),
            ])
          );
        }
      } else {
        handleAddItemShape(
          isCmdPressed,
          itemClickable,
          setActiveZones,
          activeZones,
          dispatch
        );
      }
      console.log(activeZones, "activeZoneFor this");
    }
    function handleChangeTitle (value, item) {
      API.patch(`${item?.type}/${item?.id}`, { title: value });
      setSlotListFormatted((prev) =>
        prev.map((_slot) => {
          if (_slot?.id === slot_id) {
            return {
              ..._slot,
              fullList: _slot.fullList.map((_item) => {
                if (_item.id === item?.id && _item.type === item?.type) {
                  return { ..._item, title: value };
                } else return _item;
              }),
            };
          }
          return _slot;
        })
      );
    }
    const isCmdPressed = useSelector((state) => state.zone.isCmdPressed);
    const activeZones = useSelector((state) => state.zone.activeZones);
    const isShiftPressed = useSelector((state) => state.zone.isShiftPressed);
    const clientView = useSelector((state) => state.zone.clientView);
    return (
      <div style={{ marginBottom: 5 }}>
        {isSelected && (
          <DrawingView
            currentPrestation={currentPrestation}
            params={{ slot_id }}
            handleAddItem={handleAddItem}
          />
        )}
        {_itemsList?.map((item, index) => {
          if (item.client_visible || !clientView) {
            const itemId = `item-${item?.type}-${item?.id}`;
            if (
              item?.type !== "missions" ||
              item?.location?.positions?.length > 0
            ) {
              return (
                <Draggable
                  key={`draggable-slot-${index}-${item?.type}-${item?.id}`}
                  draggableId={`draggable-slot-${slot_id}-${index}`}
                  index={index}
                >
                  {(provided) => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => {
                          handleClickAddZone(item, index);
                          if (item.polygon) {
                            handleActivePolygon(null, item.ref, item);
                          } else if (item.line) {
                            handleActivePolyline(null, item.ref, item);
                          } else if (item.type === "spots") {
                            handleClickMarker(null, item.ref, item);
                          }
                        }}
                      >
                        <MapsDraw
                          type={item?.type}
                          {...{
                            item,
                            handleActivePolygon,
                            handleActivePolyline,
                            handleClickMarker,
                            setSlotListFormatted,
                            slot_id,
                            activePolygon,
                            activePolyline,
                            currentPrestation,
                          }}
                          index={index}
                          lengthItems={_itemsList?.length}
                        />
                        {showRow && (
                          <ContentName
                            {...{
                              polyIsLoad,
                              item,
                              _slot,
                              currentPrestation,
                              handleRemoveItem,
                              isSelected,
                              mapRef,
                              itemId,
                              handleChangeTitle,
                              itemsHide,
                            }}
                          />
                        )}
                      </div>
                    );
                  }}
                </Draggable>
              );
            }
          }
        })}
        {provided.placeholder}
      </div>
    );
  }
);

const SlotView = ({
  setSlotListFormatted,
  _slot,
  provided,
  handleRemoveItem,
  handleActivePolygon,
  handleClickMarker,
  mapRef,
  slotSelected,
  handleChangeTitle,
  currentPrestation,
  activePolygon,
  activePolyline,
  handleActivePolyline,
}) => {
  const [itemsHide, setItemsHide] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const lastSelectedZone = useSelector((state) => state.zone.lastSelectedZone);
  const dispatch = useDispatch();
  function handleRemoveSlot (slot_id) {
    API.delete(`slots/${slot_id}`).then((res) => {
      setSlotListFormatted((prev) =>
        prev.filter((_slot) => !(_slot?.id === slot_id))
      );
    });
  }

  function handleHideItems () {
    setItemsHide((prev) => !prev);
    _slot.fullList.forEach((_item) => {
      _item?.ref?.current?.setOptions({ visible: itemsHide });
      _item?.additional_ref?.current?.setOptions({ visible: itemsHide });
    });
  }

  function handleSendModify (params) {
    API.patch(`slots/${_slot?.id}`, { ...params })
      .then((res) => {
        enqueueSnackbar("Le slot à bien été modifié", { variant: "success" });
        setSlotListFormatted((prev) =>
          prev.map((_current) => {
            if (_slot?.id === _current?.id) {
              return {
                ..._current,
                ...params,
              };
            }
            return _current;
          })
        );
      })
      .catch((_) =>
        enqueueSnackbar("La modification du slot n'a pas fonctionnée", {
          variant: "error",
        })
      );
  }

  return (
    <div className="slot-view">
      {/* <div className={`active-slot-view ${isSelected && "active"}`}></div> */}
      <div style={{ flex: 1 }}>
        <div
          className="bar-drop"
          onClick={() => {
            dispatch(setLastSelectedZone({ id: _slot.id }));
            setIsSelected(!isSelected);
          }}
        >
          <IconedStatus item={_slot} />
          <div style={{ gap: 5, display: "flex", alignItems: "center" }}>
            <DropdownSvg
              style={{ width: 10, height: 10, marginTop: "-1px" }}
              className={isSelected ? "rotate" : ""}
            />
            <InputChangeActive
              title={_slot.title || "SLOT"}
              isSelected={isSelected}
              onChange={(e) => handleChangeTitle(e, _slot?.id)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {itemsHide && <VisibilityOffIcon onClick={handleHideItems} />}
            <MenuDialog
              {...{
                handleRemoveSlot,
                handleHideItems,
                itemsHide,
                handleSendModify,
              }}
              slot={_slot}
              campaign={{
                status: currentPrestation?.campaign_status,
                id: currentPrestation?.campaign_id,
              }}
            />
          </div>
        </div>
        <div
          style={{
            visibility: itemsHide ? "hidden" : "visible",
            height: itemsHide ? "1px" : "unset",
          }}
        >
          <SlotListItems
            key={`items-slot-${_slot.id}`}
            {...{
              provided,
              handleRemoveItem,
              handleActivePolygon,
              handleClickMarker,
              mapRef,
              setSlotListFormatted,
              itemsHide,
              currentPrestation,
              activePolygon,
              activePolyline,
              handleActivePolyline,
            }}
            _slot={_slot}
            slot_id={_slot?.id}
            _itemsList={_slot.fullList}
            showRow={isSelected}
            isSelected={slotSelected && lastSelectedZone?.id === _slot?.id}
          />
        </div>
      </div>
    </div>
  );
};

const SlotListView = ({
  slotListFormatted,
  handleSelectSlot,
  slotSelected,
  handleRemoveItem,
  handleActivePolygon,
  handleClickMarker,
  mapRef,
  setSlotListFormatted,
  currentPrestation,
  activePolygon,
  activePolyline,
  handleActivePolyline,
}) => {
  function handleChangeTitle (value, slot_id) {
    API.patch(`slots/${slot_id}`, { title: value });
    setSlotListFormatted((prev) =>
      prev.map((_slot) => {
        if (_slot?.id === slot_id) return { ..._slot, title: value };
        return _slot;
      })
    );
  }

  function handleAddSlot () {
    API.post("slots", {
      prestation_id: currentPrestation?.id,
      campaign_id: currentPrestation?.campaign_id,
    }).then((res) => {
      setSlotListFormatted((prev) => [...prev, { ...res.data, fullList: [] }]);
    });
  }

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {slotListFormatted?.map((_slot, index) => (
        <Droppable
          key={`current-slot-droppable-${_slot?.id}`}
          droppableId={`slots-${_slot?.id}-slots`}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              key={`slot-${_slot?.id}-view`}
              onClick={() => handleSelectSlot(_slot)}
            >
              <SlotView
                {...{
                  setSlotListFormatted,
                  _slot,
                  provided,
                  handleRemoveItem,
                  handleActivePolygon,
                  handleClickMarker,
                  mapRef,
                  handleChangeTitle,
                  currentPrestation,
                  activePolygon,
                  activePolyline,
                  handleActivePolyline,
                  slotSelected,
                }}
                isSelected={slotSelected?.id === _slot?.id}
              />
            </div>
          )}
        </Droppable>
      ))}
      <div className="add-slot-btn" onClick={() => handleAddSlot()}>
        Ajouter un slot
      </div>
    </div>
  );
};

export default SlotListView
