/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import './style.scss'
import '../styles.scss'
import HeaderList from '../Headers'

import ViewsListTable from 'components/Table/ViewsListTable'
import { createData } from 'components/Table/ViewsListTable/function'
import { enqueueSnackbar } from 'notistack'
import { getAllCampaigns, getAllDevis } from './functions'
import CampaignCardView from '../../Campaign/CampaignCard'
import SlotCardView from '../../Slot/SlotCard'
import { HandleMap, VisuStatus, CampaignName } from './campaignsComponent'
import { useSelector, useDispatch } from 'react-redux'

import { setCampaignsList, updateCampaignList, clearCampaignsList } from '_redux/Campaigns/reducers'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import { Filter } from './filter'
import API from 'services/axios-config'
import ComponentCampaignModification from '../../Slot/SlotCard/ComponentSlotCardView/ComponentCampaignModification'
// eslint-disable-next-line no-unused-vars
import { RightModalProvider, useRightModal } from './rightModalContext'
import { SlidingModal } from './devisModal'
import { Modal } from '@mui/material'
// eslint-disable-next-line no-unused-vars
import CampaignViewMap from 'views/Cartes/CampaignViewMap'
import FilterComponent from 'components/GlobalInputs/Filters'

const campaignFilterStatutList = ['Brouillon', 'En recherche de SM', 'En cours', 'Terminée']
const campaignFilterDateList = ['7 DERNIERS JOURS', '14 DERNIERS JOURS', '28 DERNIERS JOURS', '3 DERNIERS MOIS']
const campaignFilterPrestationsList = ['Flying Box', 'Flying Hand', 'Flying Bike', 'Flying Wall', 'Flying Store']
const campaignFilterResponsableOperationnel = ['THIAGO', 'SYFAX']
const campaignFilterResponsableCommercial = ['CHEIKH']

const RightAction = (props) => {
  const [, setSearchParams] = useSearchParams()
  const { addToStack } = useRightModal()

  function handleClick() {
    API.post('campaigns')
      .then(res => {
        setSearchParams(`campaign_id=${res?.data?.id}`)
        addToStack({ component: <ComponentCampaignModification {...props} campaignData={{ campaign: res.data }} /> })
      })
      .catch(err => enqueueSnackbar('L\'ajout de campagne à échouée', err))
  }
  return <BlackBtn handleAction={handleClick} reverse label="NOUVELLE CAMPAGNE" />
}

const LeftComponent = (props) => {
  const [inputValue, setInputValue] = useState('')
  const [typingTimeout, setTypingTimeout] = useState(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleInputChange = (event) => {
    const value = event.target.value
    setInputValue(value)
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    const newTimeout = setTimeout(() => {
      const params = new URLSearchParams(searchParams)
      if (params.has('search')) {
        params.set('search', value)
      } else {
        params.append('search', value)
      }
      setSearchParams(params)
    }, 500)

    setTypingTimeout(newTimeout)
  }

  return (
    <div className="bookmark-list">
      <h1 className="bookmark-title">{props.title}</h1>
      <div className="bookmark-filter">
        <input value={inputValue} onChange={handleInputChange} type="text" placeholder="Rechercher..." className="temporary-filter" />
      </div>
    </div>
  )
}

const CampaignList = () => {
  const [fetching, setFetching] = useState(true)
  const [campaignsListFormated, setCampaignsListFormated] = useState([])
  const [campaignsListRaw, setCampaignsListRaw] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const mapOpen = searchParams.get('maps')
  const campaignOpen = searchParams.get('campaign_id')
  const devisOpen = searchParams.get('devis_id')
  const [currentPage, setCurrentPage] = useState(0)
  const [per, setPer] = useState(25)
  const [maxPage, setMaxPage] = useState(1)
  const rawList = useSelector(state => state.campaignsList.list)
  const dispatch = useDispatch()
  const [isDevis, setIsDevis] = useState(false)

  /* FOR FILTERS */
  const [filtersParams, setFiltersParams] = useState({})
  const [selectedFilters, setSelectedFilters] = useState({
    status: [],
    date: null,
    prestation: [],
    responsable_com: null,
    responsable_op: null,
  });

  const filters = [
    { key: 'status', label: "STATUT", options: campaignFilterStatutList, multiple: true },
    { key: 'date', label: "DATE", options: campaignFilterDateList, multiple: false },
    { key: 'prestation', label: "PRESTATION", options: campaignFilterPrestationsList, multiple: true },
    { key: 'responsable_com', label: "RESPONSABLE COMMERCIAL", options: campaignFilterResponsableCommercial, multiple: false },
    { key: 'responsable_op', label: "RESPONSABLE OPÉRATIONNEL", options: campaignFilterResponsableOperationnel, multiple: false },
  ];

  useEffect(() => {
    // status
    if (selectedFilters.status?.length > 0) {
      setFiltersParams(prev => ({ ...prev, status: selectedFilters.status }))
    } else {
      setFiltersParams(prev => {
        const { status, ...rest } = prev
        return rest;
      })
    }

    // prestation
    if (selectedFilters.prestation?.length > 0) {
      setFiltersParams(prev => ({ ...prev, prestation: selectedFilters.prestation }))
    } else {
      setFiltersParams(prev => {
        const { prestation, ...rest } = prev
        return rest;
      })
    }

    // date
    if (selectedFilters.date) {
      setFiltersParams(prev => ({ ...prev, date: selectedFilters.date }))
    } else {
      setFiltersParams(prev => {
        const { date, ...rest } = prev
        return rest;
      })
    }

    // responsable_com
    if (selectedFilters.responsable_com) {
      setFiltersParams(prev => ({ ...prev, responsable_com: selectedFilters.responsable_com }))
    } else {
      setFiltersParams(prev => {
        const { responsable_com, ...rest } = prev
        return rest;
      })
    }

    // responsable_op
    if (selectedFilters.responsable_op) {
      setFiltersParams(prev => ({ ...prev, responsable_op: selectedFilters.responsable_op }))
    } else {
      setFiltersParams(prev => {
        const { responsable_op, ...rest } = prev
        return rest;
      })
    }
  }, [selectedFilters])

  /* END FOR FILTERS */

  useEffect(() => {
    dispatch(clearCampaignsList())
  }, [currentPage])

  useEffect(() => {
    dispatch(clearCampaignsList())
    setCurrentPage(0);
  }, [isDevis])

  useEffect(() => {
    dispatch(setCampaignsList([]))
    if (searchParams.get('devis') || searchParams.get('devis_id')) {
      setIsDevis(true)
      setFetching(false)
      handleChangeData({
        campaigns: [],
        campaigns_id: [],
        maxPage: 0,
      })
    } else {
      setIsDevis(false)
      const newSearchParams = new URLSearchParams()
      if (searchParams?.get('search')) newSearchParams.set('search', searchParams.get('search'))
      if (searchParams?.get('status')) newSearchParams.set('status', searchParams.get('status'))
      if (searchParams?.get('future')) newSearchParams.set('future', searchParams.get('future'))
      if (searchParams?.get('past')) newSearchParams.set('past', searchParams.get('past'))
      /* filters params */
      Object.entries(filtersParams).forEach(([key, value]) => {
        newSearchParams.set(key, value)
      });
      /* end filters params */
      setCurrentPage(0)
      if (!searchParams.has('campaign_id')) setSearchParams(newSearchParams)
    }
  }, [searchParams, filtersParams])

  useEffect(() => {
    if (rawList?.some(item => item?.campaign) || rawList.length === 0) {
      setCampaignsListRaw(rawList)
      setCampaignsListFormated(createData(rawList.map((item) => item?.campaign), isDevis ? devisItems : items))
    }
  }, [rawList, isDevis])

  async function handleActionCampaign(action) {
    switch (action?.type) {
      case 'row-action': {
        const campaign_id = action?.row?.find(_item => _item?.id === 'id').value
        setSearchParams(`campaign_id=${campaign_id}`)
        break
      }
      default:
        enqueueSnackbar('Action non connecté')
        break
    }
  }

  async function handleActionDevis(action) {
    switch (action?.type) {
      case 'row-action': {
        const devisId = action?.row?.find(_item => _item?.id === 'id').value
        setSearchParams(`devis_id=${devisId}`)
        break
      }
      default:
        enqueueSnackbar('Action non connecté')
        break
    }
  }

  function handleMap(e, id) {
    e.stopPropagation()
    setSearchParams(`campaign_id=${id}&maps=true`)
  }

  const items = [
    { id: 'id', exist: true },
    { id: 'campaign_name', column_name: 'CAMPAGNE', maxLength: 20, exist: true, isVisible: true, component: <CampaignName campaignsList={rawList} /> },
    { id: 'is_alert', column_name: 'IS ALERT', exist: true, isVisible: false },
    { id: 'company_name', column_name: 'CLIENT', sub_items: ['company', 'company_name'], maxLength: 20, isVisible: true },
    { id: 'previsionnal_start_date', exist: true, format: 'date', column_name: 'DATES', isVisible: true },
    { id: 'status', width: 400, column_name: 'STATUT', exist: true, align: 'center', isVisible: true, component: <VisuStatus campaignsList={rawList} /> },
    { id: 'chat', column_name: 'CARTE', type: 'picture', align: 'center', isVisible: true, isSVG: true, component: <HandleMap action={handleMap} /> }
  ]

  const devisItems = [
    { id: 'id', exist: true },
    { id: 'campaign_name', column_name: 'NOM', maxLength: 20, exist: true, isVisible: true },
    { id: 'company_name', column_name: 'CLIENT', sub_items: ['company', 'company_name'], maxLength: 20, isVisible: true },
    { id: 'date_start', exist: true, format: 'date', column_name: 'DATES', isVisible: true },
    { id: 'status', width: 400, column_name: 'STATUT', exist: true, isVisible: true, component: <VisuStatus campaignsList={rawList} isDevis /> },
  ]

  function handleChangeData(data) {
    setMaxPage(data?.maxPage)
    dispatch(updateCampaignList(data.campaigns_id))
    dispatch(setCampaignsList(data.campaigns.map((campaignData) => ({ campaign: campaignData }))))
  }

  function handleChangeDevisData(data) {
    setMaxPage(data?.maxPage)
    dispatch(updateCampaignList(data.devis_ids))
    dispatch(setCampaignsList(data.devis.map((devisData) => ({ campaign: devisData }))))
  }

  useEffect(() => {
    if (searchParams.get('devis') || searchParams.get('devis_id')) {
      setFetching(true)
      getAllDevis(currentPage, per, searchParams)
        .then(res => {
          handleChangeDevisData(res?.data)
        })
        .catch(err => enqueueSnackbar(err, { variant: 'error' }))
        .finally(_ => setFetching(false))
    } else {
      getAllCampaigns(currentPage, per, searchParams)
        .then(res => {
          handleChangeData(res?.data)
        })
        .catch(err => enqueueSnackbar(err, { variant: 'error' }))
        .finally(_ => setFetching(false))
    }
  }, [currentPage, per, searchParams])

  const handleCloseDevisModal = () => {
    setSearchParams((prevParams) => {
      prevParams.delete('devis_id');
      prevParams.set('devis', 'true');
      return prevParams;
    });
  };

  const createCampaignFromDevis = (devisId) => {
    API.post('campaigns', { from_devis_id: devisId })
      .then(res => {
        setSearchParams(`campaign_id=${res?.data?.id}&fromDevis=true`)
      })
      .catch(err => enqueueSnackbar('L\'ajout de campagne à échouée', err))
  }

  return (
    <RightModalProvider>
      <div className="list-container">
        <div className="list-content">
          <HeaderList leftComponent={<LeftComponent title={isDevis ? 'DEVIS' : 'CAMPAGNES'} isDevis={isDevis} />} rightComponent={<RightAction />} />
          {!isDevis && <div className="filter-component-compaigns-container">
            <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} />
          </div>}
          <ViewsListTable {...{ fetching, setCurrentPage, currentPage, setPer, per, setMaxPage, maxPage }} handleActions={(action) => {
            if (isDevis) handleActionDevis(action)
            else handleActionCampaign(action);
          }} columns={isDevis ? devisItems : items} list={campaignsListFormated} filter={<Filter />} />
        </div>
        {campaignOpen && !mapOpen && <CampaignCardView {...{ rawList }} campaignsListRaw={campaignsListRaw} />}
        {/* {devisOpen && <div>Here</div>} */}
        {devisOpen && <SlidingModal isOpen={devisOpen} onClose={handleCloseDevisModal} devisId={devisOpen} onCreateCampaign={createCampaignFromDevis} />}
        <SlotCardView />
        <Modal open={mapOpen}><CampaignViewMap /></Modal>
        {/* <Modal open={mapOpen}><CreateCampaignMap/></Modal> */}
      </div>
    </RightModalProvider>
  )
}
export default CampaignList
