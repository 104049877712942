import API from 'services/axios-config'

export const handleSaveUsecase = (id, updateInfos, setIsSaved, setOpenModal, setIsLoading) => {
  API?.patch(`/sections/${id}`, { datas: updateInfos })
    .then(res => {
      setIsSaved(true)
      setOpenModal(false)
      setIsLoading(false)
    })
    .catch((err) => console.log(err))
}

export const handleHideUsecase = (id, updateInfos) => {
  API?.patch(`/sections/${id}`, updateInfos)
    .then(_ => _)
    .catch((err) => console.log(err))
}

export const handleSaveUsecaseCover = (id, updateInfos, navigate) => {
  API?.patch(`/usecases/${id}`, updateInfos)
    .then(_ => _)
    .catch((err) => console.log(err))
}

export const createUseCase = (navigate, setCurrentUseCase, setisLoaded) => {
  API?.post('/usecases')
    .then((res) => {
      setCurrentUseCase(null)
      navigate(`?id=${res?.data?.id}`)
      setisLoaded(true)
    })
    .catch((err) => console.log(err))
}

export const getUseCaseById = (id, setCurrentUseCase, setisLoaded) => {
  API?.get(`/usecases/${id}`)
    .then((res) => {
      setCurrentUseCase(res?.data)
      setisLoaded(true)
    })
    .catch((err) => console.log(err))
}

export const updateIndexSectionsOfUseCase = (id, index) => {
  API.patch(`/sections/${id}`, { position: index })
    .then(_ => _)
    .catch((err) => console.log(err))
}

const headers = {
  'Content-Type': 'multipart/form-data'
}

export const sendImageFile = (section, fileImage, id) => {
  const formData = new FormData()
  formData?.append('section', section)
  formData?.append('picture', fileImage)
  API.patch(`/usecases/${id}/add_picture_to_section`, formData, { headers })
    .then((res) => console.log(res?.data))
    .catch((err) => console.log(err))
}

export const getAllUsecases = (setUseCaseList, setIsLoaded) => {
  API?.get('/usecases')
    .then((res) => {
      setUseCaseList(res?.data)
      setUseCaseList((prev) => {
        return prev?.map((use, index) => ({ ...use, disable: false }))
      })
      setIsLoaded(true)
    })
    .catch((err) => console.log(err))
}

export const deleteUsecase = (id, setUseCaseList, setOpenConfirm, setIsLoading) => {
  setIsLoading(true)
  API.delete(`usecases/${id}`)
    .then(() => {
      setUseCaseList(prev => prev?.filter(usecase => usecase?.id !== id))
      setOpenConfirm(false)
      setIsLoading(false)
    })
    .catch((err) => { console.log(`delete usecase ${err}`) })
}

export const activateUseCase = (id, disable) => {
  API.patch(`usecases/${id}`, { visible: disable })
    .then(_ => _)
    .catch((err) => console.log(`disable usecase ${err}`)
    )
}
