import { createSlice } from "@reduxjs/toolkit";

const fetchingData = { fetching: true };

export const campaignListAction = createSlice({
  name: "campaignList",
  initialState: {
    type: "view",
    fetching: false,
    adminsList: [],
    companyList: [],
    list: [], // [{campaign:{...}, company:{...}, prestations:{..., slots:{..., missions:{...}}}, have_picture: int]
    assignmentList: [],
    changeAssignments: {},
    newAssignments: [],
    newSlots: [],
    currentPrestations: [],
    currentCampaign: {},
  },
  reducers: {
    getCampaignsList: (state) => ({ ...state, ...fetchingData }),
    campaignsListFetched: (state, action) => ({
      ...state,
      list: action.payload,
      fetching: false,
    }),
    updateCampaignList: (state, action) => ({
      ...state,
      list: state.list.filter((item) =>
        action.payload.includes(item.campaign.id)
      ),
    }),
    clearCampaignsList: (state) => {
      return { ...state, list: [] };
    },
    setCampaignsList: (state, action) => {
      let updatedList = []
      if (action.payload?.length > 0) {
        updatedList = [...state.list];
      }
      action.payload.forEach((payloadItem) => {
        const existingIndex = state.list.findIndex(
          (item) => item.campaign.id === payloadItem.campaign.id
        );
        if (existingIndex !== -1) {
          updatedList[existingIndex] = {
            ...state.list[existingIndex],
            ...payloadItem,
            campaign: {
              ...state.list[existingIndex].campaign,
              ...payloadItem.campaign,
            },
          };
        } else {
          updatedList.push(payloadItem);
        }
      });
      return { ...state, list: updatedList };
    },
    addSlot: (state, action) => {
      const { campaignId, prestationId, addSlot, address } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    slots: [...prestation.slots, {
                      ...addSlot,
                      address: address,
                    }],
                  };
                }
                return prestation;
              }),
            };
          }
          return campaign;
        }),
      };
    },
    updateSlot: (state, action) => {
      const { campaignId, prestationId, slotId, updatedSlot } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    slots: prestation.slots.map((slot) => {
                      return slot.id === slotId ? updatedSlot : slot;
                    }),
                  };
                }
                return prestation;
              }),
            };
          }
          return campaign;
        }),
      };
    },
    deleteTeam: (state, action) => {
      const { campaignId, prestationId, teamId } = action.payload;
      return {
        ...state,
        list: state.list.map((_campaign) => {
          if (_campaign.campaign.id === campaignId) {
            return {
              campaign: {
                ..._campaign.campaign,
                teams: _campaign.campaign.teams.filter((t) => t.id !== teamId),
              },
              prestations: _campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    slots: prestation.slots.map((slot) => ({
                      ...slot,
                      teams: slot?.teams?.filter((team) => team.id !== teamId),
                    })),
                  };
                }
                return prestation;
              }),
            };
          }
          return _campaign;
        }),
      };
    },
    removeSlot: (state, action) => {
      const { campaignId, prestationId, slotId } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    slots: prestation.slots.filter(
                      (slot) => slot.id !== slotId
                    ),
                  };
                }
                return prestation;
              }),
            };
          }
          return campaign;
        }),
      };
    },
    addMission: (state, action) => {
      const { campaignId, prestationId, slotId, addMission } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    slots: prestation.slots.map((slot) => {
                      if (slot.id === slotId) {
                        return {
                          ...slot,
                          missions:
                            slot?.missions?.length > 0
                              ? [...slot.missions, addMission]
                              : [addMission],
                        };
                      }
                      return slot;
                    }),
                  };
                }
                return prestation;
              }),
            };
          }
          return campaign;
        }),
      };
    },
    afterMissionValidate: (state, action) => {
      // missionId here is the one that got validated, so all others should be already refused
      const { campaignId, prestationId, slotId, missionId } =
        action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    slots: prestation.slots.map((slot) => {
                      if (slot.id === slotId) {
                        return {
                          ...slot,
                          missions: slot.missions.map((mission) => {
                            return mission.id === missionId
                              ? mission
                              : { ...mission, status: 'Refusé' };
                          }),
                        };
                      }
                      return slot;
                    }),
                  };
                }
                return prestation;
              }),
            };
          }
          return campaign;
        }),
      };
    },
    updateMission: (state, action) => {
      const { campaignId, prestationId, slotId, missionId, updatedSlot } =
        action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    slots: prestation.slots.map((slot) => {
                      if (slot.id === slotId) {
                        return {
                          ...slot,
                          missions: slot.missions.map((mission) => {
                            return mission.id === missionId
                              ? { ...mission, ...updatedSlot }
                              : mission;
                          }),
                        };
                      }
                      return slot;
                    }),
                  };
                }
                return prestation;
              }),
            };
          }
          return campaign;
        }),
      };
    },
    modifyCampaignInfo: (state, action) => {
      const { id, campaignData } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === id) {
            return {
              ...campaign,
              campaign: {
                ...campaign.campaign,
                ...campaignData,
              },
            };
          }
          return campaign;
        }),
      };
    },
    modifyDocumentCampaignInfo: (state, action) => {
      const { id, documentsData } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === id) {
            return {
              ...campaign,
              documents: [...campaign.documents, documentsData],
            };
          }
          return campaign;
        }),
      };
    },
    deleteDocumentCampaignInfo: (state, action) => {
      console.log(action, "iiiii");
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === action.payload.campaign_id) {
            return {
              ...campaign,
              documents: campaign.documents.filter(
                (doc) => doc.id !== action.payload.document_id
              ),
            };
          }
          return campaign;
        }),
      };
    },
    modifyCompanyInfo: (state, action) => {
      const { id, company } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === id) {
            return {
              ...campaign,
              company: {
                ...campaign.company,
                ...company,
              },
            };
          }
          return campaign;
        }),
      };
    },
    modifyPrestationInfo: (state, action) => {
      const { campaignId, prestationId, prestationInfo } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.map((prestation) => {
                if (prestation.id === prestationId) {
                  return {
                    ...prestation,
                    ...prestationInfo,
                  };
                }
                return prestation;
              }),
            };
          }
          return campaign;
        }),
      };
    },
    deleteCampaignAction: (state, action) => {
      const { id } = action.payload;
      return {
        ...state,
        list: state.list.filter((campaign) => campaign.campaign.id !== id),
      };
    },
    addPrestation: (state, action) => {
      const { campaignId, prestationData } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: [...campaign.prestations, prestationData],
            };
          }
          return campaign;
        }),
      };
    },
    deletePrestation: (state, action) => {
      const { campaignId, prestationId } = action.payload;
      return {
        ...state,
        list: state.list.map((campaign) => {
          if (campaign.campaign.id === campaignId) {
            return {
              ...campaign,
              prestations: campaign.prestations.filter(
                (prestation) => prestation.id !== prestationId
              ),
            };
          }
          return campaign;
        }),
      };
    },
  },
});

export const {
  getCampaignsList,
  campaignsListFetched,
  updateCampaignList,
  setCampaignsList,
  clearCampaignsList,
  addSlot,
  updateSlot,
  removeSlot,
  deleteTeam,
  addMission,
  updateMission,
  afterMissionValidate,
  modifyCampaignInfo,
  deleteDocumentCampaignInfo,
  modifyCompanyInfo,
  modifyPrestationInfo,
  deleteCampaignAction,
  modifyDocumentCampaignInfo,
  addPrestation,
  deletePrestation,
} = campaignListAction.actions;

export default campaignListAction.reducer;
