/* eslint-disable react/prop-types */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import React, { useRef, useState } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export const SelectSimpleComponent = ({
  defaultValue = '',
  value,
  handleAction,
  label,
  items = [],
  multiple = false,
  style: newStyle,
  disabled = false,
  color = 'black'
}) => {
  const [focused, setFocused] = useState(false)
  const [valueSelect, setValueSelect] = useState(value
    // multiple ? isStringArray(value) : value
  )
  const selectRef = useRef(null)
  const selectedColor = color.toLowerCase() === 'black'

  const styles = { ...defaultStyle(selectedColor, disabled), ...newStyle }

  const handleChange = (event) => {
    const {
      target: { value }
    } = event

    setValueSelect(typeof value === 'string' ? value?.split(',') : value)
    handleAction(value)
  }

  function handleFocus () {
    setFocused(true)
  }
  function handleBlur () {
    setFocused(false)
  }

  return (
    <FormControl fullWidth style={styles.global}>
      <InputLabel
        style={
          focused || valueSelect?.length
            ? { ...styles.label, ...styles.labelFocused }
            : styles.label
        }
      >
        {label}
      </InputLabel>
      <Select
        ref={selectRef}
        multiple={multiple}
        disabled={disabled}
        defaultValue={defaultValue}
        value={valueSelect}
        sx={styles.select}
        IconComponent={(props) => (
          <ArrowDropDownIcon
            {...props}
            style={styles.icon}
          />
        )}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        {items?.map((item, index) => (
          <MenuItem
            key={`current-select-${index}`}
            value={typeof item === 'object' ? item?.value : item}
          >
            {typeof item === 'object' ? item?.name : item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
const defaultStyle = (selectedColor, disabled) => ({
  select: {
    '& .MuiOutlinedInput-input': {
      // padding: "10px 15px",
      borderRadius: '30px !important',
      backgroundColor: selectedColor ? 'rgba(0,0,0)' : 'rgba(255,255,255)',
      color: selectedColor ? 'white' : 'black',
      fontSize: 12,
      fontWeight: 700,
      border: '1px solid black',
      overflow: 'hidden',
      '&.Mui-disabled': {
        color: 'black !important',
        WebkitTextFillColor: 'unset',
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  labelFocused: {
    backgroundColor: 'white',
    padding: '2px 15px',
    borderRadius: '20px',
    color: '#000'
  },
  label: {
    fontSize: 17,
    fontWeight: 700,
    color: selectedColor ? 'white' : 'black'
  },
  icon: {
    color: disabled ? 'rgba(0,0,0,0)' : selectedColor ? 'white' : 'black',
    fontSize: 35
  }
})
