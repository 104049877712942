/* eslint-disable react/prop-types */
import React from 'react'
import moment from 'moment'
import { Skeleton } from '@mui/material'
import './style.scss'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import ComponentCampaignModification from 'views/Slot/SlotCard/ComponentSlotCardView/ComponentCampaignModification'
import { useSearchParams } from 'react-router-dom'
import CampaignStatusCard from 'components/CampaignStatusCard'

const LeftContent = (props) => {
  const { campaignData, loading } = props
  const { addToStack } = useRightModal()
  const [searchParams] = useSearchParams()

  const flyersQuantity =
    campaignData && campaignData.prestations
      ? campaignData.prestations.reduce((total, prestation) => {
        const quantity = parseInt(prestation.quantity)
        return isNaN(quantity) ? total : total + quantity
      }, 0)
      : 0

  React.useEffect(() => {
    if (searchParams.get('fromDevis') && campaignData?.campaign?.devis) {
      addToStack({
        component: (
          <ComponentCampaignModification {...props} type="update" />
        )
      })
    }
  }, [searchParams, campaignData])

  return (
    <div className="left-content">
      <div>
        <div className="rowed-content">
          {!loading
            ? (
            <div className="campaign-name_date">
              <div className="campaign-name">
                {campaignData?.campaign?.campaign_name
                  ? campaignData?.campaign?.campaign_name
                  : '|Nom de campagne non défini|'}
              </div>
              <div className="campaign-date">
                Du{' '}
                {campaignData?.campaign?.previsionnal_start_date
                  ? moment(campaignData?.campaign?.previsionnal_start_date).format(
                    'DD/MM/YYYY'
                  )
                  : '|à définir|'}{' '}
                au{' '}
                {campaignData?.campaign?.previsionnal_end_date
                  ? moment(campaignData?.campaign?.previsionnal_end_date).format(
                    'DD/MM/YYYY'
                  )
                  : '|à définir|'}
              </div>
              <CampaignStatusCard status={campaignData?.campaign?.status} />
            </div>
              )
            : (
            <div>
              <Skeleton variant="rounded" width={450} height={40} />
            </div>
              )}
        </div>
        {!loading
          ? (
          <div className="campaign-company-name">
            {campaignData?.company?.company_name}
          </div>
            )
          : (
          <Skeleton variant="rounded" width={300} height={20} />
            )}
        {!loading
          ? (
          <div className="campaign-flyers-quantity">
            QUANTITE DE FLYERS : {flyersQuantity}
          </div>
            )
          : (
          <Skeleton variant="rounded" width={300} height={20} />
            )}
        {!loading
          ? (
          <div className="campaign-flyers-quantity">
            NOM DE LA CAMPAGNE (DASHBOARD CLIENT) :{' '}
            {campaignData?.campaign?.campaign_name_client
              ? campaignData?.campaign?.campaign_name_client
              : '|Non défini|'}
          </div>
            )
          : (
          <Skeleton variant="rounded" width={300} height={20} />
            )}
        {!loading
          ? (
          <div className="campaign-flyers-quantity" style={{ whiteSpace: 'pre-wrap', maxWidth: '600px' }}>
            DESCRIPTION DE CAMPAGNE : <br />
            {campaignData?.campaign?.description
              ? campaignData?.campaign?.description.length > 300
                ? campaignData?.campaign?.description.slice(0, 300) + '...'
                : campaignData?.campaign?.description
              : '|Aucune observation|'}
          </div>
            )
          : (
          <Skeleton variant="rounded" width={300} height={20} />
            )}
      </div>
      {!loading
        ? (
        <BlackBtn
          label="MODIFIER LA CAMPAGNE"
          styles={{ width: 'fit-content' }}
          handleAction={() =>
            addToStack({
              component: (
                <ComponentCampaignModification {...props} type="update" />
              )
            })
          }
        />
          )
        : (
        <Skeleton
          variant="rounded"
          width={235}
          height={60}
          sx={{ borderRadius: 50 }}
        />
          )}
    </div>
  )
}

export default LeftContent
