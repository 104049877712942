export const secteurActivity = [
  'RESTAURATION', 'IMMOBILIER', 'SONDAGE', 'LOCATION VÉHICULE', 'MISE EN RELATION',
  'FINANCE', 'ESTHÉTIQUE', 'INNOVATION', 'LIVRAISON', 'POLITIQUE', 'SPORT', 'RADIO',
  'MUSIQUE', 'HÔTELLERIE', 'E-COMMERCE', 'AUTOMOBILE', 'ENERGIE', 'ANIMAUX', 'BATIMENT',
  'EDUCATION', 'MEDICAL', 'INSTITUTION PUBLIQUE', 'MODE', "CULTURE"
]

export const problmatique = [
  'ACQUISITION CLIENT', 'VISIBILITÉ', 'IDENTITÉ', 'LANCEMENT'
]

export const solutionsName = [
  'FLYING HAND', 'FLYING BOX', 'FLYING STORE', 'FLYING BIKE', 'FLYING WALL',
  'CRÉATION GRAPHIQUE', 'SITE WEB', 'PUB EN LIGNE'
]
