/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { BsFillImageFill } from 'react-icons/bs'
import { useSearchParams } from 'react-router-dom'
import { sendImageFile } from '../../../functions/apiFunctions'
import './inputImage.scss'

const InputImage = ({ useCaseSection, img, setState, inputID, className, uploadclass }) => {
  const [queryParams] = useSearchParams()
  const id = queryParams?.get('id')
  const [styles, setStyles] = useState({ style: null })

  const handleMouseEvent = (zIndex) => {
    setStyles({ style: { zIndex } })
  }

  const handleUploadImage = (e) => {
    const file = e?.target?.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setState(reader?.result)
      }
      sendImageFile(useCaseSection, file, id)
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className={`inputImage-temp ${className}`} onMouseEnter={() => handleMouseEvent(1)} onMouseLeave={() => handleMouseEvent(-1)}>
      <input type={'file'} accept={'.webp, .png, .jpeg, .jpg'}
        name={inputID} id={inputID} onChange={handleUploadImage} />
        {
          img && <img src={img} alt={inputID}
            className={`lazyload ${uploadclass}`} />
        }
      <label htmlFor={inputID}>
        <BsFillImageFill size={40} className="select-logo"
          style={img && styles?.style} color={img ? 'white' : 'black'} />
      </label>
    </div>
  )
}

export default InputImage
