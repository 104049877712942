/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Button, CircularProgress, Popover } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import moment from 'moment'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'
import ComponentGaleriePhoto from '../../ComponentGaleriePhoto'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import './style.scss'
import { useNavigate } from 'react-router-dom'
import API from 'services/axios-config'
import { enqueueSnackbar } from 'notistack'

const InfosMission = (props) => {
  const { mission } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [disabledClick, setDisabledClick] = useState(false)
  const color = styleMission()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover-infos-mission' : undefined
  const { addToStack } = useRightModal()
  const navigate = useNavigate()

  function styleMission () {
    let color
    if (['Terminée'].includes(mission?.status)) { color = '#07C025' } else if (['En cours'].includes(mission?.status)) { color = '#60EEDD' } else if (['Validé', 'En attente de lancement', 'En attente de confirmation'].includes(mission?.status)) { color = '#60EEDD' } else { color = '#FF0000' }
    return color
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChangeMission = (state) => {
    setDisabledClick(true)
    props.handleModifyMission({ ...state, id: mission?.id }).finally(() => {
      handleClose()
      setDisabledClick(false)
    })
  }

  function handleGoTo(url) {
    window.open(url, '_blank');
  }

  const handleSendCheckIn = () => {
    API.get(`/missions/${mission?.id}/force_sm_check_in`)
    .then(_ => {
      enqueueSnackbar("Notification vers SM pour forcer le check-in est envoyer avec success.", { variant: "success" });
    })
    .catch((err) => {
      console.error(err);
      enqueueSnackbar("Erreur lors de l'envoie de notification pour forcer le check-in : ", err, { variant: "error" });
    });
  }

  return (
    <div className='infos-missionCandidate-container'>
      <div className='missionCandidate-container'>
        <div style={{ position: "absolute", marginBottom: 50, fontSize: 8 }}>{mission.id}</div>
        <div onClick={() => navigate(`/admin/street-marketeurs?marketer_id=${mission.user_id}`)} style={{ display: "inline", cursor: "pointer" }}>{mission.user_name}</div>
        <Button aria-describedby={id} variant="contained" onClick={handleClick} style={{ color, background: color + '50' }} className='missionCandidate-state-container' disabled={disabledClick}>
          <div className='container-flex'>
            <div>
            {disabledClick && <div><CircularProgress style={{ position: 'absolute', width: '30px', height: '30px', top: '15%', left: '40%' }} /></div> }
              <span style={{ background: color }} className='missionCandidate-state-dot' ></span>
              <div style={{ filter: disabledClick ? 'blur(4px)' : '', display: 'inline' }}>{mission.status}</div>
            </div>
            <ArrowDropDownIcon />
          </div>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div className='popover-missionCandidate-container' >
            {
              ['Terminée', 'En cours', 'Validé', 'En attente de lancement', 'En attente de confirmation', 'En attente de validation', 'Refusé'].map((state, index) => {
                return <div
                  key={index}
                  className='popover-item'
                  style={{ color: state === mission.status ? color : 'black', pointerEvents: state === mission.status || disabledClick ? 'none' : '' }}
                  onClick={disabledClick ? null : () => handleChangeMission({ status: state })}
                >
                  {state}
                </div>
              })
            }
          </div>
        </Popover>
      </div>
      <div className='missionInfos-container'>
        <div className='missionInfos-check'>
          <div>Check-in:</div>
          <div style={{ color: mission.check_in ? '' : '#FF0000' }}>{mission.check_in ? moment(mission.check_in).format('LLLL') : 'Non défini' }</div>
          <div>Check-out:</div>
          <div style={{ color: mission.check_out ? '' : '#FF0000' }}>{mission.check_out ? moment(mission.check_out).format('LLLL') : 'Non défini' }</div>
        </div>
        <div className='missionInfos-buttons'>
          {["En cours", "Terminée"].includes(mission.status) && <>
            <BlackBtn label='VOIR LES PHOTOS' handleAction={() => addToStack({ component: <ComponentGaleriePhoto missionId={mission.id} /> })} />
            <BlackBtn label='VALIDATION DE MISSION' handleAction={() => handleGoTo(`/admin/waiting_validation?mission_id=${mission.id}`)} />
          </>}
          {["Validé", "En attente de lancement"].includes(mission.status) && <>
            <BlackBtn label='LANCER LE CHECK-IN' handleAction={handleSendCheckIn} styles={{ margin: 'auto' }} />
          </>}

        </div>
      </div>
    </div>
  )
}

export default InfosMission
