/* eslint-disable camelcase */
import API from 'services/axios-config'

const addObjectAtIndex = (index, newObject, list) => {
  const updatedItems = [...list]
  updatedItems.splice(index, 0, newObject)
  return updatedItems
}

const addArrayAtIndex = (index, newArray, list) => {
  const updatedItems = [...list]
  updatedItems.splice(index, 0, ...newArray)
  return updatedItems
}
// const sendChange = (slot_id, prestation_id, item, type) => {
//       API.patch(`${type}/${item?.id}`, {...{slot_id, prestation_id}})
//       .then(_ => {

//         console.log("OK")
//       })
//       console.log("SEND ==> ", slot_id, prestation_id, item)
//     }

const onDragEnd = (
  setActiveZones,
  activeZones,
  slotListFormatted,
  initialsListFormatted,
  result,
  setSlotListFormatted,
  setInitialsListFormatted,
  currentPrestation
) => {
  if (!result?.destination?.droppableId) return
  let removed = null

  if (activeZones.length < 2) {
    if (
      result.source.droppableId.split('-')[
        result.source.droppableId.split('-').length - 1
      ] === 'initials'
    ) {
      setInitialsListFormatted((prev) =>
        prev.filter((_item, index) => {
          if (index === result.source.index) removed = _item
          return !(result.source.index === index)
        })
      )
    } else if (
      result.source.droppableId.split('-')[
        result.source.droppableId.split('-').length - 1
      ] === 'slots'
    ) {
      const slot_id =
        result.draggableId.split('-')[result.draggableId.split('-').length - 2]
      setSlotListFormatted((prev) =>
        prev.map((_slot) => {
          if (_slot?.id.toString() === slot_id) {
            return {
              ..._slot,
              fullList: _slot.fullList.filter((_item, index) => {
                if (index === result.source.index) removed = _item
                return !(index === result.source.index)
              })
            }
          } else return _slot
        })
      )
    }
    if (
      result.destination.droppableId.split('-')[
        result.destination.droppableId.split('-').length - 1
      ] === 'initials'
    ) {
      if (removed?.type !== 'missions') {
        API.patch(`${removed?.type}/${removed?.id}`, {
          slot_id: null,
          prestation_id: currentPrestation?.id
        }).then((_) => {
          console.log('OK')
        })
      }
      setInitialsListFormatted((prev) =>
        addObjectAtIndex(
          result.destination.index,
          { ...removed, slot_id: null, prestation_id: currentPrestation?.id },
          prev
        )
      )
    } else if (
      result.destination.droppableId.split('-')[
        result.destination.droppableId.split('-').length - 1
      ] === 'slots'
    ) {
      const slot_id =
        result.destination.droppableId.split('-')[
          result.destination.droppableId.split('-').length - 2
        ]
      if (removed?.type !== 'missions') {
        API.patch(`${removed?.type}/${removed?.id}`, { slot_id }).then(
          (_) => {
            console.log('OK')
          }
        )
      }
      setSlotListFormatted((prev) =>
        prev.map((_slot) => {
          if (_slot?.id.toString() === slot_id) {
            return {
              ..._slot,
              fullList: addObjectAtIndex(
                result.destination.index,
                { ...removed, slot_id: parseInt(slot_id) },
                _slot.fullList
              )
            }
          } else return _slot
        })
      )
    }
  } else {
    {
      const itemsInitialThatPassFilter = []
      const itemsInitialThatFailFilter = []

      initialsListFormatted.forEach((item) => {
        const passesFilter = !activeZones.some(
          (zone) =>
            zone.id === item.id && zone.type === item.type && !zone.slot_id
        )

        if (passesFilter) {
          itemsInitialThatPassFilter.push(item)
        } else {
          itemsInitialThatFailFilter.push(item)
        }
      })
      setInitialsListFormatted(itemsInitialThatPassFilter)
      const itemsSlotsThatPassFilter = slotListFormatted.map((_slot) => {
        const foundActiveZones = activeZones.filter(
          (element) => _slot?.id === element.slot_id
        )
        if (foundActiveZones.length > 0) {
          return {
            ..._slot,
            fullList: _slot.fullList.filter((_item) => {
              return !foundActiveZones.some(
                (activeZone) =>
                  activeZone.id === _item.id && activeZone.type === _item.type
              )
            })
          }
        } else {
          return _slot
        }
      })
      const itemsSlotsThatFailFilter = slotListFormatted.map((_slot) => {
        const foundActiveZones = activeZones.filter(
          (element) => _slot?.id === element.slot_id
        )
        if (foundActiveZones.length > 0) {
          return {
            ..._slot,
            fullList: _slot.fullList.filter((_item) => {
              return foundActiveZones.some(
                (activeZone) =>
                  activeZone.id === _item.id && activeZone.type === _item.type
              )
            })
          }
        }
      })
      setSlotListFormatted(itemsSlotsThatPassFilter)
      if (
        result.destination.droppableId.split('-')[
          result.destination.droppableId.split('-').length - 1
        ] === 'slots'
      ) {
        const slot_id = parseInt(
          result.destination.droppableId.split('-').slice(-2)[0]
        )
        const newActiveZones = activeZones.map((zone) => ({
          ...zone,
          slot_id
        }))
        API.patch('update_many_zones_slots', { data: newActiveZones })
          .then((response) => {
            const updatedSlotListFormatted = itemsSlotsThatPassFilter.map((_slot) => {
              if (_slot?.id === slot_id) {
                const updatedFullList = addArrayAtIndex(
                  result.destination.index,
                  [...itemsInitialThatFailFilter.map((zone) => ({ ...zone, slot_id })), ...itemsSlotsThatFailFilter.filter(item => item !== undefined).flatMap((el) => el.fullList.map((el) => ({ ...el, slot_id })))],
                  _slot.fullList
                )
                return {
                  ..._slot,
                  fullList: updatedFullList
                }
              } else {
                return _slot
              }
            })
            setSlotListFormatted(updatedSlotListFormatted)
            setActiveZones(newActiveZones)
          })
          .catch((error) => {
            console.log(error, 'error updatemanyzones')
          })
      } else if (
        result.destination.droppableId.split('-')[
          result.destination.droppableId.split('-').length - 1
        ] === 'initials'
      ) {
        const newActiveZones = activeZones.map((zone) => ({
          ...zone,
          slot_id: null,
          prestation_id: currentPrestation?.id
        }))
        API.patch('update_many_zones_slots', { data: newActiveZones })
          .then((response) => {
            setInitialsListFormatted((prev) =>
              addArrayAtIndex(result.destination.index, itemsSlotsThatFailFilter.filter(item => item !== undefined).flatMap((el) => el.fullList), prev)
            )
            setActiveZones(newActiveZones)
          })
          .catch((error) => {
            console.log(error, 'error updatemanyzones')
          })
      }
    }
  }
}

export { onDragEnd }
