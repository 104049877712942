/* eslint-disable react/prop-types */
import React from 'react'
import './style.scss'
import { CircularProgress } from '@mui/material'

export const BlackBtn = (props) => {
  const { styles, label, reverse, handleAction = function (e) { }, icon, disabled = false } = props
  return (
    <div className={`black-btn ${reverse && 'reverse'} ${disabled && 'disabled'}`} style={{ ...styles }} onClick={(e) => !disabled && handleAction(e)}>
      {icon || null}
      {disabled ? <CircularProgress/> : label}
    </div>
  )
}
