import { CircularProgress } from '@mui/material'
import './style.scss'
const BtnComponent = ({ isIcon = false, disabled, text, onClick = function() {}, bgColor = "black", color = "#60EED2", style = {}, variant = "default", fetching }) => {
  const inactive = disabled || fetching
  return (
    <div
      style={{ backgroundColor: disabled ? "#f2f2f2" : bgColor, color: inactive ? "white" : color, ...style }} onClick={!inactive && !isIcon ? (e) => onClick(e) : null}
      className={`btn-styles ${inactive && "disabled"} ${isIcon && "isIcon"} ${variant}`}
    >
      {fetching ? <CircularProgress size={20} /> : text || "text"}
    </div>
  )
}

export default BtnComponent
