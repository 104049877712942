/* eslint-disable react/prop-types */
import React, { useState } from 'react'

const InputChangeActive = ({ title, isSelected, onChange }) => {
  const [isActive, setIsActive] = useState(false)
  const [value, setValue] = useState(title)

  function handleGetEnter (e) {
    console.log(e.target.value)
    if (e.key === 'Enter') {
      e.target.disabled = true
      onChange(e.target.value)
    }
  }

  return (
      <>
        {!isActive && <div style={{ alignSelf: 'center' }} onClick={() => isSelected && setIsActive(true)}>{value || 'Nouveau slot'}</div>}
        {isActive && <input
          onKeyUp={handleGetEnter}
          onClick={e => e.stopPropagation()}
          autoFocus
          onBlur={(e) => { setIsActive(false); setValue(e.target.value); onChange(e.target.value) }}
          defaultValue={value}/>}

      </>
  )
}

export default InputChangeActive
