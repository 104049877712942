/* eslint-disable react/prop-types */
import { Modal } from '@mui/material'
import React from 'react'
import './style.scss'
import { BlackBtn } from 'views/Admin/Shared/Forms/BlackBtn'

const DeleteConfirmationModal = ({ open, handleConfirm, handleCancel, type }) => {
  return (
    <Modal
      open={open}
      onClose={(e) => {
        e.stopPropagation()
        handleCancel()
      }}
    >
      <div className="delete-cadence-zone-spot-modal-container" onClick={(e) => e.stopPropagation()}>
        <div className="delete-cadence-zone-spot-modal-title">Es-tu sûr de vouloir supprimer {type === "spot" ? "ce Spot" : "cette Zone"}?</div>
        <div className="btns-container">
            <BlackBtn handleAction={(e) => {
              e.stopPropagation()
              handleConfirm()
            }} label="OUI" styles={{ color: 'red', padding: '15px 50px' }} />
            <BlackBtn handleAction={(e) => {
              e.stopPropagation()
              handleCancel()
            }} label="NON" styles={{ padding: '15px 50px' }}/>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConfirmationModal
