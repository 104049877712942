import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { setShowGraph } from "_redux/Missions/reducers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector, useDispatch } from "react-redux";
import {
  haversineDistance,
  customRound,
  calculateAverageSpeed,
} from "utils/helpers";
import { Marker } from "@react-google-maps/api";
import moment from 'moment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LocationTracker = ({ mapRef }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState({});
  const [coordinates, setCoordinates] = useState(null);
  const [timestamps, setTimestamps] = useState(null);
  const [distances, setDistances] = useState(null);
  const [markerLocation, setMarkerLocation] = useState({})
  const [labels, setLabels] = useState(null);
  const { showGraph } = useSelector((state) => state.missions);
  const chartRefInstance = useRef(null);
  // const markerRefInstance = useRef(null);
  // const redCircleIcon = {
  //   path: window.google.maps.SymbolPath.CIRCLE,
  //   fillColor: "red",
  //   fillOpacity: 1,
  //   strokeWeight: 0,
  //   scale: 8,
  // };

  const { currentMissionLaunch } = useSelector((state) => state.missions);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentMissionLaunch.locations) {
      const locationsCoordinates = currentMissionLaunch.locations?.flat()
      const locationsTimestamps = currentMissionLaunch.locations?.flat().map((point) =>
        new Date(point.timestamp).getTime()
      );
      const startTime = Math.min(...locationsTimestamps);
      const locationsDistances = [];
      let totalDistance = 0;
      let distanceDiff = 0;
      const locationLabels = [];

      for (let i = 0; i < locationsTimestamps.length; i++) {
        const timeDiff = locationsTimestamps[i] - startTime;
        if (i !== 0) {
          const point1 = locationsCoordinates[i - 1];
          const point2 = locationsCoordinates[i];
          if (
            point1?.latitude &&
            point1?.longitude &&
            point2?.latitude &&
            point2?.longitude
          ) {
            distanceDiff = haversineDistance(
              point1.latitude,
              point1.longitude,
              point2.latitude,
              point2.longitude
            );
          }
          totalDistance += distanceDiff;
          locationLabels.push((timeDiff / 60000).toFixed(2));
          locationsDistances.push(totalDistance.toFixed(2));
        }
      }

      setDistances(locationsDistances);
      setTimestamps(locationsTimestamps);
      setLabels(locationLabels);
      setCoordinates(locationsCoordinates);
    }
  }, [currentMissionLaunch.locations]);

  useEffect(() => {
    if (coordinates) {
      setChartData({
        labels,
        datasets: [
          {
            label: "",
            data: distances,
            fill: false,
            backgroundColor: "black",
            borderColor: "black",
            pointHoverBackgroundColor: "red",
            pointHoverBorderColor: "red",
            pointHoverBorderWidth: "5",
          },
        ],
      });

      const diffLabelsTime = Math.floor(labels.length / 9);

      const numbersDistance = distances.map(parseFloat);
      const minValueDistance = Math.min(...numbersDistance);
      const maxValueDistance = Math.max(...numbersDistance);
      const diffDistance = (maxValueDistance - minValueDistance) / 9;
      const stepY = customRound(diffDistance);

      setChartOptions({
        onHover: handleChartHover,
        hover: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: false,
            borderWidth: 20,
            mode: "nearest",
            axis: "x",
            intersect: false,
            bodySpacing: 6,
            bodyFont: { size: 14, family: "Overpass", weight: 700 },
            callbacks: {
              title: () => null,
              labelTextColor: () => "white",
              label: (context) => {
                const index = context.dataIndex;
                return [
                  `Distance :  ${distances[index]}km`,
                  `Temps : ${formatMinutes(labels[index])}`,
                  `Allure :  ${
                    parseFloat(labels[index])
                      ? (
                          distances[index] /
                          (parseFloat(labels[index]) / 60)
                        ).toFixed(2)
                      : 0
                  }km/h`,
                  `Batterie :  ${
                    (currentMissionLaunch?.locations?.flat()[index]?.battery_level * 100).toFixed(0)
                  }%`,
                  `Date/heure :  ${
                    moment(timestamps[index]).format('DD/MM - HH:mm')
                  }`,
                ];
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "",
            },
            ticks: {
              color: "black",
              callback: (val) => {
                if (val === 0 || val % diffLabelsTime === 0) {
                  const minutes = (labels[val] % 60).toFixed(0) >= 10 ? (labels[val] % 60).toFixed(0) : '0' + (labels[val] % 60).toFixed(0)
                  const fixedMinute = labels[val] > 0 ? parseInt(labels[val]).toFixed(0) : labels[val]
                  return labels[val] > 60 ? (labels[val] / 60).toFixed(0) + 'h' + minutes : fixedMinute + "mn";
                }
              },
            },
          },
          y: {
            title: {
              display: true,
              text: "",
            },
            ticks: {
              color: "black",
              stepSize: stepY,
              callback: (val) => `${val} km`,
            },
          },
        },
      });
    }
  }, [coordinates, distances, timestamps, labels]);

  function formatMinutes(minutes) {
    const hours = Math.floor(minutes / 60).toFixed(0);
    const remainingMinutes = (minutes % 60).toFixed(0);
    return `${hours} Heures ${remainingMinutes} minutes`;
  }

  const handleChartHover = (_, activeElements) => {
    activeElements[0] && setMarkerLocation(coordinates[activeElements[0]?.index])
  };

  return (
    <>
    {markerLocation && <Marker position={{ lat: markerLocation?.latitude, lng: markerLocation?.longitude }} />}
      {showGraph && currentMissionLaunch.locations?.length && chartData && (
        <>
          <div className="left-layout-graph">
            <ChevronLeftIcon
              onClick={() => dispatch(setShowGraph(false))}
              style={{ cursor: "pointer", transform: "scaleX(-1)" }}
            />
          </div>
          <div
            style={{
              position: "relative",
              height: "350px",
              width: "633px",
              border: "solid 3px black",
              padding: "10px",
              borderRadius: "4%",
            }}
          >
            <Line
              data={chartData}
              onMouseLeave={() => setMarkerLocation(null)}
              // plugins={[
              //   {
              //     beforeDraw: (chart) => {
              //       if (chart.tooltip?._active?.length) {
              //         const x = chart.tooltip._active[0].element.x;
              //         const yAxis = chart.scales.y;
              //         const ctx = chart.ctx;

              //         // Draw hovered point (replace with your desired point style)

              //         // Draw line after the point
              //         ctx.beginPath();
              //         ctx.setLineDash([5, 5]);
              //         ctx.moveTo(x, yAxis.top);
              //         ctx.lineTo(x, yAxis.bottom);
              //         ctx.lineWidth = 1;
              //         ctx.strokeStyle = "black";
              //         ctx.stroke();
              //         ctx.save();
              //       }
              //     },
              //   },
              // ]}
              options={chartOptions}
              ref={chartRefInstance}
            />

            <div className="graph-bottom-legend">
              Temps de mission:{" "}
              {labels?.length && formatMinutes(labels[labels?.length - 1])}
              <div>
                Allure moyenne:{" "}
                {labels?.length && distances?.length
                  ? calculateAverageSpeed(
                      distances,
                      labels.map(parseFloat)
                    ).toFixed(2)
                  : 0}
                km/h
              </div>
              <div>
                Distance parcourue:{" "}
                {distances?.length ? distances[distances?.length - 1] : 0} km
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default LocationTracker;
