import React, { useState, useCallback, useEffect } from "react";
import { Popover, Collapse } from "@mui/material";
import { ReactComponent as CloseCircleSvg } from 'assets/close-circle.svg';
import { ReactComponent as BackLogo } from 'assets/back-arrow.svg';
import { useSearchParams } from "react-router-dom";
import './style.scss';

const FilterComponent = ({ filters, updateSelectedFilters, setFilterIsReady, activateSearchParams = false }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterItemOpen, setFilterItemOpen] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();

    // Helper: Parse stringified array back to an array
    const parseSearchParamArray = (param) => {
        try {
            return param ? JSON.parse(param) : [];
        } catch (e) {
            return [];
        }
    };

    // Initialize filters from search params or default
    const initializeFilters = () => {
        const initialFilters = {};
        filters.forEach(filter => {
            const paramValue = searchParams.get(filter.key);
            if (paramValue) {
                if (filter.multiple) {
                    initialFilters[filter.key] = parseSearchParamArray(paramValue);
                } else if (filter.customComponent) {
                    initialFilters[filter.key] = JSON.parse(paramValue);
                } else {
                    initialFilters[filter.key] = paramValue || null;
                }
            }
        });
        if (Object.keys(initialFilters).length > 0) updateSelectedFilters(initialFilters);
        if (setFilterIsReady) setFilterIsReady(true)
        return initialFilters;
    };

    const [filtersData, setFiltersData] = useState(initializeFilters);
    const [prevFiltersData, setPrevFiltersData] = useState(filtersData);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleUpdateFilters = useCallback(() => {
        if (JSON.stringify(filtersData) !== JSON.stringify(prevFiltersData)) {
            updateSelectedFilters(filtersData);
            setPrevFiltersData(filtersData);

            if (activateSearchParams) {
                // Update search params
                const updatedParams = Object.fromEntries(
                    [...searchParams.entries()].filter(([key]) => !(Object.keys(filtersData)).includes(key))
                );

                Object.entries(filtersData).forEach(([key, value]) => {
                    if (Array.isArray(value) && value.length > 0) {
                        // Store array as JSON string in search params
                        updatedParams[key] = JSON.stringify(value);
                    } else if (value && typeof value === 'object' && Object.keys(value).length > 0) {
                        // Store object as JSON string in search params
                        updatedParams[key] = JSON.stringify(value);
                    } else if (value) {
                        updatedParams[key] = value;
                    } else {
                        searchParams.delete(key); // Remove empty filters from search params
                    }
                });
                setSearchParams(updatedParams);
            }
        }
    }, [filtersData, prevFiltersData, setSearchParams, searchParams, activateSearchParams]);

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleSingleItemClick = (key, value) => {
        setFiltersData(prev => ({
            ...prev,
            [key]: prev[key] === value ? null : value
        }));
    };

    const handleListItemClick = (key, value) => {
        setFiltersData(prev => {
            return {
                ...prev,
                [key]: prev?.[key]?.includes(value) ? prev[key].filter(item => item !== value) : [...(prev?.[key] ?? []), value]
            }
        });
    };

    const handleItemToggle = (key) => {
        setFilterItemOpen(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const handleRemoveFilter = useCallback((key, value) => {
        const newFilters = { ...filtersData };
        if (value === true) {
            newFilters[key] = Array.isArray(filtersData[key]) ? [] : null;
        } else if (Array.isArray(filtersData[key])) {
            newFilters[key] = filtersData[key].filter(item => item !== value);
        } else {
            newFilters[key] = null;
        }
        setFiltersData(newFilters);
    }, [filtersData]);

    useEffect(() => {
        handleUpdateFilters();
    }, [filtersData, handleUpdateFilters]);

    const isPopoverOpen = Boolean(anchorEl);
    const popoverId = isPopoverOpen ? 'simple-popover' : undefined;

    const renderCapsules = (key, value) => {
        if (Array.isArray(value)) {
            return (
                <>
                    {value.map(item => (
                        <div key={item} className="filter-modal-filter-item">
                            <div>{item}</div>
                            <CloseCircleSvg color="white" fill="black" style={{ cursor: 'pointer' }} onClick={() => handleRemoveFilter(key, item)} />
                        </div>
                    ))}
                    <CloseCircleSvg color="black" fill="white" style={{ cursor: 'pointer' }} onClick={() => handleRemoveFilter(key, true)} />
                </>
            );
        }

        if (key === 'availability') {
            value = "DISPONIBILITÉ";
        }

        return (
            <div className="filter-modal-filter-single-item">
                <div>{value}</div>
                <CloseCircleSvg color="black" fill="white" style={{ cursor: 'pointer' }} onClick={() => handleRemoveFilter(key, true)} />
            </div>
        );
    };

    return (
        <div className="filter-component-container">
            <div aria-describedby={popoverId} className="add-filters-btn" onClick={handlePopoverOpen}>
                + AJOUTER UN FILTRE
            </div>
            <div className="filter-modal-filters-container">
                {Object.entries(filtersData).map(([key, value]) => (
                    (Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value)
                        ? (
                            <div key={key} className="filter-modal-filter-container">
                                {renderCapsules(key, value)}
                            </div>
                        )
                        : null
                ))}
            </div>
            <Popover
                id={popoverId}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="filter-popover-container">
                    {filters.map(filter => (
                        <div key={filter.key} className="filter-popover-collapse-container">
                            <div className="filter-popover-collapse-title-button" onClick={() => handleItemToggle(filter.key)} style={{ backgroundColor: filterItemOpen[filter.key] ? '#F2F2F2' : 'white' }}>
                                <BackLogo className={`arrow ${filterItemOpen[filter.key] ? 'open' : 'close'}`} width={15} height={15} /> {filter.label.toUpperCase()}
                            </div>
                            <Collapse in={filterItemOpen[filter.key]}>
                                <div className="filter-popover-collapse-content">
                                    {!filter.multiple && !filter.customComponent && filter.options.map(option => (
                                        <div key={option} className="filter-popover-collapse-item" onClick={() => handleSingleItemClick(filter.key, option)}>
                                            {filtersData[filter.key] === option && <div style={{ marginRight: 4 }}>⬤</div>}
                                            {option}
                                        </div>
                                    ))}
                                    {filter.multiple && !filter.customComponent && filter.options.map(option => (
                                        <div key={option} className="filter-popover-collapse-item" onClick={() => handleListItemClick(filter.key, option)}>
                                            {filtersData[filter.key]?.includes(option) && <div style={{ marginRight: 4 }}>⬤</div>}
                                            {option}
                                        </div>
                                    ))}
                                    {filter.customComponent && (
                                        React.cloneElement(filter.customComponent, {
                                            handleUpdateFilter: (value) => {
                                                if (filter.multiple) handleListItemClick(filter.key, value);
                                                else handleSingleItemClick(filter.key, value);
                                            },
                                            currentFilters: filtersData
                                        })
                                    )}
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </div>
            </Popover>
        </div>
    );
};

export default FilterComponent;
