export const flyerFormats = [
  'Flyers A4',
  'Flyers A5',
  'Flyers A6',
  'Sac à baguettes',
  'Sets de Table',
  'Tracts',
  'Couronnes',
  'Dépliants',
  'Enveloppe',
  'Bache format classique XL 2X2',
  'Bache format grandeur XXL 2X4',
  'Bache format personnalisé'
]

export const flyerFormatBox = [
  'Flyers A4',
  'Flyers A5',
  'Flyers A6',
  'Dépliants',
  'Enveloppe',
  'Brochures',
  'Livrets',
  'Catalogues',
  'Carte Postale',
  'Journaux'
]
export const flyerFormatHand = [
  'Flyers A4',
  'Flyers A5',
  'Flyers A6',
  'Goodies',
  'Stickers',
  'Dépliant',
  'Brochure',
  'Enveloppe',
  'Livrets',
  'Catalogues',
  'Carte Postale'
]

export const numberOfPagesList = ['2', '4', '6', '8', '10', '12', '16', '20', '24+']

export const flyerFormatBike = [
  'Bache format classique XL 2X2',
  'Bache format grandeur XXL 2X4',
  'Bache format personnalisé',
  'Flyers A4',
  'Flyers A5',
  'Flyers A6',
  'Goodies',
  'Stickers',
  'Dépliant',
  'Carte Postale'
]
export const flyerFormatWall = [
  'Affiches A0',
  'Affiches A1',
  'Affiches A2',
  'Affiches A3',
  'Affiches B0',
  'Affiches B1',
  'Affiches B2',
  'ABRIBUS'
]

export const flyerFormatStore = [
  'Sac à baguettes',
  'Sets de Table',
  'Couronnes',
  'Dépliants',
  'Tracts',
  'Carte Postale'
]
export const jours = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche'
]
