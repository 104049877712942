/* eslint-disable */
import { modifyInStack, newItemDrag } from "_redux/Map/reducers";
import API from "services/axios-config";

export function onDragEnd (result, prestationId, setPrestationsList, itemsDragSelected, dispatch) {
  if (!result.destination) { return; }
  const dropType = result.destination.droppableId.split('-')[0]
  // ENLEVER LES ANCIENNE VALEURS AUX PRESTATIONS ET INITIALS
  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;
  const persistSlots = (prestationId, updatedSlots) => {
    const slotIds = updatedSlots.map(slot => slot.id);
    localStorage.setItem(`prestation-${prestationId}-slots`, JSON.stringify(slotIds));
  };
  
  setPrestationsList(prev => prev.map(prestation => {
    if (prestation.id === prestationId) {
      let updatedSlots = Array.from(prestation.slots);
      const [movedSlot] = updatedSlots.splice(sourceIndex, 1);
      updatedSlots.splice(destinationIndex, 0, movedSlot);
      updatedSlots = updatedSlots.filter((slot) => slot !== undefined)
      persistSlots(prestationId, updatedSlots);
      return ({
        ...prestation,
        initials: prestation.initials.filter((initial) => {
          return !itemsDragSelected.filter((item) => (item.id === initial.id && item.polygon === initial.polygon && item.address_id === initial.address_id && item.location_type === initial.location_type)).length
        }),
        slots: result.type === 'SLOT'
          ? updatedSlots
          : prestation.slots.map((_slot) => {
            const existInSlot = itemsDragSelected.filter((item) => item.slot_id === _slot.id)
            let currentArray = _slot.items || []
            if (existInSlot.length > 0) {
              existInSlot.map((_item) => {
                currentArray = currentArray.filter(prev => !(prev.id === _item.id && prev.polygon === _item.polygon && prev.address_id === _item.address_id && prev.location_type === _item.location_type))
              })
              return ({ ..._slot, items: currentArray })
            } else {
              return _slot
            }
          }),
      })
    } else {
      return prestation
    }
  }))
  if (dropType === 'initiales') {
    // AJOUTER LES NOUVELLES VALEURS AUX INITIALS
    setPrestationsList(prev => prev.map(prestation => {
      if (prestation.id === prestationId) {
        return ({
          ...prestation,
          initials: addInitials(prestation, itemsDragSelected, dispatch)
        })
      } else {
        return prestation
      }
    }))
  } else if (dropType === "droppable") {
    const dropId = result.destination.droppableId.split('-')[1]
    const destinationIndex = result.destination.index
    // AJOUTER LES NOUVELLES VALEURS AUX SLOTS

    const persistItems = (slotId, updatedItems) => {
      const itemType = (item) => {
        if (item.polygon) {
          return 'zone';
        } else if (item.user) {
          return 'polyline';
        } else {
          return 'spot';
        }
      };

      const items = updatedItems.map(item => ({
        id: item.id,
        type: itemType(item)
      }));

      localStorage.setItem(`slot-${slotId}-items`, JSON.stringify(items));
    };

    setPrestationsList(prev => prev.map(prestation => {
      if (prestation.id === prestationId) {
        return ({
          ...prestation,
          slots: prestation.slots.map((_slot) => {
            if (_slot.id === parseInt(dropId)) {
              const currentArray = [..._slot.items]
              const newItemDragValue = itemsDragSelected.map(current => {
                dispatch(modifyInStack({ ...current, slot_id: parseInt(dropId) }))
                return { ...current, slot_id: parseInt(dropId) }
              })
              currentArray.splice(destinationIndex, 0, ...newItemDragValue);
              // currentArray.push(...newItemDragValue)
              persistItems(_slot.id, currentArray)
              dispatch(newItemDrag(newItemDragValue))
              newItemDragValue.map((item) => {
                API.patch(`${getItemType(item) + 's'}/${item.id}`, item)
              })
              return ({ ..._slot, items: currentArray }) // UPDATE DE L'ID DU SLOT
            } else {
              return _slot
            }
          }),
        })
      } else {
        return prestation
      }
    }))
  }
}

function addInitials (prestation, itemsDragSelected, dispatch) {
  const currentArray = prestation.initials || []
  const newItemDragValue = itemsDragSelected.map(current => ({ ...current, slot_id: null }))
  currentArray.push(...newItemDragValue)
  newItemDragValue.map((item) => {
    API.patch(`${getItemType(item) + 's'}/${item.id}`, item)
  })
  dispatch(newItemDrag(newItemDragValue))
  return currentArray // UPDATE DE L'ID DU SLOT
}

export function needResetDrag (draggableId, prestationId, prestationsList, itemsDragSelected, dispatch) {
  const draggableType = draggableId.split('-')[0]
  const draggableItemId = draggableId.split('-')[1]
  const draggableSlotId = draggableId.split('-')[2]
  const existInList = itemsDragSelected?.filter(current => current.type === draggableType && current.id === parseInt(draggableItemId)).length > 0
  if (!existInList) {
    prestationsList.map(prestation => {
      if (prestation.id === prestationId) {
        if (draggableSlotId) {
          prestation.slots.map(slot => {
            if (slot.id === parseInt(draggableSlotId)) {
              const currentItem = slot.items?.filter((_item) => (_item.id === parseInt(draggableItemId) && getItemType(_item) === draggableType))
              dispatch(newItemDrag(currentItem))
            }
          })
        } else {
          prestation.initials.map(initial => {
            if (initial.id === parseInt(draggableItemId) && getItemType(initial) === draggableType) {
              dispatch(newItemDrag([initial]))
            }
          })
        }
      }
    })
  }
}

export function getItemType (item) {
  if (item.polygon) {
    return ("zone")
  } else if (item.address_id) {
    return ("spot")
  } else if (item.user) {
    return ("mission")
  }
}

export const calculateCenter = (coordinatesArray) => {
  if (coordinatesArray.length === 0) {
    return null; // Si le tableau de coordonnées est vide, retourner null
  }

  const bounds = new window.google.maps.LatLngBounds();

  coordinatesArray.forEach((coord) => {
    bounds.extend(new window.google.maps.LatLng(coord[0], coord[1]));
  });

  const center = bounds.getCenter();
  return { lat: center.lat(), lng: center.lng() };
};
