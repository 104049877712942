/* eslint-disable react/prop-types */
import { SimpleButton } from '../../components'
import { IoMdAddCircle } from 'react-icons/io'
import React, { useEffect, useState } from 'react'
import { solutionsName } from '../../Inputs/InputSelect/dataSelection'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import InputSelect from '../../Inputs/InputSelect/inputSelect'
import SolutionExplain from './solutionExplain'
import '../problimatisation.scss'

const SolutionContent = ({ solutions, setExplain }) => {
  const [solutionList, setSolutionList] = useState(solutions)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (isLoad) {
      setExplain(prev => ({ ...prev, solutions: solutionList }))
    }
    setIsLoad(true)
  }, [solutionList])

  const handleAddInputsolution = () => setSolutionList((prev) => ([...prev, '']))
  const handleDelete = () => setSolutionList((prev) => (prev.splice(0, prev?.length - 1)))

  const handleSelect = (index, value) => {
    setSolutionList(prev => prev.map((sol, _index) => {
      return _index === index ? { ...sol, title: value } : sol
    }
    ))
  }

  return (
    <div key={'content}'} className='content'>
      <div className='btn'>
        <SimpleButton text={'SOLUTION PROPOSÉE'}
          style={{ fontSize: 13, padding: '10px 20px', cursor: 'unset' }}
          className={'simpleBtnWhite'} />
      </div>
      <div className='content_bloc_list'>
        <div style={{ border: '1px solid white' }} className='divider'></div>
        <div className='content_list'>
          <div className="action-input">
            <div className="inputs-solution">
              {solutionList?.map((solution, index) => <div key={`input-solution-${index}`}
                className="solution-content">
                <InputSelect multiple={false} fullWidth={true} list={solutionsName}
                  handleSelect={handleSelect} value={solution?.title} index={index} margin={5} />
                <SolutionExplain setSolutionList={setSolutionList}
                  solutionIndex={index}
                  explains={solution?.list ? solution?.list : ['']} />
              </div>
              )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div className="action" onClick={handleAddInputsolution}>
                <IoMdAddCircle color="#00FFE0" size={30} />
              </div>
              <div className="action" onClick={handleDelete}>
                <DeleteRoundedIcon color="error" size={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolutionContent
