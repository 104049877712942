import { ReactComponent as MyPOLYGON } from 'assets/polygon-svgrepo-com-ivan.svg'
import { ReactComponent as MyPIN } from 'assets/pin.svg'
import { ReactComponent as AnomaliePin } from 'assets/anomalie_pin.svg'
import { ReactComponent as MyPOLYLINE } from 'assets/polyline.svg'
import { ReactComponent as MyCLOCK } from 'assets/clock.svg'
import { ReactComponent as CircleSvg } from 'assets/circle.svg'
import './CircleFlash.scss';

import { memo } from 'react'

export const ItemTitleIcon = memo(({ type, item, pendingItem, onIconClick }) => {
  if (type === 'zone') {
    return <MyPOLYGON
        fill={item?.fillColor || "black"}
        stroke={item?.fillColor || "black"}
        style={{ flex: 1, minHeight: '16px', minWidth: '16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
        onClick={onIconClick}
      />
  } else if (type === 'spot' && item.anomalie_type) {
    return <AnomaliePin style={{ flex: 1, minHeight: '16px', minWidth: '16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
        onClick={onIconClick}
      />
  } else if (type === 'spot') {
    return <MyPIN fill={item?.fillColor || "black"} stroke={item?.fillColor || "black"} style={{ flex: 1, minHeight: '16px', minWidth: '16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
        onClick={onIconClick}
      />
  } else if (type === 'mission') {
    if (pendingItem) {
      return <CircleSvg className={`circle-icon ${pendingItem.on_break ? 'no-flash' : 'flash'}`} onClick={onIconClick}/>
    }
    if (item?.sorted_positions?.[0]?.length > 0) {
      return <MyPOLYLINE
      fill={item?.fillColor || "black"} stroke={item?.fillColor || "black"} style={{ flex: 1, minHeight: '16px', minWidth: '16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
        onClick={onIconClick}
      />
    } else {
      return <MyCLOCK
      fill={item?.fillColor || "black"} stroke={item?.fillColor || "black"} style={{ flex: 1, minHeight: '16px', minWidth: '16px', marginRight:'4px' }}
        id={`colored-square-${item?.id}`}
        onClick={onIconClick}
      />
    }
  }
})
