/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Button } from '@mui/material'
import { handleSaveUsecase } from '../../functions/apiFunctions'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import FetchingScreen from 'components/Loader/fetching-screen'
import './modal.scss'

export const ModalComponent = ({ id, open, disable, setOpenModal, component, data, setIsSaved }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleSave = () => {
    setIsLoading(true)
    handleSaveUsecase(id, data, setIsSaved, setOpenModal, setIsLoading)
  }

  return (
    <div id="testingmodalselect" tabIndex={0} // onClick={(e) => setOpenModal(false)}
    >
      <Modal open={open} aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        {!isLoading
          ? <div className={'box'} onClick={(e) => {
            // if (e.target))
            //  e?.stopPropagation()
          }}
            >
            {component}
            <Box className={'buttons'}>
              <Button onClick={handleSave} disabled={disable || isLoading} variant="outlined"
                color='success'>Enregistrer
              </Button>
              <Button variant="outlined" color='error'
                onClick={() => setOpenModal(false)}>Annuler
              </Button>
            </Box>
          </div>
          : <FetchingScreen isFetching={isLoading} />
        }
      </Modal>
    </div>
  )
}
