import React, { useState } from 'react'
import { ReactComponent as Star2Svg } from 'assets/star2.svg';
import BtnComponent from 'components/GlobalInputs/Btn';
import API from 'services/axios-config';
import SpotsModal from './spotsModal';
import './styles.scss'

const SpotsNoteSection = ({ spots, setMissionSelected }) => {
  const handleAttributeNoteToSpot = (spotId, note) => {
    API.post(`spots/${spotId}/add_note_to_spot`, { note })
      .then(_ => {
        setMissionSelected(prev => ({ ...prev, slot: { ...prev.slot, spots: prev.slot.spots.map(spot => spot.id === spotId ? { ...spot, note } : spot) } }))
      })
  }
  if (spots?.length === 0) {
    return (
      <div className="spot-note-section-container">
        <div className="spot-note-section-title">
          SPOT DE DISTRIBUTION
        </div>
        <div className="spot-note-section-content-container">
          <div className="spot-note-section-content-value">
            PAS DE SPOT POUR CE MISSION
          </div>
        </div>
      </div>
    )
  }
  if (spots?.length === 1) {
    const spot = spots[0];
    return (
      <div className="spot-note-section-container">
        <div className="spot-note-section-title">
          SPOT DE DISTRIBUTION
        </div>
        <div className="spot-note-section-content-container">
          <div className="spot-note-section-key-value-conatiner">
            <div className="spot-note-section-content-key">
              Nom du spot:
            </div>
            <div className="spot-note-section-content-value">
              {spot.title ?? '-'}
            </div>
          </div>
          <div className="spot-note-section-key-value-conatiner">
            <div className="spot-note-section-content-key">
              Type du spot:
            </div>
            <div className="spot-note-section-content-value">
              {spot.spot_type ?? '-'}
            </div>
          </div>
          <div className="spot-note-section-key-value-conatiner">
            <div className="spot-note-section-content-key">
              Description du spot:
            </div>
            <div className="spot-note-section-content-value">
              {spot.description ?? '-'}
            </div>
          </div>
          <div className="spot-note-section-key-value-conatiner">
            <div className="spot-note-section-content-key">
              Adresse du spot:
            </div>
            <div className="spot-note-section-content-value">
              {spot.address?.label ?? '-'}
            </div>
          </div>
          <div className="spot-note-section-key-value-conatiner">
            <div className="spot-note-section-content-key">
              Note du spot:
            </div>
            <div className="spot-note-section-content-value">
              <div className="spot-note-section-notation-container">
                {[...Array(5)].map((_, index) => (
                  <Star2Svg
                    key={index}
                    color={spot.note && index < spot.note ? "#60EED2" : "white"}
                    height={20}
                    width={20}
                    onClick={() => handleAttributeNoteToSpot(spot.id, index + 1)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const [modalOpen, setModalOpen] = useState(false)

  const handleOpenSpotsModal = () => {
    setModalOpen(true)
  }

  const handleCloseSpotsModal = () => {
    setModalOpen(false)
  }
  return (<div className="spot-note-section-container">
    {modalOpen && <SpotsModal modalOpen={modalOpen} handleCloseModal={handleCloseSpotsModal} spots={spots} handleAttributeNoteToSpot={handleAttributeNoteToSpot} />}
    <div className="spot-note-section-title">
      SPOT DE DISTRIBUTION
    </div>
    <div className="spot-note-section-content-container" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <BtnComponent text={"VOIR LES SPOTS"} color={"white"} variant="rounded" onClick={handleOpenSpotsModal} />
    </div>
  </div>)
}

export default SpotsNoteSection;
