/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import API from 'services/axios-config'
import { setCampaignsList } from '_redux/Campaigns/reducers'
import { useDispatch } from 'react-redux'
import PrestationsView from './PrestationsView'
import TopPageContent from './TopPageContent'
import './style.scss'

const CampaignCardView = (props) => {
  const { rawList } = props
  const [campaignData, setCampaignData] = useState({})
  const [searchParams, setSearchParams] = useSearchParams()
  const [campaignLoading, setCampaignLoading] = useState(true)
  const dispatch = useDispatch()
  let chargementSnackBar

  useEffect(() => {
    const foundCampaign = rawList.find(item => item?.campaign.id === parseInt(searchParams.get('campaign_id')))
    setCampaignData(foundCampaign)
  }, [rawList])

  useEffect(() => {
    chargementSnackBar = enqueueSnackbar('Chargement...', { persist: true })
    API.get(`campaigns/${searchParams.get('campaign_id')}/campaign_informations`)
      .then(res => {
        closeSnackbar(chargementSnackBar)
        chargementSnackBar = enqueueSnackbar('Contenu à jour', { variant: 'success', autoHideDuration: 3000 })
        dispatch(setCampaignsList([res.data]))
      })
      .catch(err => {
        closeSnackbar(chargementSnackBar)
        chargementSnackBar = enqueueSnackbar(err, { variant: 'error', autoHideDuration: 3000 })
      })
      .finally(() => {
        setCampaignLoading(false)
      })
  }, [])

  const handleUpdateCampaignData = useCallback((data) => {
    setCampaignData(data)
  }, [campaignData, setCampaignData])

  return (
        <div className="campaign-card-container" onMouseDown={(e) => { searchParams.delete('campaign_id'); searchParams.delete('create'); setSearchParams(searchParams); closeSnackbar(chargementSnackBar) }}>
            <div className="campaign-card-content" onMouseDown={e => e.stopPropagation()}>
                <TopPageContent {...props} loading={campaignLoading} campaignData={campaignData} />
                <PrestationsView {...props} loading={campaignLoading} campaignData={campaignData} updateCampaignData={handleUpdateCampaignData} />
            </div>
        </div>
  )
}

export default CampaignCardView
