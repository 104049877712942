/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import { Box } from '@mui/system'
import { MdClose } from 'react-icons/md'
import { Collapse, IconButton, List, ListItem, Typography } from '@mui/material'
import './drawer.scss'
import { icon_black_bottom_left_arrow, icon_black_top_right_arrow } from '../../../utils/imageUrls'
import { navbarLinks } from '../../../utils/links'

const ListLinks = ({ handleClose, handleOpenSubMenu, handleCloseSubMenu, subMenu }) => {
  return (
    <div className="list_links" role="presentation">
      <Box display='flex' padding={'10px 20px 30px 20px'} justifyContent={'flex-end'}>
        <IconButton onClick={handleClose}> <MdClose color='black' /> </IconButton>
      </Box>
      <List>
        {navbarLinks.map((link, index) => (
          <ListItem key={link?.name} disablePadding>
            <Box className="link_container">
              <Box className='item_container'
                onMouseOver={subMenu !== index ? () => handleOpenSubMenu(index) : () => handleCloseSubMenu}
              // onClick={subMenu !== index ? () => handleOpenSubMenu(index) : () => handleCloseSubMenu}
              >
                <Typography variant='p' className={'main_item'}> {link?.name} </Typography>
                {link?.subLinks
                  ? index < 3 && (subMenu !== index)
                    ? <img src={icon_black_top_right_arrow} alt="open_menu" width={20} height={20} className='icon' />
                    : <img src={icon_black_bottom_left_arrow} alt="close_menu" width={20} height={20} className='icon' />
                  : null}
                {/* {index < 2 && <FiArrowUpRight className='icon' size={30} />} */}
              </Box>
              <Box >
                {link?.subLinks && link?.subLinks?.map((subItem, subIndex) => (
                  <Collapse
                    in={subMenu === index}
                    className={'list_sub_items'}
                    timeout={600}
                    unmountOnExit
                    key={subIndex}
                  >
                    <List component="div" disablePadding>
                      <Box>
                        <Typography className="sub_item">{subItem?.name}</Typography>
                      </Box>
                    </List>
                  </Collapse>
                ))}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  )
}

export default ListLinks
