/* eslint-disable react/prop-types */
import * as React from 'react'
// import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { dialogConfig } from './config'
import { CircularProgress } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import BtnComponent from 'components/GlobalInputs/Btn'

export default function AlertDialog (props) {
  const {
    open,
    setOpen,
    fetching = false,
    dialogType = 'error',
    action1 = () => {},
    action2 = () => props.setOpen(false),
    subAction = () => {},
    component = null
  } = props

  const [subActionOpen, setSubActionOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    setSubActionOpen(false)
  }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogConfig[dialogType].title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {fetching
              ? <CircularProgress/>
              : dialogConfig[dialogType].description}
          </DialogContentText>
        </DialogContent>
        {
          component
        }
        <DialogActions>
        {!fetching && dialogConfig[dialogType].action1 && <BtnComponent style={{ fontWeight: 700 }} text={dialogConfig[dialogType].action1} variant="rounded" color="red" id="alert-action1-btn" onClick={action1}/>}
        {!fetching && dialogConfig[dialogType].action2 && <BtnComponent style={{ fontWeight: 700 }} text={dialogConfig[dialogType].action2} variant="rounded" color="white" id="alert-action2-btn" onClick={action2}/>}
        </DialogActions>

        {dialogConfig[dialogType].subActionText &&
          <div style={{ textDecoration: 'underline', margin: '0 15px 15px', fontSize: 12, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ cursor: 'pointer' }} onClick={() => setSubActionOpen(true)}>{dialogConfig[dialogType].subActionText}</div>
            {subActionOpen && <div style={{ display: 'flex', gap: 20 }}>
              <div style={{ cursor: 'pointer' }} onClick={subAction}>
                <CheckCircleIcon style={{ color: 'green' }}/>
              </div>
              <div style={{ cursor: 'pointer' }} onClick={() => setSubActionOpen(false)}>
                <CancelIcon style={{ color: 'red' }}/>
              </div>
            </div>}
          </div>}
      </Dialog>
  )
}
