/* eslint-disable react/prop-types */
import React, { memo, useEffect, useRef, useState } from "react";
import "./style.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import RealTimeLeftLayoutMap from "views/Admin/Views/Cartes/Views/RealTime/leftLayout";
import { ReactComponent as CarteLogo } from "assets/icon_carte.svg";
import { ReactComponent as CampaignLogo } from "assets/campagne.svg";
import LocationTracker from "components/LocationTracker/LocationTracker";
import {

  setShowGraph,

} from "_redux/Missions/reducers";

const tabsInfosMapsLayout = [
  {
    name: "missions",
    icon: <CampaignLogo />,
  },
  {
    name: "location",
    icon: <CarteLogo />,
  },
];

// eslint-disable-next-line react/display-name
const MapsLayout = memo((props) => {
  const [isLayoutOpen, setIsLayoutOpen] = useState(false);
  const [isLayoutOpenTimer, setIsLayoutOpenTimer] = useState(false);
  const [value, setValue] = useState(0);

  const {
    // missionSelected,
    // setMissionSelected,
    // missionLaunchedList,
    mapRef,
    clickedLocation,
    content = ["missions", "location"],
    positionX = "left",
    positionY = "top",
    X = 10,
    Y = 90,
  } = props;

  const reversePosition = positionX === "left" ? "right" : "left";

  const styles = {
    positionnedStyle: {
      [positionX]: X,
      [positionY]: Y,
      borderRadius:
        positionX === "left" ? "10px 0 10px 10px" : "0px 10px 10px 0px",
    },
    leftChevron: {
      [reversePosition]: "-30px",
      borderRadius:
        positionX === "left" ? "0 10px 10px 0" : "10px 0px 0px 10px",
    },
  };

  const timerRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (state) => {
    if (isLayoutOpen === false) {
      setIsLayoutOpenTimer(false);
    }
    if (state === "change") {
      setIsLayoutOpen((prev) => !prev);
    } else if (state === "true") {
      setIsLayoutOpen(true);
    }
  };
  const { currentMissionLaunch, showGraph } = useSelector((state) => state.missions);

  function selectDisplayContent () {
    if (content[value] === "missions") {
      return (
        <RealTimeLeftLayoutMap
          // missionSelected={missionSelected}
          // setMissionSelected={setMissionSelected}
          // missionLaunchedList={missionLaunchedList}
          mapRef={mapRef}
        />
      );
    } else if (content[value] === "location") {
      return (
        <div>
          Adresse séléctionnée :
          <div style={{ marginTop: 10 }}>
            {clickedLocation?.formatted_address === undefined
              ? "Aucune adresse séléctionnée"
              : clickedLocation?.formatted_address}
          </div>
        </div>
      );
    }
  }

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setIsLayoutOpenTimer(true);
    }, 500);
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isLayoutOpen === false]);

  return (
    <div>
      <div
        style={styles.positionnedStyle}
        className={`maps-layout-container ${isLayoutOpen && "layoutOpen"}`}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon label tabs example"
          orientation="vertical"
          className="tabs"
        >
          {content.map((data, index) => {
            return (
              <Tab
                key={`${data}-${index}`}
                icon={React.cloneElement(
                  tabsInfosMapsLayout?.find((item) => item?.name === data).icon,
                  { fill: value === index ? "#60eed2" : "black" }
                )}
                className="tab"
                onClick={() => { handleClick("true"), setShowGraph(true) }}
              />
            );
          })}
        </Tabs>

        <div
          className={`${
            isLayoutOpen && isLayoutOpenTimer ? "content" : "display-none"
          }`}
        >
          <div className="content-data">{selectDisplayContent()}</div>
        </div>
        <div
          style={styles.leftChevron}
          className="left-layout-chevron"
          onClick={() => handleClick("change")}
        >
          <ChevronLeftIcon />
        </div>
      </div>
   {showGraph && currentMissionLaunch?.locations?.length && <div className="real-map-graph">
        <LocationTracker mapRef={mapRef} />
      </div>}
    </div>
  );
});

export default MapsLayout;
