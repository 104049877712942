/* eslint-disable react/prop-types */
import React from 'react'
import { CgMenuRightAlt } from 'react-icons/cg'
import CustomDrawer from '../Drawer/drawer'
import './horizontalNavbar.scss'

const HorizontalNavbar = ({ open, handleClose, handleOpen }) => {
  return (
    <>
      <div className='nav_container'>
        <div className='nav'>
          <div></div>
          <div style={{
            display: 'flex',
            border: '1px solid',
            backgroundColor: 'white',
            padding: 10,
            borderRadius: 60,
            cursor: 'pointer'
          }} onClick={handleOpen} >
            <CgMenuRightAlt size={25} color='black' />
          </div>
        </div>
      </div>
      <CustomDrawer open={open} handleClose={handleClose} />
    </>
  )
}

export default HorizontalNavbar
