/* eslint-disable react/prop-types */
import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import { APP_STORE, GOOGLE_PLAY } from '../../utils/imageUrls'
import { ParentContainer } from '../ParentContainer/parentContainer'
import './buttons.scss'

export const SimpleButton = ({ text, style, className, icon, event }) => {
  return (
    <div className={className} style={{ ...style }} onClick={event} >
      {text}
      {icon && <FiArrowUpRight className='icon' size={30} />}
    </div>
  )
}

export const DownloadBtns = ({ position, padding }) => {
  return <div className='download' style={{ display: 'flex', padding, justifyContent: position, gap: 50 }}>
    <a target={'_blank'} href={'APP_STORE_LINK'} rel="noreferrer" className='download_btn'>
      <img src={APP_STORE} alt="app-store" />
    </a>
    <a target={'_blank'} href={'PLAY_STORE_LINK'} rel="noreferrer" className='download_btn'>
      <img src={GOOGLE_PLAY} alt="google-play" />
    </a>
  </div>
}

export const UseCaseAction = ({ prevId, nextId, navigate }) => {
  const nextUsecase = () => {
    navigate(`?id=${nextId}`)
  }
  const prevUsecase = () => {
    navigate(`?id=${prevId}`)
  }

  return (
    <ParentContainer style={{ backgroundColor: 'white' }}>
      <div className='action-usecase'>
        {prevId && <SimpleButton event={prevUsecase}
          className={'simpleBtnWhite'} style={{ fontSize: 24 }} icon={true} text="PRÉCÉDENT"
        />}
        {nextId && <SimpleButton event={nextUsecase}
          className={'simpleBtnWhite'} style={{ fontSize: 24 }} icon={true} text="SUIVANT"
        />}
      </div>

    </ParentContainer>
  )
}
