/* eslint-disable camelcase */
import React from 'react'
import { ReactComponent as MyBIKE } from 'assets/FLYING_BIKE.svg'
import { ReactComponent as MySTORE } from 'assets/FLYING_STORE.svg'
import { ReactComponent as MyWALL } from 'assets/FLYING_WALL.svg'
import { ReactComponent as MyHAND } from 'assets/FLYING_HAND.svg'
import { ReactComponent as MyBOX } from 'assets/FLYING_BOX.svg'

export const presta_icons = {
  'Flying Box': <MyBOX style={{ width: '20px', height: '20px', fill: 'black', stroke: 'black' }}/>,
  'Flying Hand': <MyHAND style={{ width: '20px', height: '20px', fill: 'black', stroke: 'black' }}/>,
  'Flying Bike': <MyBIKE style={{ width: '20px', height: '20px', fill: 'black', stroke: 'black' }}/>,
  'Flying Wall': <MyWALL style={{ width: '20px', height: '20px', fill: 'black', stroke: 'black' }}/>,
  'Flying Store': <MySTORE style={{ width: '20px', height: '20px', fill: 'black', stroke: 'black' }}/>
}
