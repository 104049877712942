/* eslint-disable camelcase */
const BASE_IMG_URL_V3 = 'https://dmsc62vqrvq4p.cloudfront.net/vitrine_v3/'

export const youtube = `${BASE_IMG_URL_V3}icons/ICON_YOUTUBE.webp`
export const linkedin = `${BASE_IMG_URL_V3}icons/ICON_LINKEDIN.webp`
export const facebook = `${BASE_IMG_URL_V3}icons/ICON_FACEBOOK.webp`
export const instagram = `${BASE_IMG_URL_V3}icons/ICON_INSTAGRAM.webp`
export const ICON_FLECHE_BLANC_CERCLE = `${BASE_IMG_URL_V3}icons/FLECHE_BAS_BLANC.webp`
export const icon_white_top_right_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_BLANC.webp`
export const icon_black_top_right_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_NOIR_HAUT.webp`
export const icon_white_ciecle_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_BLANC_CERCLE.webp`
export const icon_black_bottom_left_arrow = `${BASE_IMG_URL_V3}icons/ICON_FLECHE_NOIR_BAS.webp`
export const APP_STORE = `${BASE_IMG_URL_V3}marketing-digital/APP-STORE.webp`
export const GOOGLE_PLAY = `${BASE_IMG_URL_V3}marketing-digital/GOOGLE-PLAY.webp`
export const IMG_SHOW_REEL = `${BASE_IMG_URL_V3}images/SHOWREEL_MINIATURE.webp`
export const CASE_BOXY = `${BASE_IMG_URL_V3}useCases/CASE_BOXY.webp`
export const CASE_CAMPUSCOACH = `${BASE_IMG_URL_V3}useCases/CASE_CAMPUSCOACH.webp`
export const COUVERTURE_STREET_MARKETEUR = `${BASE_IMG_URL_V3}COUVERTURE_STREET_MARKETEUR.webp`
export const logo = `${BASE_IMG_URL_V3}logo.webp`
