/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react'
import { Route, Navigate, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { PATH_ADMIN, PATH_ADMIN_CAMPAGNES, PATH_ADMIN_VALIDATION_MISSIONS, PATH_ADMIN_CARTES, PATH_ADMIN_DASHBOARD, PATH_ADMIN_COMPANY, PATH_ADMIN_STREET_MARKETEURS, PATH_ADMIN_DEVTOOLS, PATH_ADMIN_SM_CALENDLY, PATH_ADMIN_GALLERY, PATH_USECASE_CMS, PATH_USECASE_VISUALISATION, PATH_USECASE_CMS_CREATION, PATH_PROFIL_ADMIN, PATH_ADMIN_NOTIFICATION_CENTER, PATH_ADMIN_CADENCE, PATH_ADMIN_INVOICES } from './routesAdmin'
import { PrivateRoutes, USER_ROLE } from 'routes/privateRoutes'
import FetchingScreen from 'components/Loader/fetching-screen'
import AdminGalleryView from 'views/Gallery'
import CadenceView from 'views/Cadence'
import RealTimeMaps from 'views/Admin/Views/Cartes/Views/RealTime'
import UseCaseCMS from 'views/UseCases/UsecaseCMS/index'
import HomeUseCaseContainer from 'views/UseCases/home'
import HomeCaseCMS from 'views/UseCases/UsecaseHome/home'
import CreateCampaignMap from 'views/Admin/Views/Cartes/Views/CreateCampaign'
import AdminLayout from 'views/Admin/Layout/adminLayout'
import CampaignList from 'views/Lists/Campaigns'
import PageTemplateInput from 'components/GlobalInputs'
import ValidationMission from 'views/Lists/ValidationMissions'
import AdminInvoicesView from 'views/Lists/Invoices'

const LoginAdminContainer = lazy(() => import('views/Logins/loginAdmin'))
const AdminDashboardView = lazy(() => import('views/Dashboard'))
const AdminStreetMarketeursView = lazy(() => import('views/Lists/Marketers'))
// const ChatView = lazy(() => import('views/Chat'))
const NotificationCenterView = lazy(() => import('views/NotificationCenter'))
const AdminSMCalendlyView = lazy(() => import('views/Calendly'))
const AdminCompaniesListView = lazy(() => import('views/Lists/Companies'))
const AdminDevtoolsView = lazy(() => import('views/Devtools'))
const AdminProfilSM = lazy(() => import('views/Lists/Marketers/ProfilSM/profilSM'))
const ProfilAdmin = lazy(() => import('views/ProfilAdmin'))

const SuspenseComponent = ({ children }) =>
  <Suspense fallback={<div style={{ minHeight: '85vh' }}><FetchingScreen isFetching={true} /></div>}>
    {children}
  </Suspense>

const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path="/loginAdmin" element={<SuspenseComponent><LoginAdminContainer /></SuspenseComponent>} />
      <Route path={PATH_USECASE_VISUALISATION} element={<SuspenseComponent><HomeUseCaseContainer/></SuspenseComponent>} />
      <Route path={PATH_ADMIN} element={<PrivateRoutes authorizations={[USER_ROLE.ADMIN]} component={AdminLayout}/>}>
        <Route path={PATH_ADMIN_CARTES} element={<SuspenseComponent><RealTimeMaps /></SuspenseComponent>} />
        <Route path={'templates'} element={<SuspenseComponent><PageTemplateInput /></SuspenseComponent>} />
        <Route path={PATH_ADMIN_DASHBOARD} element={<SuspenseComponent><AdminDashboardView /></SuspenseComponent>} />
        <Route path="/admin/carte_temp" element={<SuspenseComponent><CreateCampaignMap /></SuspenseComponent>} />
        <Route path={PATH_USECASE_CMS} element={<SuspenseComponent><HomeCaseCMS/></SuspenseComponent>} />
        <Route path={PATH_USECASE_CMS_CREATION} element={<SuspenseComponent><UseCaseCMS/></SuspenseComponent>} />
        <Route path={PATH_ADMIN_CAMPAGNES}>
          <Route index element={<SuspenseComponent><CampaignList /></SuspenseComponent>} />
        </Route>
        <Route path={PATH_ADMIN_VALIDATION_MISSIONS}>
          <Route index element={<SuspenseComponent><ValidationMission /></SuspenseComponent>} />
        </Route>
        <Route path={PATH_ADMIN_STREET_MARKETEURS}>
          <Route index element={<SuspenseComponent><AdminStreetMarketeursView /></SuspenseComponent>} />
          <Route path='profil' element={<SuspenseComponent><AdminProfilSM /></SuspenseComponent>} />
        </Route>
        {/* <Route path={PATH_ADMIN_CHAT}>
          <Route index element={<SuspenseComponent ><ChatView /></SuspenseComponent>} />
        </Route> */}
        <Route path="/admin/carte_temp" element={<SuspenseComponent ><CreateCampaignMap /></SuspenseComponent>} />
        <Route path={PATH_ADMIN_SM_CALENDLY} element={<SuspenseComponent ><AdminSMCalendlyView /></SuspenseComponent>} />
        <Route path={PATH_ADMIN_GALLERY} element={<SuspenseComponent ><AdminGalleryView /></SuspenseComponent>} />
        <Route path={PATH_ADMIN_CADENCE} element={<SuspenseComponent ><CadenceView /></SuspenseComponent>} />
        <Route path={PATH_ADMIN_NOTIFICATION_CENTER} element={<SuspenseComponent ><NotificationCenterView /></SuspenseComponent>}/>
        <Route path={PATH_ADMIN_COMPANY} >
          <Route index element={<SuspenseComponent ><AdminCompaniesListView /></SuspenseComponent>} />
        </Route>
        <Route path={PATH_ADMIN_DEVTOOLS} element={<SuspenseComponent ><AdminDevtoolsView /></SuspenseComponent>} />
        <Route path={PATH_PROFIL_ADMIN} element={<SuspenseComponent ><ProfilAdmin /></SuspenseComponent>} />
        <Route path="*" element={<SuspenseComponent ><AdminDashboardView /></SuspenseComponent>} />
        <Route path={PATH_ADMIN_INVOICES} element={<SuspenseComponent ><AdminInvoicesView /></SuspenseComponent>} />
      {/* Backup */}
      </Route>
      <Route path="*" element={<SuspenseComponent haveIntercom ><Navigate to="/loginAdmin" /></SuspenseComponent>} />
    </React.Fragment>
  )
)

export default router
