/* eslint-disable react/prop-types */
import * as React from 'react'
import HeaderTable from './header'
import BodyTable from './body'
import {
  CircularProgress,
  Paper,
  Table,
  TableContainer,
  TablePagination
} from '@mui/material'
import './style.scss'
import 'moment/locale/fr'

const ViewsListTable = (props) => {
  const [closeAll, setCloseAll] = React.useState(false)
  const {
    handleActions,
    fetching = false,
    columns = [],
    list = [],
    currentPage = 0,
    per = 50,
    setCurrentPage,
    setPer,
    filter,
    maxPage
  } = props

  const handleChangePage = (event, newPage) => {
    closeAll && setCloseAll(false)
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    closeAll && setCloseAll(false)
    setPer(+event.target.value)
    setCurrentPage(0)
  }

  return (
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        position: 'relative'
      }}
    >
      {filter}
      <TableContainer
        component={Paper}
        sx={{ height: '100%' }}
        className="table-container"
      >
        <Table stickyHeader aria-label="collapsible table">
          <HeaderTable {...{ setCloseAll, closeAll }} columns={columns} />
          {!fetching && (
            <BodyTable
              {...{
                per,
                currentPage,
                list,
                closeAll,
                setCloseAll,
                handleActions
              }}
            />
          )}
        </Table>
        {fetching && (
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <CircularProgress />
          </div>
        )}
      </TableContainer>
      <TablePagination
        style={{ overflow: 'hidden' }}
        rowsPerPageOptions={[2, 10, 25, 50]}
        component="div"
        count={maxPage}
        rowsPerPage={per}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default ViewsListTable
