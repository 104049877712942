/* eslint-disable react/prop-types */
import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

// eslint-disable-next-line react/display-name
const MenuDialog = memo(({ handleAddKml, itemsHide, handleGenerateKml }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [, setVisibleContent] = useState(!itemsHide)
  const open = Boolean(anchorEl)

  useEffect(() => {
    setVisibleContent(!itemsHide)
  }, [itemsHide])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // const removeSlot = () => {
  //   handleRemoveSlot(slot?.id)
  //   handleClose()
  // }

  return (
      <div>
        <IconButton
         style={{ width:"4px", color:'black' }}
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          style={{ zIndex: 99999 }}
        >
          <MenuItem onClick={handleAddKml}><NoteAddIcon style={{ marginRight: 10 }}/>Importer KML/CSV</MenuItem>
          <MenuItem onClick={handleGenerateKml}><FileDownloadIcon style={{ marginRight: 10 }}/>Télécharger au format KML</MenuItem>
          {/* <Divider/>
          <MenuItem onClick={() => function(){}}><DownloadingIcon style={{ marginRight: 10 }}/>Télécharger toute la campagne au format KML</MenuItem> */}
        </Menu>
      </div>
  )
})

export default MenuDialog
