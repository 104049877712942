/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { memo, useEffect, useState, useCallback } from 'react'
import { Polyline } from '@react-google-maps/api'
import { parse } from 'wellknown'
import { wktToPath } from 'views/Admin/Views/Cartes/functions/functionsMap'

const MemoizedNewPolylineComponent = memo(({ item, onPolylineChanged, handleActivePolyline, isSelected }) => {
  const [selectedVertexIndex, setSelectedVertexIndex] = useState()
  const { ref, line, fillColor, strokeWeight, fillOpacity } = item
  const new_zone_ref = ref

  const handleKeyDown = useCallback(
    (event) => {
      console.log('CLICK ??')
      console.log(ref.current.getPath())
      const path = ref?.current?.getPath() ?? 0
      console.log(event.key)
      if (event.shiftKey &&
                (event.key === 'Delete' || event.key === 'Backspace') &&
            selectedVertexIndex !== undefined && selectedVertexIndex !== null && isSelected && path.length > 2
      ) {
        console.log('SHIFT + DELETE')
        ref.current.setPath(ref.current.getPath().removeAt(selectedVertexIndex))
        console.log(ref.current.getPath())
        setSelectedVertexIndex(null)
        onPolylineChanged(null, 'modify')
      }
    },
    [selectedVertexIndex, isSelected]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    if (!isSelected) window.removeEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedVertexIndex, isSelected])

  const onLoad = (ref) => {
    new_zone_ref.current = ref
  }

  return (
        <Polyline
            key={item.id}
            onLoad={(e) => onLoad(e)}
            path={wktToPath(line ? parse(line)?.coordinates : [], 'line')}
            clickable
            onMouseUp={(e) => {
              setSelectedVertexIndex(e.vertex)
              onPolylineChanged(e, 'modify')
            }}
            onClick={(e) => {
              setSelectedVertexIndex(null)
              handleActivePolyline(e, new_zone_ref, item)
            }}
            onDragEnd={(e) => onPolylineChanged(e, 'drag')}
            options={{
              fillColor,
              strokeWeight,
              fillOpacity,
              strokeColor: fillColor,
              supressUndo: true,
              zIndex: 69
            }}
        />
  )
})

export default MemoizedNewPolylineComponent
