import React, { useMemo, useRef, useState } from "react";
import { GoogleMap, StandaloneSearchBox } from "@react-google-maps/api";
import "./style.scss";
import API from "services/axios-config.js";
import SpotsListModal from "./SpotsList";
import { Backdrop, CircularProgress } from "@mui/material";
import FlyingHandCadenceMapContent from "./FlyingHandCadenceMapContent";
import FlyingBoxCadenceMapContent from "./FlyingBoxCadenceMapContent";
import ZonesListModal from "./ZonesList";

const CadenceView = () => {
  const mapRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("Hand"); // or Box
  const [spotsList, setSpotsList] = useState([]);
  const [zonesList, setZonesList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeInfoWindow, setActiveInfoWindow] = useState(null);
  const [spotsListModalOpen, setSpotsListModalOpen] = useState(false);
  const [zonesListModalOpen, setZonesListModalOpen] = useState(false);
  const [mapCenter] = useState({ lat: 48.8584, lng: 2.2945 }); // initial center

  const handleMarkerClick = (spotId) => {
    setActiveInfoWindow(null);
    setActiveInfoWindow(spotId);
  };

  const handlePolygonClick = (zoneId) => {
    setActiveInfoWindow(null);
    setActiveInfoWindow(zoneId);
  }

  const handleInfoWindowClose = () => {
    setActiveInfoWindow(null);
  };

  const onMapLoad = (map) => {
    if (mapRef) {
      mapRef.current = map;
      getAllSpots();
    }
  };

  const getAllSpots = async () => {
    setIsLoading(true);
    return API.get("/get_all_flying_hand_spots")
      .then((res) => {
        if (res?.data?.length) setSpotsList(res.data);
        return res?.data;
      })
      .catch((_) => console.log("Une erreur est survenue"))
      .finally(() => setIsLoading(false));
  };

  const getAllZones = async () => {
    setIsLoading(true);
    return API.get("/get_all_flying_box_zones")
      .then((res) => {
        if (res?.data?.length) setZonesList(res.data);
        return res?.data;
      })
      .catch((_) => console.log("Une erreur est survenue"))
      .finally(() => setIsLoading(false));
  };

  const searchBoxRef = useRef(null);

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        if (place.geometry && place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  };

  const handleTabChange = (tab) => {
    handleInfoWindowClose();
    setSelectedTab(tab);
    if (tab === "Box" && zonesList === null) {
      getAllZones()
    }
  };

  const isAnyModalOpen = useMemo(() => {
    return spotsListModalOpen || zonesListModalOpen
  }, [spotsListModalOpen, zonesListModalOpen])

  const handleShowListModalBtnClick = () => {
    if (selectedTab === "Hand") setSpotsListModalOpen(true)
    if (selectedTab === "Box") setZonesListModalOpen(true)
  }

  const handleDeleteSpotFromCadenceView = (spotId) => {
    API.patch(`spots/${spotId}`, { cadence_visible: false });
    setSpotsList((prev) => prev.filter(spot => spot.id !== spotId))
    handleInfoWindowClose();
  }

  const handleDeleteZoneFromCadenceView = (zoneId) => {
    API.patch(`/zones/${zoneId}`, { cadence_visible: false })
    setZonesList((prev) => prev.filter(zone => zone.id !== zoneId))
    handleInfoWindowClose();
  }

  return (
    <div
      style={{
        backgroundColor: "#fff",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        position: "relative",
      }}
    >
      {!isAnyModalOpen && <div className="cadence-tabs-btns-container">
        <div
          className={`cadence-tab-btn ${
            selectedTab === "Hand" ? "selected" : ""
          }`}
          onClick={() => handleTabChange("Hand")}
        >
          FLYING HAND
        </div>
        <div
          className={`cadence-tab-btn ${
            selectedTab === "Box" ? "selected" : ""
          }`}
          onClick={() => handleTabChange("Box")}
        >
          FLYING BOX
        </div>
      </div>}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SpotsListModal
        isOpen={spotsListModalOpen}
        handleClose={() => setSpotsListModalOpen(false)}
        spots={spotsList}
      />
      <ZonesListModal
        isOpen={zonesListModalOpen}
        handleClose={() => setZonesListModalOpen(false)}
        zones={zonesList ?? []}
      />
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        center={mapCenter}
        onZoomChanged={() => setTimeout(() => {}, 2000)}
        zoom={10}
        onLoad={onMapLoad}
      >
        <StandaloneSearchBox
          onLoad={(searchBox) => (searchBoxRef.current = searchBox)}
          onPlacesChanged={handlePlacesChanged}
        >
          <input
            type="text"
            placeholder="Rechercher une adresse..."
            className="inputAddressSearch"
          />
        </StandaloneSearchBox>
        {selectedTab === "Hand" && (
          <FlyingHandCadenceMapContent
            activeInfoWindow={activeInfoWindow}
            handleInfoWindowClose={handleInfoWindowClose}
            handleMarkerClick={handleMarkerClick}
            spotsList={spotsList}
            handleDeleteSpotFromCadenceView={handleDeleteSpotFromCadenceView}
          />
        )}
        {selectedTab === "Box" && (
          <FlyingBoxCadenceMapContent
            activeInfoWindow={activeInfoWindow}
            handleInfoWindowClose={handleInfoWindowClose}
            handlePolygonClick={handlePolygonClick}
            zonesList={zonesList}
            handleDeleteZoneFromCadenceView={handleDeleteZoneFromCadenceView}
          />
        )}
      </GoogleMap>
      {!isAnyModalOpen && (
        <div
          className="cadence-main-action-btn"
          onClick={handleShowListModalBtnClick}
        >
          AFFICHER LA LISTE
        </div>
      )}
    </div>
  );
};

export default CadenceView;
