import React, { useMemo, useState, useRef, useCallback } from "react";
import {
  Polygon,
  InfoWindow,
  MarkerClusterer,
  Marker,
} from "@react-google-maps/api";
import { calculatePolygonAreaWithTurf, normalizeZIndex, wktToPath } from "views/Admin/Views/Cartes/functions/functionsMap";
import { parse } from "wellknown";
import "./style.scss";
import { formatHoursToHHMM } from "utils/helpers";
import { ReactComponent as CloseSvg } from 'assets/close.svg'
import { ReactComponent as PoubelleSvg } from "assets/poubelle.svg";
import DeleteConfirmationModal from "../DeleteConfirmationModal";

const FlyingBoxCadenceMapContent = ({
  zonesList,
  activeInfoWindow,
  handlePolygonClick,
  handleInfoWindowClose,
  handleDeleteZoneFromCadenceView,
}) => {
  const [visiblePolygons, setVisiblePolygons] = useState({});
  const visiblePolygonsRef = useRef({});
  const [selectedZoneToDelete, setSelectedZoneToDelete] = useState(null)

  const getPolygonCentroid = (paths) => {
    const bounds = new window.google.maps.LatLngBounds();
    paths.forEach((point) => {
      bounds.extend(point);
    });
    return bounds.getCenter();
  };

  const polygonsWithCentroids = useMemo(
    () =>
      zonesList?.map((zone) => {
        const zonePath = wktToPath(
          zone.polygon ? parse(zone.polygon)?.coordinates[0] : []
        );
        const centroid = getPolygonCentroid(zonePath);
        return {
          ...zone,
          path: zonePath,
          centroid,
          fillColor: zone.fillColor ?? "#000000",
          strokeColor: zone.fillColor ?? "#000000",
          fillOpacity: zone.fillOpacity ?? 0.5,
          strokeWeight: zone.strokeWeight ?? 3,
        };
      }),
    [zonesList]
  );

  const updatePolygonVisibility = useCallback((clusterer) => {
    const newVisible = {};
    clusterer.getClusters().forEach((cluster) => {
      const markers = cluster.getMarkers() || [];
      markers.forEach((marker) => {
        const polygonId = marker.getTitle();
        newVisible[polygonId] = cluster.getSize() <= 1;
      });
    });

    // Only update state if visible polygons have changed
    if (JSON.stringify(visiblePolygonsRef.current) !== JSON.stringify(newVisible)) {
      visiblePolygonsRef.current = newVisible;
      setVisiblePolygons(newVisible);
    }
  }, [visiblePolygonsRef, visiblePolygons]);

  const handleClusterClick = useCallback((cluster) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (cluster && cluster.getMarkers) {
      const markers = cluster.getMarkers();
      if (markers && markers.length > 0) {
          markers.forEach((marker) => {
            const polygonId = marker.getTitle();
            const polygon = polygonsWithCentroids.find(
              (zone) => zone.id === Number(polygonId)
            );
            if (polygon) {
              polygon.path.forEach((point) => {
                bounds.extend(point);
              });
            }
          });
        }
      }
      if (!bounds.isEmpty()) {
        cluster.map.fitBounds(bounds);
      }
  }, [polygonsWithCentroids]);

  const closeDeleteConfirmationModal = () => {
    setSelectedZoneToDelete(null)
  }

  const handleConfirmDelete = () => {
    handleDeleteZoneFromCadenceView(selectedZoneToDelete)
    setSelectedZoneToDelete(null);
  }

  return (
    <MarkerClusterer
      onLoad={updatePolygonVisibility}
      onClusteringEnd={updatePolygonVisibility}
      onClick={handleClusterClick}
      zoomOnClick={false}
    >
      {(clusterer) => (
        <>
          {polygonsWithCentroids?.map((zone) => {
          const smsNames = zone.sms_names?.length ? zone.sms_names.join(" / ") : "-";
          const formattedTotalHoursWithoutPause = zone?.total_hours_without_pause ? formatHoursToHHMM(zone?.total_hours_without_pause) : '-';
          const flyersPerHour = Math.round((zone?.total_hours_without_pause > 0 ? zone.total_distributed_flyers / zone.total_hours_without_pause : 0));
          const polygonArea = calculatePolygonAreaWithTurf(zone.path)
          const zIndex = normalizeZIndex(polygonArea)

          return (
              <React.Fragment key={zone.id}>
                {selectedZoneToDelete && selectedZoneToDelete === zone.id && <DeleteConfirmationModal type={"zone"} handleCancel={closeDeleteConfirmationModal} open handleConfirm={handleConfirmDelete} />}
                <Polygon
                  paths={zone.path}
                  visible={visiblePolygons[zone.id] ?? true}
                  options={{
                    fillColor: zone.fillColor,
                    strokeColor: zone.fillColor,
                    fillOpacity: zone.fillOpacity,
                    strokeWeight: zone.strokeWeight,
                    zIndex
                  }}
                  onClick={() => handlePolygonClick(zone.id)}
                />

                <Marker
                  clusterer={clusterer}
                  position={zone.centroid}
                  title={zone.id.toString()}
                  visible={false}
                />

                {activeInfoWindow === zone.id && (
                  <InfoWindow
                    position={zone.centroid}
                    onCloseClick={handleInfoWindowClose}
                    options={{ headerDisabled: true }}
                  >
                    <div className="cadence-zone-infowindow-container">
                    <div className="cadence-zone-infowindow-header">
                        <PoubelleSvg width={22} height={22} style={{ cursor: 'pointer' }} onClick={() => setSelectedZoneToDelete(zone.id)}/>
                        <CloseSvg width={22} height={22} style={{ cursor: 'pointer' }} onClick={handleInfoWindowClose}/>
                      </div>
                      <div className="cadence-zone-title-conatiner">Informations de la zone et cadence</div>
                      <div className="cadence-zone-key-value-conatiner">
                        <div className="zone-key">Nom de la zone :</div>
                        <div className="zone-value">
                          {zone?.title ?? "-"}
                        </div>
                      </div>
                      <div className="cadence-zone-key-value-conatiner">
                        <div className="zone-key">Nom du SM :</div>
                        <div className="zone-value">
                          {smsNames}
                        </div>
                      </div>
                      <div className="cadence-zone-key-value-conatiner">
                        <div className="zone-key">Total heures sans pause :</div>
                        <div className="zone-value">
                          {formattedTotalHoursWithoutPause}
                        </div>
                      </div>
                      <div className="cadence-zone-key-value-conatiner">
                        <div className="zone-key">Nombre total de flyers distribués :</div>
                        <div className="zone-value">
                          {zone?.total_distributed_flyers ?? "-"}
                        </div>
                      </div>
                      <div className="cadence-zone-key-value-conatiner" style={{ fontWeight: 700 }}>
                        <div className="zone-key">Nombre de flyers par heure :</div>
                        <div className="zone-value">
                          {flyersPerHour}
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </React.Fragment>
            )
          })}
        </>
      )}
    </MarkerClusterer>
  );
};

export default FlyingBoxCadenceMapContent;
