import React from 'react';
import './style.scss';
import { getButtonColorFromStatusDevis } from "utils/helpers";

const DevisStatusBadge = ({ status }) => {
    return (
        <div
            className="status-badge"
            style={{
                backgroundColor: `${getButtonColorFromStatusDevis(status)}40`,
                border: status.toLowerCase() === 'en attente de confirmation' ? '1px solid #000' : 'none',
            }}
        >
            <div
                className="circle"
                style={{
                    backgroundColor:
                        status.toLowerCase() === 'en attente de confirmation'
                            ? 'black'
                            : getButtonColorFromStatusDevis(status),
                }}
            />
            <span
                className="text"
                style={{
                    color:
                        status.toLowerCase() === 'en attente de confirmation'
                            ? 'black'
                            : getButtonColorFromStatusDevis(status),
                }}
            >
                {status}
            </span>
        </div>
    )
}

export default DevisStatusBadge;
