/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import InputText from '../Inputs/inputText/inputText'

// eslint-disable-next-line react/display-name
export const StatsContent = forwardRef((props, ref) => {
  const { content, setStats, setIsSaved } = props
  const [contentList, setContentList] = useState(content)
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (isLoad) { setStats({ content: contentList }) }
    setIsLoad(true)
  }, [contentList])

  useImperativeHandle(ref, () => ({
    handleAddInputStat () {
      setContentList((prev) => ([...prev, '']))
    }
  }))

  const handleDeleteStat = (index) => {
    if (contentList?.length > 1) {
      setContentList((prev) => {
        const newcontent = [...prev]
        newcontent?.splice(index, 1)
        return newcontent
      })
    }
  }

  const handleChange = (e, index, type) => {
    setIsSaved(false)
    setContentList(prev => prev.map((stat, _index) => {
      return _index === index ? { ...stat, [type]: e?.target?.value } : stat
    }))
  }

  return (
    <div className='statistics_container'>
      {contentList?.map((stat, index) => {
        return (
          <div className='statistics' key={`stat-${index}`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='stat' style={{ color: '#23272A' }}>
                <div className="input-title">
                  <InputText placeholder={'Titre statistique'} value={stat?.title}
                    handleChange={(e) => handleChange(e, index, 'title')} label="Titre" />
                </div>
                <div className="input-title">
                  <InputText placeholder={'Contenu statistique'} value={stat?.text}
                    handleChange={(e) => handleChange(e, index, 'text')} label="Contenu" />
                </div>
              </div>
              <div className="action" onClick={() => handleDeleteStat(index)}>
                <MdDelete color='red' cursor="pointer" size={30} />
              </div>
            </div>
            {index < contentList?.length - 1 &&
              <div className='divider' style={{ border: '1px solid #23272A' }} ></div>}
          </div>)
      })}
    </div>
  )
})
