/* eslint-disable react/prop-types */
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DateModal from "./Modals/dateModal";
import SettingsIcon from "@mui/icons-material/Settings";

// eslint-disable-next-line react/display-name
const MenuDialog = memo(
  ({
    handleRemoveSlot,
    handleHideItems,
    itemsHide,
    slot,
    handleSendModify,
    campaign,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [visibleContent, setVisibleContent] = useState(!itemsHide);
    const [ModalOpen, setModalOpen] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
      setVisibleContent(!itemsHide);
    }, [itemsHide]);

    const handleClick = (event) => {
      event.stopPropagation();

      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const removeSlot = () => {
      handleRemoveSlot(slot?.id);
      handleClose();
    };

    const changeVisibility = (e) => {
      setVisibleContent((prev) => !prev);
      handleHideItems(slot?.id);
      handleClose();
    };

    return (
      <div>
        <DateModal
          open={ModalOpen === "date"}
          {...{ setModalOpen, slot, handleSendModify, campaign }}
        />
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
        >
          <MoreVertIcon style={{ width: "15px", color: "black" }} />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => setModalOpen("date")}>
            <SettingsIcon style={{ marginRight: 10 }} />
            settings slot
          </MenuItem>
          <Divider />
          <MenuItem onClick={removeSlot}>
            <DeleteRoundedIcon color="error" style={{ marginRight: 10 }} />
            Supprimer le Slot
          </MenuItem>
          <MenuItem onClick={changeVisibility}>
            {visibleContent
              ? (
              <>
                <VisibilityIcon style={{ marginRight: 10 }} />
                Cacher le contenu
              </>
                )
              : (
              <>
                <VisibilityOffIcon />
                Afficher le contenu
              </>
                )}
          </MenuItem>
        </Menu>
      </div>
    );
  }
);

export default MenuDialog;
