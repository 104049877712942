import API from "services/axios-config";
import { useCampaignMap } from "views/Cartes/CampaignViewMap/campaignMapContext";
import './styles.scss'

export const NewSlotBtn = ({ prestation }) => {
  const { setPrestationsList } = useCampaignMap()
  function handleAddSlot () {
    API.post("slots", {
      prestation_id: prestation?.id,
      campaign_id: prestation?.campaign_id,
    }).then((res) => {
      setPrestationsList(current => current.map((presta) => {
        if (presta.id === prestation.id) {
          return { ...presta, slots: [...presta.slots, res.data] }
        } else {
          return presta
        }
      }))
    });
  }
  return (
    <div className="new-slot-btn" onClick={handleAddSlot}>Ajouter un crénaux</div>
  )
}
