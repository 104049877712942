/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import logo from 'assets/FFY-logo.png'
import './introductionffy.scss'
import { ParentContainer } from '../ParentContainer/parentContainer'

export const IntroductionUseCase = (props) => {
  const { avatar, w_title, paraph1, paraph2, ffy_style, paraph_style1, w_style, paraph_style2, activity, wactivity_style } = props
  return (
    <ParentContainer style={{ backgroundColor: '#23272a' }}>
      <div className='intro'>
        <div className='logos'>
          <div className='ffylogo'>
            <img src={logo} alt="logoffy" width={100} height={100} />
          </div>
          <div style={{ borderLeft: '1px solid white', height: 60, marginLeft: 10, marginRight: 20 }}></div>
          <div className='usecase-logo'>
            <img src={avatar} alt="usecase-logo" width={100} height={100} />
          </div>
        </div>
        <div className='container'>
          <div className='content' style={ffy_style}>
            <h1 style={w_style} >{w_title} </h1>
            <h1 style={wactivity_style} >{activity} </h1>
            <div className='paragraphs'>
              <p style={paraph_style1}>{paraph1} </p>
              <p style={paraph_style2} className='paraph2'>{paraph2} </p>
            </div>
          </div>
        </div>
      </div>
    </ParentContainer>
  )
}
