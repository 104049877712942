import { createSlice } from "@reduxjs/toolkit";

export const mission = createSlice({
  name: "missions",
  initialState: {
    fetching: {
      list: false,
      mission: false,
      assignSm: false,
    },
    missionsList: [],
    missionLaunchList: [],
    currentMissionLaunch: [],
    slotsList: [],
    modalAssign: null,
    showGraph: true,
    fullLocation: [],
    typeOfVisual: {},
    selectedMission: null,
    campaignExcludeList: [],
    campaignExcludeListZones: [],
    campaignExcludeListLocation: [],
    missionExcludeList: [],
    missionExcludeListLocation: [],
    missionExcludeListZones: [],
  },
  reducers: {
    setShowGraph: (state, action) => ({ ...state, showGraph: action.payload }),
    setCurrentMissionLaunch: (state, action) => ({
      ...state,
      currentMissionLaunch: action.payload,
    }),
    getAllMissionsError: (state) => ({ ...state, fetching: false }),
    setCampaignExcludeList: (state, action) => ({
      ...state,
      campaignExcludeList: [...state.campaignExcludeList, action.payload],
    }),
    setCampaignExcludeListLocation: (state, action) => ({
      ...state,
      campaignExcludeListLocation: [
        ...state.campaignExcludeListLocation,
        action.payload,
      ],
    }),
    setCampaignExcludeListZones: (state, action) => ({
      ...state,
      campaignExcludeListZones: [
        ...state.campaignExcludeListZones,
        action.payload,
      ],
    }),
    setMissionExcludeList: (state, action) => ({
      ...state,
      missionExcludeList: [...state.missionExcludeList, action.payload],
    }),
    setMissionExcludeListZones: (state, action) => ({
      ...state,
      missionExcludeListZones: [
        ...state.missionExcludeListZones,
        action.payload,
      ],
    }),
    setMissionExcludeListLocation: (state, action) => ({
      ...state,
      missionExcludeListLocation: [
        ...state.missionExcludeListLocation,
        action.payload,
      ],
    }),
    setTypeOfVisual: (state, action) => ({
      ...state,
      typeOfVisual: { ...state.typeOfVisual, ...action.payload },
    }),

    removeCampaignExcludeList: (state, action) => ({
      ...state,
      campaignExcludeList: state.campaignExcludeList.filter(
        (el) => el !== action.payload
      ),
    }),
    removeCampaignExcludeListZones: (state, action) => ({
      ...state,
      campaignExcludeListZones: state.campaignExcludeListZones.filter(
        (el) => el !== action.payload
      ),
    }),
    removeCampaignExcludeListLocation: (state, action) => ({
      ...state,
      campaignExcludeListLocation: state.campaignExcludeListLocation.filter(
        (el) => el !== action.payload
      ),
    }),
    removeMissionExcludeList: (state, action) => ({
      ...state,
      missionExcludeList: state.missionExcludeList.filter(
        (el) => el !== action.payload
      ),
    }),
    removeMissionExcludeListZones: (state, action) => ({
      ...state,
      missionExcludeListZones: state.missionExcludeListZones.filter(
        (el) => el !== action.payload
      ),
    }),
    removeMissionExcludeListLocation: (state, action) => ({
      ...state,
      missionExcludeListLocation: state.missionExcludeListLocation.filter(
        (el) => el !== action.payload
      ),
    }),
    haveAllMissions: (state, action) => ({
      ...state,
      fetching: false,
      missionsList: action.payload,
    }),
    addFullLocation: (state, action) => ({
      ...state,
      fetching: false,
      fullLocation: action.payload,
    }),
    openAssignSm: (state, action) => ({
      ...state,
      modalAssign: action?.payload,
    }),
    addMissionsLaunchToList: (state, action) => ({
      ...state,
      missionLaunchList: action?.payload,
    }),
    addLocationsToMission: (state, action) => ({
      ...state,
      missionLaunchList: state.missionLaunchList.map((mission) =>
        action.payload.mission_id === mission?.id
          ? { ...mission, locations: action.payload.locations }
          : mission
      ),
    }),
    addZonesToMission: (state, action) => ({
      ...state,
      missionLaunchList: state.missionLaunchList.map((mission) =>
        action.payload.mission_id === mission?.id
          ? { ...mission, zones: action.payload.zones }
          : mission
      ),
    }),
    addSpotsToMission: (state, action) => ({
      ...state,
      missionLaunchList: state.missionLaunchList.map((mission) =>
        action.payload.mission_id === mission?.id
          ? { ...mission, spots: action.payload.spots }
          : mission
      ),
    }),

    addNewLocation: (state, action) =>
      action.payload.mission_id !== state.currentMissionLaunch.id
        ? {
            ...state,
            missionLaunchList: state.missionLaunchList.map((mission) =>
              action.payload.mission_id === mission?.id
                ? {
                    ...mission,
                    on_break: action.payload.on_break,
                    locations: mission.locations.concat(
                      action.payload.new_location
                    )
                  }
                : mission
            ),
          }
        : {
            ...state,
            missionLaunchList: state.missionLaunchList.map((mission) =>
              action.payload.mission_id === mission?.id
                ? {
                    ...mission,
                    on_break: action.payload.on_break,
                    locations: mission.locations.concat(
                      action.payload.new_location
                    )
                  }
                : mission
            ),
            currentMissionLaunch: {
              ...state.currentMissionLaunch,
              on_break: action.payload.on_break,
              locations: state.currentMissionLaunch.locations.concat(
                action.payload.new_location
              ),
            },
          },
  },
});

export const {
  getAllMissionsError,
  haveAllMissions,
  openAssignSm,
  addFullLocation,
  addMissionsLaunchToList,
  addLocationsToMission,
  addNewLocation,
  setCampaignExcludeList,
  removeCampaignExcludeList,
  setTypeOfVisual,
  addZonesToMission,
  addSpotsToMission,
  setShowGraph,
  setCampaignExcludeListLocation,
  setCampaignExcludeListZones,
  removeCampaignExcludeListZones,
  setMissionExcludeList,
  setMissionExcludeListZones,
  setMissionExcludeListLocation,
  removeMissionExcludeListZones,
  removeMissionExcludeListLocation,
  removeMissionExcludeList,
  removeCampaignExcludeListLocation,
  setCurrentMissionLaunch,
} = mission.actions;

export default mission.reducer;
