import { Modal } from "@mui/material"

const ConfirmationModal = ({ openValidation, setOpenValidation, validAction }) => {
  return (<Modal
    open={openValidation}
    style={modalStyle.container}
    onClose={(e) => {
      e.stopPropagation()
      setOpenValidation(false)
    }}
  >
    <div style={modalStyle.content}>
      <div style={modalStyle.title}> Êtes-vous sûr de vouloir continuer ?</div>
      <div style={modalStyle.subtitle}>Vous êtes sur le point de supprimer un élément de la carte. Vous risquez de ne pas pouvoir le recupérer !</div>
      <div style={modalStyle.btnContainer}>
        <div onClick={validAction} style={{ ...modalStyle.btn, ...modalStyle.green }}>CONTINUER</div>
        <div onClick={(e) => {
          e.stopPropagation()
          setOpenValidation(false)
        }} style={{ ...modalStyle.btn, ...modalStyle.red }}>ANNULER</div>
      </div>
    </div>
    </Modal>)
}
const modalStyle = {
  container: {
    flex: 1,
    backgroundColor: "transparent",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    backgroundColor: 'white',
    padding: 15,
    color: 'black',
    maxWidth: 600
  },
  title: {
    fontSize: 16,
    marginBottom: 15
  },
  subtitle: {
    fontSize: 12,
  },
  btnContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  btn: {
    cursor: 'pointer',
    padding: "5px 10px",
  },
  green: {
    backgroundColor: "green"
  },
  red: {
    backgroundColor: 'red'
  }
}

export default ConfirmationModal
