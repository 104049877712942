import { useEffect, useRef, useState } from "react"

export const InputChangeName = ({ text, handleChange }) => {
  const [value, setValue] = useState(text || "")
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [inputRef.current])

  useEffect(() => {
    const handleMouseDownOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        inputRef.current.blur();
      }
    };
    document.addEventListener('mousedown', handleMouseDownOutside);
    return () => {
      document.removeEventListener('mousedown', handleMouseDownOutside);
    };
  }, []);

  return (
    <input style={{ width: "100%" }} onClick={e => e.stopPropagation()} ref={inputRef} value={value} onChange={e => setValue(e.target.value)} onKeyDown={e => e.key === "Enter" && handleChange(value)} onBlur={() => handleChange(value)}/>
  )
}

export const SimpleInput = ({ text, handleChange, disabled }) => {
  const [value, setValue] = useState("")

  useEffect(() => {
    setValue(text)
  }, [text])

  return (
    <input style={{ width: 200, borderRadius: 8, padding: 5 }} onClick={e => e.stopPropagation()} value={value} disabled={disabled} onChange={e => {
      setValue(e.target.value);
      handleChange(e.target.value);
    }} />
  )
}
export const SelectChangeName = ({ value, options, handleChange, canAddCustom = true }) => {
  const [optionsList, setOptionsList] = useState([])
  const [selectedValue, setSelectedValue] = useState(value || "");
  const [isCustom, setIsCustom] = useState(false);
  const [customValue, setCustomValue] = useState("");
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!options.includes(value)) setOptionsList([...options, value])
    else setOptionsList(options)
  }, [value, options])

  useEffect(() => {
    if (isCustom && inputRef.current) {
      inputRef.current.focus();
    } else if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [isCustom]);

  useEffect(() => {
    const handleMouseDownOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        selectRef.current.blur();
      }
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        inputRef.current.blur();
      }
    };
    document.addEventListener('mousedown', handleMouseDownOutside);
    return () => {
      document.removeEventListener('mousedown', handleMouseDownOutside);
    };
  }, []);

  const handleSelectChange = (value) => {
    if (value === "custom") {
      setIsCustom(true);
      setSelectedValue("");
    } else {
      setIsCustom(false);
      setSelectedValue(value);
      handleChange(value);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCustomValue(value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      handleChange(customValue);
    }
  };

  return (
    <div>
      {isCustom
      ? (
        <input
          type="text"
          ref={inputRef}
          value={customValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          style={{ width: "100%" }}
        />
      )
      : (
        <select
          style={{ width: "100%" }}
          onClick={e => e.stopPropagation()}
          ref={selectRef}
          value={selectedValue}
          onChange={e => handleSelectChange(e.target.value)}
        >
          {optionsList.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
          {canAddCustom && <option value="custom">[AJOUTER UN NOUVEAU TYPE]</option>}
        </select>
      )}
    </div>
  );
};
