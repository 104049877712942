/* eslint-disable react/prop-types */
import React from 'react'

export const ParentContainer = (props) => {
  return <div style={{ ...props?.style }}>
      <div style={{ maxWidth: 1440, margin: 'auto' }}>
        {props.children}
    </div>
  </div>
}
