/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { enqueueSnackbar } from 'notistack'

import API from 'services/axios-config'

export const PrivateRoutes = (props) => {
  const { component: RouteComponent } = props
  const navigate = useNavigate()
  useEffect(() => {
    API.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const status = error.response.status
          console.log('API INTERCEPTOR = ', error.response)
          if (status === 401) {
            console.log('NON AUTHORISE')
            navigate('/loginAdmin')
          } else if (status === 419) {
            enqueueSnackbar("La donnée recherchée n'existe plus, veuillez recharger votre navigateur", { variant: 'error', persist: true })
          } else {
            enqueueSnackbar('Une erreur est survenu. Rechargez votre navigateur et contacter immédiatement le dev si le problème persiste', { variant: 'error' })
          }
        } else if (error.request) {
          console.error('Pas de réponse du serveur:', error.request)
        } else {
          console.error('Erreur de configuration de la requête:', error.message)
        }
        return Promise.reject(error)
      }
    )
  }, [])

  return (<RouteComponent />)
}

export const USER_ROLE = {
  ADMIN: 'admin',
  CLIENT: 'client'
}
