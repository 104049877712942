/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { DrawingManager } from '@react-google-maps/api'
import React, { createRef, memo, useState } from 'react'
import API from 'services/axios-config'
import { toWKT, toWKT2 } from '../../../../../functions/functionsMap'
import './style.scss'

const DrawingView = memo((props) => {
  const { params, handleAddItem } = props
  const [drawingMode, setDrawingMode] = useState(null)
  // const selectedPrestation = useSelector(state => state.map.selectedPrestation)
  // const dispatch = useDispatch();

  const onPolylineComplete = (poly) => {
    setDrawingMode(null)
    if (poly) {
      API.post('/zones', [{
        ...params,
        line: toWKT2(poly)
      }])
        .then((res) => {
          handleAddItem({
            ...res?.data[0],
            type: 'zones',
            ref: createRef()
          })
        })
      poly.setMap(null)
    }
  }

  const onPolygonComplete = (poly) => {
    setDrawingMode(null)
    if (poly) {
      API.post('/zones', [{
        ...params,
        polygon: toWKT(poly)
      }])
        .then((res) => {
          handleAddItem({
            ...res?.data[0],
            type: 'zones',
            ref: createRef()
          })
        })
      poly.setMap(null)
    }
  }

  const onMarkerComplete = (marker) => {
    const adressFormat = (infos, location, formattedAdress) => {
      const findItem = (key) => {
        const value = infos?.find(item => item.types.includes(key))
        return value?.long_name
      }

      const newObject = {
        housenumber: findItem('street_number'),
        street: findItem('route'),
        postcode: findItem('postal_code'),
        city: findItem('locality'),
        longitude: location.lng(),
        latitude: location.lat(),
        label: formattedAdress

      }
      return newObject
    }

    setDrawingMode(null)
    if (marker) {
      const latLng = marker.position
      const geocoder = new window.google.maps.Geocoder()
      geocoder
        .geocode({ location: latLng })
        .then((response) => {
          if (response.results[0]) {
            const new_addr = adressFormat(response.results[0]?.address_components, response.results[0]?.geometry?.location, response.results[0]?.formatted_address)
            API.post('spots', [{
              ...params,
              address_attributes: new_addr
            }])
              .then(res => {
                handleAddItem({
                  ...res?.data[0],
                  type: 'spots',
                  ref: createRef(),
                  additional_ref: createRef()
                })
              })
          } else {
            window.alert('No results found')
          }
        })
        .catch((e) => window.alert('Geocoder failed due to: ' + e))
    }
    marker.setMap(null)
  }

  return (
    <>
      <DrawingManager
        style={{ backgroundColor: 'blue' }}
        options={{
          style: { backgroundColor: 'red' },
          drawingMode,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            // drawingModes: [
            //   window.google.maps.drawing.OverlayType.MARKER,
            //   window.google.maps.drawing.OverlayType.POLYGON,
            //   window.google.maps.drawing.OverlayType.POLYLINE,
            // ],
          }
          // polygonOptions: { editable: true, draggable: true },
        }}
        onPolygonComplete={(polygon) => onPolygonComplete(polygon)}
        onMarkerComplete={(marker) => onMarkerComplete(marker)}
        onPolylineComplete={(polyline) => onPolylineComplete(polyline)}
      />
    </>
  )
})

export default DrawingView
