/* eslint-disable react/prop-types */
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import React, { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import './inputfilter.scss'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const filterParams = [
  { param: 'Usecase désactivé', disable: true },
  { param: 'Usecase activé', disable: false }
]

const FilterSelect = ({ setFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState([])

  const handleChange = (event) => {
    const { target: { value } } = event
    setSelectedFilter(
      typeof value === 'string' ? value.split(',') : value
    )
    setFilter(
      typeof value === 'string' ? value.split(',') : value
    )
  }

  return (
    <FormControl className='filter-container'>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="filter"
        multiple
        style={{
          border: 'none',
          height: 40,
          width: 30,
          color: 'white'
        }}
        value={selectedFilter}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
        IconComponent={MenuIcon}
      >
        {filterParams.map((param) => (
          <MenuItem key={param?.param} value={param?.disable}>
            <Checkbox checked={(selectedFilter.indexOf(param?.disable) > -1)} />
            <ListItemText primary={param?.param} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default FilterSelect
