/* eslint-disable react/prop-types */
import { OverlayView } from '@react-google-maps/api'
import React, { useState } from 'react'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded'
import FormatColorFillRoundedIcon from '@mui/icons-material/FormatColorFillRounded'
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded'
import { CompactPicker } from 'react-color'
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded'
import { calculateArea, calculateLength, wktToCoordinates } from 'views/Admin/Views/Cartes/functions/functionsMap'

const WindowInfo = (props) => {
  const { modalInfos, setModalInfos } = props
  const [pickColor, setPickColor] = useState(false)

  const handleModifLayer = (infoChange) => {
    modalInfos?.currentRef?.current.setOptions({ ...infoChange })
  }

  const currentCoordinates = wktToCoordinates(modalInfos?.currentRef?.current?.getPath().getArray()?.map((path, i) => [path?.lng(), path?.lat()]))

  const sendModifyToBackend = () => {
    modalInfos?.handleModifyLayer()
  }

  return (
    <div>
    <OverlayView
      position={modalInfos?.position}
      mapPaneName={'floatPane'}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'space-between', backgroundColor: '#fff', padding: 10, minWidth: 285 }}
        ref={ ref => ref && window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(ref)}
      >
        <div style={{ display: 'flex', backgroundColor: '#fff', padding: '0 5px', position: 'absolute', top: -20, alignSelf: 'end', alignItems: 'center', justifyContent: 'center' }} onClick={() => setModalInfos(null)}><CancelPresentationRoundedIcon/>Fermer</div>
        <div style={{ fontSize: 15, fontFamily: 'Fredoka One' }}>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px' }} >
              <SquareRoundedIcon /> {calculateArea(currentCoordinates)} km²
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px' }}>
              <CropSquareRoundedIcon /> {calculateLength(currentCoordinates)} km
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 100 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', cursor: 'pointer' }}
              onClick={() => setPickColor((prev) => !prev)}>
              <FormatColorFillRoundedIcon />

            </div>
          </div>
        </div>
      {pickColor &&
              <div style={{ display: 'flex', flexDirection: 'column', padding: 10 }}>
                <CompactPicker onChangeComplete={(e) => {
                  handleModifLayer({ fillColor: e?.hex })
                  sendModifyToBackend({ fillColor: e?.hex })
                }}/>
                <p>Polygon transparency</p>
                <input type={'range'} max={100} min={2}
                  defaultValue={modalInfos?.currentRef?.current?.fillOpacity * 100 || 50}
                  onChange={(e) => handleModifLayer({ fillOpacity: e?.target?.value / 100 })}
                  onMouseUp={(e) => sendModifyToBackend({ fillOpacity: e?.target?.value / 100 })}
                />
                <p>Border width</p>
                <input type={'range'} max={30} min={1}
                  defaultValue={modalInfos?.currentRef?.current?.strokeWeight || 1}
                  onChange={(e) => handleModifLayer({ strokeWeight: e?.target?.value })}
                  onMouseUp={(e) => sendModifyToBackend({ strokeWeight: e?.target?.value })}
                />
              </div>
            }
      </div>
      </OverlayView>
      </div>
  )
}

export default WindowInfo
