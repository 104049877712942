import { createSlice } from '@reduxjs/toolkit';

export const streetMarketer = createSlice({
  name: 'streetMarketer',
  initialState: {
    fetching: false,
    users: [],
    currentSm: null,
    error: null
  },
  reducers: {
    startFetching: (state) => {
      state.fetching = true;
      state.error = null;
    },
    haveAllSM: (state, action) => {
      state.fetching = false;
      state.users = action.payload;
    },
    getAllSMError: (state, action) => {
      state.fetching = false;
      state.error = action.payload || 'Error fetching marketers';
    },
    smIsGet: (state, action) => {
      state.fetching = false;
      state.currentSm = action.payload;
    }
  }
});

export const {
  startFetching,
  haveAllSM,
  getAllSMError,
  smIsGet
} = streetMarketer.actions;

export default streetMarketer.reducer;
