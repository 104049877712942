/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { createSlice } from '@reduxjs/toolkit'

export const zoneAction = createSlice({
  name: 'zone',
  initialState: {
    activeZones: [],
    visibilityZones: {
      publish: [],
      hide: []
    },
    isCmdPressed: false,
    isShiftPressed: false,
    clientView: false,
    status: 'default',
    isLoading: false,
    error: null,
    lastSelectedZone: null

  },
  reducers: {
    setLastSelectedZone: (state, action) => ({
      ...state, lastSelectedZone: action.payload
    }),
    setStatus: (state, action) => ({
      ...state, status: action.payload
    }),
    setIsLoading: (state, action) => ({
      ...state, isLoading: action.payload
    }),
    setError: (state, action) => ({
      ...state, status: action.payload
    }),
    setActiveZones: (state, action) => ({
      ...state,
      activeZones: action.payload
    }),
    setIsCmdPressed: (state, action) => ({
      ...state,
      isCmdPressed: action.payload
    }),
    setIsShiftPressed: (state, action) => ({
      ...state,
      isShiftPressed: action.payload
    }),
    setClientView: (state, action) => ({
      ...state,
      clientView: action.payload
    }),
    removeVisibilityZones: (state, action) => ({
      ...state,
      visibilityZones: {
        publish: [],
        hide: []
      }
    }),

    setVisibilityZone: (state, action) => {
      const {
        type,
        id,
        client_visible,
        title,
        prestationTitle,
        prestationId,
        slotTitle,
        slot_id
      } = action.payload
      const { publish, hide } = state.visibilityZones

      const existingIndex = publish.findIndex(
        (zone) => zone.type === type && zone.id === id
      )

      // If the zone exists in publish, remove it
      if (existingIndex !== -1) {
        const newPublishZones = publish.filter(
          (zone) => !(zone.type === type && zone.id === id)
        )
        return {
          ...state,
          visibilityZones: {
            publish: newPublishZones,
            hide
          }
        }
      }

      const existingHideIndex = hide.findIndex(
        (zone) => zone.type === type && zone.id === id
      )

      // If the zone exists in hide, remove it and add it to publish
      if (existingHideIndex !== -1) {
        const newHideZones = hide.filter(
          (zone) => !(zone.type === type && zone.id === id)
        )
        return {
          ...state,
          visibilityZones: {
            publish,
            hide: newHideZones
          }
        }
      }

      // If the zone doesn't exist, add it to publish or hide based on client_visible
      return {
        ...state,
        visibilityZones: {
          publish: !client_visible
            ? [
                ...publish,
                {
                  type,
                  id,
                  title,
                  prestationTitle,
                  prestationId,
                  slot_id,
                  slotTitle,
                  client_visible: !client_visible
                }
              ]
            : publish,
          hide: !client_visible
            ? hide
            : [
                ...hide,
                {
                  type,
                  id,
                  title,
                  prestationTitle,
                  prestationId,
                  slot_id,
                  slotTitle,
                  client_visible: !client_visible
                }
              ]
        }
      }
    }
  }
})

export const {
  setActiveZones,
  setIsCmdPressed,
  setIsShiftPressed,
  setVisibilityZone,
  setClientView,
  removeVisibilityZones,
  setStatus,
  setIsLoading,
  setError,
  setLastSelectedZone
} = zoneAction.actions

export default zoneAction.reducer
