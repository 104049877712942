import moment from "moment";
import { toWKT, toWKT2 } from "views/Admin/Views/Cartes/functions/functionsMap";
import API from "services/axios-config";
import { ReactComponent as MyBIKE } from "assets/FLYING_BIKE.svg";
import { ReactComponent as MySTORE } from "assets/FLYING_STORE.svg";
import { ReactComponent as MyWALL } from "assets/FLYING_WALL.svg";
import { ReactComponent as MyHAND } from "assets/FLYING_HAND.svg";
import { ReactComponent as MyBOX } from "assets/FLYING_BOX.svg";

export const anomalieSvg = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: $$$/GeneralStr/196=Adobe Illustrator 27.6.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 64 80" style="enable-background:new 0 0 64 80;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FF0000;}
</style>
<g id="Calque_2">
</g>
<g id="Calque_1">
<path class="st0" d="M32,27.2c-6.1,0-11,4.9-11,11s4.9,11,11,11s11-4.9,11-11S38.1,27.2,32,27.2z M32,45.2c-0.6,0-1-0.5-1-1
s0.5-1,1-1s1,0.5,1,1S32.5,45.2,32,45.2z M33,40.2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-8c0-0.6,0.4-1,1-1s1,0.4,1,1V40.2z"/>
<path class="st0" d="M32,17.2c-11.6,0-21,9.4-21,21C11,54.5,30.6,66.5,31.5,67c0.2,0.1,0.3,0.1,0.5,0.1s0.4,0,0.5-0.1
C33.4,66.5,53,54.5,53,38.2C53,26.6,43.6,17.2,32,17.2z M32,53.2c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15S40.3,53.2,32,53.2z"/>
</g>
</svg>`;

export const companiesActivitiesList = ["Agence Immobilière", "Agence Marketing", "Aide à la personne", "Animaux", "Application B2C", "Artisan", "Association", "Automobile", "Banques / Assurances / Conseil", "BTP", "Culture / Sport", "Éducation", "Énergie", "Esthétique / soins", "Etudiant(e)", "Imprimeur", "Industrie", "Logistique", "Mairies / Institutions publiques", "Mode", "Partis politiques / Députés", "Promoteur Immobilier", "Restauration", "Retail", "Santé", "Service aux entreprises", "Telecommunications", "Tourisme"]

export function parseCampaignString(inputString) {
  // Find the last occurrence of "-" character
  const lastDashIndex = inputString.lastIndexOf("-");

  if (lastDashIndex !== -1) {
    // Get the substring after the last "-" character
    const name = inputString.substring(0, lastDashIndex);
    const id = inputString.substring(lastDashIndex + 1);
    return { name, id };
  } else {
    // If "-" is not found, set name as inputString and id as empty string
    return { name: inputString, id: "" };
  }
}
export const calculateTimeDifference = (prevTimestamp, currentTimestamp) => {
  const prevTime = new Date(prevTimestamp);
  const currentTime = new Date(currentTimestamp);
  const timeDifference = Math.abs(currentTime - prevTime) / 60000; // Difference in minutes
  return timeDifference;
};

export const formatHoursToHHMM = (hours) => {
  const totalMinutes = hours * 60;

  const hh = Math.floor(totalMinutes / 60);
  const mm = Math.round(totalMinutes % 60);

  return `${String(hh).padStart(2, '0')}:${String(mm).padStart(2, '0')}`;
}

export const formatHoursToHeuresMinutes = (hours) => {
  const totalMinutes = hours * 60;

  const hh = Math.floor(totalMinutes / 60);
  const mm = Math.round(totalMinutes % 60);

  return `${hh} heure${hh > 1 ? 's ' : ' '}\n${mm > 0 ? `${mm} minute${mm > 1 ? 's' : ''}` : ''}`;
}

export const formatTimestampDate = (timestampDate) => {
  const today = new Date();
  const date = new Date(timestampDate);
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return moment(timestampDate).format("HH:mm");
  } else if (date.getFullYear() !== today.getFullYear()) {
    return moment(timestampDate).format("DD/MM/YYYY, HH:mm");
  } else if (date.getMonth() !== today.getMonth()) {
    return moment(timestampDate).format("DD/MM, HH:mm");
  } else if (date.getDate() !== today.getDate()) {
    return moment(timestampDate).format("dddd, HH:mm");
  }
  return moment(timestampDate).format("DD/MM/YYYY, HH:mm");
};

export function changePosition(event, startPosition, activeZones, item) {
  // const heading = window.google.maps.geometry.spherical.computeHeading(
  //   event.latLng,
  //   startPosition.current
  // )
  // const movedDistance =
  //   window.google.maps.geometry.spherical.computeDistanceBetween(
  //     event.latLng,
  //     startPosition.current
  //   )

  // activeZones.forEach((polygonInst, index) => {
  //   if (polygonInst.id !== item.id && polygonInst.type === "zones") {
  //     const currentPath = polygonInst.ref.current.getPath().getArray();
  //     const newPath = currentPath.map((path) => {
  //       return window.google.maps.geometry.spherical.computeOffset(
  //         path,
  //         movedDistance,
  //         heading + 180
  //       );
  //     });
  //     polygonInst.ref.current.setPath(newPath);
  //   }
  //   if (polygonInst.id !== item.id && polygonInst.type === "spots") {
  //     const currentPoint = polygonInst.ref.current.getPosition();
  //     const newPoint = window.google.maps.geometry.spherical.computeOffset(
  //       currentPoint,
  //       movedDistance,
  //       heading + 180
  //     );
  //     polygonInst.ref.current.setPosition(newPoint);
  //   }
  // });
  startPosition.current = event.latLng;
}
export const handleAddItemShape = (
  isCmdPressed,
  item,
  setActiveZone,
  activeZones,
  dispatch
) => {
  if (isCmdPressed) {
    const isItemAlreadyPresent = activeZones.some(
      (el) => el.id === item.id && el.type === item.type
    );

    if (!isItemAlreadyPresent) {
      dispatch(
        setActiveZone([
          ...activeZones,
          { type: item.type, id: item.id, slot_id: item.slot_id },
        ])
      );
    }
  } else {
    dispatch(
      setActiveZone([{ type: item.type, id: item.id, slot_id: item.slot_id }])
    );
  }
};

export const updateZoneService = (activeZones) => {
  const addressFormat = (infos, location, formattedAddress) => {
    const findItem = (key) => {
      const value = infos?.find((item) => item.types.includes(key));
      return value?.long_name;
    };

    const newObject = {
      housenumber: findItem("street_number"),
      street: findItem("route"),
      postcode: findItem("postal_code"),
      city: findItem("locality"),
      longitude: location.lng(),
      latitude: location.lat(),
      label: formattedAddress,
    };

    return newObject;
  };

  const data = activeZones.map((el) => {
    if (el.type === "zones") {
      if (el.polygon) {
        return { id: el.id, type: el.type, polygon: toWKT(el.ref.current) };
      } else {
        return { id: el.id, type: el.type, line: toWKT2(el.ref.current) };
      }
    } else {
      const latLng = el.ref.current.position;
      const geocoder = new window.google.maps.Geocoder();
      return geocoder.geocode({ location: latLng }).then((response) => {
        if (response.results[0]) {
          return {
            id: el.id,
            address: addressFormat(
              response.results[0]?.address_components,
              response.results[0]?.geometry?.location,
              response.results[0]?.formatted_address
            ),
            type: "slot",
          };
        }
      });
    }
  });

  Promise.all(data)
    .then((updatedData) => {
      const filteredData = updatedData.filter((el) => el); // Remove undefined elements
      API.patch("updatemanyzones", { data: filteredData })
        .then((response) => {})
        .catch((error) => {
          console.log(error, "error updatemanyzones");
        });
    })
    .catch((error) => {
      console.log(error, "Promise.all updatemanyzones");
    });
};

export const handelIcon = (style) => {
  return {
    "Flying Box": <MyBOX style={style} />,
    "Flying Hand": <MyHAND style={style} />,
    "Flying Bike": <MyBIKE style={style} />,
    "Flying Wall": <MyWALL style={style} />,
    "Flying Store": <MySTORE style={style} />,
  };
};

export const getClientVisibility = (item, visibilityZones) => {
  const { type, id } = item;
  const foundZone = visibilityZones.find(
    (zone) => zone.type === type && zone.id === id
  );

  if (foundZone) {
    return { exist: true, visibility: foundZone.client_visible };
  } else {
    return { exist: false, visibility: false };
  }
};

export const hasVisibleClient = (visibilityZones) => {
  return visibilityZones.some((zone) => zone.client_visible === true);
};

export function haversineDistance(lat1, lon1, lat2, lon2) {
  // Convert latitude and longitude from degrees to radians
  const toRadians = (angle) => angle * (Math.PI / 180);
  lat1 = toRadians(lat1);
  lon1 = toRadians(lon1);
  lat2 = toRadians(lat2);
  lon2 = toRadians(lon2);

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const earthRadiusKm = 6371;

  const distanceKm = earthRadiusKm * c;

  return distanceKm;
}

export function formatSecondsToTime(seconds) {
  const totalMinutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return `${totalHours}h ${remainingMinutes}m ${remainingSeconds}s`;
}

export function verifyEntities(entitiesArray) {
  let hasVisibleEntities = false;

  entitiesArray?.forEach((entity) => {
    if (entity) {
      const hasSlots =
        entity.slots &&
        entity.slots.length > 0 &&
        entity.slots.some((slot) => {
          return (
            slot.zones &&
            slot.zones.length > 0 &&
            slot.zones.some((zone) => zone.client_visible === true)
          );
        });

      if (hasSlots) {
        hasVisibleEntities = true;
        return;
      }

      const hasInitialsZonesWithClientVisible =
        entity?.initials?.zones &&
        entity?.initials.zones.length &&
        entity.initials.zones.some((zone) => zone.client_visible === true);
      if (hasInitialsZonesWithClientVisible) {
        hasVisibleEntities = true;
        return;
      }

      const hasInitialsSpotsWithClientVisible =
        entity?.initials?.spots &&
        entity.initials.spots.length &&
        entity.initials.spots.some((spot) => spot.client_visible === true);
      if (hasInitialsSpotsWithClientVisible) {
        hasVisibleEntities = true;
      }
    }
  });

  return hasVisibleEntities;
}

export function extractProperty(objects, property) {
  // Use the map function to extract the specified property from each object
  const extractedValues = objects?.map((obj) => obj[property]);

  // Return the array of extracted values
  return extractedValues;
}
export function polylineDistance(coords) {
  // Calculate total distance along a polyline defined by a list of coordinates
  let totalDistance = 0;
  for (let i = 0; i < coords.length - 1; i++) {
    const point1 = coords[i];
    const point2 = coords[i + 1];
    const segmentDistance = haversineDistance(
      point1.lat,
      point1.lng,
      point2.lat,
      point2.lng
    );
    totalDistance += segmentDistance;
  }
  return totalDistance;
}

export function customRound(number) {
  if (number === 0) {
    // If the number is 0, return 0
    return 0;
  } else {
    // Calculate the absolute value of the number
    const absNumber = Math.abs(number);

    // Calculate the magnitude using the logarithm
    const magnitude = Math.floor(Math.log10(absNumber));

    // Define the rounding factor based on the magnitude
    let roundingFactor = 1;
    if (magnitude < 0) {
      // For numbers between 0 and 1, round to the number of decimal places
      roundingFactor = Math.pow(10, Math.abs(magnitude));
    } else {
      // For numbers greater than or equal to 1, round to the nearest integer
      roundingFactor = 1;
    }

    // Round the number using the calculated rounding factor
    return Math.round(number * roundingFactor) / roundingFactor;
  }
}

export function calculateAverageSpeed(distances, times) {
  if (distances.length !== times.length) {
    return 0;
  }

  let totalSpeed = 0;

  for (let i = 0; i < distances.length; i++) {
    if (times[i] !== 0) {
      totalSpeed += distances[i] / (times[i] / 60);
    }
  }

  const averageSpeed = totalSpeed / distances.length;
  return averageSpeed;
}

export function updateArrayCompany(data, id, updates) {
  for (let i = 0; i < data.length; i++) {
    const currentArray = data[i];
    let idMatched = false;

    for (let j = 0; j < currentArray.length; j++) {
      const item = currentArray[j];

      if (item.id === "id" && item.value === id.toString()) {
        idMatched = true;
        break;
      }
    }
    if (idMatched) {
      for (let j = 0; j < currentArray.length; j++) {
        const item = currentArray[j];
        if (Object.hasOwn(updates, item.id)) {
          item.value = updates[item.id];
        }
      }
      return data;
    }
  }

  return data;
}

export function transformObjectCompany(inputObject, currentObject) {
  const companyName = inputObject.company_name;

  const owner = inputObject.owner;

  const transformedObject = {
    company_name: companyName || currentObject.company_name,
    full_name:
      (owner?.first_name || currentObject.owner?.first_name) +
      " " +
      (owner?.last_name || currentObject.owner?.last_name),
    poste: owner?.poste || currentObject.owner?.poste,
    phone: owner?.phone || currentObject.owner?.phone,
  };

  return transformedObject;
}
export function removeCollaboratorById(company, collaboratorId) {
  // Filter out the collaborator with the given ID
  const updatedCollaborateurs = company.collaborateurs.filter(
    (collab) => collab.id !== collaboratorId
  );
  return updatedCollaborateurs;
}

export function getClientVisibleObject(array) {
  if (array.length === 0) {
    return { client_visible: false }; // If the array is empty, return { client_visible: false }
  }

  const firstClientVisible = array[0].client_visible; // Get the client_visible value of the first object

  for (let i = 1; i < array.length; i++) {
    if (array[i].client_visible !== firstClientVisible) {
      return { client_visible: false }; // If any object's client_visible is different from the first one, return { client_visible: false }
    }
  }

  return { client_visible: { value: firstClientVisible } }; // If all objects have the same client_visible value, return { client_visible: true } or { client_visible: false }
}

export const getButtonColorFromStatusDevis = (status) => {
  switch (status.toLowerCase()) {
      case 'à venir':
          return '#FF9900';
      case 'validé':
          return '#07C025';
      case 'refusé':
      case 'archivé':
          return '#FF0000';
      default:
          return '#FFFFFF';
  }
};

export const formatDate = (date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const year = d.getFullYear() % 100;
  return `${month.toString().padStart(2, '0')}/${year.toString().padStart(2, '0')}`;
};

export const getEstimatedCadence = (flyersWeight, flatsPercentage, stopPub) => {
  const cadenceTable = {
    "0 à 100gr": {
      "95%+": 460,
      "75%-95%": 375,
      "55%-75%": 340,
      "45%-55%": 310,
      "25%-45%": 230,
      "10%-25%": 160,
      "-10% à 3%": 90,
      "-3%": 70
    },
    "101gr à 150gr": {
      "95%+": 440,
      "75%-95%": 350,
      "55%-75%": 330,
      "45%-55%": 285,
      "25%-45%": 220,
      "10%-25%": 150,
      "-10% à 3%": 90,
      "-3%": 70
    }
  };

  let range;
  if (flatsPercentage >= 95) {
    range = "95%+";
  } else if (flatsPercentage >= 75) {
    range = "75%-95%";
  } else if (flatsPercentage >= 55) {
    range = "55%-75%";
  } else if (flatsPercentage >= 45) {
    range = "45%-55%";
  } else if (flatsPercentage >= 25) {
    range = "25%-45%";
  } else if (flatsPercentage >= 10) {
    range = "10%-25%";
  } else if (flatsPercentage >= -10) {
    range = "-10% à 3%";
  } else {
    range = "-3%";
  }

  if (flyersWeight) {
    let cadence = cadenceTable[flyersWeight][range];
    if (stopPub === false) {
      cadence = cadence * 0.75; // Reduce by 25%
    }
    return Math.round(cadence);
  } else {
    return null;
  }
};
