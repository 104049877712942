import { Polygon } from "@react-google-maps/api"
import { memo, useRef } from "react"

export const DrawPolygon = memo(({ params, handleModifyPolygon }) => {
  const ref = useRef()

  return (
    <Polygon
      onLoad={(e) => { ref.current = e }}
      {...params}
      clickable={true}
      onMouseUp={() => {
        handleModifyPolygon(ref.current)
      }}
    />
  )
})
