/* eslint-disable react/prop-types */

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './ValidationPublish.scss'
import { useRightModal } from 'views/Lists/Campaigns/rightModalContext'
import API from 'services/axios-config'
import { setStatus } from '_redux/Zones/reducers'

const ValidationPublish = ({ type }) => {
  const visibilityZones = useSelector((state) => state.zone.visibilityZones)

  const { removedLastStack } = useRightModal()
  const dispatch = useDispatch()
  function validateVisibility () {
    dispatch(setStatus('pending'))
    API.patch('update_visibility', { data: type === 'hide' ? visibilityZones.hide : visibilityZones.publish })
      .then((response) => {
        dispatch(setStatus('fulfilled'))
      })
      .catch((error) => {
        console.log(error, 'error')
      })
      .finally(() => {
        dispatch(setStatus('default'))
        removedLastStack()
      })
  }
  function renderData (data) {
    const prestations = []
    const slots = []
    const zones = []

    data.forEach((item) => {
      if (item.prestationTitle && !prestations.includes(item.prestationTitle)) {
        prestations.push(item.prestationTitle)
      }
      if (
        item.slot_id &&
        item.slotTitle &&
        !slots.some((slot) => slot.type === item.type && slot.id === item.id)
      ) {
        slots.push({ type: item.type, id: item.id, name: item.slotTitle })
      }

      if (
        item.title &&
        !zones.some((zone) => zone.type === item.type && zone.id === item.id)
      ) {
        zones.push({ type: item.type, id: item.id, name: item.title })
      }
    })

    return (
      <div>
        {prestations.length > 0 && (
          <div className="ValidationPublish-container">
            <h2 >PRESTATION:</h2>
            <div className="ValidationPublish-sub-container">
            {prestations.map((prestation, index) => (
              <div key={index}>{prestation}</div>
            ))}

            </div>

          </div>
        )}
        {slots.length > 0 && (
          <div className="ValidationPublish-container">
            <h2 className={'ValidationPublish-container-first-title'}>SLOT:</h2>
            <div className="ValidationPublish-sub-container">

            {slots.map((slot, index) => (
              <div key={index}>{slot.name}</div>
            ))}
                        </div>

          </div>
        )}
        {zones.length > 0 && (
          <div className="ValidationPublish-container">
          <h2 className={'ValidationPublish-container-first-title'}>ZONE:</h2>
          <div className="ValidationPublish-sub-container">

            {zones.map((zone, index) => (
              <div key={index}>{zone.name}</div>
            ))}
                        </div>

          </div>
        )}
      </div>
    )
  }

  return (
    <div style={{ padding: 20 }}>
      <div className="ValidationPublish-first-title">
       {type === 'publish' ? 'TU ES SUR LE POINT D’AJOUTER UNE/DES NOUVELLE(S) ZONE(S) SUR L’ESPACE CLIENT !' : 'TU ES SUR LE POINT DE DÉPUBLIER TOUTES LES ZONES AFFICHÉES AU CLIENT'}
      </div>
      <div className="ValidationPublish-second-title">
        Vérifie bien que les zones que tu souhaites afficher sont bien les
        bonnes avant de valider.
      </div>
      <div className="ValidationPublish-second-title">
        {type === 'publish' ? 'Le client pourra voir ces informations : ' : 'Le client ne pourra plus voir ces informations : '}
      </div>
      <div >{renderData(type === 'publish' ? visibilityZones.publish : visibilityZones.hide)}</div>
      <div>
        <div
          style={{
            marginTop: '100px',
            display: 'flex',
            flexDirection: 'row',
            gap: '20px'
          }}
        >
          <div
            className="ValidationPublish-publish-map-btn"
            onClick={() => {
              validateVisibility()
            }}
          >
            VALIDER
          </div>
          <div
            className="ValidationPublish-publish-map-btn"
            style={{ color: 'red' }}
            onClick={() => {
              removedLastStack()
            }}
          >
            ANNULER
          </div>
        </div>
      </div>
    </div>
  )
}

export default ValidationPublish
