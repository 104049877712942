import { config, settingsConfig } from './configRoutes'
import { useSelector, useDispatch } from 'react-redux'
import { initializeApp } from '@firebase/app'
import { getDatabase, ref, onValue } from '@firebase/database'
import './style.scss'
import { useNavigate, useLocation } from 'react-router-dom'
import React, { cloneElement, useState, useEffect } from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import API from 'services/axios-config'
import { Badge } from '@mui/material'
import { updateHasNewMessage } from '_redux/Admins/reducers'
import { ReactComponent as SettingsLogo } from 'assets/settings.svg'
import Popover from '@mui/material/Popover'
import ffyLogo from 'assets/FFY-logo.png'

const firebaseConfig = {
  apiKey: 'AIzaSyB4kkwTjkEMIz7YoRnoTy36VB_ieG9J2kI',
  authDomain: 'test-3bc1b.firebaseapp.com',
  databaseURL: 'https://test-3bc1b-default-rtdb.firebaseio.com',
  projectId: 'test-3bc1b',
  storageBucket: 'test-3bc1b.appspot.com',
  messagingSenderId: '947004379595',
  appId: '1:947004379595:web:6f8768472036e45ae8a64e',
  measurementId: 'G-SEQ9FJZL08'
}

const NavBarIcon = ({ route, slotsWaitingValidationCount, missionsWaitingValidationCount, smInvoicesToComeCount, hasNewMessage }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  if (route.name === 'Campagnes' && slotsWaitingValidationCount > 0) {
    return (
    <div style={{ cursor: 'pointer', justifySelf: 'center' }} onClick={() => navigate(route?.path)}>
      <Badge
        badgeContent={slotsWaitingValidationCount}
        color="error"
        invisible={false}
        overlap="circular"
        max={99}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        {route.icon && cloneElement(route.icon, { fill: pathname?.includes(route?.path) ? '#60EED2' : 'white' })}
      </Badge>
    </div>
    )
  }

  if (route.name === 'Missions à valider' && missionsWaitingValidationCount > 0) {
    return (
    <div style={{ cursor: 'pointer', justifySelf: 'center' }} onClick={() => navigate(route?.path)}>
      <Badge
        badgeContent={missionsWaitingValidationCount}
        color="error"
        invisible={false}
        overlap="circular"
        max={99}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        {route.icon && cloneElement(route.icon, { fill: pathname?.includes(route?.path) ? '#60EED2' : 'white' })}
      </Badge>
    </div>
    )
  }

  if (route.name === 'Facturation SM' && smInvoicesToComeCount > 0) {
    return (
    <div style={{ cursor: 'pointer', justifySelf: 'center' }} onClick={() => navigate(route?.path)}>
      <Badge
        badgeContent={smInvoicesToComeCount}
        color="error"
        invisible={false}
        overlap="circular"
        max={99}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        {route.icon && cloneElement(route.icon, { fill: pathname?.includes(route?.path) ? '#60EED2' : 'white' })}
      </Badge>
    </div>
    )
  }

  if (route.name === 'Chat' && hasNewMessage) {
    return (<Badge
      color="error"
      invisible={false}
      overlap="circular"
      variant="dot"
      size="large"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      {route.icon && cloneElement(route.icon, { fill: pathname?.includes(route?.path) ? '#60EED2' : 'white' })}
    </Badge>)
  }

  return (<div style={{ cursor: 'pointer', justifySelf: 'center' }} onClick={() => navigate(route?.path)}>
            {route.icon && cloneElement(route.icon, { fill: pathname?.includes(route?.path) ? '#60EED2' : 'white' })}
          </div>)
}

const app = initializeApp(firebaseConfig)
const db = getDatabase(app)

const LeftNavbar = () => {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.admin)
  const dbRef = ref(db, `/admin/${userData?.user?.id}`)
  const [isOpen, setIsOpen] = useState(false)
  const [slotsWaitingValidationCount, setSlotsWaitingValidationCount] = useState(0);
  const [missionsWaitingValidationCount, setMissionsWaitingValidationCount] = useState(0);
  const [smInvoicesToComeCount, setSmInvoicesToComeCount] = useState(0);

  const [anchorElSettingsPopover, setAnchorElSettingsPopover] = React.useState(null)
  const handleOpenSettingsPopover = (event) => {
    setAnchorElSettingsPopover(event.currentTarget)
  }
  const handleCloseSettingsPopover = () => {
    setAnchorElSettingsPopover(null)
  }
  const settingsPopoverOpen = Boolean(anchorElSettingsPopover)

  useEffect(() => {
    const unsubscribe = onValue(dbRef, (snapshot) => {
      const data = snapshot.val()
      if (data) {
        dispatch(updateHasNewMessage(data.newMessage))
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  useEffect(() => {
    API.get('navbar_data')
        .then((res) => {
          if (res.data && res.data.slots_need_validations_count) {
            setSlotsWaitingValidationCount(res.data.slots_need_validations_count)
          }
          if (res.data && res.data.missions_to_validate_count) {
            setMissionsWaitingValidationCount(res.data.missions_to_validate_count)
          }
          if (res.data && res.data.sm_invoices_to_come_count) {
            setSmInvoicesToComeCount(res.data.sm_invoices_to_come_count)
          }
        })
        .catch((error) => console.log('error /navbar_data:', error))
  }, [pathname])

  function handleDisconnect () {
    API.delete('users/logout')
      .then(_ => navigate('/'))
      .catch(_ => _)
  }

  return (
        <div className={`desktop-navbar ${isOpen && 'is-open'}`}>
            <div className="navbar-content"
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
                >
                    <div className="top-navbar-header">
                        <img className="logo-ffy" src={ffyLogo}/>
                        {isOpen ? <div className="title-navbar first-name-admin">Bonjour, <strong>{userData?.user?.first_name}</strong></div> : <div className="title-navbar">&nbsp</div>}
                    </div>
                    <div className="separate"></div>
                <div className="list-container">
                <div className={`router-list ${isOpen && 'open'}`}>
                    {config?.map((route) => {
                      return (
                        <div key={route.path} className="route-wrapper">
                          <NavBarIcon route={route} slotsWaitingValidationCount={slotsWaitingValidationCount} missionsWaitingValidationCount={missionsWaitingValidationCount} smInvoicesToComeCount={smInvoicesToComeCount} hasNewMessage={userData?.hasNewMessage} />
                          {isOpen && <div className="item-text">
                              <div onClick={() => navigate(route?.path)} className={`item-name ${pathname?.includes(route?.path) ? 'isSelect' : ''}`}>{route?.name?.toUpperCase()}</div>
                              <div className="sub-item-container">
                              {route?.subItem?.map((subItem) => {
                                return (
                                      <div key={`current-route-${subItem?.path}`} onClick={() => navigate(subItem?.path)} className={`sub-item-name ${search && subItem?.path?.includes(pathname) && (pathname + decodeURIComponent(search)) === subItem.path ? 'isSelect' : ''}`}>{subItem?.name?.toUpperCase()}</div>
                                )
                              })}
                              </div>
                          </div>}
                        </div>
                      )
                    })}
                </div>
                <div className={`setting-logo-container ${isOpen && 'open'}`}>
                <div
                    aria-describedby="nav-settings-popover"
                    className="settings-btn-container"
                    onClick={handleOpenSettingsPopover}
                >
                    <SettingsLogo color="#23272A" />
                </div>
                </div>
                <Popover
                    className="nav-settings-popover"
                    id="nav-settings-popover"
                    open={settingsPopoverOpen}
                    anchorEl={anchorElSettingsPopover}
                    onClose={handleCloseSettingsPopover}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}>
                    <div className="nav-settings-popover-content-container">
                        {settingsConfig.map((configRoute, index) => {
                          return (<div className={`nav-settings-popover-btn-container ${pathname?.includes(configRoute?.path) ? 'isSelect' : ''}`} onClick={() => navigate(configRoute?.path)} key={index}>
                                {configRoute.icon && cloneElement(configRoute.icon, {
                                  className: 'nav-settings-popover-btn-icon'
                                })}

                                <div className="nav-settings-popover-btn-text">{configRoute?.name}</div>
                            </div>)
                        })}

                        <div className="nav-settings-popover-btn-container logout" onClick={handleDisconnect}>
                            <ExitToAppIcon className="nav-settings-popover-btn-icon" />
                            <div className="nav-settings-popover-btn-text">SE DÉCONNECTER</div>
                        </div>
                    </div>
                </Popover>
            </div>
            </div>
        </div>
  )
}

export default LeftNavbar
