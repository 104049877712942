/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Draggable } from "react-beautiful-dnd";
import MapsDraw from "./MapsDraw";
import { cloneElement, memo, useEffect, useState } from "react";
import { ReactComponent as MyPOLYGON } from "assets/polygon-svgrepo-com-ivan.svg";
import { ReactComponent as MyPIN } from "assets/pin.svg";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import API from "services/axios-config";
import InputChangeActive from "components/Inputs/InputChangeActive";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../style.scss";
import "./style.scss";
import DrawingView from "../drawingView";
import { getClientVisibility, handleAddItemShape } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveZones,
  setIsCmdPressed,
  setIsShiftPressed,
  setVisibilityZone,
} from "_redux/Zones/reducers";
import { ReactComponent as MyMapVision } from "assets/map-vision.svg";

const ItemTitleIcon = memo(({ type, item }) => {
  if (type === "zones") {
    return cloneElement(
      <MyPOLYGON
        style={{ height: "16px", width: "16px", marginRight: "4px" }}
        id={`colored-square-${item?.id}`}
      />,
      { fill: item?.fillColor, stroke: item?.fillColor }
    );
  } else if (type === "spots") {
    return cloneElement(
      <MyPIN
        style={{ height: "16px", width: "16px", marginRight: "4px" }}
        id={`colored-square-${item?.id}`}
      />,
      { fill: item?.fillColor, stroke: item?.fillColor }
    );
  }
});

const ContentName = memo(
  ({
    polyIsLoad,
    item,
    handleRemoveItem,
    isSelected,
    mapRef,
    itemId,
    handleChangeTitle,
    currentPrestation,
    _slot,
  }) => {
    const [isHidden, setIsHidden] = useState(false);
    const dispatch = useDispatch();
    const visibilityZones = useSelector((state) => state.zone.visibilityZones);

    function OverItem (item) {
      item?.ref?.current?.setOptions({ fillColor: "red" });
    }
    function handleVisibilityZone (item, visibilityZones) {
      const visibilityInfo = getClientVisibility(item, visibilityZones);
      return visibilityInfo.exist
        ? visibilityInfo.visibility
        : item.client_visible;
    }
    function handleClickZoneName (currentRef) {
      if (mapRef.current && currentRef.current) {
        if (currentRef.current.getPosition) {
          const markerPosition = currentRef.current.getPosition();
          mapRef.current.panTo(markerPosition);
          const desiredZoomLevel = 18;
          mapRef.current.setZoom(desiredZoomLevel);
        } else {
          const bounds = new window.google.maps.LatLngBounds();
          currentRef.current.getPath().forEach((latLng) => {
            bounds.extend(latLng);
          });
          mapRef.current.fitBounds(bounds);
        }
      }
    }

    function handleHideItem (e) {
      e.stopPropagation();
      setIsHidden((prev) => !prev);
      item?.ref?.current?.setOptions({ visible: isHidden });
    }
    const activeZones = useSelector((state) => state.zone.activeZones);
    return (
      <div
        id={`${itemId}`}
        style={
          activeZones.some((el) => el.id === item.id && el.type === item.type)
            ? {
                backgroundColor: "#f1f1f1",
                fontWeight: "800",
              }
            : {}
        }
        className="title-item-container"
        onMouseEnter={() => OverItem(item)}
        onMouseLeave={() =>
          item?.ref?.current?.setOptions({ fillColor: item?.fillColor })
        }
        onClick={() => handleClickZoneName(item?.ref)}
      >
        {polyIsLoad && <ItemTitleIcon type={item?.type} {...{ item }} />}
        <div className="title-part-2-container">
          <InputChangeActive
            title={item.title || "new"}
            isSelected={true}
            onChange={(e) => handleChangeTitle(e, item)}
          />
          <div className="icons-container">
            {isHidden
              ? (
              <VisibilityOffIcon
                onClick={handleHideItem}
                style={{ width: "18px", marginTop:0 }}
              />
                )
              : (
              <VisibilityIcon
                className="eye-open"
                onClick={handleHideItem}
                style={{ width: "18px", marginTop:0 }}
              />
                )}
            <MyMapVision
              className={item.client_visible ? 'map-vision-true' : 'map-vision'}
              style={{ width:"18px" }} onClick={() =>
                dispatch(
                  setVisibilityZone({
                    ...item,
                    prestationTitle: currentPrestation.presta_type,
                    prestationId: currentPrestation.id,
                    slotTitle: _slot?.title || 'nouvelle zone'
                  })
                )
              }
              color={
                item.client_visible &&
                handleVisibilityZone(item, [
                  ...visibilityZones.hide,
                  ...visibilityZones.publish
                ])
                  ? '#60EED2'
                  : handleVisibilityZone(item, [
                    ...visibilityZones.hide,
                    ...visibilityZones.publish
                  ]) !== item.client_visible
                    ? '#FF7A00'
                    : 'black'
              }
            />
            <div
              className="remove-item"
              onClick={(e) => handleRemoveItem(item, e)}
            >
              <DeleteRoundedIcon color="error" style={{ width: "18px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const InitialListItems = memo(
  ({
    initialsListFormatted,
    provided,
    slot_id,
    isSelected,
    handleActivePolyline,
    handleActivePolygon,
    handleClickMarker,
    currentPrestation,
    mapRef,
    setInitialsListFormatted,
    activePolygon,
    activePolyline,
  }) => {
    const [polyIsLoad, setPolyIsLoad] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (event.metaKey || event.ctrlKey) {
          dispatch(setIsCmdPressed(true));
        }
        if (event.key === "Shift") {
          dispatch(setIsShiftPressed(true));
        }
      };

      const handleKeyUp = (event) => {
        if (!event.metaKey && !event.ctrlKey) {
          dispatch(setIsCmdPressed(false));
        }
        if (event.key === "Shift") {
          dispatch(setIsShiftPressed(false));
        }
      };

      // Add event listeners when the component mounts
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);

      // Remove event listeners when the component unmounts
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);
      };
    }, []);
    useEffect(() => {
      !polyIsLoad && setPolyIsLoad(true);
    }, []);
    function handleClickAddZone (itemClickable, indexItem) {
      if (isShiftPressed) {
        let index = -1;
        for (const item of activeZones) {
          const foundIndex = initialsListFormatted.findIndex(
            (elem) => elem.id === item.id && elem.type === item.type
          );
          if (foundIndex !== -1) {
            index = foundIndex;
            break;
          }
        }

        if (index === -1) {
          handleAddItemShape(
            true,
            itemClickable,
            setActiveZones,
            activeZones,
            dispatch
          );
        } else {
          const startIndex = Math.min(index + 1, indexItem);
          const endIndex = Math.max(index, indexItem);

          const filteredItems = initialsListFormatted
            .slice(startIndex, endIndex + 1)
            .filter((item) => {
              return !activeZones.some(
                (el) => el.id === item.id && el.type === item.type
              );
            });
          dispatch(
            setActiveZones([
              ...activeZones,
              ...filteredItems.map((item) => ({
                type: item.type,
                id: item.id,
                slot_id: item.slot_id,
              })),
            ])
          );
        }
      } else {
        handleAddItemShape(
          isCmdPressed,
          itemClickable,
          setActiveZones,
          activeZones,
          dispatch
        );
      }
    }

    function handleRemoveItem (item, e) {
      e.stopPropagation();
      API.delete(`${item?.type}/${item?.id}`);
      setInitialsListFormatted((prev) =>
        prev.filter(
          (_item) => !(_item?.id === item?.id && _item?.type === item?.type)
        )
      );
    }

    function handleChangeTitle (value, item) {
      API.patch(`${item?.type}/${item?.id}`, { title: value });
      setInitialsListFormatted((prev) =>
        prev.map((_item) => {
          if (_item?.id === item?.id && _item?.type === item?.type) {
            return { ..._item, title: value };
          }
          return _item;
        })
      );
    }
    const isCmdPressed = useSelector((state) => state.zone.isCmdPressed);
    const activeZones = useSelector((state) => state.zone.activeZones);
    const isShiftPressed = useSelector((state) => state.zone.isShiftPressed);
    const clientView = useSelector((state) => state.zone.clientView);

    return (
      <div ref={provided.innerRef} {...provided.droppableProps}>
        {initialsListFormatted?.map((item, index) => {
          if (item.client_visible || !clientView) {
            const itemId = `item-${item?.type}-${item?.id}`;
            return (
              <Draggable
                key={`draggable-${item?.id}-initials-${index}`}
                draggableId={`draggable-initials-${index}`}
                index={index}
              >
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => {
                        handleClickAddZone(item, index);
                        if (item.polygon) {
                          handleActivePolygon(null, item.ref, item);
                        } else if (item.line) {
                          handleActivePolyline(null, item.ref, item);
                        } else if (item.type === "spots") {
                          handleClickMarker(null, item.ref, item);
                        }
                      }}
                    >
                      <MapsDraw
                        type={item?.type}
                        {...{
                          item,
                          handleActivePolyline,
                          handleActivePolygon,
                          setInitialsListFormatted,
                          slot_id,
                          currentPrestation,
                          handleClickMarker,
                          activePolygon,
                          activePolyline,
                          mapRef,
                        }}
                      />
                      <ContentName
                        {...{
                          polyIsLoad,
                          item,
                          handleRemoveItem,
                          currentPrestation,
                          isSelected,
                          mapRef,
                          itemId,
                          handleChangeTitle,
                        }}
                      />
                    </div>
                  );
                }}
              </Draggable>
            );
          }
        })}
        <div style={{ height: 1, width: "100%" }}></div>
        {provided.placeholder}
      </div>
    );
  }
);

const InitialListView = ({
  initialsListFormatted,
  provided,
  handleActivePolyline,
  handleActivePolygon,
  handleSelectPrestation,
  prestationSelected,
  mapRef,
  setInitialsListFormatted,
  currentPrestation,
  handleClickMarker,
  activePolygon,
  activePolyline,
}) => {
  function handleAddItem (newItems) {
    setInitialsListFormatted((prev) => [...prev, newItems]);
  }

  return (
    <div
      style={{
        backgroundColor: "#fff",
        marginTop: 5,
        borderRadius: "0px 0px 10px 10px",
      }}
    >
      <div className="first-line-zone-init">
        <div></div>
        <div>Zones initiales</div>
        <div
          onClick={() => handleSelectPrestation(currentPrestation)}
          className={`adding-initial-btn ${
            currentPrestation?.id === prestationSelected?.id && "isSelected"
          } `}
        >
          +
        </div>
        {currentPrestation?.id === prestationSelected?.id && (
          <DrawingView
            currentPrestation={currentPrestation}
            {...{ handleAddItem }}
            params={{ prestation_id: currentPrestation?.id }}
          />
        )}
      </div>
      <InitialListItems
        {...{
          initialsListFormatted,
          provided,
          handleActivePolyline,
          handleActivePolygon,
          mapRef,
          setInitialsListFormatted,
          currentPrestation,
          handleClickMarker,
          activePolygon,
          activePolyline,
        }}
      />
    </div>
  );
};

export default InitialListView
