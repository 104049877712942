/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import FFYDrawer from './Drawer/ffyDrawer'
import HorizontalNavbar from './HorizontalNavbar/horizontalNavbar'

const Navbar = ({ windowHeight, scroll, windowWidth }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  if (windowHeight - (scroll * 2) > 0 && (windowHeight < windowWidth)) {
    return (
    <FFYDrawer height={windowHeight - (scroll * 2)} scroll={scroll}
    open={open} handleClose={handleClose} handleOpen={handleOpen} />
    )
  }
  return <HorizontalNavbar open={open} handleClose={handleClose} handleOpen={handleOpen} />
}

export default Navbar
