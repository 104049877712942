/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import API from 'services/axios-config'
import IndexSectionsUseCase, { IntroductionForUseCase } from './sections/indexSections'
import VitrineContainer from './vitrine/vitrineContainer'

const UseCaseView = ({ id }) => {
  const [useCase, setUseCase] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(false)
    API?.get(`/usecases/${id}`)
      .then((res) => {
        setUseCase(res?.data)
        setIsLoaded(true)
      })
      .catch((err) => console.log(err))
  }, [id])

  return (
    <>
      {isLoaded &&
      <VitrineContainer
        Sections={() => <IndexSectionsUseCase data={useCase} />} Introduction={() =>
          <IntroductionForUseCase data={useCase?.header?.datas } header_url={useCase?.header_url} />}
        hidden={!useCase?.video_header?.visible} widthp1='90%'
        videoUrl={useCase?.video_header?.datas?.url}
      />}
    </>
  )
}

export default UseCaseView
