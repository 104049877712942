/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { memo } from 'react'
import { Polyline } from '@react-google-maps/api'

// eslint-disable-next-line react/display-name
const MemoizedPolylineComponent = memo(({ item, handleClickPolyline }) => {
  const { ref } = item
  const new_zone_ref = ref

  const onLoad = (ref) => {
    new_zone_ref.current = ref
  }

  return (
      <Polyline
            onLoad={(e) => onLoad(e)}
            path={item?.location?.positions?.flat(Infinity)?.map(direction => {
              return { lat: direction?.coords?.latitude, lng: direction?.coords?.longitude }
            })}
            clickable
            // onClick={(e) => handleClickPolyline(e, new_zone_ref, item)}
            options={{
              ...item?.location,
              strokeColor: 'orange',
              icons: [{
                // icon: lineSymbol,
                color: 'red',
                offset: '0',
                repeat: '20px'
              }]
            }}
        />
  )
})

export default MemoizedPolylineComponent
