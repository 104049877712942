/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { ReactComponent as DropdownSvg } from 'assets/dropdown-icon.svg'
import SMPhoto from './SMPhoto'
import './style.scss'

const ServicePhoto = (props) => {
  const { prestationImages } = props
  const [isServiceOpen, setIsServiceOpen] = useState(false)

  const toggleService = () => {
    setIsServiceOpen((prev) => !prev)
  }

  return (
    <div className="service-photo-container">
      <div className="service-photo-header" onClick={toggleService}>
        <DropdownSvg
          className={`dropdown-icon ${isServiceOpen ? 'open' : ''}`}
        />
        <div className="service-name">{prestationImages[0]}</div>
      </div>
      {isServiceOpen && Object.entries(prestationImages[1]).map(
        (mission, index) => {
          return (
            <div key={`${prestationImages[0]}-mission-${index}`}>
              <SMPhoto {...props} missionImages={mission} />
            </div>
          )
        })}
    </div>
  )
}

export default ServicePhoto
