/* eslint-disable camelcase */
import { Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { didMount, sortSectionsByIndex } from './functions/functions'
import CMS from './CMS/indexView'
import HeaderCMS from './Header/headerCMS'
import './index.scss'

const UseCaseCMS = () => {
  const bodyRef = useRef()
  const navigate = useNavigate()
  const [, setWindowScroll] = useState(0)
  const [hasModifiedHeader, setHasModifiedHeader] = useState(false)
  const [queryParams] = useSearchParams()
  const [currentUseCase, setCurrentUseCase] = useState(null)
  const id = queryParams?.get('id')
  const [isLoaded, setIsLoaded] = useState(false)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    const handleScroll = () => {
      setWindowScroll(window.pageYOffset)
    }
    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    didMount(id, setCurrentUseCase, setIsLoaded, navigate)
  }, [id])

  const handleVisualisation = () => {
    bodyRef?.current?.visualisation()
  }

  const serializedList = () => {
    const sections = ['header', 'explain', 'stats', 'sub_image', 'double_image', 'commentary', 'video_header']
    if (currentUseCase) {
      const key_list = Object.keys(currentUseCase)?.filter((key, _) => sections?.includes(key))
      const results = key_list?.map((key, _) => ({ ...currentUseCase[key], name: key }))
        ?.map((section, _) => {
          switch (section?.name) {
            case 'header':
              return ({ ...section, header_url: currentUseCase.header_url })
            case 'commentary':
              return ({ ...section, commentary_url: currentUseCase.commentary_url })
            case 'sub_image':
              return { ...section, sub_image_url: currentUseCase.sub_image_url }
            case 'double_image':
              return { ...section, file1_url: currentUseCase.file1_url, file2_url: currentUseCase.file2_url }
            default: return section
          }
        })
      return sortSectionsByIndex(results)
    } else {
      return sections?.map((section) => ({ name: section }))
    }
  }

  const headerValues = () => {
    const keys = ['name', 'cover_url', 'size', 'activity']
    if (currentUseCase) {
      return Object.keys(currentUseCase).reduce((acc, key) => {
        if (keys?.includes(key)) {
          acc[key] = currentUseCase[key]
        }
        return acc
      }, {})
    }
  }

  const zoom = windowSize?.width < 1200 ? windowSize?.width / 1200 : 1

  return (
    <div id='vitrineFFY' style={{ zoom, transformOrigin: 'top left', flex: 1, overflow: 'auto' }}>
      {isLoaded && <>
        <HeaderCMS presentation={headerValues()}
          setHasModified={setHasModifiedHeader} hasModifiedHeader={hasModifiedHeader}
        />
        <div style={{ backgroundColor: 'white' }}>
          <CMS ref={bodyRef} sectionsList={serializedList()} />
        </div>
      </>}
      {
        <div className='action-btns'>
          <Button className='show' variant='contained' style={{ marginLeft: 50 }} color='warning'
            onClick={handleVisualisation} disabled={hasModifiedHeader} >
            {hasModifiedHeader ? 'Enregistrer les modifs !' : 'Visualiser'}
          </Button>
        </div>}
    </div>
  )
}

export default UseCaseCMS
