/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import BtnComponent from "components/GlobalInputs/Btn";
import { Popover } from "@mui/material";

export const VisuStatus = (props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const currentRowStatus = props.row.find((_item) => _item?.id === "status").value;
  const colors = {
    "Non générée": "red",
    Contestée: "red",
    "À venir": "#FF7A00",
    Réglée: "#60EED2",
    Validé: "#e5d0ff",
  };
  const getDropdownOptions = () => {
    if (currentRowStatus === "Validé") {
      return ["Réglée"];
    } else if (currentRowStatus === "À venir") {
      return ["Validé"];
    }
    return [];
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    if (currentRowStatus === "Validé" || currentRowStatus === "À venir")
      setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef?.current?.contains(event.target) &&
      !buttonRef?.current?.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
  };

  const popoverContent = useMemo(() => {
    const currentRowGenerationErrors = props.row.find((_item) => _item?.id === "generation_errors").value;
    const currentRowSmContestationDetails = props.row.find((_item) => _item?.id === "sm_contest_details").value;
    if (currentRowStatus === 'Contestée') {
      if (currentRowSmContestationDetails && currentRowSmContestationDetails !== "[empty]")
        return currentRowSmContestationDetails
      else return "[PAS D'INFORMATION]"
    }
    if (currentRowStatus === 'Non générée') {
      if (currentRowGenerationErrors?.length > 0)
        return (<>{currentRowGenerationErrors.map(error => <div>• {error}</div>)}</>)
      else return "[PAS D'INFORMATION]"
    }
    return false
  }, [currentRowStatus])

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={(e) => {
        if (popoverContent) handlePopoverOpen(e)
      }}
      onMouseLeave={
        (e) => {
          if (popoverContent) handlePopoverClose(e)
        }}
    >
      <BtnComponent
        ref={buttonRef}
        onClick={toggleDropdown}
        text={currentRowStatus?.toUpperCase()}
        variant="regular"
        color={colors[currentRowStatus]}
        style={{ borderRadius: 10, width: 100, fontWeight: 800, fontSize: 12 }}
      />
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{ minWidth: '160px', maxWidth: '300px', maxHeight: '700px', overflow: 'auto', padding: 8 }}>{popoverContent}</div>
      </Popover>

      {dropdownVisible && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: 40,
            left: 0,
            backgroundColor: "white",
            border: "1px solid #000",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            width: 160,
            borderRadius: "10px",
          }}
        >
          {getDropdownOptions().map((status) => (
            <div
              key={status}
              onClick={(e) => {
                e.stopPropagation();
                props.action(e, status, props.row);
                setDropdownVisible(false);
              }}
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                fontSize: "12px",
              }}
            >
              {status.toUpperCase()}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const HandleModify = (props) => {
  const currentRowId = props.row.find((_item) => _item?.id === "id").value;
  return (
    <BtnComponent
      text={"MODIFIER"}
      onClick={(e) => {
        e.stopPropagation();
        props.action(e, currentRowId);
      }}
      variant="regular"
      color="#ffff"
      style={{ borderRaduis: 10, width: 100, fontWeight: 800, fontSize: 12 }}
    />
  );
};
