import React, { useState } from "react";
import {
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import FilterComponent from 'components/GlobalInputs/Filters'
import './style.scss'
import { formatHoursToHeuresMinutes } from "utils/helpers";
import { ReactComponent as CalcSvg } from 'assets/calc.svg'
import { fromZonesToGeoJSON } from "views/Admin/Views/Cartes/functions/functionsMap";
import { AutocompleteInput } from "components/GlobalInputs/Select";
import API from "services/axios-config";

const ZonesListModal = ({ isOpen, handleClose, zones }) => {
    const [isSimulationApiIsLoading, setIsSimulationApiIsLoading] = useState(false)
    const [zonesSimulationApiData, setZonesSimulationApiData] = useState({})

    /* FOR FILTERS */
    const [selectedFilters, setSelectedFilters] = useState({
        cities: [],
    });

    const CampaignAutoComplete = ({ handleUpdateFilter }) => {
        const [fetching, setFetching] = useState(false);
        const [items, setItems] = useState([]);
        const [value, setValue] = useState('');

        React.useEffect(() => {
            async function getCampaigns(value) {
                if (value.trim().length > 0) {
                    setFetching(true);
                    try {
                        const res = await API.get(`campaign_autocomplete_by_name?campaign_name=${value}`);
                        setItems(res.data.campaigns.map(campaign => campaign.campaign_name));
                    } finally {
                        setFetching(false);
                    }
                }
            }

            if (value.trim().length > 0) {
                getCampaigns(value);
            }
        }, [value]);

        const handleChange = (value) => {
            setValue(value);
        };

        function handleSelectValue(value) {
          setValue(value)
          handleUpdateFilter(value, true)
      }

        return (
          <div style={{ padding: '40px 20px', overflow: 'auto', margin: '0 0 20px 0' }}>
            <AutocompleteInput label="Campaign" onChange={handleChange} onSelect={handleSelectValue} {...{ items, fetching, value }} />
          </div>
        );
    };

    const filters = [
        { key: 'campaigns', label: 'Campagne', customComponent: <CampaignAutoComplete />, multiple: true }
    ];

    /* END FOR FILTERS */

    const filteredExistingZonesList = React.useMemo(() => {
        return zones.filter(zone => {
            const { campaigns } = selectedFilters;
            const matchesCampaigns = !campaigns?.length || campaigns.includes(zone.campaign_name);

            return matchesCampaigns;
        });
    }, [zones, selectedFilters])

    const handleCalculateClick = async (zone) => {
        if (!isSimulationApiIsLoading && !zonesSimulationApiData?.[zone.id]) {
            const apiUrl = `${process.env.REACT_APP_SIMULATION_API_URL}/api/get_overlap_statistics`;
            const headers = {
              'Content-Type': 'application/json; charset=utf-8',
              Accept: 'application/json',
            };
            try {
              setIsSimulationApiIsLoading(true);
              const template = { type: 'FeatureCollection', features: [] }
              const geoJson = fromZonesToGeoJSON([zone], template, 'zones')
              const response = await fetch(apiUrl, {
                method: 'POST',
                headers,
                body: JSON.stringify({ zones: geoJson?.features ?? [] }),
              });
              if (response.ok) {
                const data = await response.json();
                const housesCount = data?.housing_stats?.type_logements?.P20_MAISON || 0;
                const flatsCount = data?.housing_stats?.type_logements?.P20_APPART || 0;
                const total = housesCount + flatsCount;
                const flatsPercentage = total > 0 ? Math.round((flatsCount / total) * 100) : 0;

                const zoneSimulationApiData = {
                    logements: data?.housing_stats?.logements,
                    flatsPercentage,
                };
                setZonesSimulationApiData(prev => ({ ...prev, [zone.id]: zoneSimulationApiData }))
              } else {
                console.error('Error fetching data:', response.statusText);
              }
            } finally {
              setIsSimulationApiIsLoading(false);
            }
        }
    }

    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                className="zones-list-modal"
            >
                <div className="zones-list-modal-content">
                    <Backdrop
                        sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                        open={isSimulationApiIsLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div className="zones-list-modal-header-container">
                        <div className="zones-list-modal-header-title">LISTE DES ZONES ACTUELLEMENT VISIBLES</div>
                    </div>
                    <TableContainer component={Paper} sx={{ height: '100%', boxShadow: 'none' }}>
                        <div className="add-filters-btn-container">
                            <FilterComponent filters={filters} updateSelectedFilters={setSelectedFilters} />
                        </div>
                        <Table sx={{
                            borderCollapse: 'separate',
                            borderSpacing: '0 8px',
                            '& .MuiTableCell-root': {
                                borderColor: 'black',
                            },
                        }}>
                            <TableHead className="sticky-header">
                                <TableRow>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800 }}>Nom</TableCell>
                                    <TableCell style={{ width: 150, fontSize: 14, fontWeight: 800 }}>Nom de campagne</TableCell>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Nb de logements</TableCell>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>% d&apos;appartements</TableCell>
                                    <TableCell style={{ width: 120, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Nb flyers</TableCell>
                                    <TableCell style={{ width: 100, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Nb d&apos;heures</TableCell>
                                    <TableCell style={{ width: 100, fontSize: 14, fontWeight: 800, textAlign: 'center' }}>Cadence</TableCell>
                                    <TableCell style={{ width: 80, fontSize: 14, fontWeight: 800, textAlign: 'center' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredExistingZonesList.map((row) => {
                                    const cadence = Math.round((row?.total_hours_without_pause > 0 ? row.total_distributed_flyers / row.total_hours_without_pause : 0));
                                    const totalHoursFormatted = row?.total_hours_without_pause ? formatHoursToHeuresMinutes(row.total_hours_without_pause) : '-';
                                    const rowLogementsCount = zonesSimulationApiData?.[row.id]?.logements ?? "À calculer";
                                    const rowFlatsPercentage = typeof zonesSimulationApiData?.[row.id]?.flatsPercentage === 'number' ? `${zonesSimulationApiData?.[row.id]?.flatsPercentage}%` : "À calculer";
                                    return (
                                    <TableRow key={row.id} sx={{ height: 50 }}>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {row.title || `#${row.id} [PAS DE TITRE]`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {row.campaign_name || '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {rowLogementsCount}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {rowFlatsPercentage}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {row.total_distributed_flyers ? `${row.total_distributed_flyers} flyers distribués` : '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    maxWidth: 250,
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {totalHoursFormatted.split('\n').map((line, index) => (
                                                    <div key={index}>{line}</div>
                                                ))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {cadence ? `${cadence} flyers/heure` : '-'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                component="div"
                                                sx={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    WebkitLineClamp: 2,
                                                    maxWidth: 250,
                                                    textOverflow: 'ellipsis',
                                                    wordWrap: 'break-word',
                                                    fontSize: 12,
                                                    fontWeight: 500,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <div style={{ width: '40px', height: '40px', margin: 'auto', cursor: zonesSimulationApiData?.[row.id] ? 'not-allowed' : 'pointer' }} onClick={() => handleCalculateClick(row)}>
                                                    <CalcSvg fill={zonesSimulationApiData?.[row.id] ? "#ccc" : "white"} width="100%" height="100%" />
                                                </div>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Modal>
        </>
    );
};

export default ZonesListModal;
