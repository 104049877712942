/* eslint-disable camelcase */
import axios from "axios";

const REACT_APP_BACK_API = process.env.REACT_APP_BACK_API;
const API = axios.create({
  crossDomain: true,
  withCredentials: true,
  baseURL: `${REACT_APP_BACK_API}/admin_api`,
});
// const API = axios.create({ crossDomain: true, withCredentials: true, baseURL: "https://flying-for-you-backend.osc-fr1.scalingo.io/admin_api" });

const set_headers = () => {
  API.defaults.headers.Accept = "application/json";
  API.defaults.headers["Content-Type"] = "application/json";
  if (localStorage.getItem("Authorization")) {
    API.defaults.headers.Authorization = localStorage.getItem("Authorization");
  }
};

set_headers();

export default API;
