import { useEffect } from 'react'

const SCROLL_THRESHOLD_PERCENTAGE = 0.2 // 20%

const debounce = (fn, delay) => {
  let timeoutId

  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => fn(...args), delay)
  }
}

const useInfiniteScroll = (callback, containerRef, isLoading, hasMore, reverse = false) => {
  useEffect(() => {
    const handleScroll = async () => {
      const container = containerRef.current
      if (container && hasMore && !isLoading) {
        const scrollThreshold = (container.scrollHeight * SCROLL_THRESHOLD_PERCENTAGE)
        if (reverse) {
          if (container.scrollTop <= scrollThreshold) await callback()
        } else {
          if (container.scrollTop + container.clientHeight >= (container.scrollHeight - scrollThreshold)) await callback()
        }
      }
    }

    const debouncedHandleScroll = debounce(() => handleScroll(), 100)

    const handleScrollListener = () => {
      debouncedHandleScroll()
    }

    if (containerRef.current) {
      containerRef.current.removeEventListener('scroll', handleScrollListener)
      containerRef.current.addEventListener('scroll', handleScrollListener)
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScrollListener)
      }
    }
  }, [callback, hasMore, isLoading, containerRef, reverse])
}

export default useInfiniteScroll
