/* eslint-disable react/prop-types */
import React from 'react'
import InputImage from '../Inputs/InputFileImage/inputImage'
import logoFFY from 'assets/FFY-logo.png'

const IntroductionLogo = ({ data, setLogo, logo, id }) => {
  return (
    <div className='logos'>
      <div className='ffylogo'>
        <img src={logoFFY} alt="logoffy" />
      </div>
      <div className="divider"></div>
      <InputImage useCaseSection={'header'} id={id} img={logo?.img || data?.header_url }
        inputID="logo-usecase" className="usecase-logo" uploadclass="upload-logo"
        setState={(param) => {
          setLogo({ img: param })
        }}
      />
    </div>
  )
}

export default IntroductionLogo
