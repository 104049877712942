/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import { toWKT, toWKT2 } from 'views/Admin/Views/Cartes/functions/functionsMap'
import MemoizedPolygonComponent from '../../slots/polygons'
import MemoizedNewPolylineComponent from '../../slots/newPolyline'
import API from 'services/axios-config'
import MemoizedPointComponent from '../../slots/point'
import React, { useEffect, useState, useCallback } from 'react'

const MapsDraw = ({ type, item, handleActivePolyline, handleActivePolygon, setInitialsListFormatted, handleClickMarker, currentPrestation, activePolygon, activePolyline }) => {
  const [polylineHistory, setPolylineHistory] = useState([])
  const [polygonHistory, setPolygonHistory] = useState([])

  const onPolygonChanged = (e, currentMovement) => {
    if ((currentMovement === 'delete') || (currentMovement === 'modify')) {
      API.patch(`${type}/${item?.id}`, { polygon: toWKT(item?.ref?.current) })
      if (polygonHistory.length === 0 || JSON.stringify(toWKT(item?.ref?.current)) !== JSON.stringify(polygonHistory[polygonHistory.length - 1])) {
        setPolygonHistory(prev => [...prev, toWKT(item?.ref?.current)])
      }
      setInitialsListFormatted(prev =>
        prev.map(_item => {
          if (_item?.id === item?.id && item?.type === type) { return { ...item, polygon: toWKT(item?.ref?.current) } } else { return _item }
        })
      )
    }
  }

  const onPolylineChanged = useCallback((e, currentMovement) => {
    if ((currentMovement === 'delete') || (currentMovement === 'modify')) {
      API.patch(`${type}/${item?.id}`, { line: toWKT2(item?.ref?.current) })
      if (polylineHistory.length === 0 || JSON.stringify(toWKT2(item?.ref?.current)) !== JSON.stringify(polylineHistory[polylineHistory.length - 1])) {
        setPolylineHistory(prev => [...prev, toWKT2(item?.ref?.current)])
      }
      setInitialsListFormatted(prev =>
        prev.map(_item => {
          if (_item?.id === item?.id && item?.type === type) { return { ...item, line: toWKT2(item?.ref?.current) } } else { return _item }
        })
      )
    }
  }, [polylineHistory, item])

  const handleActivePolygonParams = (event, item_ref, itemId) => {
    handleActivePolygon(event, item_ref, itemId, 'initials')
  }

  const handleActivePolylineParams = (event, item_ref, itemId) => {
    handleActivePolyline(event, item_ref, itemId, 'initials')
  }

  const handleUndo = useCallback(() => {
    if (activePolyline && polylineHistory.length > 1) {
      const previousItem = polylineHistory[polylineHistory.length - 2]

      API.patch(`${type}/${item?.id}`, { line: previousItem })
        .then(() => {
          setInitialsListFormatted((prev) =>
            prev.map((_item) =>
              _item?.id === item?.id && item?.type === type
                ? { ...item, line: previousItem }
                : _item
            )
          )
          if (polylineHistory.length > 1) { setPolylineHistory((prev) => prev.slice(0, -1)) }
        })
        .catch((error) => {
          console.error('Undo API error:', error)
        })
    } else if (activePolygon && polygonHistory.length > 1) {
      const previousItem = polygonHistory[polygonHistory.length - 2]
      API.patch(`${type}/${item?.id}`, { polygon: previousItem })
        .then(() => {
          setInitialsListFormatted((prev) =>
            prev.map((_item) =>
              _item?.id === item?.id && item?.type === type
                ? { ...item, polygon: previousItem }
                : _item
            )
          )
          if (polygonHistory.length > 1) { setPolygonHistory((prev) => prev.slice(0, -1)) }
        })
        .catch((error) => {
          console.error('Undo API error:', error)
        })
    }
  }, [polylineHistory, item, activePolyline, activePolygon, polygonHistory])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        handleUndo()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleUndo])

  if (type === 'zones') {
    if (item.polygon) {
      const isSelected = activePolygon && JSON.stringify(activePolygon?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      }))) === JSON.stringify(item.ref?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      })))

      return <MemoizedPolygonComponent handleActivePolygon={handleActivePolygonParams} {...{ onPolygonChanged, item }} isSelected={isSelected ?? false} />
    } else if (item.line) {
      const isSelected = activePolyline && JSON.stringify(activePolyline?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      }))) === JSON.stringify(item.ref?.current?.getPath().getArray().map((latLng) => ({
        lat: latLng.lat(),
        lng: latLng.lng()
      })))
      return <MemoizedNewPolylineComponent handleActivePolyline={handleActivePolylineParams} {...{ onPolylineChanged, item }} isSelected={isSelected ?? false} />
    }
  }
  if (type === 'spots') {
    return <MemoizedPointComponent {...{ item, currentPrestation, handleClickMarker }} />
  }
}

export default MapsDraw
