/* eslint-disable */
import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BtnComponent from 'components/GlobalInputs/Btn';
import { Delete, updateSmInvoice } from './functions';
import { enqueueSnackbar } from 'notistack';


const DeleteModal = ({ open, onClose, invoiceDetailId, setRows, rows, updateTotals, smInvoiceId }) => {
    const handleDelete = () => {
        Delete(invoiceDetailId)
            .then((res) => {
                const updatedRows = rows.filter(row => row.id !== invoiceDetailId);
                setRows(updatedRows);
                updateTotals(updatedRows);
                updateSmInvoice(smInvoiceId, {
                    invoice_details: updatedRows,
                })
            })
            .catch((err) => enqueueSnackbar(err.message, { variant: "error" }))
            .finally(onClose)
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={textStyle}>
                        TU ES SUR LE POINT DE SUPPRIMER UNE LIGNE DE LA FACTURE DU SM !
                    </Typography>
                    <CloseIcon sx={{ color: 'black' }} onClick={onClose} />
                </div>
                <Typography id="modal-modal-title" style={contentText}>
                    Il ne sera donc pas payé ce mois-ci pour cette mission, la mission sera toujours visible sur la page “Vue Missions à Valider” et devra être traitée le prochain mois !
                    <br />
                    Es-tu-sûr ?
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <BtnComponent text={'CONFIRMER'} variant="rounded" onClick={handleDelete} style={buttonStyle} />
                    <BtnComponent text={'ANNULER'} variant="rounded" onClick={() => onClose()} color='red' style={buttonStyle} />
                </div>

            </Box>
        </Modal>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh', overflowY: 'auto'
};

const textStyle = {
    color: 'black',
    fontWeight: '800',
    fontSize: '20px',
}
const contentText = {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'left',
    color: 'black',
    fontWeight: '800',
    fontSize: '14px',
}
const buttonStyle = {
    borderRaduis: 10,
    width: 130,
    fontWeight: 800,
    fontSize: 12,
}
export default DeleteModal;
